import React, {useEffect, useState} from 'react'
import axios from '@plugins/axios'
import Page from '@modules/page'
import {ActionList, Badge, Button, Card, Layout, Popover} from '@shopify/polaris'
import {initialData} from "./initialData"
import {showToast} from "@plugins/toast"
import {showConfirm} from "@plugins/confirm"
import AddModal from './addModal'

const Integration = () => {
	const [integrations, setIntegrations] = useState([])
	const [popoverActive, setPopoverActive] = useState(false)
	const [platformState, setPlatformState] = useState({platform: "", api_key: "", label: "", helper_url: ""})
	const [active, setActive] = useState(false)
	const [saving, setSaving] = useState(false)

	useEffect(() => {
		const getData = async () => {
			try {
				const data = await axios.get("/api/plugin/integration")
				if (data.ok && data.payload) {
					setIntegrations(data.payload)
				}
			} catch (error) {
				console.log(error)
			}
		}
		getData()
	}, [])

	const handleAction = (platform, label, helper_url, api_key) => {
		setActive(true)
		setPlatformState({...platformState, platform: platform, label: label, helper_url: helper_url, api_key: api_key})
	}

	const togglePopoverActive = () => setPopoverActive((popoverActive) => !popoverActive)
	const toggleModal = () => {
		setActive(prev => !prev)
		setPlatformState({...platformState, api_key: ""})
	}

	const handleSave = () => {
		setSaving(true)
		const postData = async () => {
			await axios.post("/api/plugin/integration/verify", {
				platform: platformState.platform, api_key: platformState.api_key
			}).then(async (data) => {
					if (data.ok) {
						if (integrations.every(item => item._id !== data.payload._id)) {
							setIntegrations([...integrations, data.payload])
						}
						setActive(false)
						setPlatformState({...platformState, api_key: ""})
						return showToast({
							message: "Update Successfully!"
						})
					}
				}
			).catch(
				(error) => {
					setActive(true)
					return showToast({
						message: error.message,
						error: true
					})
				}
			).finally(() => setSaving(false))
		}
		postData()
	}
	const handleEdit = (platform) => {
		setActive(true)
		const data = integrations.find(data => data.platform === platform.name)
		setPlatformState({
			...platformState,
			platform: platform.name,
			label: platform.label,
			api_key: data?.api_key || '',
			helper_url: platform.helper_url
		})
	}
	const handleDelete = (e, platform) => {
		e.preventDefault()
		e.stopPropagation()
		showConfirm({
			title: `Delete this platform?`,
			message: 'This can’t be undone.',
			danger: true,
			confirm: 'Delete'
		}).then(res => {
			if (res) {
				const filterPlatform = integrations.find(item => item.platform === platform)
				if (!filterPlatform) return
				axios.delete(`/api/plugin/integration/${filterPlatform._id}`)
				setIntegrations(integrations.filter(item => item.platform !== platform))
				return showToast({
					message: "Delete Successfully!"
				})
			}
		})
	}
	return <Page title="Integrations" primaryAction={
		<Popover
			active={popoverActive}
			activator={(
				<Button onClick={togglePopoverActive} disclosure>
					Add integration
				</Button>
			)}
			onClose={togglePopoverActive}
		>
			<ActionList
				actionRole="menuitem"
				items={[{
					content: 'Klaviyo',
					onAction: () => handleAction("klaviyo", "Klaviyo", "https://help.klaviyo.com/hc/en-us/articles/115005062267-How-to-Manage-Your-Account-s-API-Keys", "")
				},
					{
						content: 'Mailchimp',
						onAction: () => handleAction("mailchimp", "Mailchimp", "https://mailchimp.com/help/about-api-keys/", "")
					},
					{
						content: 'Omnisend',
						onAction: () => handleAction("omnisend", "Omnisend", "https://support.omnisend.com/en/articles/1061890-generating-api-key", "")
					},
					{
						content: 'Sendgrid',
						onAction: () => handleAction("sendgrid", "Sendgrid", "https://docs.sendgrid.com/ui/account-and-settings/api-keys", "")
					}]}
			/>
		</Popover>
	}>
		<Layout>
			<Layout.Section>
				<div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: '1.6rem'}}>
					{initialData.map((item, key) => {
						let integrationCheck = integrations.find(data => data.platform === item.name)
						return (
							<div
								style={{
									width: "100%",
									cursor: "pointer",
									position: "relative"
								}}
								onClick={() => handleEdit(item)}
								key={key}>
								<Card
									sectioned
									title={<Badge
									status={integrationCheck ? 'success' : 'critical'}>{integrationCheck ? "Integrated" : "Not Integrated"}</Badge>}
									actions={integrationCheck ? [{content: 'Delete', destructive: true, onAction: e => handleDelete(e, item.name)}] : undefined}
								>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column"
										}}>
										<img src={item.img} alt={item.name} style={{maxWidth: "70px"}}/>
										<p style={{marginTop: "20px"}}>{item.label}</p>
									</div>
								</Card>
							</div>
						)
					})}
				</div>
			</Layout.Section>
		</Layout>
		{active && <AddModal handleChange={setPlatformState} platformState={platformState} handleSave={handleSave} handleToggle={toggleModal} saving={saving}/>}
	</Page>
}

export default Integration
