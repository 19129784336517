import API from "@helpers/api"
import { formatDate } from "@helpers/utils"
import Page from '@modules/page'
import { Skeleton } from "@modules/skeleton"
import { CancelToken } from "@plugins/axios"
import { showConfirm } from "@plugins/confirm"
import { showToast } from "@plugins/toast"
import { navigate, useParams } from "@reach/router"
import { ContextualSaveBar, Layout, PageActions } from "@shopify/polaris"
import { isEqual, omit } from "lodash"
import { useCallback, useEffect, useState } from "react"
import DiscardModal from "../../components/discardModal"
import CustomerCard from "./components/customer"
import ProductCard from "./components/product"
import { ContextProvider, useStore } from "./container"
const EditBIS = () => {
  return (
    <ContextProvider>
      <EditBISView />
    </ContextProvider>
  )
}

const EditBISView = () => {
  const [ready, setReady] = useState(false)
  const [saving, setSaving] = useState(false)
  const { pageID } = useParams()
  const { state, dispatch } = useStore()
  const source = CancelToken.source()

  const { title, _id, createdAt, customer } = state
  const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState({})

	const isEqualState = isEqual(omit(state, ['save_to', "active", 'isEqualState', 'product', 'updatedAt', 'show_name', 'show_note', 'show_phone_number', 'show_signup_newsletter']), omit(prevState, ['updatedAt']))

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

  useEffect(() => {
    const handleReloadPage = (e) => {
      e.preventDefault()
      e.returnValue = ""
    }
    if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
    return () => window.removeEventListener("beforeunload", handleReloadPage)
  }, [isEqualState])

  useEffect(() => {
    let result
    const fetchData = async () => {
      try {
        result = await API.getDetail('back-in-stock', pageID, source.token)
        console.log('Data details', result)
        if (result && result.ok && result.payload) {
          dispatch({ type: 'setData', payload: result.payload })
          setPrevState(result.payload)
          setReady(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
    return () => source.cancel()
  }, [pageID])
  const handleSave = async () => {
    setSaving(true)
    API.update('back-in-stock', {
      ...state,
    }).then((res) => {
      if (res.ok) {
        setPrevState({
					...prevState,
					...res.payload,
          customer: customer
				})
        return showToast({
          message: 'Updated successfully'
        })
      }
    }).finally(() => setSaving(false))
  }

  const handleDelete = () => {
    showConfirm({
      title: `Delete ${title}?`,
      message: 'This can’t be undone.',
      danger: true,
      confirm: 'Delete'
    }).then(res => {
      if (res) {
        API.bulkDelete('bundle', [_id]).then((res) => {
          console.log(res)
          if (res.ok) {
            showToast({ message: 'Subscription(s) deleted' })
            return navigate('/back-in-stock')
          } else {
            showToast({
              message: 'Cannot delete, please try again',
              error: true
            })
          }
        })
      }
    })
  }
  return ready ? (
    <Page
      title={title}
      subtitle={formatDate(createdAt, true)}
      breadcrumbs={[{ content: 'Subscriptions', onAction: () => isEqualState ? navigate('/back-in-stock') : handleModalChange() }]}
    >
      {/*{!isEqualState && <ContextualSaveBar*/}
      {/*  message="Unsaved changes"*/}
      {/*  saveAction={{*/}
      {/*    onAction: handleSave,*/}
      {/*    loading: saving,*/}
      {/*    disabled: false,*/}
      {/*  }}*/}
      {/*  discardAction={{*/}
      {/*    onAction: () => handleModalChange(),*/}
      {/*  }}*/}
      {/*/>}*/}
      <Layout>
        <Layout.Section>
          <ProductCard />
        </Layout.Section>
        <Layout.Section oneThird>
          <CustomerCard />
        </Layout.Section>
      </Layout>
      <PageActions
        // primaryAction={{
        //   content: 'Save',
        //   loading: saving,
        //   onAction: handleSave
        //
        // }}
        secondaryActions={[
          {
            content: 'Delete',
            destructive: true,
            onAction: handleDelete
          },
        ]}
      />
      {/*<DiscardModal */}
			{/*	activeModal={activeModal}*/}
			{/*	handleModalChange={handleModalChange}*/}
      {/*  backLink="/back-in-stock"*/}
			{/*/>*/}
    </Page>
  ) : <Skeleton />
}

export default EditBIS
