import {createContext, useContext, useReducer} from 'react'

export const defaultLocaleData = {
	date_format: 'MM/dd/yyyy',
	discount_summary: "You will get <strong>{discount_value} OFF</strong> on each product",
	discount_title: "SPECIAL OFFER",
	free: "FREE",
	add_button: "Add",
	added_button: "Added",
	bundle_button: 'Add selected item(s)',
	bundle_select: 'Select',
	bundle_selected: 'Selected',
	bundle_total: 'Total price',
	bundle_saved: 'Saved',
	bundle_this_item: 'This item',
	qty_discount_title: '{item_count} item(s) get {discount_value} OFF',
	qty_discount_note: 'on each product',
	prepurchase_title: 'Frequently bought with "{product_title}"',
	prepurchase_added: 'You just added',
	incart_title: 'Customers also bought with "{product_title}"',
	sizechart_button: 'Size chart',
	field_name: 'Enter your name',
	field_email: 'Enter your email',
	field_birthday: 'Date of birth',
  discount_noti: '* Discount will be calculated and applied at checkout',
  fox_discount_noti: `* You are entitled to 1 discount offer of <span>{price}</span> (<span>{discount_title}</span>). This offer <b>can't be combined</b> with any other discount you add here!`,
	bis_open: "Notify me when available",
	bis_heading: "Back in stock alert 📬",
	bis_desc: "We will send you a notification as soon as this product is available again.",
	bis_submit: "Notify me",
	bis_email: "Your email",
	bis_name: "Your name",
	bis_phone: "Your phone number",
	bis_note: "Your note",
	bis_signup: "Email me with news and offers",
  bis_thankyou: "Thank you! We'll send you an email when this product is available!",
	preorder_discount_title: "🎁 Preorder now to get <strong>{discount_value} OFF</strong>",
	preorder_shipping_note: "🚚 Item will be delivered on or before <strong>{eta}</strong>",
	preorder_end_note: "🔥 Preorder will end at <strong>{end_time}</strong>",
}

const Context = createContext(defaultLocaleData)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, defaultLocaleData)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
