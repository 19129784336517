import { navigate } from "@reach/router"
import Page from '@modules/page'
import { Layout, Card, FormLayout, TextField, Checkbox, ContextualSaveBar, RangeSlider } from "@shopify/polaris"
import { ContextProvider, useStore } from "./container"
import { useCallback, useEffect, useState } from "react"
import API from "@helpers/api"
import { showToast } from "@plugins/toast"
import { Skeleton } from "@modules/skeleton"
import { CancelToken } from "@plugins/axios"
import { isEqual, pick } from "lodash"
import DiscardModal from "../../components/discardModal"

const CartCountDown = () => {
	return (
		<ContextProvider>
			<CartCountDownView />
		</ContextProvider>
	)
}

const CartCountDownView = () => {
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const { state, dispatch } = useStore()
	const source = CancelToken.source()
	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState(state)

	const isEqualState = isEqual(
		pick(state, ['active', 'duration', 'message', 'message_out', 'repeat', 'show_on_drawer']),
		pick(prevState, ['active', 'duration', 'message', 'message_out', 'repeat', 'show_on_drawer'])
	)

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

	useEffect(() => {
		const handleReloadPage = (e) => {
		  e.preventDefault()
		  e.returnValue = ""
		}
		if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
		return () => window.removeEventListener("beforeunload", handleReloadPage)
	  }, [isEqualState])

	useEffect(() => {
		let result
		const fetchData = async () => {
			try {
				result = await API.getList('cart-countdown', {}, source.token)
				console.log('Data details', result)
				if (result && result.ok) {
					dispatch({ type: 'setData', payload: result.payload })
					setReady(true)
				}
				if (result && result.ok && result.payload) {
					setPrevState({ ...prevState, ...result.payload })
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
		return () => source.cancel()
	}, [])
	const handleSave = async () => {
		setSaving(true)
		API.updateByShop('cart-countdown', {
			...state
		}).then((res) => {
			if (res.ok) {
				setPrevState({
					...prevState,
					...res.payload,
				})
				return showToast({
					message: 'Updated successfully'
				})
			}
		}).finally(() => setSaving(false))
	}
	const { active, message, show_on_drawer, duration, message_out, repeat } = state

	const handleChange = (key, value) => {
		dispatch({ type: 'setData', payload: { [key]: value } })
	}

	const suffixStyles = {
		minWidth: '24px',
		textAlign: 'right',
	}
	return ready ? (
		<Page
			title="Cart countdown"
			breadcrumbs={[{ content: 'Cart countdown', onAction: () => isEqualState ? navigate('/boost-conversion') : handleModalChange() }]}
			subtitle={'Add scarcity and encourage customers to checkout by showing a countdown timer in their cart.'}
		>
			{!isEqualState && <ContextualSaveBar
				saveAction={{
					onAction: handleSave,
					loading: saving,
					disabled: false,
				}}
				discardAction={{
					onAction: () => navigate('/boost-conversion'),
				}}
			/>}

			<Layout>
				<Layout.Section oneHalf>
					<Card>
						<Card.Section>
							<FormLayout>
								<Checkbox
									label={'Active'}
									checked={active}
									onChange={v => handleChange('active', v)}
								/>
								<Checkbox
									label={'Show on cart drawer'}
									checked={show_on_drawer}
									onChange={v => handleChange('show_on_drawer', v)}
								/>
								<Checkbox
									label={'Repeat after ends'}
									checked={repeat}
									onChange={v => handleChange('repeat', v)}
								/>
								<FormLayout.Group>
									<RangeSlider
										value={duration + ''}
										label={'Duration'}
										onChange={v => handleChange('duration', v)}
										helpText={'Time to countdown'}
										output
										max={10}
										min={1}
										step={1}
										suffix={<p style={suffixStyles}>{duration} minutes</p>}
									/>
									<div />
								</FormLayout.Group>
							</FormLayout>
						</Card.Section>
						<Card.Section>
							<FormLayout>
								<TextField
									value={message}
									label={'Motivational message'}
									onChange={v => handleChange('message', v)}
									helpText={'{{timer}} is countdown timer.'}
								/>
								{!repeat && <TextField
									value={message_out}
									label={'Time out message'}
									onChange={v => handleChange('message_out', v)}
									helpText={'Show after times up'}
								/>}
							</FormLayout>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
			<DiscardModal
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink='/boost-conversion'
			/>
		</Page>
	) : <Skeleton />
}

export default CartCountDown
