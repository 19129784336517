import {Card, FormLayout, TextField} from "@shopify/polaris"
import {useStore} from "../container"

const TitleCard = ({error}) => {
	const {state, dispatch} = useStore()
	const {title, offer_heading} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card sectioned>
			<FormLayout>
				<TextField
					label={'Offer name'}
					value={title}
					placeholder={'Enter a offer name'}
					onChange={v => handleChange('title', v)}
					error={error.includes('title')}
				/>
			</FormLayout>
		</Card>
	)
}

export default TitleCard
