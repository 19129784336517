import {Card, FormLayout, ChoiceList, TextStyle} from "@shopify/polaris"
import {useStore} from "../container"
import ResourcePicker from "@components/resourcePicker"

const RecommendedProducts = ({error}) => {
	const {state, dispatch} = useStore()
	const {recommend_to, recommended_collection, recommended_products} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card>
			<Card.Section subdued title="Recommended products">
				<TextStyle variation={'subdued'}>These products will appear under the target product in cart page.</TextStyle>
			</Card.Section>
			<Card.Section subdued>
				<FormLayout>
					<ChoiceList
						choices={[
							{label: 'Specific products', value: 'specific_products'},
							{label: 'Specific collection', value: 'specific_collection'},
						]}
						selected={recommend_to}
						onChange={v => handleChange('recommend_to', v[0])}
					/>
					{recommend_to === 'specific_products' && (
						<ResourcePicker
							label={'Specific products'}
							labelHidden
							isMulti
							resourceType={'product'}
							value={recommended_products}
							onChange={v => handleChange('recommended_products', v)}
							isClearable
							error={error.includes('recommended_products')}
							placeholder={'Search products'}
							maxLength={3}
							helpText={'Maximum 3 products'}
						/>
					)}

					{recommend_to === 'specific_collection' && (
						<ResourcePicker
							label={'Specific collection'}
							labelHidden
							resourceType={'collection'}
							value={recommended_collection}
							onChange={v => handleChange('recommended_collection', v)}
							isClearable
							error={error.includes('recommended_collection')}
							placeholder={'Search collection'}
						/>
					)}
				</FormLayout>
			</Card.Section>
		</Card>
	)
}

export default RecommendedProducts
