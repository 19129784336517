import {createContext, useContext, useReducer} from 'react'
export const initialState = {
	active: true,
	created_at: '',
	updated_at: '',
	title: '',
	heading: '',
	bundle_heading: 'Frequently bought together',
	products: [],
	product: '',
	layout: 'layout-1',
	position: 'inside',
	show_target_only: false,
	target_product: '',
	active_discount: false,
	discount: {
		value: 10,
		type: 'PERCENTAGE'
	}
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
