import {Card, TextStyle, FormLayout, TextField, TextContainer, Link, Button} from "@shopify/polaris"
import {useStore} from "./container"
import Table from "@modules/table"
import ColorPicker from "@components/colorPicker"
import {useAuthStore} from "../../container/Auth"

const PrizesList = () => {
	const {state, dispatch} = useStore()
	const {prizes} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}
	let totalWeight = 0
	prizes?.map(p => totalWeight += p.chance)

	console.log(totalWeight, 'totalWeight')

	const {shop_domain} = useAuthStore()
	return (
		<Card title={'Prizes list'}>
			<Card.Section>
				<FormLayout>
					<TextContainer>
						<TextStyle variation={"subdued"}>
							Make sure that discount codes are correctly set up in Shopify.
							Once they are created, just paste the codes in the "Discount code" fields.&nbsp;
							<Link url="https://help.shopify.com/en/manual/discounts/create-discount-codes" external>Learn how to create discount codes with Shopify.</Link>
						</TextStyle>
					</TextContainer>
					<Button size={'slim'} url={`https://${shop_domain}/admin/discounts`} external>View discount codes</Button>
					<Table bordered>
						<thead>
						<tr>
							<th>#</th>
							<th>Prize name</th>
							<th>Discount code</th>
							<th width={30}>Color</th>
							<th>Chance to win</th>
						</tr>
						</thead>
						<tbody>
						{prizes && prizes.length > 0 && prizes.map((item, index) => {
							const {name, code, bg_color, chance} = item
							return (
								<tr key={index}>
									<td>{index + 1}</td>
									<td>
										<TextField
											value={name}
											onChange={v => {
												prizes[index] = {...item, name: v}
												handleChange('prizes', [...prizes])
											}}
										/>
									</td>
									<td>
										<TextField
											placeholder={'10%OFF'}
											value={code}
											onChange={v => {
												prizes[index] = {...item, code: v}
												handleChange('prizes', [...prizes])
											}}
										/>
									</td>
									<td>
										<ColorPicker
											value={bg_color}
											onChange={v => {
												prizes[index] = {...item, bg_color: v}
												handleChange('prizes', [...prizes])
											}}
											disableAlpha
										/>
									</td>
									<td width={140}>
										<TextField
											placeholder={'Weight'}
											value={chance + ''}
											type={'number'}
											min={0}
											onChange={v => {
												prizes[index] = {...item, chance: Number(v)}
												handleChange('prizes', [...prizes])
											}}
											connectedRight={<div className={'flex font-bold'} style={{width: '50px', height: '100%', alignItems: 'center'}}>{(100/totalWeight*chance).toFixed(2)}%</div>}
										/>
									</td>
								</tr>
							)
						})}
						</tbody>
					</Table>
					<p>Each prize will be displayed 2 times</p>
				</FormLayout>
			</Card.Section>
		</Card>
	)
}

export default PrizesList
