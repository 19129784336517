import React from 'react'
import {Router} from "@reach/router"
import CustomersList from "@pages/customers"
import EditCustomer from "@pages/customers/edit"

export default function CustomerPages() {
	return (
		<Router>
			<CustomersList path="/" />
			<EditCustomer path="/:pageID" />
		</Router>
	)
}
