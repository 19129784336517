import API from "@helpers/api"
import Page from "@modules/page"
import { Skeleton } from "@modules/skeleton"
import { CancelToken } from "@plugins/axios"
import { showToast } from "@plugins/toast"
import { navigate } from "@reach/router"
import {
  Banner, Card, Checkbox, ContextualSaveBar, FormLayout, Layout, Link, PageActions, TextContainer, TextField
} from "@shopify/polaris"
import { useCallback, useEffect, useState } from "react"
import DiscardModal from "../../components/discardModal"
import { useAuthStore } from "../../container/Auth"
import { ContextProvider, useStore } from "./container"
import { isEqual, pick } from "lodash"
const CartGoal = () => {
  return (
    <ContextProvider>
      <CartGoalView />
    </ContextProvider>
  )
}

const CartGoalView = () => {
  const [ready, setReady] = useState(false)
  const [saving, setSaving] = useState(false)
  const { state, dispatch } = useStore()
  const source = CancelToken.source()
  const [activeModal, setActiveModal] = useState(false);
  const [prevState, setPrevState] = useState(state)
  const isEqualState = isEqual(
    pick(state, ['active', 'disable_foxkit_discount', 'goal_amount', 'message', 'message_reached']),
    pick(prevState, ['active', 'disable_foxkit_discount', 'goal_amount', 'message', 'message_reached'])
  )

  useEffect(() => {
    const handleReloadPage = (e) => {
      e.preventDefault()
      e.returnValue = ""
    }
    if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
    return () => window.removeEventListener("beforeunload", handleReloadPage)
  }, [isEqualState])

  useEffect(() => {
    let result
    const fetchData = async () => {
      try {
        result = await API.getList('cart-goal', {}, source.token)
        console.log('Data details', result)
        if (result && result.ok) {
          dispatch({ type: 'setData', payload: result.payload })
          if (result.payload) {
            setPrevState(result.payload)
          }
          setReady(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
    return () => source.cancel()
  }, [])
  const handleSave = async () => {
    setSaving(true)
    API.updateByShop('cart-goal', {
      ...state,
      goal_amount: Number(state.goal_amount)
    }).then((res) => {
      if (res.ok) {
        setPrevState({ ...prevState, ...res.payload })
        return showToast({
          message: 'Updated successfully'
        })
      }
    }).finally(() => setSaving(false))
  }

  const { message, active, message_reached, goal_amount, disable_foxkit_discount } = state
  const { shop, currency_symbol = '$', discount_apply_by } = useAuthStore()

  const handleChange = (key, value) => {
    dispatch({ type: 'setData', payload: { [key]: value } })
  }

  const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);
  return ready ? (
    <Page
      title="Free shipping goal"
      subtitle={'You can set goal for cart that a user will get Free shipping once a specific cart goal amount reached.'}
      breadcrumbs={[{
        content: 'Boost conversion', onAction: () => {
          isEqualState ? navigate("/boost-conversion") : handleModalChange()
        }
      }]}
    >
      {!isEqualState && <ContextualSaveBar
        saveAction={{
          onAction: handleSave,
          loading: saving,
          disabled: false,
        }}
        discardAction={{
          onAction: () => {
            handleModalChange()
          },
        }}
      />}
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>
              <FormLayout>
                <Checkbox
                  label={'Active'}
                  checked={active}
                  onChange={v => handleChange('active', v)}
                />
                <FormLayout.Group>
                  <TextField
                    label={'Goal amount'}
                    value={goal_amount + ''}
                    onChange={v => handleChange('goal_amount', Number(v))}
                    type={'number'}
                    min={0}
                    prefix={currency_symbol}
                  />
                  <div />
                  <div />
                </FormLayout.Group>
                <Checkbox
                  label={'Give free shipping by shipping rates'}
                  checked={disable_foxkit_discount}
                  onChange={v => handleChange('disable_foxkit_discount', v)}
                  helpText={'Required when discount method is Discount code'}
                />
                <div style={{ marginTop: 10 }}>
                  {disable_foxkit_discount ? (
                    <TextContainer>
                      <Banner>
                        <p>You need to set up a free <Link url={`http://${shop}/admin/settings/shipping`} external>shipping rates</Link> based on order value matching the goal amount entered above.</p>
                      </Banner>
                    </TextContainer>
                  ) : (
                    <TextContainer>
                      <Banner>
                        <p>No destination restrictions, shipping costs will be free when the order price reaches the goal amount.</p>
                      </Banner>
                    </TextContainer>
                  )}
                </div>
              </FormLayout>
            </Card.Section>
            <Card.Section title={'Messages'}>
              <FormLayout>
                <TextField
                  label={'Motivational message'}
                  value={message}
                  onChange={v => handleChange('message', v)}
                  helpText={'{{variable}} is left amount to reach the goal.'}
                />
                <TextField
                  label={'Reached message'}
                  value={message_reached}
                  onChange={v => handleChange('message_reached', v)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <PageActions
        primaryAction={{
          content: 'Save',
          onAction: handleSave,
          loading: saving
        }}
      />
      <DiscardModal
        activeModal={activeModal}
        handleModalChange={handleModalChange}
        backLink='/boost-conversion'
      />
    </Page>

  ) : <Skeleton />
}

export default CartGoal
