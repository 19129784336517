/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import UserLayout from '@modules/userLayout';
import { showToast } from '@plugins/toast';
import { Link, navigate } from '@reach/router';
import { forgotPasswordService } from '@services/user';
import { Button, DisplayText, Form, FormLayout, TextContainer, TextField } from '@shopify/polaris';
import { useMemo, useState } from 'react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const handleSubmit = () => {};

  const handleEmailChange = (value) => {
    setFormError('');
    setEmail(value);
  };

  const disabled = useMemo(() => {
    return !email;
  }, [email]);

  const handleSendMail = async () => {
    try {
      setLoading(true);

      await forgotPasswordService(email);

      setLoading(false);

      setEmailSent(true);

      showToast({
        message: 'Email sent!',
      });
    } catch (error) {
      setLoading(false);
      setFormError(error?.message);
    }
  };

  return (
    <UserLayout title={'Recover password'}>
      <div className="form-container">
        {!emailSent ? (
          <>
            <div className="form-header">
              <TextContainer>
                <DisplayText size={'extraLarge'}>
                  <strong>Forgot your password?</strong>
                </DisplayText>
                <p className={'Polaris-DisplayText Polaris-DisplayText--sizeSmall Polaris-DisplayText--mobile'}>
                  Please enter the email address associated <br />
                  with your <strong>FoxHome</strong> account.
                </p>
              </TextContainer>
            </div>
            <div className="form-content">
              <Form onSubmit={handleSubmit}>
                <FormLayout>
                  <TextField
                    placeholder={'Enter your email'}
                    error={formError}
                    value={email}
                    onChange={handleEmailChange}
                    label="Email address"
                    type="email"
                    autoComplete="email"
                  />
                  <Button disabled={disabled} primary loading={loading} fullWidth={true} size="large" onClick={handleSendMail}>
                    Recover password
                  </Button>
                  <div className="form-footer">
                    <DisplayText size={'small'}>
                      Back to{' '}
                      <Link to="/login">
                        <u>Login</u>
                      </Link>
                    </DisplayText>
                  </div>
                  {/*<InlineError message={formError} />*/}
                </FormLayout>
              </Form>
            </div>
          </>
        ) : (
          <>
            <div className="form-header">
              <FormLayout>
                <TextContainer>
                  <DisplayText size={'extraLarge'}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 15C0 18.9782 1.58035 22.7936 4.3934 25.6066C7.20644 28.4196 11.0218 30 15 30C18.9782 30 22.7936 28.4196 25.6066 25.6066C28.4196 22.7936 30 18.9782 30 15C30 11.0218 28.4196 7.20644 25.6066 4.3934C22.7936 1.58035 18.9782 0 15 0C11.0218 0 7.20644 1.58035 4.3934 4.3934C1.58035 7.20644 0 11.0218 0 15ZM22.8 12.3C23.0166 12.0111 23.1218 11.6538 23.0962 11.2937C23.0706 10.9335 22.916 10.5947 22.6607 10.3393C22.4053 10.084 22.0665 9.92937 21.7063 9.90377C21.3462 9.87818 20.9889 9.98336 20.7 10.2L13.5 17.4L10.05 13.95C9.76114 13.7334 9.40383 13.6282 9.04367 13.6538C8.6835 13.6794 8.34466 13.834 8.08934 14.0893C7.83402 14.3447 7.67937 14.6835 7.65377 15.0437C7.62818 15.4038 7.73336 15.7611 7.95 16.05L12.45 20.55C13.05 21.15 13.95 21.15 14.55 20.55L22.8 12.3Z"
                        fill="#34A853"
                      />
                    </svg>
                    <strong style={{ marginLeft: 10 }}>Email sent</strong>
                  </DisplayText>
                  <DisplayText size={'small'}>
                    A link to reset your password has been sent to you on <br />
                    <a href="#">
                      <u>{email}</u>
                    </a>
                  </DisplayText>
                </TextContainer>
              </FormLayout>
            </div>
            <div className="form-content">
              <Button primary onClick={() => navigate('/login')} fullWidth={true} size="large">
                Return to Login
              </Button>
            </div>
          </>
        )}
      </div>
    </UserLayout>
  );
};

export default ForgotPassword;
