import {createContext, useContext, useReducer} from 'react'
export const initialState = {
	active: true,
	created_at: '',
	updated_at: '',
	title: '',
	heading: '',
	target_product: "",
	active_discount: false,
	start_time: new Date(),
	end_time: new Date(),
	eta: new Date(),
	set_end_time: false,
	show_eta: false,
	discount: {
		value: 10,
		type: 'PERCENTAGE'
	}
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
