import Page from '@modules/page'
import {navigate, useParams} from "@reach/router"
import {Badge, ContextualSaveBar, Layout, PageActions} from "@shopify/polaris"
import {ContextProvider, useStore} from "@pages/customers/container"
import TitleCard from "./components/title"
import Overview from "./components/overview"
import {useCallback, useEffect, useState} from "react"
import API from "@helpers/api"
import {showToast} from "@plugins/toast"
import {Skeleton} from "@modules/skeleton"
import {CancelToken} from "@plugins/axios"
import {checkRequiredFields} from "@helpers/utils"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'
const EditCustomer = () => {
	return (
		<ContextProvider>
			<EditCustomerView />
		</ContextProvider>
	)
}

const EditCustomerView = () => {
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	let [error, setError] = useState([])
	const {pageID} = useParams()
	const {state, dispatch} = useStore()
	const source = CancelToken.source()

	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState({})
	const {firstName, lastName, email, birthday} = state
	const isEqualState = isEqual(omit(state, ['tags', 'shopify_id', 'updatedAt']), omit(prevState, ['tags', 'updatedAt']))

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

	useEffect(() => {
		const handleReloadPage = (e) => {
		  e.preventDefault()
		  e.returnValue = ""
		}
		if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
		return () => window.removeEventListener("beforeunload", handleReloadPage)
	  }, [isEqualState])

	useEffect(() => {
		let result
		const fetchData = async () => {
			try {
				result = await API.getDetail('customer', pageID, source.token)
				console.log('Data details', result)
				if (result && result.ok && result.payload) {
					dispatch({type: 'setData', payload: result.payload})
					if (!result.payload.hasOwnProperty("birthday")) {
						result.payload.birthday = ""
					}
					if (!result.payload.hasOwnProperty("locale")) {
						result.payload.locale = ""
					}
					if (!result.payload.hasOwnProperty("note")) {
						result.payload.note = ""
					}
					if (!result.payload.hasOwnProperty("phone")) {
						result.payload.phone = ""
					}
					setPrevState(result.payload)
					setReady(true)
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
		return () => source.cancel()
	}, [pageID])
	const handleSave = async () => {
		error = await checkRequiredFields(['email'], state)
		if (error.length > 0) {
			setError(error)
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: 'Please fill in all the mandatory fields',
				error: true
			})
		}

		setSaving(true)
		API.update('customer', {
			...state
		}).then((res) => {
			if (res.ok) {
				setPrevState({
					...prevState,
					...res.payload, 
					birthday: birthday
				})
				return showToast({
					message: 'Updated successfully'
				})
			}
		}).finally(() => setSaving(false))
	}

	const handleDelete = () => {
		// TODO: handle delete
	}

	
	const name = `${firstName} ${lastName}`
	return ready ? (
		<Page
			title={firstName ? name : email}
			breadcrumbs={[{content: 'Subscribers', onAction: () => isEqualState ? navigate('/manage/leads') : handleModalChange()}]}
			titleMetadata={<Badge status={'success'}>Subscribed</Badge>}
		>
			{!isEqualState && <ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: handleSave,
					loading: saving,
					disabled: false,
				}}
				discardAction={{
					onAction: () => handleModalChange(),
				}}
			/>}
			<Layout>
				<Layout.Section>
					<TitleCard error={error} />
				</Layout.Section>
				<Layout.Section secondary>
					<Overview error={error} />
				</Layout.Section>
			</Layout>
			<PageActions
				primaryAction={{
					content: 'Save',
					loading: saving,
					onAction: handleSave

				}}
				secondaryActions={[
					{
						content: 'Delete',
						destructive: true,
						onAction: handleDelete
					},
				]}
			/>
			<DiscardModal 
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink="/manage/leads"
			/>
		</Page>
	) : <Skeleton />
}

export default EditCustomer
