import { getParam } from '@helpers/utils';
import UserLayout from '@modules/userLayout';
import { showToast } from '@plugins/toast';
import { grantAccessService } from '@services/user';
import { Banner, Button, ChoiceList, DisplayText, Form, FormLayout, TextContainer, TextField, TextStyle } from '@shopify/polaris';
import { addDays } from 'date-fns';
import React, { useCallback, useState } from 'react';

const ReviewStoreAccess = () => {
  const [expireDate, setExpireDate] = useState('3');
  const [customTime, setCustomTime] = useState('7');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = getParam('token');
  const [confirmSent, setConfirmSent] = useState(false);

  const handleSubmit = async () => {
    try {
      setError(expireDate === 'custom' && !customTime);
      if (expireDate === 'custom' && !customTime) return;
      setLoading(true);
      const date = addDays(new Date(), expireDate !== 'custom' ? Number(expireDate) : Number(customTime));
      await grantAccessService(date.toISOString(), token);
      setConfirmSent(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast({
        message: error?.message,
        error: true,
      });
    }
  };

  const renderChildren = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          label="Custom time"
          labelHidden
          onChange={(value) => setCustomTime(value)}
          value={customTime}
          autoComplete="off"
          type={'number'}
          suffix={'day(s)'}
          autoFocus
          error={error ? 'Please enter custom time value' : undefined}
        />
      ),
    [error, customTime],
  );

  return (
    <UserLayout title={'Grant access permission'} showQuote={false}>
      <div className="form-container">
        {confirmSent ? (
          <Banner status="success" title="Grant access successfully!">
            To withdraw the access anytime or have any questions, please reach out to us via live chat or at{' '}
            <a href="mailto:happytohelp@foxecom.com">
              <u>happytohelp@foxecom.com</u>
            </a>
            .
          </Banner>
        ) : (
          <>
            <div className="form-header">
              <FormLayout>
                <TextContainer>
                  <DisplayText size={'extraLarge'}>
                    <strong>{'Grant access permission'}</strong>
                  </DisplayText>
                  <div className={'Polaris-DisplayText Polaris-DisplayText--sizeSmall Polaris-DisplayText--mobile'}>
                    <p>
                      Someone is requesting permission to manage your store through their <br />
                      FoxHome account. Please review this request carefully.
                    </p>
                  </div>
                </TextContainer>
              </FormLayout>
            </div>

            <div className="form-content">
              <Form onSubmit={handleSubmit}>
                <FormLayout>
                  <p style={{ fontSize: '16px' }}>Choose access permission expiration time</p>
                  <ChoiceList
                    title={'Choose access permission expiration time'}
                    titleHidden
                    selected={[expireDate]}
                    choices={[
                      {
                        label: '1 day',
                        value: '1',
                      },
                      {
                        label: '3 day',
                        value: '3',
                      },
                      {
                        label: '7 days',
                        value: '7',
                      },
                      {
                        label: '30 days',
                        value: '30',
                      },
                      {
                        label: 'Forever (Recommended for store owner)',
                        value: '36500',
                      },
                      {
                        label: 'Custom',
                        value: 'custom',
                        renderChildren,
                      },
                    ]}
                    onChange={(value) => setExpireDate(value[0])}
                  />
                  <Button primary loading={loading} submit fullWidth={true} size="large">
                    <strong>Grant permission</strong>
                  </Button>

                  <p>
                    <TextStyle variation={'subdued'}>
                      To withdraw the access anytime or have any questions, please reach out to us via live chat or at{' '}
                      <a href="mailto:happytohelp@foxecom.com">
                        <u>happytohelp@foxecom.com</u>
                      </a>
                      .
                    </TextStyle>
                  </p>
                </FormLayout>
              </Form>
            </div>
          </>
        )}
      </div>
    </UserLayout>
  );
};

export default ReviewStoreAccess;
