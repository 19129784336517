import { useCallback, useEffect, useState } from 'react'
import {
	Layout,
	Card,
	TextField,
	FormLayout,
	Checkbox,
	ContextualSaveBar,
	ChoiceList,
	Select,
	Tabs, PageActions, Badge, Button, InlineError
} from "@shopify/polaris"
import { ContextProvider, descriptions, success_messages, button_texts, titles } from "./container"
import Table from "@modules/table"
import Page from "@modules/page"
import LayoutSection from "@modules/layoutSection"
import { useStore } from "./container"
import { navigate } from "@reach/router"
import PreviewPopup from "./preview"
import API from "@helpers/api"
import { showToast } from "@plugins/toast"
import { Skeleton } from "@modules/skeleton"
import axios, { CancelToken } from "@plugins/axios"
import PopupTemplate from "@pages/popup/templates"
import RichText from "@components/richtext"
import { useAuthStore } from "../../container/Auth"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'
import { DeleteMinor, PlusMinor } from '@shopify/polaris-icons';
import { showConfirm } from "@plugins/confirm"
import PlatformModal from '../../components/platformModal'
const Popup = () => {
	return (
		<ContextProvider>
			<PopupView />
		</ContextProvider>
	)
}

const PopupView = () => {
	const [activeModalIntegration, setActiveModalIntegration] = useState(false);
	const [error, setError] = useState(false)
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [selected, setSelected] = useState(0)
	const { state, dispatch } = useStore()
	const source = CancelToken.source()
	const [prevState, setPrevState] = useState({ ...state, description: `<p>${state.description}</p>` })
	const [activeModal, setActiveModal] = useState(false);
	const isEqualState = isEqual(
		omit(state, ['updatedAt', 'createdAt', 'auto_trigger', 'deleted', "__v", "_id", 'shop']),
		omit(prevState, ['updatedAt', 'createdAt', 'auto_trigger', 'deleted', "__v", "_id", 'shop'])
	)
	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);
	const { active, popup_type, title, description, show_on_mobile, success_text, button, save_to, email_placeholder, coupon, trigger, delay_show, repeat_open, display_on, updatedAt, copy_button, button_url, teaser_activate, teaser_title, teaser_when, teaser_position, shop, platforms } = state
	const handleTabChange = useCallback(
		(selectedTabIndex) => setSelected(selectedTabIndex),
		[]
	)

	useEffect(() => {
		const handleReloadPage = (e) => {
			e.preventDefault()
			e.returnValue = ""
		}
		if (isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
		return () => window.removeEventListener("beforeunload", handleReloadPage)
	}, [isEqualState])

	useEffect(() => {
		let result
		const fetchData = async () => {
			try {
				result = await API.getList('popup', {}, source.token)
				console.log('Data details', result)
				if (result && result.ok) {
					dispatch({ type: 'setData', payload: result.payload })
					if (result.payload) {
						setPrevState({ ...prevState, ...result.payload })
					}
					setReady(true)
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
		return () => source.cancel()
	}, [])

	const handleSave = async (data = {}) => {
		setError(!save_to.length && !platforms.length)
		if (!save_to.length && !platforms.length) {
			return showToast({
				message: 'Please choose at least 1 option in the "Data collection"',
				error: true
			})
		}
		setSaving(true)
		const saveData = { ...state, ...data }
		dispatch({ type: 'setData', payload: saveData })
		delete saveData['updatedAt']
		API.updateByShop('popup', saveData).then((res) => {
			if (res.ok) {
				setPrevState({
					...prevState,
					...res.payload,
				})
				return showToast({
					message: 'Updated successfully'
				})
			}
		}).finally(() => setSaving(false))
	}
	const handleChange = (key, value) => {
		dispatch({ type: 'setData', payload: { [key]: value } })
	}
	const renderChildren = useCallback(
		(isSelected) =>
			isSelected && (
				<div style={{ width: 300 }}>
					<TextField
						label="Delay time to show"
						labelHidden
						onChange={v => handleChange('delay_show', Number(v))}
						value={delay_show + ''}
						suffix={'seconds'}
					/>
				</div>
			),
		[handleChange, delay_show]
	)
	const handleDelete = (data) => {
		showConfirm({
			title: `Delete this list?`,
			message: 'This can’t be undone.',
			danger: true,
			confirm: 'Delete'
		}).then(res => {
			if (res) {
				const filterPlatforms = platforms.filter(item => item._id !== data._id)
				setError(!save_to.length && !filterPlatforms.length)
				if (!save_to.length && !filterPlatforms.length) {
					return showToast({
						message: 'Please choose at least 1 option in the "Data collection"',
						error: true
					})
				}

				axios.delete(`/api/plugin/integration/lists/${data._id}`)
				const saveData = { ...state, platforms: filterPlatforms }
				dispatch({
					type: 'setData', payload: {
						...saveData
					}
				})

				handleSave(saveData)
			}
		})
	}
	const tabs = [
		{
			id: 'general',
			content: 'General',
			accessibilityLabel: 'general-settings',
			panelID: 'general-content'
		},
		{
			id: 'design',
			content: 'Design',
			panelID: 'design-content'
		},
		{
			id: 'displays',
			content: 'Display',
			panelID: 'display-settings'
		}
	]

	const { shop_domain } = useAuthStore()
	return ready ? (
		<Page
			title="Popup settings"
			subtitle={"Create coupon or newsletter popup to interact with your customer."}
			breadcrumbs={[{ content: 'Boost conversion', onAction: () => isEqualState ? navigate('/boost-conversion') : handleModalChange() }]}
			fullWidth
		>
			{!isEqualState && <ContextualSaveBar
				fullWidth
				saveAction={{
					onAction: () => handleSave(),
					loading: saving,
					disabled: false
				}}
				discardAction={{
					onAction: () => handleModalChange()
				}}
			/>}
			<Layout>
				<Layout.Section secondary>
					<Card>
						<Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
							{selected === 0 && (
								<>
									<Card sectioned title={'Visibility'}>
										<FormLayout>
											<Checkbox
												label={'Active'}
												checked={active}
												onChange={v => handleChange('active', v)}
											/>
											<Checkbox
												label={'Show on mobile'}
												checked={show_on_mobile}
												disabled={!active}
												onChange={v => handleChange('show_on_mobile', v)}
											/>
										</FormLayout>
									</Card>
									<Card title={'Popup type'}>
										<Card.Section>
											<FormLayout>
												<ChoiceList
													title="Choose goal for your promo popup"
													choices={[
														{
															label: 'Collect leads',
															value: 'collect_lead'
														},
														{
															label: 'Give a discount code',
															value: 'give_coupon'
														},
														{
															label: 'Subscribe to get discount',
															value: 'subscribe_to_discount'
														},
														{
															label: 'Announcement',
															value: 'announcement'
														}
													]}
													selected={popup_type}
													onChange={v => {
														handleChange('popup_type', v[0])
														if (!updatedAt) {
															const title = titles[v]
															const success_text = success_messages[v]
															const button = button_texts[v]
															const description = descriptions[v]
															if (title) handleChange('title', title)
															if (success_text) handleChange('success_text', success_text)
															if (button) handleChange('button', button)
															if (description) handleChange('description', description)
														}
													}}
												/>
											</FormLayout>
										</Card.Section>
									</Card>
									<Card title={'Content settings'}>
										<Card.Section>
											<FormLayout>
												<TextField
													label={'Title'}
													value={title}
													onChange={v => handleChange('title', v)}
												/>
												<RichText
													label={'Description'}
													value={description}
													compact
													onChange={e => {
														handleChange('description', e)
														console.log(e, 'description')
													}}
												/>
												{['give_coupon', 'subscribe_to_discount'].includes(popup_type) ? (
													<TextField
														label={'Discount code'}
														value={coupon}
														onChange={v => handleChange('coupon', v)}
														labelAction={{
															content: 'Create discount',
															onAction: () => window.open(`https://${shop_domain}/admin/discounts/new`)
														}}
													/>
												) : ''}
												{popup_type === 'give_coupon' ? <TextField
													label={'Copy button'}
													value={copy_button}
													onChange={v => handleChange('copy_button', v)}
												/> : ''}
												{['collect_lead', 'subscribe_to_discount'].includes(popup_type) ? (
													<FormLayout.Group>
														<TextField
															label={'Button'}
															value={button}
															onChange={v => handleChange('button', v)}
														/>
														<TextField
															label={'Copy button'}
															value={copy_button}
															onChange={v => handleChange('copy_button', v)}
														/>
													</FormLayout.Group>
												) : ''}
												{['give_coupon', 'collect_lead', 'subscribe_to_discount'].includes(popup_type) ? (
													<TextField
														label={'Email placeholder'}
														value={email_placeholder}
														onChange={v => handleChange('email_placeholder', v)}
													/>
												) : ''}
												{['collect_lead', 'subscribe_to_discount'].includes(popup_type) && (
													<TextField
														label={'Thank you message'}
														value={success_text}
														onChange={v => handleChange('success_text', v)}
													/>
												)}
												{['announcement'].includes(popup_type) && (
													<FormLayout.Group>
														<TextField
															label={'Button'}
															value={button}
															onChange={v => handleChange('button', v)}
														/>
														<TextField
															label={'Button link'}
															value={button_url}
															placeholder={'https://'}
															onChange={v => handleChange('button_url', v)}
														/>
													</FormLayout.Group>
												)}
											</FormLayout>
										</Card.Section>
									</Card>
									<Card title={'Data collection'} sectioned>
										<FormLayout>
											<ChoiceList
												title={'Store subscribers at:'}
												selected={save_to}
												onChange={v => handleChange('save_to', v)}
												allowMultiple
												// disabled
												choices={[
													// { label: "Shopify customers list", value: "shopify_customers" },
													{ label: "FoxKit subscribers list", value: "foxkit_subscribers" },
												]}
											/>
											{platforms?.length !== 0 && <Table>
												<thead>
													<tr>
														<th width={'30%'}>Platform</th>
														<th width={'30%'}>Status</th>
														<th>List</th>
														<th />
													</tr>
												</thead>
												<tbody>
													{platforms.map((item, key) => {
														return (
															<tr key={key}>
																<td>
																	<p>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</p>
																</td>
																<td>
																	<Badge status={item.active ? "success" : "critical"}>{item.active ? "Active" : "Not Active"}</Badge>
																</td>
																<td>
																	<p>{item.list_name}</p>
																</td>
																<td>
																	<Button icon={DeleteMinor} onClick={() => { handleDelete(item) }} />
																</td>
															</tr>
														)
													})}
												</tbody>
											</Table>}
											<Button onClick={() => setActiveModalIntegration(prev => !prev)} icon={PlusMinor}>Add integration</Button>
											{error && <InlineError fieldID={'fdf'} message={'Choose at least one option to for Data Collection'} />}
										</FormLayout>
									</Card>
									{activeModalIntegration && <PlatformModal setActiveModalIntegration={setActiveModalIntegration} useStore={useStore} plugin="popup" link_api="popup"/>}
								</>
							)}
							{selected === 1 && <PopupTemplate handleSave={handleSave} />}
							{selected === 2 && (
								<Card title={'Display settings'}>
									<Card.Section>
										<FormLayout>
											<ChoiceList
												title={'Trigger open automatically'}
												selected={trigger}
												onChange={v => handleChange('trigger', v[0])}
												choices={[
													{ label: "After page loaded", value: "after_page_loaded" },
													{ label: "After specific time", value: "after_specific_time", renderChildren },
													{ label: "After scrolling down", value: "after_scroll" },
													{ label: "User is leaving the website", value: "exit_intent", helpText: "This option is only working on the desktop. On mobile, it will be shown after page loaded." },
													{ label: "Don't open automatically", value: "off", helpText: "Should enable Teaser button" },
												]}
											/>
											<ChoiceList
												title={'Displays on'}
												selected={display_on}
												onChange={v => handleChange('display_on', v[0])}
												choices={[
													{ label: "All pages", value: "all_pages" },
													{ label: "Home page only", value: "home_only" }
												]}
											/>
										</FormLayout>
									</Card.Section>
									<Card.Section>
										<FormLayout>
											<FormLayout.Group>
												<Select
													label={'Repeat open automatically'}
													value={repeat_open}
													onChange={v => handleChange('repeat_open', v)}
													options={[
														{ label: "No repeat", value: "no_repeat" },
														{ label: "Every 3 minutes", value: "every_3_mins" },
														{ label: "Every 5 minutes", value: "every_5_mins" },
														{ label: "Every 10 minutes", value: "every_10_mins" },
														{ label: "Every 15 minutes", value: "every_15_mins" },
														{ label: "Every 30 minutes", value: "every_30_mins" },
														{ label: "Every 1 hour", value: "every_1_hr" },
														{ label: "Every 6 hours", value: "every_6_hrs" },
														{ label: "Every 12 hours", value: "every_12_hrs" },
														{ label: "Every day", value: "every_day" },
														{ label: "Every 3 days", value: "every_3_days" },
														{ label: "Every week", value: "every_week" },
														{ label: "Every 2 weeks", value: "every_2_week" },
														{ label: "Every month", value: "every_month" },
													]}
													helpText={'We\'ll stop automatically showing this popup to customers who have already converted.'}
												/>
												<div />
											</FormLayout.Group>
										</FormLayout>
									</Card.Section>
									<Card.Section title={'Teaser button settings'}>
										<FormLayout>
											<Checkbox
												label={'Show teaser button'}
												checked={teaser_activate}
												onChange={v => handleChange('teaser_activate', v)}
												helpText={'Encourage users to open the popup.'}
											/>
											<ChoiceList
												title={'When to show'}
												selected={teaser_when}
												onChange={v => handleChange('teaser_when', v[0])}
												choices={[
													{ label: "Show after Popup opened at the 1st time", value: "after" },
													{ label: "Always show", value: "always" }
												]}
											/>
											<ChoiceList
												title={'Position'}
												selected={teaser_position}
												onChange={v => handleChange('teaser_position', v[0])}
												// disabled
												choices={[
													{ label: "Left", value: "left" },
													{ label: "Right", value: "right" },
												]}
											/>
											<TextField
												label={'Title'}
												value={teaser_title}
												onChange={v => handleChange('teaser_title', v)}
											/>
										</FormLayout>
									</Card.Section>
								</Card>
							)}
						</Tabs>
					</Card>
				</Layout.Section>
				<LayoutSection isStuck>
					<Card title={'Preview'}>
						<Card.Section>
							<PreviewPopup />
						</Card.Section>
					</Card>
					<PageActions
						primaryAction={{
							content: 'Save',
							loading: saving,
							onAction: () => handleSave()

						}}
					/>
				</LayoutSection>
			</Layout>
			<DiscardModal
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink='/boost-conversion'
			/>
		</Page>
	) : <Skeleton />
}

export default Popup
