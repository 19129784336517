import {createContext, useContext, useReducer} from 'react'

export const initialState = {
	active: false,
	message: 'Only {{stock_number}} item(s) left in stock!',
	applies_to: 'all_products',
	target_collections: [],
	target_products: [],
	countdown_type: 'use_quantity',
	from: 5,
	to: 10,
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
