import { WHITE_LIST } from '@helpers/constants';
import {
  ACCOUNT_EXISTED,
  EMAIL_EXISTED,
  EMAIL_NOT_EXISTED,
  ERR_INVALID_CHAR,
  MISSING_ACCESS_SCOPE,
  NOT_SELECTED_SHOP,
  OLD_PASSWORD_INVALID,
  PURCHASE_CODE_INVALID,
  SESSION_EXPIRED,
  SHOP_ACCESS_TOKEN_INVALID,
  SHOP_CONNECTED_TO_OTHER_ACCOUNT,
  SHOP_DOMAIN_INVALID,
  SHOP_NOT_ACTIVE,
  SHOP_NOT_FOUND,
  TOKEN_INVALID,
  UNAUTHORIZED,
  UNAUTHORIZED_SHOP,
  USER_NOT_FOUND,
} from '@helpers/error.constant';
import { isJson } from '@helpers/utils';
import { navigate } from '@reach/router';
import axios from 'axios';

export const CancelToken = axios.CancelToken;
const customInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const ERROR_MAPPING_MESSAGE = {
  [UNAUTHORIZED]: 'Email or password invalid, please try again.',
  [UNAUTHORIZED_SHOP]: 'This user does not have any shop.',
  [EMAIL_EXISTED]: 'Email already exists.',
  [EMAIL_NOT_EXISTED]: 'Email does not exist.',
  [SHOP_DOMAIN_INVALID]: 'Invalid shop domain.',
  [SHOP_ACCESS_TOKEN_INVALID]: 'Invalid shop access token.',
  [PURCHASE_CODE_INVALID]: 'Invalid purchase code.',
  [TOKEN_INVALID]: 'Invalid token.',
  [OLD_PASSWORD_INVALID]: 'The current password is incorrect.',
  [SHOP_CONNECTED_TO_OTHER_ACCOUNT]: 'This shop is associated with another account.',
  [MISSING_ACCESS_SCOPE]: 'Missing access scopes',
  [NOT_SELECTED_SHOP]: 'No selected shop found.',
  [SHOP_NOT_ACTIVE]: 'No active shop found.',
  [SHOP_NOT_FOUND]: 'Shop not found.',
  [USER_NOT_FOUND]: 'User not found.',
  [ERR_INVALID_CHAR]: 'Invalid character.',
  [SESSION_EXPIRED]: 'User session expired!',
  [ACCOUNT_EXISTED]: 'The account exists. Please reload the page to update the latest status'
};

customInstance.defaults.validateStatus = (status) => {
  return status >= 200 && status < 300;
};

customInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const errorResponseData = error?.response?.data || {};
    const { shouldRefresh, requireAuth } = errorResponseData;

    const message = isJson(errorResponseData?.message) ? JSON.parse?.(errorResponseData?.message) : errorResponseData?.message;

    const path = window.location.pathname;

    if (!WHITE_LIST.includes(path) && message === UNAUTHORIZED) {
      navigate('/login');
      return Promise.reject(errorResponseData);
    }
    if (message === UNAUTHORIZED_SHOP) {
      navigate('/add-store');
      return Promise.reject(errorResponseData);
    }
    if (message === SHOP_NOT_ACTIVE || message === NOT_SELECTED_SHOP) {
      navigate(`/select-store`);
      return Promise.reject(errorResponseData);
    }
    if (shouldRefresh) {
      window.location.reload();
    } else if (requireAuth) {
      navigate('/login');
    }

    // let newMessage;
    let dataError;
    if (typeof message === 'object') {
      dataError = Object.keys(message).reduce((res, key) => {
        const errorCode = message[key]?.code;
        let data = {
          message: ERROR_MAPPING_MESSAGE?.[errorCode] || errorCode,
        };
        console.log({ data });
        if (errorCode === MISSING_ACCESS_SCOPE) {
          data.details = message[key]?.missingScopes ?? [];
        }
        res.push(data);
        return res;
      }, []);
    }

    let errorData = {
      ...errorResponseData,
      code: message,
      message: ERROR_MAPPING_MESSAGE?.[errorResponseData?.message] ?? errorResponseData?.message,
      data: dataError,
    };
    return Promise.reject(errorData);
  },
);

export default customInstance;
