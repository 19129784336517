export const BUILD_PATH = process.env.BUILD_PATH;
export const PORT = process.env.PORT;
export const REACT_APP_ITEM_URL = process.env.REACT_APP_ITEM_URL;
export const REACT_APP_PURCHASE_URL = process.env.REACT_APP_PURCHASE_URL;
export const REACT_APP_EVANTO_API = process.env.REACT_APP_EVANTO_API;
export const REACT_APP_EVANTO_USERNAME = process.env.REACT_APP_EVANTO_USERNAME;
export const REACT_APP_EVANTO_TOKEN = process.env.REACT_APP_EVANTO_TOKEN;
export const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID;
export const REACT_APP_GTM_AUTH = process.env.REACT_APP_GTM_AUTH;
export const REACT_APP_GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW;
export const REACT_APP_MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const REACT_APP_FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const REACT_APP_FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const REACT_APP_FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const REACT_APP_FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const REACT_APP_FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const REACT_APP_FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const REACT_APP_FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const NODE_ENV = process.env.NODE_ENV;
export const REACT_APP_FOX_KIT_URL = process.env.REACT_APP_FOX_KIT_URL;
