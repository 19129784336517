import { navigate, useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import axios from '@plugins/axios';
import { ContextProvider, useStore } from '../container';
import { Card, Layout, SkeletonBodyText, SkeletonPage } from '@shopify/polaris';
import Page from '@modules/page';
import VariantsModal from './modal';
import VariantList from './variantsList';
import { getSizedImageUrl } from '@shopify/theme-images';
const Variants = () => {
  return (
    <ContextProvider>
      <VariantsView />
    </ContextProvider>
  );
};

const VariantsView = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useStore();
  const [headers, setHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [metafield, setMetafield] = useState({});
  const [variantsGroup, setVariantsGroup] = useState([]);
  const [variants, setVariants] = useState({});
  const [ready, setReady] = useState(false);
  const [selectedSingleVariant, setSelectedSingleVariant] = useState('');
  const [selectedVariants, setSelectedVariants] = useState([]);

  useEffect(() => {
    const getVariants = async () => {
      const [data, variantGroupImage] = await Promise.all([
        axios.get(`/api/shopify/variants-product/${id}`),
        axios.get(`/api/plugin/variant-group-images/${id}`),
      ]);

      if (data.ok && data.payload) {
        const { variants, media, options } = data.payload.product;

        setVariants(variants);

        setTitle(data.payload.product.title);

        const variantsGroup = options.reduce((prev, cur) => {
          return [...prev, ...cur.values];
        }, []);

        setVariantsGroup(variantsGroup);

        const filterFile = media.edges.filter((item) => item.node.id);

        const inputImages = filterFile
          .map((item) => ({
            alt: item.node.image?.altText,
            image: item.node.image?.originalSrc,
            value: item.node.id.replace('gid://shopify/MediaImage/', ''),
          }))
          .reverse();

        let isSavedToServer = false;
        let metafield = data.payload.product.metafields.edges.find(
          (item) => item.node.key === 'variant-images' && item.node.namespace === 'foxecom-bs-kit'
        );

        if (!metafield && variantGroupImage?.payload?.data) {
          metafield = {
            node: {
              value: JSON.stringify(variantGroupImage.payload.data),
            },
          };
        }

        if (variantGroupImage?.payload?.data) {
          isSavedToServer = true;
        }

        let variantsState;
        if (metafield) {
          const metafieldData = JSON.parse(metafield.node.value);

          variants.edges.forEach((variant) => {
            if (
              metafieldData.mapping.every(
                (item) => variant.node.id.replace('gid://shopify/ProductVariant/', '') !== item.id
              )
            ) {
              const metafieldItem = { id: variant.node.id.replace('gid://shopify/ProductVariant/', ''), media: [] };
              metafieldData.mapping.push(metafieldItem);
            }
          });

          const filterMapping = metafieldData.mapping.filter((item) => {
            return variants.edges.some(
              (variant) => variant.node.id.replace('gid://shopify/ProductVariant/', '') === item.id
            );
          });

          variantsState = filterMapping.map((item) => {
            let inputVariant, inputImage;
            variants.edges.forEach((variant) => {
              if (variant.node.id.replace('gid://shopify/ProductVariant/', '') === item.id) {
                inputVariant = variant.node;
              }
            });
            inputImage = item.media.map((value) => {
              const filterData = media?.edges?.filter(
                (image) => image?.node?.id?.replace('gid://shopify/MediaImage/', '') === value
              );
              let imageThumbnail;
              if (filterData[0]?.node?.image?.originalSrc) {
                imageThumbnail = getSizedImageUrl(filterData[0]?.node?.image?.originalSrc, '200x200')
                  ? getSizedImageUrl(filterData[0]?.node?.image?.originalSrc, '200x200')
                  : filterData[0]?.node?.image?.originalSrc;
              }
              return {
                sourceUrl: imageThumbnail ? imageThumbnail : '',
                id: filterData[0]?.node?.id.replace('gid://shopify/MediaImage/', ''),
              };
            });
            return {
              variantItem: inputVariant,
              images: inputImage,
              id: inputVariant?.id.replace('gid://shopify/ProductVariant/', ''),
            };
          });

          const inputMapping = filterMapping.map((item) => {
            const filterMedia = item.media.filter((id) => inputImages.some((image) => image.value === id));
            return { ...item, media: filterMedia };
          });

          const filterMetafield = { ...metafieldData, mapping: inputMapping };

          const result = await axios.put('/api/shopify/update-metafield', { metafieldData: filterMetafield, id: id });

          if (!isSavedToServer) {
            axios.put(`/api/plugin/variant-group-images/${id}`, {
              data: { ...filterMetafield },
            });
          }

          setMetafield(JSON.parse(result.payload[0].value));
        } else {
          const mapping = variants.edges.map((item) => ({
            id: item.node?.id?.replace('gid://shopify/ProductVariant/', ''),
            media: [],
          }));

          const imageFile = filterFile
            .map((item) => {
              if (item.node) {
                const imageName = item.node.image?.originalSrc?.slice(
                  item.node.image.originalSrc.indexOf('/products/') + 10,
                  item.node.image.originalSrc.indexOf('?v=')
                );
                return { id: item.node?.id?.replace('gid://shopify/MediaImage/', ''), value: imageName };
              } else {
                return null;
              }
            })
            .filter(Boolean);

          const enable = mapping?.filter((item) => item.media.length > 0)?.length > 0;

          setMetafield({ ...metafield, mapping: mapping, imageFile: imageFile });

          const result = await axios.put('/api/shopify/update-metafield', {
            metafieldData: { mapping: mapping, imageFile: imageFile, enable },
            id: id,
          });

          if (!isSavedToServer) {
            axios.put(`/api/plugin/variant-group-images/${id}`, {
              data: { mapping: mapping, imageFile: imageFile, enable },
            });
          }

          const metafieldData = JSON.parse(result.payload[0].value);

          variantsState = metafieldData.mapping.map((item) => {
            let inputVariant;
            variants.edges.forEach((variant) => {
              if (variant.node.id.replace('gid://shopify/ProductVariant/', '') === item.id) {
                inputVariant = variant.node;
              }
            });
            return {
              variantItem: inputVariant,
              images: [],
              id: inputVariant.id.replace('gid://shopify/ProductVariant/', ''),
            };
          });
        }
        const headerVariant = variants.edges[0].node.selectedOptions.map((item) => ({ name: item.name }));

        setHeaders([...headers, ...headerVariant]);

        dispatch({ type: 'setData', payload: { ...state, variantsState: variantsState } });

        setReady(true);
      }
    };

    getVariants();
  }, []);

  return ready ? (
    <Page title={title} breadcrumbs={[{ content: 'Products', onAction: () => navigate('/variant-group-images') }]}>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <VariantList
              headers={headers}
              variantsGroup={variantsGroup}
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
              setOpen={setOpen}
              selectedSingleVariant={selectedSingleVariant}
              setSelectedSingleVariant={setSelectedSingleVariant}
            />
          </Card>
        </Layout.Section>
        {open && (
          <VariantsModal
            setOpen={setOpen}
            productId={id}
            setMetafield={setMetafield}
            metafield={metafield}
            selectedVariants={selectedVariants}
            variants={variants}
            setSelectedVariants={setSelectedVariants}
            selectedSingleVariant={selectedSingleVariant}
            setSelectedSingleVariant={setSelectedSingleVariant}
          />
        )}
      </Layout>
    </Page>
  ) : (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
};

export default Variants;
