import React from 'react'
import {Router} from "@reach/router"
import BundlesList from "@pages/bundles"
import CreateBundle from "@pages/bundles/create"
import EditBundle from "@pages/bundles/edit"

export default function BundlePages() {
	return (
		<Router>
			<BundlesList path="/" />
			<CreateBundle path="/new" />
			<EditBundle path="/:pageID" />
		</Router>
	)
}
