const getMaskedCharacter = (length = 2) => {
  return Array.from({ length })
    .map(() => '*')
    .join('');
};

const maskEmail = (email = '') => {
  let masked = email;
  const emailNameLength = email.split('@')?.[0]?.length;
  if (emailNameLength >= 7) {
    const matches = email.match(/[A-Za-z0-9]{4}(.*?)(?=@)/);
    masked = email.replace(/(\w{4})[\w.-]+@([\w.]+\w)/, `$1${getMaskedCharacter(matches?.[1]?.length)}$2`);
  } else {
    const matches = email.match(/^[A-Za-z0-9]{2}(.*?)(?=@)/);
    masked = email.replace(/(\w{2})[\w.-]+@([\w.]+\w)/, `$1${getMaskedCharacter(matches?.[1]?.length)}$2`);
  }
  return masked;
};

export default maskEmail;
