import {Banner, Card, FormLayout, TextContainer, Checkbox, TextStyle, Button, Badge} from "@shopify/polaris"
import {useStore} from "@pages/bundles/container"
import ResourcePicker from "@components/resourcePicker"
import Table from "@modules/table"
import {getImageSizeHardCrop} from "@helpers/utils"
import Thumbnail from "@components/thumbnail"

const ProductsCard = ({error}) => {
	const {state, dispatch} = useStore()
	const {products, show_target_only, target_product} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card>
			<Card.Section subdued title={'Bundle products'}>
				<TextStyle variation={'subdued'}>Motivate customers to add this bundle to cart when they visit any of the
					bundled products</TextStyle>
			</Card.Section>
			<Card.Section>
				<FormLayout>
					<ResourcePicker
						label={'Bundle products'}
						labelHidden
						isMulti
						resourceType={'product'}
						value={products}
						onChange={v => {
							handleChange('products', v)
							console.log(v, '==============> v')
						}}
						isClearable
						error={error.includes('products')}
					/>
					<TextContainer>
						<Banner title="Pro tip">
							<p>According to our statistics, bundles of 2 or 3 products see over 20% conversion.</p>
						</Banner>
					</TextContainer>
				</FormLayout>
			</Card.Section>
			<Card.Section>
				<FormLayout>
					<Checkbox
						label={'Only show this bundle when customers visit target product.'}
						checked={show_target_only}
						onChange={v => {
							handleChange('show_target_only', v)
							if (v) handleChange('target_product', products[0]?.value)
						}}
						helpText={'If this option is unchecked, offer will show up when customers visit any product in the bundle.'}
					/>
					{show_target_only && (
						<Table bordered>
							<thead>
							<tr>
								<th colSpan={3}>Selected products:</th>
							</tr>
							</thead>
							<tbody>
							{products.map((product, index) => {
								return (
									<tr key={index}>
										<td width={60}>
											<Thumbnail size={'small'} source={getImageSizeHardCrop(product?.featuredImage?.originalSrc, '100x100')} />
										</td>
										<th>{product?.label}</th>
										<td width={132}>
											{product?.value === target_product ? (
												<Badge status={'info'}>Target product</Badge>
											) : (
												<Button size={'slim'} onClick={() => handleChange('target_product', product?.value)}>Set as target</Button>
											)}
										</td>
									</tr>
								)
							})}
							</tbody>
						</Table>
					)}
				</FormLayout>
			</Card.Section>
		</Card>
	)
}

export default ProductsCard
