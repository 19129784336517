import {Card, FormLayout, TextField} from "@shopify/polaris"
import {useStore} from "@pages/bundles/container"

const TitleCard = ({error = []}) => {
	const {state, dispatch} = useStore()
	const {title, bundle_heading} = state
	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}
	return (
		<Card sectioned>
			<FormLayout>
				<TextField
					label={'Title'}
					value={title}
					onChange={v => handleChange('title', v)}
					error={error.includes('title')}
				/>
				<TextField
					value={bundle_heading}
					label={'Bundle heading'}
					onChange={v => handleChange('bundle_heading', v)}
					placeholder={'Frequently bought together'}
				/>
			</FormLayout>
		</Card>
	)
}

export default TitleCard
