import InputField from '@components/inputField';
import { useAuthStore } from '@container/Auth';
import { SHOP_SELECTED_KEY } from '@helpers/constants';
import UserLayout from '@modules/userLayout';
import axios from '@plugins/axios';
import { showToast } from '@plugins/toast';
import { navigate } from '@reach/router';
import { createNewShopService } from '@services/shop';
import { Button, DisplayText, Form, FormLayout, InlineError, Link, TextContainer, TextStyle, ChoiceList, Card } from '@shopify/polaris';
import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import useSignIn from 'src/hooks/useSignIn';
import PurchaseCode from './purchaseCode';
import { REACT_APP_FOX_KIT_URL } from '@helpers/env';

function AddStorePage() {
  const [value, setValue] = useState({
    accessToken: '',
    store: '',
    apiSecretKey: '',
  });
  const [selected, setSelected] = useState('foxkit');

  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuthStore();
  const [formErrors, setFormErrors] = useState([]);
  const { handleLogout } = useSignIn();
  const ref = useRef();

  const handleChange = useCallback((value) => setSelected(value[0]), []);

  useEffect(() => {
    if (currentUser?.authorizedShop) {
      navigate('/');
      return;
    }
  }, [currentUser]);

  const [purchaseMode, setPurchaseMode] = useState(false);
  const shopifyDomain = useMemo(
    () =>
      value?.store
        .replace(/\s/g, '')
        .replace(/(https?:).*?/i, '')
        .replace(/\//g, '')
        .replace(/\.com.*/, '.com'),
    [value?.store],
  );

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const regexShopifyDomain = /^[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com$/;
      if (!regexShopifyDomain.test(shopifyDomain)) throw new Error('Invalid shop domain.');

      if (selected === 'custom') {
        const requestPayload = {
          shopDomain: shopifyDomain,
          accessToken: value?.accessToken,
          apiSecretKey: value?.apiSecretKey,
        };

        await createNewShopService(requestPayload);

        setLoading(false);

        localStorage.setItem(SHOP_SELECTED_KEY, value?.store);

        axios.defaults.headers.common['X-Request-Shop-Origin'] = value?.store;

        setPurchaseMode(true);

        showToast({
          message: 'Add shop successfully!',
        });
      } else {
        ref.current?.submit();
      }
    } catch (error) {
      const newFormErrors = error?.data || error?.message;
      setFormErrors(newFormErrors);
      setLoading(false);
    }
  };

  const handleChangeValue = (field, newValue) => {
    setFormErrors('');
    setValue((prev) => ({ ...prev, [field]: newValue }));
  };

  const disabled = useMemo(() => {
    return Object.values(value).findIndex((item) => !item) !== -1;
  }, [value]);

  const displayName = [currentUser?.firstName, currentUser?.lastName];

  return (
    <>
      {purchaseMode ? (
        <PurchaseCode shop={shopifyDomain} />
      ) : (
        <UserLayout title={'Connect your first store'}>
          <div className="form-container">
            <div className="form-header">
              <TextContainer>
                <DisplayText size={'extraLarge'}>
                  <strong>Connect your store with FoxHome</strong>
                </DisplayText>
                <p className={'Polaris-DisplayText Polaris-DisplayText--sizeSmall Polaris-DisplayText--mobile'}>
                  Please provide the following information to allow <br />
                  <strong>FoxHome</strong> works with your store
                </p>
              </TextContainer>
            </div>
            <div className="form-content">
              <Card>
                <Card.Section>
                  <FormLayout>
                    <ChoiceList
                      title="Choose which method you want to use to connect store"
                      choices={[
                        { label: 'FoxKit app (Recommended)', value: 'foxkit' },
                        { label: 'Custom app', value: 'custom' },
                      ]}
                      selected={[selected]}
                      onChange={handleChange}
                    />
                  </FormLayout>
                </Card.Section>
                <Card.Section subdued>
                  {selected === 'custom' && (
                    <Form onSubmit={handleSubmit}>
                      <FormLayout>
                        <InputField
                          value={value?.store}
                          label="Store domain"
                          onChange={(value) => handleChangeValue('store', value)}
                          autoComplete="off"
                          placeholder="your-store-domain.myshopify.com"
                          helpText={'Enter your store domain'}
                          autoFocus
                        />
                        <div style={{ marginTop: '3rem' }}>
                          <TextContainer>
                            <DisplayText size={'small'}>
                              <strong>API credentials</strong>
                            </DisplayText>
                            <TextStyle variation={'subdued'}>
                              <strong>FoxHome</strong> uses these credentials to work with your store, kindly create a custom app to get credentials.{' '}
                              <Link url={'https://docs.minimog.co/foxhome/getting-started/how-to-sign-up-in-foxhome'} external>
                                Learn more.
                              </Link>
                            </TextStyle>
                          </TextContainer>
                        </div>
                        <InputField
                          value={value?.accessToken}
                          onChange={(value) => handleChangeValue('accessToken', value)}
                          label="Admin API access token"
                          type="password"
                          autoComplete={'off'}
                        />
                        <InputField
                          value={value?.apiSecretKey}
                          onChange={(value) => handleChangeValue('apiSecretKey', value)}
                          label="API secret key"
                          type="password"
                          autoComplete={'off'}
                        />
                        <Button primary loading={loading} disabled={disabled} submit fullWidth={true} size="large">
                          Connect
                        </Button>
                      </FormLayout>
                    </Form>
                  )}
                  {selected === 'foxkit' && (
                    <FormLayout>
                      <TextContainer>
                        <p>
                          This method requires the{' '}
                          <Link url={'https://foxecom.link/foxkit'} external>
                            FoxKit app
                          </Link>
                          , which should be installed on your store.
                        </p>
                      </TextContainer>
                      <InputField
                        value={value?.store}
                        label="Store domain"
                        onChange={(value) => handleChangeValue('store', value)}
                        autoComplete="off"
                        placeholder="your-store-domain.myshopify.com"
                        helpText={'Enter your store domain'}
                        autoFocus
                      />
                      <form ref={ref} action={`${REACT_APP_FOX_KIT_URL}/auth`} method="GET">
                        <input type="hidden" name="shop" value={shopifyDomain} />
                        <input type="hidden" name="userId" value={currentUser?._id} />
                        <input type="hidden" name="source" value={'foxhome'} />
                      </form>
                      <Button primary onClick={handleSubmit} loading={loading} disabled={!value?.store} fullWidth={true} size="large">
                        Connect
                      </Button>
                    </FormLayout>
                  )}
                </Card.Section>
                <div style={{ padding: '2rem' }}>
                  {Array.isArray(formErrors) ? (
                    formErrors?.map((err) => {
                      const details = err?.details;
                      let message = `${err?.message}:`;
                      return (
                        <>
                          <InlineError message={message} />
                          {details && (
                            <div style={{ marginTop: 5, fontSize: 13 }}>
                              <code>{details.join(', ')}</code>
                            </div>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <InlineError message={formErrors} />
                  )}
                  <div className="center-wrapper" style={{paddingTop: '2rem'}}>
                    <span>
                      <TextStyle variation={'subdued'}>Logged in as</TextStyle> {displayName.filter(Boolean).join(' ')}.
                    </span>
                    &nbsp;
                    <Button plain destructive onClick={handleLogout}>
                      Logout?
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </UserLayout>
      )}
    </>
  );
}

export default AddStorePage;
