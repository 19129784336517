import {createContext, useContext, useReducer} from 'react'

export const initialState = {
	active: false,
	message: 'Buy {{variable}} more to enjoy <strong>FREE Shipping</strong>',
	message_reached: 'Congrats! You are eligible for <strong>FREE Shipping</strong>',
	goal_amount: 60,
	updated_at: '',
	disable_foxkit_discount: false
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
