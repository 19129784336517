import { Banner, DropZone, List, Modal, Spinner, Stack, TextField, TextStyle } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStore } from '../container';
import pluginAxios from '@plugins/axios';
import axios from 'axios';
import { showToast } from '@plugins/toast';
import { getSizedImageUrl } from '@shopify/theme-images';
import { isEqual } from 'lodash';
const ListView = styled.div`
  .images-list-box {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0;
    padding: 0;
  }
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.0625rem solid rgba(201, 204, 207, 1);
    background: rgba(246, 246, 247, 1);
    border-radius: 6px;
    position: relative;
    width: 100%;
    height: 100px;
  }
  .Polaris-Checkbox {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  ul {
    list-style-type: none;
  }
  li {
    display: inline-block;
    cursor: pointer;
  }
  .image {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .image > img {
    max-height: 90%;
    max-width: 100%;
    object-fit: contain;
  }
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const VariantsModal = ({
  setOpen,
  productId,
  setMetafield,
  metafield,
  selectedVariants,
  variants,
  setSelectedVariants,
  selectedSingleVariant,
  setSelectedSingleVariant,
}) => {
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [metafieldState, setMetafieldState] = useState(metafield);
  const { state, dispatch } = useStore();
  const [imagesState, setImagesState] = useState([]);
  const [imageChoices, setImageChoices] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [prevLength, setPrevLength] = useState(0);
  const [checkedState, setCheckedState] = useState([]);
  const hasError = rejectedFiles.length > 0;

  useEffect(() => {
    const getImages = async () => {
      setLoadingImages(true);
      try {
        const data = await pluginAxios.get(`/api/shopify/images-modal/${productId}`);
        if (data.ok && data.payload) {
          const { media } = data.payload.product;
          const filterFile = media.edges.filter((item) => item.node.id).reverse();
          const inputImages = filterFile.map((item) => {
            return {
              alt: item.node.image?.altText,
              image: getSizedImageUrl(item.node.image?.originalSrc, '200x200')
                ? getSizedImageUrl(item.node.image?.originalSrc, '200x200')
                : item.node.image?.originalSrc,
              value: item.node.id.replace('gid://shopify/MediaImage/', ''),
            };
          });
          if (selectedVariants.length > 1) {
            setCheckedState(new Array(filterFile.length).fill(false));
          } else {
            const filterMetafield = metafieldState.mapping.filter(
              (item) => item.id === selectedVariants[0] || item.id === selectedSingleVariant
            )[0]?.media;
            const inputCheckedState = filterFile.map((item) => {
              return !!filterMetafield.some((mtf) => item.node.id.replace('gid://shopify/MediaImage/', '') === mtf);
            });
            setCheckedState(inputCheckedState);
          }
          const inputImageMetafield = inputImages.map((item) => ({
            id: item.value,
            value: item.image
              .slice(item.image.indexOf('/products/') + 10, item.image.indexOf('?v='))
              .replace('_200x200', ''),
          }));
          if (!isEqual(metafieldState?.imageFile, inputImageMetafield)) {
            const metafieldData = await pluginAxios.put('/api/shopify/update-metafield', {
              metafieldData: { ...metafieldState, imageFile: inputImageMetafield },
              id: productId,
            });
            setMetafield(JSON.parse(metafieldData.payload[0].value));
          }
          setPrevLength(inputImages.length);
          setImagesState(inputImages);
          setImageChoices(inputImages);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingImages(false);
      }
    };
    getImages();
  }, []);

  const generateStagedUploads = async (acceptedFiles) => {
    const [{ name, type, size }] = acceptedFiles;
    const data = await pluginAxios.post('/api/shopify/generate-uploads', {
      input: [
        {
          filename: name,
          mimeType: type,
          resource: 'IMAGE',
          httpMethod: 'POST',
          fileSize: size.toString(),
        },
      ],
    });
    return data;
  };

  const handleDrop = useCallback(
    async (_droppedFiles, acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length === 0) {
        setLoading(true);
        const updateProductImages = async (image) => {
          const data = await pluginAxios.post('/api/shopify/images-product', {
            id: productId,
            media: {
              alt: image.slice(image.indexOf('/products/') + 10),
              originalSource: image,
              mediaContentType: 'IMAGE',
            },
          });
          if (data.ok && data.payload) {
            const imageId = data.payload.productCreateMedia.media[0].id.replace('gid://shopify/MediaImage/', '');
            const result = await pluginAxios.get(`/api/shopify/image/${imageId}`);
            if (result.ok && result.payload) {
              const imageData = result.payload.node;
              setCheckedState([false, ...checkedState]);
              const image = {
                alt: imageData.alt,
                image: getSizedImageUrl(imageData.image.originalSrc, '200x200')
                  ? getSizedImageUrl(imageData.image.originalSrc, '200x200')
                  : imageData.image.originalSrc,
                value: imageData.id.replace('gid://shopify/MediaImage/', ''),
              };
              setImageChoices([image, ...imageChoices]);
              setImagesState([image, ...imagesState]);
              const imageAdd = { id: imageData.id.replace('gid://shopify/MediaImage/', ''), value: imageData.alt };
              if (imageAdd) {
                metafieldState?.imageFile?.push(imageAdd);
                const metafieldData = await pluginAxios.put('/api/shopify/update-metafield', {
                  metafieldData: metafieldState,
                  id: productId,
                });
                setMetafield(JSON.parse(metafieldData.payload[0].value));
              }
            }
          }
        };

        const data = await generateStagedUploads(acceptedFiles);
        const [{ url, parameters, resourceUrl }] = data.payload.stagedUploadsCreate.stagedTargets;
        let formData = new FormData();
        parameters.forEach(({ name, value }) => {
          formData.append(name, value);
        });
        formData.append('file', acceptedFiles[0]);
        try {
          await axios.post(url, formData, {
            headers: { 'Content-Type': 'multipart/form-data;boundary=----foxkit----' },
          });
          await updateProductImages(resourceUrl);
        } catch (error) {
          console.log(error);
        }
        setRejectedFiles(rejectedFiles);
        showToast({
          message: 'Created successfully!',
        });
        setLoading(false);
        setPrevLength((prev) => (prev += 1));
      } else {
        showToast({
          message: 'File is not valid!',
          error: true,
        });
      }
    },
    [imagesState, imageChoices]
  );

  const handleChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);
    let selectedChoices = [];
    updatedCheckedState.forEach((item, index) => {
      if (item === true) selectedChoices.push(imageChoices[index].value);
    });

    if (selectedVariants.length > 1) {
      const filterMapping = metafield.mapping.map((item) => {
        const filterMedia = item.media.filter((id) => imagesState.some((image) => image.value === id));
        return { ...item, media: filterMedia };
      });
      const filterMetafield = { ...metafield, mapping: filterMapping };
      const inputMapping = filterMetafield.mapping.map((item) => {
        let mediaItem = [];
        if (selectedVariants.includes(item.id)) {
          selectedChoices.forEach((value) => {
            if (!mediaItem.includes(value)) {
              mediaItem = [...mediaItem, value];
            }
          });
          return { ...item, media: mediaItem };
        } else {
          return item;
        }
      });
      setMetafieldState({ ...metafieldState, mapping: inputMapping });
    } else if (selectedVariants.length === 1 || selectedSingleVariant !== '') {
      const filterMapping = metafield.mapping.map((item) => {
        const filterMedia = item.media.filter((id) => imagesState.some((image) => image.value === id));
        return { ...item, media: filterMedia };
      });
      const filterMetafield = { ...metafield, mapping: filterMapping };
      let inputMapping = filterMetafield.mapping.map((item) => {
        let mediaItem = [];
        if (selectedVariants[0] === item.id || selectedSingleVariant === item.id) {
          selectedChoices.forEach((value) => {
            if (item.media.includes(value) && mediaItem.includes(value)) {
              mediaItem = item.media;
            } else {
              mediaItem = [...mediaItem, value];
            }
          });
          return { ...item, media: mediaItem };
        } else {
          return item;
        }
      });
      setMetafieldState({ ...metafieldState, mapping: inputMapping });
    }
  };
  const handleClose = () => setOpen(false);

  const handleAdd = async () => {
    let enable = false;

    const check = metafieldState?.mapping?.filter((item) => item.media.length > 0);

    enable = check?.length > 0;

    setLoadingAdd(true);

    const [result] = await Promise.all([
      pluginAxios.put('/api/shopify/update-metafield', {
        metafieldData: { ...metafieldState, enable },
        id: productId,
      }),
      pluginAxios.put(`/api/plugin/variant-group-images/${productId}`, {
        data: { ...metafieldState, enable },
      }),
    ]);

    if (result?.payload?.length) {
      const metafieldData = JSON.parse(result.payload[0].value);
      const variantsState = metafieldData.mapping.map((item) => {
        let inputVariant, inputImage;
        variants.edges.forEach((variant) => {
          if (variant.node.id.replace('gid://shopify/ProductVariant/', '') === item.id) {
            inputVariant = variant.node;
          }
        });
        inputImage = item.media.map((value) => {
          const filterData = imagesState.filter((image) => image.value === value);
          return { sourceUrl: filterData[0]?.image, id: filterData[0]?.value };
        });
        return {
          variantItem: inputVariant,
          images: inputImage,
          id: inputVariant.id.replace('gid://shopify/ProductVariant/', ''),
        };
      });
      dispatch({ type: 'setData', payload: { ...state, variantsState: variantsState } });
      setMetafield(metafieldData);
      setOpen(false);
      setSelectedVariants([]);
      setSelectedSingleVariant('');
      setLoadingAdd(false);
      showToast({
        message: 'Updated successfully',
      });
    } else {
      showToast({
        message: 'Update failed',
        error: true,
      });
    }
  };

  const errorMessage = hasError && (
    <Banner title="The following images couldn’t be uploaded:" status="critical">
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <Modal
      open={true}
      onClose={() => setOpen(false)}
      title="Add images"
      primaryAction={{
        content: 'Update',
        loading: loadingAdd,
        onAction: () => handleAdd(),
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => handleClose(),
        },
      ]}
    >
      {!loadingImages ? (
        <Modal.Section>
          {errorMessage}
          {imageChoices.length > 0 ? (
            <ListView>
              <Stack vertical>
                <ul className="images-list-box">
                  <div className="item">
                    {loading ? (
                      <div className="spinner-container">
                        <Spinner size="large" />
                      </div>
                    ) : (
                      <DropZone onDrop={handleDrop} accept="image/*" type="image">
                        <DropZone.FileUpload />
                      </DropZone>
                    )}
                  </div>
                  {imageChoices.map((item, index) => {
                    if (index === 0 && loading && imageChoices.length !== prevLength) {
                      return (
                        <div className="item" key={0}>
                          <div className="spinner-container">
                            <Spinner size="large" />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <li key={index} className="item">
                        <input
                          className="Polaris-Checkbox"
                          type="checkbox"
                          id={`checkbox-${index}`}
                          name={item.value}
                          value={item.value}
                          checked={checkedState[index]}
                          onChange={() => handleChange(index)}
                        />
                        <label htmlFor={`checkbox-${index}`} className="image">
                          <img src={item.image} alt={item.alt} />
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </Stack>
            </ListView>
          ) : (
            <DropZone onDrop={handleDrop} accept="image/*" type="image">
              {loading ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spinner size="large" />
                </div>
              ) : (
                <DropZone.FileUpload />
              )}
            </DropZone>
          )}
        </Modal.Section>
      ) : (
        <Modal.Section>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Spinner size="large" />
          </div>
        </Modal.Section>
      )}
    </Modal>
  );
};

export default VariantsModal;
