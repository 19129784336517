import {createContext, useContext, useReducer} from 'react'
import axios from "@plugins/axios"
import {showToast} from "@plugins/toast"
import {navigate} from "@reach/router"

export const defaultDiscount = {
	min_qty: 3,
	discount: 10,
	type: 'PERCENTAGE'
}

export const initialState = {
	active: true,
	title: '',
	offer_heading: 'Buy more save more!',
	applies_to: 'all_products',
	target_products: [],
	target_collection: '',
	discounts: [
		{
			min_qty: 3,
			value: 5,
			type: 'PERCENTAGE'
		},
		{
			min_qty: 5,
			value: 10,
			type: 'FIXED_AMOUNT'
		}
	]
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)



export const createQtyDiscount = (state) => {
	return new Promise((resolve, reject) => {
		axios.post('/quantity-discounts', {
			...state
		})
			.then(resolve)
			.catch(reject)
	})
}
export const getQtyDiscount = id => {
	return new Promise(resolve => {
		axios.get(`/quantity-discounts?ids=${id}`)
			.then(response => {
				console.log(response, 'response')
				const {data} = response.data
				resolve(data && data[0])
			})
			.catch(function (error) {

			})
			.then(resolve)
	})
}
export const updateQtyDiscount = (data) => {
	const {_id, ...rest} = data
	return new Promise((resolve, reject) => {
		axios.post(`/quantity-discounts/${_id}`, rest)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const deleteQtyDiscounts = (ids, redirect = false) => {
	ids = ids.join(',')
	return new Promise((resolve, reject) => {
		axios.get(`/quantity-discounts/delete?ids=${ids}`)
			.then(response => {
				console.log(response, 'response')
				if (response && response.status === 200 && response.data.success) {
					showToast({
						message: 'QtyDiscount(s) deleted'
					})
					if (redirect) {
						navigate('/quantity-discounts')
					} else {
						setTimeout(() => {
							window.location.reload()
						}, 700)
					}
				}
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const getQtyDiscountsList = (params, cancelToken) => {
	const newParams = Object.assign({
		limit: 20,
		page: 1
	}, params)
	return new Promise(resolve => {
		axios.get('/quantity-discounts', {
			params: newParams,
			cancelToken: cancelToken
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
			})
			.then(resolve)
	})
}
