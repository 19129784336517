import {Card, FormLayout, TextStyle} from "@shopify/polaris"
import {useStore} from "@pages/pre-order/container"
import ResourcePicker from "@components/resourcePicker"

const ProductsCard = ({error}) => {
	const {state, dispatch} = useStore()
	const {target_product, _id} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card title={'Pre-order product'}>
			<Card.Section>
				<FormLayout>
					<ResourcePicker
						label={'Select product'}
						resourceType={'product'}
						value={target_product}
						onChange={v => {
							handleChange('target_product', v)
							console.log(v, '==============> v')
						}}
						isClearable
						error={error.includes('target_product')}
						isDisabled={!!_id}
					/>
					<TextStyle variation={'subdued'}>
						{!_id && <p>The products selected above will be switched to pre-order mode.</p>}
						{_id && <p>You cannot edit product anymore.</p>}
					</TextStyle>
				</FormLayout>
			</Card.Section>
		</Card>
	)
}

export default ProductsCard
