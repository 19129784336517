import {Card, FormLayout, ChoiceList, TextStyle} from "@shopify/polaris"
import {useStore} from "../container"
import ResourcePicker from "@components/resourcePicker"

const ProductsCard = () => {
	const {state, dispatch} = useStore()
	const {target_products, target_collection, applies_to} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card title="Applies to">
			<Card.Section >
				<TextStyle variation={'subdued'}>This countdown timer will be shown on these products or products in the selected collection below.</TextStyle>
			</Card.Section>
			<Card.Section subdued>
				<FormLayout>
					<ChoiceList
						choices={[
							{label: 'All products', value: 'all_products'},
							{label: 'Specific products', value: 'specific_products'},
							{label: 'Specific collection', value: 'specific_collection'},
						]}
						selected={applies_to}
						onChange={v => handleChange('applies_to', v[0])}
					/>
					{applies_to === 'specific_products' && (
						<ResourcePicker
							label={'Select products'}
							isMulti
							resourceType={'product'}
							value={target_products}
							onChange={v => handleChange('target_products', v)}
							isClearable
						/>
					)}

					{applies_to === 'specific_collection' && (
						<ResourcePicker
							label={'Select collection'}
							resourceType={'collection'}
							value={target_collection}
							onChange={v => handleChange('target_collection', v)}
							isClearable
						/>
					)}
				</FormLayout>
			</Card.Section>
		</Card>
	)
}

export default ProductsCard
