import {SkeletonPage, SkeletonBodyText, TextContainer, Layout, SkeletonDisplayText, Card} from '@shopify/polaris'
import React from "react"

const Skeleton = () => {
	return (
		<div style={{paddingTop: '2.5rem'}}>
			<SkeletonPage secondaryActions={2}>
				<Layout>
					<Layout.AnnotatedSection
						title={<SkeletonDisplayText />}
						description={<SkeletonBodyText lines={2} />}
					>
						<Card sectioned>
							<TextContainer>
								<SkeletonDisplayText size="small" />
								<SkeletonBodyText />
							</TextContainer>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection
						title={<SkeletonDisplayText />}
						description={<SkeletonBodyText lines={2} />}
					>
						<Card>
							<Card.Section>
								<TextContainer>
									<SkeletonDisplayText size="small" />
									<SkeletonBodyText lines={2} />
								</TextContainer>
							</Card.Section>
						</Card>
						<Card>
							<Card.Section>
								<TextContainer>
									<SkeletonDisplayText size="small" />
									<SkeletonBodyText lines={2} />
								</TextContainer>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
			</SkeletonPage>
		</div>
	)
}

export default Skeleton
