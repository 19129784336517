import React from 'react'
import {Router} from "@reach/router"
import IntegrationList from "@pages/integration"

export default function IntegrationPages() {
	return (
		<Router>
			<IntegrationList path="/" />
		</Router>
	)
}
