import FoxifyCard from '@components/foxifyCard';
import VersionWarning from '@components/versionWarning';
import { debounce } from '@helpers/utils';
import Page from '@modules/page';
import { SkeletonList } from '@modules/skeleton';
import { ContextProvider, useStore } from '@pages/images-variants-group/container';
import axios from '@plugins/axios';
import { navigate } from '@reach/router';
import { Layout } from '@shopify/polaris';
import React, { useState } from 'react';
import PaginationComponent from './components/pagination';
import Products from './components/products';
const ImagesVariant = () => {
  return (
    <ContextProvider>
      <ImagesVariantView />
    </ContextProvider>
  );
};

const ImagesVariantView = () => {
  const { state, dispatch } = useStore();
  const [loading, setLoading] = useState(true);
  const { products, pageInfo } = state;

  const getProducts = debounce(async (searchQuery) => {
    setLoading(true);

    try {
      const data = await axios.get(`/api/shopify/products-page`, {
        params: { ...searchQuery },
      });

      if (data.ok && data.payload) {
        const inputData = data.payload.products.edges.map((item) => ({
          cursor: item.cursor,
          title: item.node.title,
          status: item.node.status,
          image: item.node.featuredImage?.originalSrc,
          id: item.node.id,
          totalVariants: item.node.totalVariants,
        }));

        dispatch({
          type: 'setData',
          payload: { ...state, products: inputData, pageInfo: data.payload.products.pageInfo },
        });
      }
    } catch (error) {
      //   console.log(error);
    } finally {
      setLoading(false);
    }
  }, 300);

  return (
    <ContextProvider>
      <Page
        title="Products"
        breadcrumbs={[{ content: 'Boost conversion', onAction: () => navigate('/boost-conversion') }]}
      >
        <VersionWarning version={'v3.0.0'} feature="Variant group images" />
        {/* {loading && <SkeletonList />} */}
        {/* <div hidden={loading}> */}
        <Layout>
          <Layout.Section>
            <Products loading={loading} products={products} getProducts={getProducts} />
            <PaginationComponent
              products={products}
              getProducts={getProducts}
              pageInfo={pageInfo}
              setLoading={setLoading}
            />
          </Layout.Section>
        </Layout>
        {/* </div> */}
      </Page>
    </ContextProvider>
  );
};

export default ImagesVariant;
