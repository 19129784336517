import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextStyle, Icon, TextContainer, TextField, Button, Link, Card, Stack, ButtonGroup, Spinner } from '@shopify/polaris';
import { CancelMinor, StarOutlineMinor, StarFilledMinor } from '@shopify/polaris-icons';
import { updateSheet } from '@services/sheet';
import { useAuthStore } from '@container/Auth';
import { setCookie, getCookie } from '@shared/utils/cookie';
import { updateShopDataService } from '@services/shop';

const FeedbackCardStyle = styled.div`
  > .Polaris-Button {
    position: absolute;
    right: var(--p-space-4);
    top: var(--p-space-4);
  }
  img {
    margin-right: var(--p-space-5);
  }
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  [data-polaris-tooltip-activator]:before {
    border-bottom: none;
  }
  .star {
    cursor: pointer;
  }
  .Polaris-Icon {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const RATING_SCORES = [
  {
    title: 'Terrible 😡',
    rating: 1,
  },
  {
    title: 'Bad 😠',
    rating: 2,
  },
  {
    title: 'Average 🙁',
    rating: 3,
  },
  {
    title: 'Good 😃',
    rating: 4,
  },
  {
    title: 'Very Good 🥰',
    rating: 5,
  },
];

export default function FeedbackCard() {
  const storageKey = `FoxHome:AskFeedback.dismissed`;

  const [loading, setLoading] = useState(false);
  const [tempRating, setTempRating] = useState(0);
  const [rating, setRating] = useState(0);
  const [visible, setVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [fbSubmitted, setFbSubmitted] = useState(false);
  const [rated, setRated] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [updatedRange, setUpdatedRange] = useState('');
  const [themeforestUser, setUsername] = useState('');
  const { shop, email, shop_owner, themeRating, licenses } = useAuthStore();


  useEffect(() => {
    if (!shop) return;
    if (themeRating === '5') {
      setRating(5);
      setTempRating(5);
      setRated(true);
      setSubmitted(true);
    }

    if (licenses?.length) {
      setUsername(licenses[0]?.buyer);
    }

    const cookieValue = getCookie(storageKey);
    let shouldShow = true;

    if (cookieValue || (themeRating && themeRating !== '5')) {
      shouldShow = false;
    }

    setVisible(shouldShow);
  }, [shop, themeRating, licenses]);

  const onDismiss = () => {
    setVisible(false);
    setCookie(storageKey, 'true', 30);
  };

  const onMouseChange = (value) => {
    setTempRating(value);
  };

  const handleSubmit = async ({rating, withFeedback = false}) => {
    if (!rating) return;
    setRating(rating);
    setTempRating(rating);
    setLoading(true);
    let submitData = { shop, email, rating, shop_owner, feedback: feedback || RATING_SCORES[rating - 1]?.title, themeforestUser };
    if (!withFeedback) {
      if (updatedRange) submitData.range = updatedRange;
      const response = await updateSheet(submitData);
      setUpdatedRange(response?.updates?.updatedRange ?? response?.updatedRange);
    } else {
      await updateSheet({ ...submitData, range: updatedRange });
    }
    setLoading(false);
    setSubmitted(true);
    setFbSubmitted(withFeedback);
    await updateShopDataService( { themeRating: rating });
  };

  return visible ? (
    <Card sectioned title={'Help us improve Minimog and service'} actions={[
      {
        icon: CancelMinor,
        plain: true,
        onAction: onDismiss
      }
    ]}>
      <FeedbackCardStyle>
        <Stack gap={'4'} blockAlign={'center'}>
          <Stack.Item fill>
            <TextContainer>
              {submitted ? (
                <>
                  {rating === 5 ? (
                    <TextContainer>
                      {!rated && (
                        <p>
                          <TextStyle variation={'positive'}>Thanks for loving Minimog!</TextStyle>
                        </p>
                      )}
                      <p>
                        Let others know how our theme is working for you by leaving a review on the{' '}
                        <Link url={'https://foxecom.link/review-minimog'} target={'_blank'}>
                          ThemeForest
                        </Link>
                      </p>
                      <div style={{ marginTop: '1rem' }}>
                        <Button primary url={'https://foxecom.link/review-minimog'} external>
                          Write a review
                        </Button>
                      </div>
                    </TextContainer>
                  ) : (
                    <TextContainer gap={'1'} inlineAlign={'start'}>
                      {!fbSubmitted ? (
                        <>
                          <p>
                            <TextStyle variation={'positive'}>Thanks for rating us!</TextStyle>
                          </p>

                          <p>How could Minimog be better? Send us your idea and feedback.</p>
                          <div style={{ width: 400 }}>
                            <TextField
                              value={feedback}
                              multiline={5}
                              label={'Feedback'}
                              labelHidden
                              onChange={(v) => setFeedback(v)}
                              placeholder={'Write your feedback here'}
                            />
                          </div>
                          <div style={{ marginTop: '1rem' }}>
                            <ButtonGroup>
                              <Button onClick={() => handleSubmit({ rating, withFeedback: true })} primary disabled={!feedback} loading={loading}>
                                Send feedback
                              </Button>
                              <Button plain onClick={() => {
                                setSubmitted(false)
                                setRating(-1)
                                setTempRating(-1)
                              }}>
                                Edit rating?
                              </Button>
                            </ButtonGroup>
                          </div>
                        </>
                      ) : (
                        <>
                          <p>
                            <TextStyle variation={'positive'}>
                              Thanks for your feedback!
                            </TextStyle>
                          </p>
                          <p>
                            <span>We'll look into it and make sure unhappy scenarios won't happen again.</span>
                            <br />
                            <span>If we can help with anything, feel free to send us a message via live chat 🙂</span>
                          </p>
                          <div style={{ marginTop: '1rem' }}>
                            <Button onClick={() => window.$crisp && window.$crisp.push(['do', 'chat:open'])}>Chat with us</Button>
                          </div>
                        </>
                      )}
                    </TextContainer>
                  )}
                </>
              ) : (
                <>
                  <TextContainer gap={'3'} inlineAlign={'start'}>
                    <p>On a scale of 1 to 5, how would you rate your experience with Minimog?</p>
                    <Rating>
                      {RATING_SCORES.map((item) => {
                        let className = 'star';
                        let icon = StarOutlineMinor;
                        let filled = tempRating && tempRating >= item.rating;
                        if (filled) {
                          className += ' star--filled';
                          icon = StarFilledMinor;
                        }
                        return (
                          <div
                            onMouseOver={() => onMouseChange(item.rating)}
                            onMouseLeave={() => onMouseChange(rating ?? 0)}
                            onClick={() => handleSubmit({rating: item.rating})}
                            className={className}
                            key={item.rating}
                          >
                            <Icon source={icon} />
                          </div>
                        );
                      })}
                      <div style={{ marginLeft: 10 }}>
                        <span>{RATING_SCORES[tempRating - 1]?.title}</span>
                      </div>
                      {loading && (
                        <div style={{ marginLeft: 10 }}>
                          <Spinner size={'small'} />
                        </div>
                      )}
                    </Rating>
                  </TextContainer>
                </>
              )}
            </TextContainer>
          </Stack.Item>
          <Stack.Item>
            <img width={120} src="https://cdn.shopify.com/s/files/1/0594/4190/5837/files/feedback.svg?v=1695124654" alt="Leave feedback" />
          </Stack.Item>
        </Stack>
      </FeedbackCardStyle>
    </Card>
  ) : (
    ''
  );
}
