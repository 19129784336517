import axios from '@plugins/axios';

const LICENSE = '/api/license';
const LICENSE_CREATE = LICENSE;

export const addPurchaseCodeService = async (shop, purchaseCode, product) => {
  const response = await axios.post(LICENSE_CREATE, {
    shop,
    code: purchaseCode,
    product
  });

  return response?.payload;
};
