import Page from '@modules/page'
import {navigate} from "@reach/router"
import {Layout, ContextualSaveBar} from "@shopify/polaris"
import {ContextProvider} from "./container"
import TimerCard from "./components/timer"
import FlashSaleCard from "./components/flash-sale"
import ProductsCard from "./components/products"
import TitleCard from "./components/title"
import StatusCard from "./components/status"
import {useCallback, useEffect, useState} from "react"
import {useStore} from "./container"
import API from "@helpers/api"
import {showToast} from "@plugins/toast"
import {checkRequiredFields} from "@helpers/utils"
import Preview from "@pages/countdown-timer/components/preview"
import LayoutSection from "@modules/layoutSection"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'

const CreateCountDown = () => {
	return (
		<ContextProvider>
			<CreateCountDownView />
		</ContextProvider>
	)
}

const CreateCountDownView = () => {
	const [loading, setLoading] = useState(false)
	let [error, setError] = useState([])
	const {state} = useStore()
	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState(state)

	const isEqualState = isEqual(omit(state, ['updatedAt']), omit(prevState, ['updatedAt']))


	useEffect(() => {
		const handleReloadPage = (e) => {
		  e.preventDefault()
		  e.returnValue = ""
		}
		if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
		return () => window.removeEventListener("beforeunload", handleReloadPage)
	  }, [isEqualState])
	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);
	const handleSave = async () => {
		error = await checkRequiredFields(['duration', 'title'], state)
		if (error.length > 0) {
			setError(error)
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: 'Please fill in all the mandatory fields',
				error: true
			})
		}

		const {target_products, target_collection, schedule_time, schedule} = state
		setLoading(true)
		API.create('countdown', {
			...state,
			target_products: target_products?.filter(Boolean).map(p => p.value),
			target_collection: target_collection.value,
			schedule_time: schedule ? schedule_time : new Date()
		}).then(res => {
			console.log(res, 'res')
			setPrevState({
				...prevState, 
				...res.payload, 
				target_products: target_products, 
				target_collection: target_collection,
				schedule_time: schedule_time
			})
			if (res.ok) {
				navigate(`/countdown-timer/${res.payload._id}`)
				return showToast({
					message: 'Countdown created'
				})
			}
		})
			.finally(() => setLoading(false))
	}

	return (
		<Page
			title="Create countdown timer"
			breadcrumbs={[{content: 'Countdown timer', onAction: () => isEqualState ? navigate('/countdown-timer') : handleModalChange()}]}
		>
			{!isEqualState && <ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: handleSave,
					loading: loading,
					disabled: false,
				}}
				discardAction={{
					onAction: () => handleModalChange()
				}}
			/>}
			<Layout>
				<Layout.Section oneHalf>
					<StatusCard />
					<TitleCard error={error} />
					<TimerCard error={error} />
					<ProductsCard />
					<FlashSaleCard />
				</Layout.Section>
				<LayoutSection oneHalf isStuck>
					<Preview />
				</LayoutSection>
			</Layout>
			<DiscardModal 
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink="/countdown-timer"
			/>
		</Page>
	)
}

export default CreateCountDown
