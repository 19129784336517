import AppNavigation from '@modules/navigation';
import AppTopBar from '@modules/topBar';
import { Frame } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import axios from '@plugins/axios';
import { useAuthStore } from '@container/Auth';

function AppFrame({ children }) {
  const { currentUser } = useAuthStore();
  const shop = axios.defaults.headers.common['X-Request-Shop-Origin'];
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const hidden = !currentUser || !shop;

  return (
    <Frame
      topBar={!hidden && <AppTopBar onNavigationToggle={toggleMobileNavigationActive} />}
      navigation={!hidden && <AppNavigation />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
    >
      {children}
    </Frame>
  );
}

export default AppFrame;
