import { Badge, Button, ButtonGroup } from '@shopify/polaris';
import { ViewMajor } from '@shopify/polaris-icons';
import differenceInDays from 'date-fns/differenceInDays';
import styled from 'styled-components';
import { useAuthStore } from '../container/Auth';

const CardInner = styled.div`
  border-radius: 5px;
  padding: 5px;
  background: #fff;
  box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
  &:hover {
    .preview-bg {
      background-position: 0 100%;

      @media (max-width: 1024px) {
        background-position: unset;
      }
    }
  }
  .preview {
    height: 400px;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }
  .os {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
  }
  .badges {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;
    display: flex;
    align-items: center;
    .Polaris-Badge {
      margin-right: 10px;
    }
    .Polaris-Badge--statusCritical {
      background: var(--p-icon-critical);
      color: #fff;
    }
    .Polaris-Badge--statusAttention {
      background-color: #ffdf4f;
    }
  }
  .Polaris-Badge {
    font-weight: 500;
  }
  .preview-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 0%;
    transition: 1.8s all ease-in-out;
  }
`;

const DemoCard = ({ selectedTheme, url, name, preview, demo_id, handleClick, osVersion, updated_at, tag, ...props }) => {
  const { themeList } = useAuthStore();

  const dateFromNow = differenceInDays(new Date(), new Date(updated_at));
  const isNew = dateFromNow < 15;

  return (
    <CardInner className={'c-col flex flex-col text-center'} id={demo_id}>
      <div className="preview relative">
        <div className="badges">
          {isNew && <Badge status={'attention'}>NEW</Badge>}
          {tag === 'hot' && <Badge status={'critical'}>HOT</Badge>}
        </div>
        <span className="os">
          <Badge status={'success'}>OS {osVersion}</Badge>
        </span>
        <a href={url} target={'_blank'} className="preview-bg" style={{ backgroundImage: `url(${preview})` }} rel="noreferrer" />
      </div>
      <div className="flex justify-between items-center w-full card-control" style={{ padding: '20px 15px' }}>
        <h3 className="text-2.5xl font-medium">{name}</h3>
        <ButtonGroup>
          <Button url={url} external icon={ViewMajor} />
          <Button primary onClick={() => handleClick(demo_id)}>
            {themeList?.includes(selectedTheme) ? 'Install' : 'Try FREE'}
          </Button>
        </ButtonGroup>
      </div>
    </CardInner>
  );
};

export default DemoCard;
