import styled from "styled-components"
import {Card, ChoiceList, FormLayout} from "@shopify/polaris"
import {useStore} from "../container"
import Countdown, { zeroPad } from 'react-countdown'
const PreviewWrapper = styled.div`
	h3 {
		color: #ff706b;
		display: flex;
		align-items: center;
		font-weight: 500;
		svg {
			margin-right: 10px;
		}
	}
	.countdown-timer {
		margin: 1.5rem -5px 0;
		> div {
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;
			color: #ff706b;
			width: 65px;
			height: 65px;
			border: 1px solid #ffd7d6;
			font-weight: 500;
			font-size: 24px;
			background-color: #fff5f4;
			border-radius: 3px;
			margin: 0 5px;
			span:last-child {
				color: #666;
				font-size: 11px;
				text-transform: uppercase;
				margin-top: 5px;
			}
		}
	}
	
	&.style-2 {
		margin-top: 20px;
		.countdown-timer__inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #FDEFEE;
			border: 1px solid #F8CCCC;
			box-sizing: border-box;
			border-radius: 5px;
			padding: 15px 10px !important;
			width: 100%;
			h3 {
				font-size: 16px;
			}
		}
		.countdown-timer {
			margin: 0;
			> div {
				width: auto;
				padding: 0 5px;
				height: auto;
				background: none;
				border: none;
				font-size: 18px;
				margin: 0 2px;
				position: relative;
				&:not(:last-child):after {
					content: ':';
					position: absolute;
					right: -4px;
					top: -2px;
				}
			}
			> div > span:last-child {
				display: none;
			}
		}
	}
	
	&.product-card {
		text-align: center;
		h3 {
			display: block;
		}
		.price {
			margin-top: 10px;
			font-weight: 500;
			font-size: 1.75rem;
			del {
				margin-right: 10px;
				opacity: .6;
			}
		}
		.progress {
			margin-top: 15px;
			&-bar {
				background: rgba(192, 44, 48, 0.12);
				height: 5px;
				width: 100%;
				position: relative;
				span {
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					background: #C02C30;
					width: 20%;
				}
			}
			.flex {
				justify-content: space-between;
				font-size: 18px;	
				margin-top: 10px;
				font-weight: 500;
				color: #777;
				span {
					color: #000;
				}
			}
		}
	}
`

const renderer = ({ days, hours, minutes, seconds }) => {

	return (
		<div className={'countdown-timer flex'}>
			{days > 0 && (
				<div>
					<span>{zeroPad(days)}</span>
					<span>Days</span>
				</div>
			)}
			<div>
				<span>{zeroPad(hours)}</span>
				<span>Hrs</span>
			</div>
			<div>
				<span>{zeroPad(minutes)}</span>
				<span>Mins</span>
			</div>
			<div>
				<span>{zeroPad(seconds)}</span>
				<span>Secs</span>
			</div>
		</div>
	)
}

const Preview = () => {
	const {state, dispatch} = useStore()
	const {duration, title, cdt_type, expires_date, show_flashsale, sold_from, cdt_style, total_quantity} = state

	const timer = cdt_type === 'evergreen' ? Date.now() + Number(duration) * 1000 * 60 * 60 : expires_date

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<>
			<Card sectioned title={'Preview'}>
				<FormLayout>
					<ChoiceList
						title={'Countdown style'}
						choices={[
							{label: 'Style 1', value: 'style-1'},
							{label: 'Style 2', value: 'style-2'},
						]}
						selected={cdt_style}
						onChange={v => handleChange('cdt_style', v[0])}
					/>
				</FormLayout>
				<PreviewWrapper className={`flex items-center flex-col ${cdt_style}`}>
					<div className={'countdown-timer__inner'} style={{padding: '2rem 0'}}>
						<h3 className={'text-3xl'}>
							{title}
						</h3>
						<Countdown date={timer} renderer={renderer} />
					</div>
				</PreviewWrapper>
			</Card>
			{show_flashsale && (
				<Card sectioned title={'Flash sale preview'}>
					<PreviewWrapper className={'flex items-center flex-col product-card'}>
						<div style={{padding: '2rem'}}>
							<img src="https://cdn.shopify.com/s/files/1/0591/1350/4958/products/35_056fa070-83a6-4279-8627-bbb11aace6e8_720x.jpg" alt="" style={{maxWidth: '100%'}} />
							<h3 className={'text-3xl mt-2 text-center'} style={{marginTop: 10, color: '#000'}}>
								Denim jacket
							</h3>
							<div className="price">
								<del>$59.45</del>
								<span>$44.99</span>
							</div>
							<div className="progress">
								<div className="progress-bar"><span style={{width: `${parseInt(sold_from) + 2/total_quantity * 100}%`}} /></div>
								<div className="flex items-center">
									<div>Sold: <span>{parseInt(sold_from) + 2}</span></div>
									<div>Available: <span>{total_quantity - sold_from - 2}</span></div>
								</div>
							</div>
						</div>
					</PreviewWrapper>
				</Card>
			)}
		</>
	)
}
export default Preview
