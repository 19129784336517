import {Card, Checkbox, FormLayout} from "@shopify/polaris"
import {useStore, filterPassedTime} from "../container"
import DatePicker from '@components/datePicker'

const StatusCard = () => {
	const {state, dispatch} = useStore()
	const {active, schedule, schedule_time} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card sectioned title={'Status'}>
			<FormLayout>
				<Checkbox
					label={'Active'}
					checked={active}
					onChange={v => handleChange('active', v)}
				/>
				<Checkbox
					checked={schedule}
					label={'Schedule a time to start the countdown'}
					onChange={v => handleChange('schedule', v)}
				/>
				{schedule && <DatePicker
					selected={new Date(schedule_time)}
					label={'Pick the start time'}
					onChange={v => handleChange('schedule_time', v)}
					dateFormat="yyyy/MM/dd h:mm aa"
					showTimeSelect
					minDate={new Date()}
					filterTime={filterPassedTime}
				/>}
			</FormLayout>
		</Card>
	)
}

export default StatusCard
