import {Card, FormLayout, ChoiceList, TextStyle} from "@shopify/polaris"
import {useStore} from "../container"
import ResourcePicker from "@components/resourcePicker"

const ProductsCard = ({error}) => {
	const {state, dispatch} = useStore()
	const {target_products, target_collection, applies_to} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card>
			<Card.Section subdued title="Target to">
				<TextStyle variation={'subdued'}>If customers visit these product pages, the recommendation will show up.</TextStyle>
			</Card.Section>
			<Card.Section subdued>
				<FormLayout>
					<ChoiceList
						choices={[
							{label: 'All products', value: 'all_products'},
							{label: 'Specific products', value: 'specific_products'},
							{label: 'Specific collection', value: 'specific_collection'},
						]}
						selected={applies_to}
						onChange={v => handleChange('applies_to', v[0])}
					/>
					{applies_to === 'specific_products' && (
						<ResourcePicker
							label={'Specific products'}
							isMulti
							labelHidden
							resourceType={'product'}
							value={target_products}
							onChange={v => handleChange('target_products', v)}
							isClearable
							error={error.includes('target_products')}
							placeholder={'Search products'}
						/>
					)}
					{applies_to === 'specific_collection' && (
						<ResourcePicker
							label={'Specific collection'}
							labelHidden
							resourceType={'collection'}
							value={target_collection}
							onChange={v => handleChange('target_collection', v)}
							isClearable
							error={error.includes('target_collection')}
							placeholder={'Search collection'}
						/>
					)}
				</FormLayout>
			</Card.Section>
		</Card>
	)
}

export default ProductsCard
