import React, {useState} from "react"
import {showToast} from "@plugins/toast"
import {Modal, TextContainer} from "@shopify/polaris"
import axios from "@plugins/axios"

const PublishModal = ({open, handleChange, selectedTheme, forceUpdate}) => {
	const [loading, setLoading] = useState(false)

	const handlePublish = async (theme) => {
		setLoading(true)
		await axios.put(`/api/shopify/theme/admin?id=${selectedTheme.shopify_theme_id}`, {
			role: 'main'
		})
			.then(response => {
				if (response.ok) {
					forceUpdate()
					showToast({
						message: 'Theme published'
					})
				}
			})
			.catch(error => {
				showToast({
					message: error.message,
					error: true
				})
			})
			.finally(() => {
				setLoading(false)
				handleChange()
			})
	}

	return (
		<Modal
			open={open}
			onClose={!loading ? handleChange : void (0)}
			title={`Publish ${selectedTheme.display_name}`}
			primaryAction={{
				content: 'Publish',
				onAction: handlePublish,
				disabled: loading,
				loading: loading
			}}
			secondaryActions={[
				{
					content: 'Cancel',
					onAction: handleChange,
					disabled: loading
				}
			]}
		>
			<Modal.Section>
				<TextContainer>
					<p>Are you sure you want to publish <strong>{selectedTheme.display_name}</strong>? This will replace your current theme.</p>
				</TextContainer>
			</Modal.Section>
		</Modal>
	)
}

export default PublishModal
