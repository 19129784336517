const {REACT_APP_SHOW_ANNOUNCEMENT, REACT_APP_GTM_ID,  REACT_APP_GTM_AUTH, REACT_APP_GTM_PREVIEW, REACT_APP_MIXPANEL_TOKEN, NODE_ENV} = process.env

const variables = {
	SHOW_ANNOUNCEMENT: REACT_APP_SHOW_ANNOUNCEMENT,
	GTM_ID: REACT_APP_GTM_ID,
	GTM_AUTH: REACT_APP_GTM_AUTH,
	GTM_PREVIEW: REACT_APP_GTM_PREVIEW,
	MIXPANEL_TOKEN: REACT_APP_MIXPANEL_TOKEN,
	NODE_ENV: NODE_ENV
}

export default variables
