import {Card, FormLayout, TextField, Stack, Checkbox, TextStyle, Link} from "@shopify/polaris"
import {useStore} from "../container"
import {useAuthStore} from "../../../container/Auth"

const FlashSaleCard = () => {
	const {state, dispatch} = useStore()
	const {sold_from, sold_to, cdt_type, total_quantity, show_flashsale, applies_to} = state

	const {shop_domain} = useAuthStore()

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	console.log(shop_domain, 'shop')

	return (
		<Card title={'🔥 Flash Sale'}>
			<Card.Section >
				<TextStyle variation={'subdued'}>
					<p>If you enable this feature, the progress bar which stays on the product card will show the number of products sold.</p>
					<p style={{marginTop: 10}}>To enable flash sale, the <strong>Countdown type</strong> must be <strong>Fixed time</strong> and <strong>Applies to</strong> is <strong>Specific collection</strong>.</p>
				</TextStyle>
			</Card.Section>
			{cdt_type === 'fixed_time' && applies_to === 'specific_collection' && (
				<>
					<Card.Section>
						<FormLayout>
							<Checkbox
								label={'Enable flash sale'}
								checked={show_flashsale}
								onChange={v => handleChange('show_flashsale', v)}
							/>
							<TextField
								label={'Total quantity'}
								value={total_quantity + ''}
								onChange={v => handleChange('total_quantity', Number(v))}
								type={'number'}
								min={5}
							/>
							<div>
								<div className="Polaris-Labelled__LabelWrapper">
									<div className="Polaris-Label">
										<label className="Polaris-Label__Text">Number sold in range</label>
									</div>
								</div>
								<Stack>
									<TextField
										label={'From'}
										labelHidden
										value={sold_from + ''}
										onChange={v => handleChange('sold_from', Number(v))}
										type={'number'}
										min={0}
										max={total_quantity}
										prefix={'From'}
									/>
									<TextField
										label={'To'}
										labelHidden
										value={sold_to + ''}
										onChange={v => handleChange('sold_to', Number(v))}
										type={'number'}
										min={0}
										prefix={'To'}
										max={total_quantity}
									/>
								</Stack>
							</div>
						</FormLayout>
					</Card.Section>
					<Card.Section subdued>
						<p>The number of sold will be shown randomly in the range above and applies to all products.</p>
						<p style={{marginTop: 10}}>You can override sold number of an individual product by using a custom <Link external url={`https://${shop_domain}/admin/metafields/product/create`}>product metafield</Link> with the following namespace:</p>
						<p style={{marginTop: 10}}><TextStyle variation="code">foxkit.sold_number</TextStyle></p>
						<p style={{marginTop: 10}}>The value of custom metafield is an integer</p>
					</Card.Section>
				</>
			)}
		</Card>
	)
}

export default FlashSaleCard
