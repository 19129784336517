import {Card, Checkbox, FormLayout, TextField} from "@shopify/polaris"
import {useStore} from "../container"
const TitleCard = ({error}) => {
	const {state, dispatch} = useStore()
	const {title, active} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card title={'Details'}>
			<Card.Section>
				<FormLayout>
					<Checkbox
						label={'Active'}
						checked={active}
						onChange={v => handleChange('active', v)}
					/>
				</FormLayout>
			</Card.Section>
			<Card.Section>
				<FormLayout>
					<TextField
						label={'Title'}
						value={title}
						placeholder={'Men\'s clothing'}
						onChange={v => handleChange('title', v)}
						error={error.includes('title')}
					/>
				</FormLayout>
			</Card.Section>
		</Card>
	)
}

export default TitleCard
