import Page from '@modules/page'
import { navigate, useParams } from "@reach/router"
import { ContextualSaveBar, Layout, PageActions } from "@shopify/polaris"
import { ContextProvider } from "./container"
import TitleCard from "./components/title"
import DiscountCard from "./components/discount"
import ProductsCard from "./components/products"
import StatusCard from "./components/status"
import RecommendedProducts from "./components/recommned_products"
import { useCallback, useEffect, useState } from "react"
import { useStore } from "./container"
import { CancelToken } from "@plugins/axios"
import API from "@helpers/api"
import { checkRequiredFields } from "@helpers/utils"
import { showToast } from "@plugins/toast"
import StatusBadge from "@components/statusBadge"
import { Skeleton } from "@modules/skeleton"
import { showConfirm } from "@plugins/confirm"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'

const EditPrePurchase = () => {
	return (
		<ContextProvider>
			<EditPrePurchaseView />
		</ContextProvider>
	)
}

const EditPrePurchaseView = () => {
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	let [error, setError] = useState([])
	const { pageID } = useParams()
	const { state, dispatch } = useStore()
	const source = CancelToken.source()
	const { active, title, _id } = state
	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState({})

	const isEqualState = isEqual(omit(state, ['created_at', 'updated_at', 'heading', 'product', 'updatedAt']), omit(prevState, ['updatedAt']))

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

	useEffect(() => {
		const handleReloadPage = (e) => {
		  e.preventDefault()
		  e.returnValue = ""
		}
		if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
		return () => window.removeEventListener("beforeunload", handleReloadPage)
	  }, [isEqualState])

	useEffect(() => {
		let result
		const fetchData = async () => {
			try {
				result = await API.getDetail('pre-purchase', pageID, source.token)
				console.log('Data details', result)
				if (result && result.ok && result.payload) {
					dispatch({ type: 'setData', payload: result.payload })
					if (!result.payload.hasOwnProperty("recommended_collection")) {
						result.payload.recommended_collection = ""
					}
					if (!result.payload.hasOwnProperty("target_collection")) {
						result.payload.target_collection = ""
					}
					setPrevState(result.payload)
					setReady(true)
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
		return () => source.cancel()
	}, [pageID])
	const handleSave = async () => {
		const requiredFields = ['title']
		const { applies_to, recommend_to, target_products, target_collection, recommended_products, recommended_collection } = state
		if (applies_to === 'specific_products') {
			requiredFields.push('target_products')
		}
		if (applies_to === 'specific_collection') {
			requiredFields.push('target_collection')
		}
		if (recommend_to === 'specific_products') {
			requiredFields.push('recommended_products')
		}
		if (recommend_to === 'specific_collection') {
			requiredFields.push('recommended_collection')
		}
		error = await checkRequiredFields(requiredFields, state)
		if (error.length > 0) {
			setError(error)
			window.scrollTo({ top: 0, behavior: 'smooth' })
			return showToast({
				message: 'Please fill in all the mandatory fields',
				error: true
			})
		}

		setSaving(true)
		API.update('pre-purchase', {
			...state,
			target_products: target_products?.filter(Boolean).map(p => p.value),
			target_collection: target_collection.value,
			recommended_products: recommended_products.map(p => p.value),
			recommended_collection: recommended_collection.value
		}).then((res) => {
			if (res.ok) {
				setPrevState({
					...prevState,
					...res.payload,
					target_products: target_products,
					target_collection: target_collection,
					recommended_products: recommended_products,
					recommended_collection: recommended_collection
				})
				return showToast({
					message: 'Updated successfully'
				})
			}
		}).finally(() => setSaving(false))
	}

	const handleDelete = () => {
		showConfirm({
			title: `Delete ${title}?`,
			message: 'This can’t be undone.',
			danger: true,
			confirm: 'Delete'
		}).then(res => {
			if (res) {
				API.bulkDelete('pre-purchase', [_id]).then((res) => {
					if (res.ok) {
						showToast({
							message: 'Offer deleted'
						})
						return navigate('/pre-purchase')
					} else {
						showToast({
							message: 'Cannot delete, please try again',
							error: true
						})
					}
				})
			}
		})
	}

	return ready ? (
		<Page
			title={title}
			breadcrumbs={[{ content: 'Pre-purchases', onAction: () => isEqualState ? navigate('/pre-purchase') : handleModalChange() }]}
			titleMetadata={<StatusBadge status={active} />}
		>
			{!isEqualState && <ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: handleSave,
					loading: saving,
					disabled: false,
				}}
				discardAction={{
					onAction: () => handleModalChange(),
				}}
			/>}
			<Layout>
				<Layout.Section>
					<TitleCard error={error} />
					<ProductsCard error={error} />
					<RecommendedProducts error={error} />
				</Layout.Section>
				<Layout.Section secondary>
					<StatusCard />
					<DiscountCard />
				</Layout.Section>
			</Layout>
			<PageActions
				primaryAction={{
					content: 'Save',
					loading: saving,
					onAction: handleSave

				}}
				secondaryActions={[
					{
						content: 'Delete',
						onAction: handleDelete,
						destructive: true,
					},
				]}
			/>
			<DiscardModal 
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink='/pre-purchase'
			/>
		</Page>
	) : <Skeleton />
}

export default EditPrePurchase
