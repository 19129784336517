import { Modal } from '@shopify/polaris';
import { createContext, useContext, useState } from 'react';

const defaultOptionsConfirmBox = {
  title: 'dialog.confirm_title',
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
  message: 'dialog.confirm_message',
  onConfirm: null,
  onClose: null,
  danger: false,
  footer: null,
  callbackAfterClose: null,
};

const NotificationContext = createContext({
  openSurvey: false,
  previewModalData: {},
  showConfirm: () => {},
  handleCloseConfirmBox: () => {},
  showToast: () => {},
  toggleWarningPlan: () => {},
  toggleSurvey: () => {},
  togglePreviewModal: () => {},
});

export const useNotificationStore = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionsConfirmBox, setOptionsConfirmBox] = useState(defaultOptionsConfirmBox);

  const resetConfirmBox = (confirm) => {
    setLoading(false);

    setOpenConfirmBox(false);

    setOptionsConfirmBox({
      ...defaultOptionsConfirmBox,
    });

    optionsConfirmBox?.callbackAfterClose && optionsConfirmBox.callbackAfterClose(confirm);
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (optionsConfirmBox.onConfirm) {
      const onConfirmAsyncFunc = optionsConfirmBox.onConfirm();
      onConfirmAsyncFunc
        .then(() => {
          resetConfirmBox(true);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      resetConfirmBox(true);
    }
  };

  const handleCloseConfirmBox = () => {
    if (optionsConfirmBox.onClose) optionsConfirmBox.onClose();
    resetConfirmBox(false);
  };

  const showConfirm = (optionsInp) => {
    setOptionsConfirmBox({
      ...defaultOptionsConfirmBox,
      ...optionsInp,
    });
    setOpenConfirmBox(true);
  };
  const ctx = {
    showConfirm,
    handleCloseConfirmBox,
  };
  return (
    <NotificationContext.Provider value={ctx}>
      {children}
      {openConfirmBox && (
        <Modal
          title={optionsConfirmBox.title}
          open={true}
          primaryAction={
            optionsConfirmBox?.footer
              ? null
              : {
                  content: optionsConfirmBox.confirmButtonText,
                  onAction: handleConfirm,
                  destructive: optionsConfirmBox.danger ? 'danger' : undefined,
                  loading: loading,
                  disabled: loading,
                }
          }
          secondaryActions={
            optionsConfirmBox?.footer
              ? null
              : [
                  {
                    content: optionsConfirmBox.cancelButtonText,
                    onAction: handleCloseConfirmBox,
                  },
                ]
          }
          onClose={handleCloseConfirmBox}
        >
          <Modal.Section>
            <div dangerouslySetInnerHTML={{ __html: optionsConfirmBox.message }} />
          </Modal.Section>
          {optionsConfirmBox?.footer && <Modal.Section>{optionsConfirmBox?.footer}</Modal.Section>}
        </Modal>
      )}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
