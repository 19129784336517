import {createContext, useContext, useReducer} from 'react'

export const initialState = {
	active: false,
	connected: false,
	page_id: '',
	language: 'en_US',
	theme_color: '#0A7CFF',
	logged_in_greeting: 'Hi! How can we help you?',
	logged_out_greeting: 'Hi! How can we help you?',
	position: 'right',
	auto_expand: false
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)


export const languageOptions = [
	{value: "af_ZA", label: "Afrikaans"},
	{value: "ar_AR", label: "العربية&lt;"},
	{value: "bn_IN", label: "বাংলা"},
	{value: "cs_CZ", label: "Čeština"},
	{value: "da_DK", label: "Dansk"},
	{value: "de_DE", label: "Deutsch"},
	{value: "el_GR", label: "Ελληνικά"},
	{value: "en_GB", label: "English (UK)"},
	{value: "en_US", label: "English (US)"},
	{value: "es_ES", label: "Español (España)"},
	{value: "es_LA", label: "Español (Spanish)"},
	{value: "fi_FI", label: "Suomi"},
	{value: "fr_FR", label: "Français (France)"},
	{value: "gu_IN", label: "ગુજરાતી"},
	{value: "he_IL", label: "עברית"},
	{value: "hi_IN", label: "हिन्दी"},
	{value: "hr_HR", label: "Hrvatski"},
	{value: "hu_HU", label: "Magyar"},
	{value: "id_ID", label: "Bahasa Indonesia"},
	{value: "it_IT", label: "Italiano"},
	{value: "ja_JP", label: "日本語"},
	{value: "kn_IN", label: "ಕನ್ನಡ"},
	{value: "ko_KR", label: "한국어"},
	{value: "ml_IN", label: "മലയാളം"},
	{value: "mr_IN", label: "मराठी"},
	{value: "ms_MY", label: "Bahasa Melayu"},
	{value: "my_MM", label: "မြန်မာဘာသာ"},
	{value: "nb_NO", label: "Norsk (bokmål)"},
	{value: "nl_NL", label: "Nederlands"},
	{value: "pa_IN", label: "ਪੰਜਾਬੀ"},
	{value: "pl_PL", label: "Polski"},
	{value: "pt_BR", label: "Português (Brasil)"},
	{value: "pt_PT", label: "Português (Portugal)"},
	{value: "ro_RO", label: "Română"},
	{value: "ru_RU", label: "Русский"},
	{value: "si_LK", label: "සිංහල"},
	{value: "sk_SK", label: "Slovenčina"},
	{value: "sr_RS", label: "Српски"},
	{value: "sv_SE", label: "Svenska"},
	{value: "sw_KE", label: "Kiswahili"},
	{value: "ta_IN", label: "தமிழ"},
	{value: "te_IN", label: "తెలుగు"},
	{value: "th_TH", label: "ภาษาไทย"},
	{value: "tl_PH", label: "Filipino"},
	{value: "tr_TR", label: "Türkçe"},
	{value: "ur_PK", label: "اردو"},
	{value: "vi_VN", label: "Tiếng Việt"},
	{value: "zh_CN", label: "中文(简体)"},
	{value: "zh_HK", label: "中文(香港)"},
	{value: "zh_TW", label: "中文(台灣)"}
]
