import {Card, FormLayout} from "@shopify/polaris"
import {useStore, presets} from "../container"
import RichText from "@components/richtext"
import ImageSelect from "@components/imageSelect"
import MensTopIcon from '@assets/mens-top.png'
import WomentsTopIcon from '@assets/womens-top.png'
import MensBottomIcon from '@assets/mens-bottom.png'
import MensShoesIcon from '@assets/mens-shoes.png'
import WomentsBottomIcon from '@assets/womens-bottom.png'
import WomentsShoesIcon from '@assets/womens-shoes.png'
import BoyBottomIcon from '@assets/boys-bottom.png'
import BoyTopIcon from '@assets/boys-top.png'
import GirlBottomIcon from '@assets/girls-bottom.png'
import GirlTopIcon from '@assets/girls-top.png'
import KidsShoesIcon from '@assets/kids-shoes.png'
import BLankIcon from '@assets/none.png'

const ChartContent = ({error}) => {
	const {state, dispatch} = useStore()
	const {chart_content, preset} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card title={'Chart content'}>
			<Card.Section>
				<FormLayout>
					<ImageSelect
						label={'Choose preset'}
						options={[
							{
								label: 'None',
								value: "none",
								image: BLankIcon
							},
							{
								label: "Men's top",
								value: "mens_top",
								image: MensTopIcon
							},
							{
								label: "Men's bottom",
								value: "mens_bottom",
								image: MensBottomIcon
							},
							{
								label: "Men's shoes",
								value: "mens_shoes",
								image: MensShoesIcon
							},
							{
								label: "Women's top",
								value: "womens_top",
								image: WomentsTopIcon
							},
							{
								label: "Women's bottom",
								value: "womens_bottom",
								image: WomentsBottomIcon
							},
							{
								label: "Women's shoes",
								value: "womens_shoes",
								image: WomentsShoesIcon
							},
							{
								label: "Boy's top",
								value: "boy_top",
								image: BoyTopIcon
							},
							{
								label: "Boy's bottom",
								value: "boy_bottom",
								image: BoyBottomIcon
							},
							{
								label: "Girl's top",
								value: "girl_top",
								image: GirlTopIcon
							},
							{
								label: "Girl's bottom",
								value: "girl_bottom",
								image: GirlBottomIcon
							},
							{
								label: "Kid's shoes",
								value: "kid_shoes",
								image: KidsShoesIcon
							}
						]}
						value={preset}
						onChange={v => {
							handleChange('preset', v)
							handleChange('chart_content', presets[v])
						}}
						imgWidth={70}
					/>
					<RichText
						label={'Chart content'}
						labelHidden
						value={chart_content}
						onChange={v => handleChange('chart_content', v)}
						height={500}
						error={error.includes('chart_content')}
					/>
				</FormLayout>
			</Card.Section>
		</Card>
	)
}

export default ChartContent
