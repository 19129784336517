import { Banner, Button, ButtonGroup, Link, FormLayout } from "@shopify/polaris"
import React from "react"
import { useAuthStore } from "@container/Auth";
import { navigate } from "@reach/router";
import { compareVersions } from "@helpers/utils";
const InfoBanner = () => {
  const { appType, currentTheme } = useAuthStore();
  const isFoxHomeUser = appType === 'foxhome' && compareVersions('3.4.0', currentTheme?.theme_version) > 0
  return isFoxHomeUser ? (
    <div style={{ paddingBottom: '1.6rem' }}>
      <Banner
        title={`Action recommended.`}
        status="info"
      >
        <FormLayout>
          <p>Use <Link url={'https://foxecom.link/foxkit'} external><strong>FoxKit app</strong></Link> and <strong>Minimog</strong> version 3.5.0 and above to explore the limitless power of Minimog x FoxKit x FoxHome.</p>
          <ButtonGroup>
            <Button url={'https://foxecom.link/foxkit'} external>Install FoxKit</Button>
            <Button onClick={() => navigate('/theme/manage')}>Upgrade Minimog</Button>
          </ButtonGroup>
        </FormLayout>
      </Banner>
    </div>
  ) : ''
}
export default InfoBanner
