import API from '@helpers/api'
import { checkRequiredFields } from "@helpers/utils"
import Page from '@modules/page'
import { ContextProvider, useStore } from "@pages/pre-order/container"
import { showToast } from "@plugins/toast"
import { navigate } from "@reach/router"
import { ContextualSaveBar, Layout } from "@shopify/polaris"
import React, { useCallback, useEffect, useState } from "react"
import DiscountCard from "./components/discount"
import ProductsCard from "./components/products"
import StatusCard from "./components/status"
import ETA from "./components/eta"
import VersionWarning from "@components/versionWarning"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'

const CreateProduct = () => {
  return (
    <ContextProvider>
      <CreateProductView />
    </ContextProvider>
  )
}

const CreateProductView = () => {
  const [loading, setLoading] = useState(false)
  let [error, setError] = useState([])
  const { state } = useStore()
  const [activeModal, setActiveModal] = useState(false);
  const [prevState, setPrevState] = useState(state)

  const isEqualState = isEqual(omit(state, ['updatedAt']), omit(prevState, ['updatedAt']))

  const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

  useEffect(() => {
    const handleReloadPage = (e) => {
      e.preventDefault()
      e.returnValue = ""
    }
    if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
    return () => window.removeEventListener("beforeunload", handleReloadPage)
  }, [isEqualState])

  const handleSave = async () => {
    const requiredFields = ['target_product', 'start_time']
    const {show_eta, end_time, set_end_time, eta} = state
    if (show_eta && !eta) requiredFields.push('eta')
    if (set_end_time && !end_time) requiredFields.push('end_time')

    error = await checkRequiredFields(requiredFields, state)
    if (error.length > 0) {
      setError(error)
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return showToast({
        message: 'Please fill in all the mandatory fields',
        error: true
      })
    }

    setLoading(true)
    API
      .create('pre-order', {
        ...state,
        title: state.target_product.label,
        target_product: state.target_product?.value
      })
      .then(res => {
        console.log(res, 'res')
        if (res.ok) {
          setPrevState({
            ...prevState,
            ...res.payload,
            title: state.target_product.label,
            target_product: state.target_product?.value
          })
          navigate(`/pre-order/${res.payload._id}`)
          return showToast({ message: 'Product created' })
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Page
      title="Add pre-order product"
      breadcrumbs={[{ content: 'Product', onAction: () => isEqualState ? navigate('/pre-order') : handleModalChange() }]}
    >
      {!isEqualState && <ContextualSaveBar
        message={'Unsaved changes'}
        saveAction={{
          onAction: handleSave,
          loading: loading
        }}
        discardAction={{
          onAction: () => handleModalChange(),
        }}
      />}
      <Layout>
        <Layout.Section>
          <ProductsCard error={error} />
          <DiscountCard />
        </Layout.Section>
        <Layout.Section secondary>
          <StatusCard error={error}/>
          <ETA error={error}/>
        </Layout.Section>
      </Layout>
      <DiscardModal
				activeModal={activeModal}
				handleModalChange={handleModalChange}
        backLink='/pre-order'
			/>
    </Page>
  )
}

export default CreateProduct
