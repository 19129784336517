import React from 'react'
import {Router} from "@reach/router"
import OrdersList from "@pages/pre-order"
import CreateProduct from "@pages/pre-order/create"
import EditProduct from "@pages/pre-order/edit"

export default function PreOrderPages() {
	return (
		<Router>
			<OrdersList path="/" />
			<CreateProduct path="/new" />
			<EditProduct path="/:pageID" />
		</Router>
	)
}
