import { navigate } from "@reach/router"
import Page from '@modules/page'
import LayoutSection from "@modules/layoutSection"
import { Layout, Card, FormLayout, TextField, Checkbox, ContextualSaveBar, ChoiceList, Banner, TextContainer, TextStyle } from "@shopify/polaris"
import { ContextProvider, useStore } from "./container"
import ResourcePicker from "@components/resourcePicker"
import Preview from "./preview"
import { useEffect, useState, useCallback } from "react"
import API from "@helpers/api"
import { showToast } from "@plugins/toast"
import { Skeleton } from "@modules/skeleton"
import { CancelToken } from "@plugins/axios"
import { isEqual, pick } from "lodash"
import DiscardModal from "../../components/discardModal"

const StockCountDown = () => {
  return (
    <ContextProvider>
      <StockCountDownView />
    </ContextProvider>
  )
}

const StockCountDownView = () => {
  const [ready, setReady] = useState(false)
  const [saving, setSaving] = useState(false)
  const { state, dispatch } = useStore()
  const source = CancelToken.source()
  const [activeModal, setActiveModal] = useState(false);
  const [prevState, setPrevState] = useState(state)

  const isEqualState = isEqual(
    pick(state, ["active", "applies_to", "countdown_type", "from", "to", "message", "target_collections", "target_products"]), 
    pick(prevState, ["active", "applies_to", "countdown_type", "from", "to", "message", "target_collections", "target_products"])
    )
  const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

  useEffect(() => {
    const handleReloadPage = (e) => {
      e.preventDefault()
      e.returnValue = ""
    }
    if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
    return () => window.removeEventListener("beforeunload", handleReloadPage)
  }, [isEqualState])

  useEffect(() => {
    let result
    const fetchData = async () => {
      try {
        result = await API.getList('stock-countdown', {}, source.token)
        if (result && result.ok) {
          dispatch({ type: 'setData', payload: result.payload })
          setReady(true)
        }
        if (result.payload) {
          setPrevState({ ...prevState, ...result.payload })
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
    return () => source.cancel()
  }, [])
  const handleSave = async () => {
    setSaving(true)
    const { target_products, target_collections } = state
    API.updateByShop('stock-countdown', {
      ...state,
      target_products: target_products?.map(p => p?.value),
      target_collections: target_collections?.map(p => p?.value),
    }).then((res) => {
      if (res.ok) {
        setPrevState({
          ...prevState,
          ...res.payload,
          target_products: target_products,
          target_collections: target_collections,
        })
        return showToast({
          message: 'Updated successfully'
        })
      }
    }).finally(() => setSaving(false))
  }
  const { active, message, applies_to, target_products, target_collections, countdown_type, from, to } = state

  const handleChange = (key, value) => {
    dispatch({ type: 'setData', payload: { [key]: value } })
  }
  return ready ? (
    <Page
      title="Stock countdown"
      subtitle={'Show a progress bar on the product pages to display the limited availability of your products.'}
    >
      {!isEqualState && <ContextualSaveBar
        saveAction={{
          onAction: handleSave,
          loading: saving,
          disabled: false,
        }}
        discardAction={{
          onAction: () => handleModalChange(),
        }}
      />}
      <Layout>
        <Layout.Section oneHalf>
          <Card sectioned>
            <FormLayout>
              <TextContainer>
                <Banner>
                  <p>Create scarcity, increase the perceived value of your products and push your customers to make faster buying decisions.</p>
                </Banner>
              </TextContainer>
              <Checkbox
                label={'Active'}
                checked={active}
                onChange={v => handleChange('active', v)}
              />
              <TextField
                value={message}
                label={'Motivational message'}
                onChange={v => handleChange('message', v)}
                helpText={'{{stock_number}} will be randomly picked from 1-12.'}
              />
            </FormLayout>
          </Card>
          <Card>
            <Card.Section title={'Applies to'}>
              <FormLayout>
                <ChoiceList
                  choices={[
                    { label: 'All products', value: 'all_products' },
                    { label: 'Specific products', value: 'specific_products' },
                    { label: 'Specific collections', value: 'specific_collections' },
                  ]}
                  selected={applies_to}
                  onChange={v => handleChange('applies_to', v[0])}
                />
                {applies_to === 'specific_products' && (
                  <ResourcePicker
                    label={'Select products'}
                    isMulti
                    resourceType={'product'}
                    value={target_products}
                    onChange={v => handleChange('target_products', v)}
                    isClearable
                  />
                )}
                {applies_to === 'specific_collections' && (
                  <ResourcePicker
                    label={'Select collections'}
                    isMulti
                    resourceType={'collection'}
                    value={target_collections}
                    onChange={v => handleChange('target_collections', v)}
                    isClearable
                  />
                )}
              </FormLayout>
            </Card.Section>
            <Card.Section title={'Countdown number'}>
              <FormLayout>
                <ChoiceList
                  choices={[
                    { label: 'Use product inventory quantity', value: 'use_quantity' },
                    { label: 'Use custom range', value: 'use_custom_range' },
                  ]}
                  selected={countdown_type}
                  onChange={v => handleChange('countdown_type', v[0])}
                />
                {countdown_type === 'use_custom_range' && (
                  <FormLayout.Group>
                    <TextStyle variation="subdued">
                      Countdown number will always show a value between {from} and {to} no matter what your Product inventory quantity is
                    </TextStyle>
                    <TextField
                      type={'number'}
                      label={'From'}
                      value={from + ''}
                      onChange={v => handleChange('from', Number(v))}
                    />
                    <TextField
                      type={'number'}
                      label={'To'}
                      value={to + ''}
                      onChange={v => handleChange('to', Number(v))}
                    />
                  </FormLayout.Group>
                )}
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.Section>
        <LayoutSection isStuck oneHalf>
          <Card sectioned title={'Preview'}>
            <Preview message={message} />
          </Card>
        </LayoutSection>
      </Layout>
      <DiscardModal
        activeModal={activeModal}
        handleModalChange={handleModalChange}
        backLink="/boost-conversion"
      />
    </Page>
  ) : <Skeleton />
}

export default StockCountDown
