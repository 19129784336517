import { ChoiceList, Filters } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { sortQuery, useStore } from '../container';
import axios from '@plugins/axios';
import { isEmpty, omit } from 'lodash';
import { getURLParams, updateParam } from '../../../helpers/utils';

const Filter = ({ getProducts }) => {
  const { state, dispatch } = useStore();
  const [vendorChoices, setVendorChoices] = useState([]);
  const [tagChoices, setTagChoices] = useState([]);
  const [typeChoices, setTypeChoices] = useState([]);
  const statusChoices = [
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
    { label: 'Draft', value: 'draft' },
  ];
  const { vendor, tag, product_type, queryValue, status, sort } = state.filter;

  useEffect(() => {
    const getProductsFilter = async () => {
      const data = await axios.get('/api/shopify/products-filter');
      if (data.ok && data.payload) {
        const result = data.payload.shop;
        setVendorChoices(result.productVendors.edges.map((item) => ({ label: item.node, value: item.node })));
        setTagChoices(result.productTags.edges.map((item) => ({ label: item.node, value: item.node })));
        setTypeChoices(result.productTypes.edges.map((item) => ({ label: item.node, value: item.node })));
      }
    };

    getProductsFilter();

    const filter = getURLParams();

    if (filter && JSON.stringify(filter) !== '{}') {
      const { status } = filter;

      if (status) filter.status = JSON.parse(filter.status);

      dispatch({ type: 'setData', payload: { ...state, filter } });
    }
  }, []);

  useEffect(() => {
    const sortValue = sortQuery(sort);

    let inputSort = {
      sortKey: sortValue.sortBy,
      reverse: sortValue.reverse,
      query: {
        sort,
        vendor,
        tag,
        product_type,
        queryValue,
        status,
      },
    };

    if (state.filter.after) {
      inputSort.after = state.filter.after;
      inputSort.first = state.filter.first;
    } else if (state.filter.before) {
      inputSort.before = state.filter.before;
      inputSort.last = state.filter.last;
    } else {
      inputSort.first = 10;
    }

    getProducts(inputSort);
  }, [vendor, tag, product_type, queryValue, status, sort]);

  const handleChange = (key, v) => {
    const filterData = omit(state.filter, ['first', 'after', 'last', 'before']);
    if (key === 'status' && v.length === 0) {
      v = '';
    }

    dispatch({ type: 'setData', payload: { ...state, filter: { ...filterData, [key]: v } } });

    updateParam(key, key === 'status' ? JSON.stringify(v) : v);
  };

  const handleQueryChange = (v) => {
    const filterData = omit(state.filter, ['first', 'after', 'last', 'before']);

    dispatch({ type: 'setData', payload: { ...state, filter: { ...filterData, queryValue: v } } });

    updateParam('queryValue', v);
  };

  const handleRemove = (key) => {
    dispatch({ type: 'setData', payload: { ...state, filter: { ...state.filter, [key]: '' } } });

    updateParam(key, '');
  };

  const handleFiltersClearAll = () => {
    dispatch({
      type: 'setData',
      payload: {
        ...state,
        filter: { ...state.filter, vendor: '', tag: '', queryValue: '', product_type: '', status: '' },
      },
    });

    updateParam('vendor', '');
    updateParam('tag', '');
    updateParam('queryValue', '');
    updateParam('product_type', '');
    updateParam('status', '');
  };

  const appliedFilters = [];

  if (!isEmpty(vendor)) {
    const key = 'vendor';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, vendor),
      onRemove: () => handleRemove(key),
    });
  }
  if (!isEmpty(tag)) {
    const key = 'taggedWith';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, tag),
      onRemove: () => handleRemove('tag'),
    });
  }

  if (!isEmpty(product_type)) {
    const key = 'product_type';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, product_type),
      onRemove: () => handleRemove(key),
    });
  }
  if (!isEmpty(status)) {
    const key = 'status';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: () => handleRemove(key),
    });
  }

  const filters = [
    {
      key: 'vendor',
      label: 'Product vendor',
      filter: (
        <ChoiceList
          titleHidden
          choices={vendorChoices}
          selected={vendor || []}
          onChange={(v) => handleChange('vendor', v)}
        />
      ),
    },
    {
      key: 'taggedWith',
      label: 'Tagged with',
      filter: (
        <ChoiceList titleHidden choices={tagChoices} selected={tag || []} onChange={(v) => handleChange('tag', v)} />
      ),
    },
    {
      key: 'product_type',
      label: 'Type',
      filter: (
        <ChoiceList
          titleHidden
          choices={typeChoices}
          selected={product_type || []}
          onChange={(v) => handleChange('product_type', v)}
        />
      ),
    },
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          allowMultiple
          titleHidden
          choices={statusChoices}
          selected={status || []}
          onChange={(v) => handleChange('status', v)}
        />
      ),
    },
  ];

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'vendor':
        return `Product vendor is ${vendor}`;
      case 'taggedWith':
        return `Tagged with ${tag}`;
      case 'product_type':
        return `Product type is ${product_type}`;
      case 'status':
        return status.map((val) => `status is ${val}`).join(', ');
      default:
        return value;
    }
  }

  return (
    <Filters
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={(v) => handleQueryChange(v)}
      queryPlaceholder="Search"
      onQueryClear={() => handleRemove('queryValue')}
      onClearAll={handleFiltersClearAll}
    />
  );
};

export default Filter;
