import React from 'react';
import { Router } from '@reach/router';
import ThemeInstall from '@pages/theme-manager/install';
import ThemeManage from '@pages/themes';
import Translations from '@pages/translation/index';
import License from '@pages/theme-manager/license';

export default function ThemePages() {
  return (
    <Router>
      <ThemeInstall path="/install" />
      <ThemeManage path="/manage" />
      <Translations path="/translations" />
    </Router>
  );
}
