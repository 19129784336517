import {Card, Select, Button, FormLayout, TextField, TextStyle} from "@shopify/polaris"
import {useStore, defaultDiscount} from "@pages/quantity-discounts/container"
import Table from "@modules/table"
import {DeleteMajor} from "@shopify/polaris-icons"
import {removeElementFromArray} from "@helpers/utils"
import {useAuthStore} from "@container/Auth"

const DiscountCard = () => {
	const {state, dispatch} = useStore()
	const {discounts} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	const handleAddMore = () => {
		handleChange('discounts', [...discounts, defaultDiscount])
	}
	const handleRemove = (index) => {
		const newDiscounts = removeElementFromArray(discounts, index)
		handleChange('discounts', [...newDiscounts])
	}

  const {currency_symbol = '$'} = useAuthStore()

	return (
		<Card>
			<Card.Section subdued title={'Offer’s discount'}>
				<TextStyle variation={'subdued'}>Offer discount to motivate customers to add Up-sell products to their
					cart.</TextStyle>
			</Card.Section>
			<Card.Section>
				<FormLayout>
					<Table>
						<thead>
						<tr>
							<th width={'30%'}>Min quantity</th>
							<th width={'30%'}>Discount</th>
							<th>Discount type</th>
							<th />
						</tr>
						</thead>
						<tbody>
						{discounts && discounts.length > 0 && discounts.map((item, index) => {
							const {min_qty, value, type} = item
							return (
								<tr key={index}>
									<td>
										<TextField
											type={'number'}
											value={min_qty + ''}
											min={1}
											placeholder={0}
											onChange={v => {
												discounts[index] = {...item, min_qty: Number(v)}
												handleChange('discounts', [...discounts])
											}}
										/>
									</td>
									<td>
										<TextField
											type={'number'}
											value={value + ''}
											placeholder={10}
											min={0}
											onChange={v => {
												discounts[index] = {...item, value: Number(v)}
												handleChange('discounts', [...discounts])
											}}
										/>
									</td>
									<td>
										<Select
											labelHidden
											value={type + ''}
											options={[
												{label: '% off on each product', value: 'PERCENTAGE'},
												{label: `Fixed ${currency_symbol} off on each product`, value: 'FIXED_AMOUNT'}
											]}
											onChange={v => {
												discounts[index] = {...item, type: v}
												handleChange('discounts', [...discounts])
											}}
										/>
									</td>
									<td>
										<Button icon={DeleteMajor} onClick={() => handleRemove(index)}/>
									</td>
								</tr>
							)
						})}
						<tr>
							<td>
								<Button onClick={handleAddMore}>{discounts.length > 0 ? 'Add more' : 'Add discount'}</Button>
							</td>
						</tr>
						</tbody>
					</Table>
				</FormLayout>
			</Card.Section>
			<Card.Section subdued title={'CAN’T COMBINE WITH OTHER DISCOUNTS YOU CREATED IN SHOPIFY ADMIN'}>
				<p>
					Customers won’t be able to enter a code if an Fox Kit discount is already applied in their cart.
				</p>
			</Card.Section>
		</Card>
	)
}

export default DiscountCard
