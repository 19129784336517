import { useAuthStore } from '@container/Auth';
import API from '@helpers/api';
import { THEME_NAME } from '@helpers/constants';
import Page from '@modules/page';
import Table from '@modules/table';
import axios from '@plugins/axios';
import { showConfirm } from '@plugins/confirm';
import { showToast } from '@plugins/toast';
import { Badge, Button, Card, ChoiceList, FormLayout, Layout, Link, TextField } from '@shopify/polaris';
import { compareAsc, format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

const License = () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [product, setProduct] = useState(THEME_NAME.MINIMOG);

  const { licenses, updateShopData, license_active } = useAuthStore();

  useEffect(() => {
    localStorage.setItem('license_active', license_active);
  }, [license_active]);

  const handleSubmit = () => {
    setLoading(true);
    API.get('shop/validate-purchase', {
      code: code,
      product,
    })
      .then((res) => {
        if (res.ok) {
          if (!licenses.find((license) => license._id === res.payload._id)) {
            updateShopData({ licenses: [...licenses, res.payload] });
          }
          setCode('');
          showToast({
            message: 'Your purchase code is validated!',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = (id) => {
    if (!id) return;
    axios
      .delete(`/api/plugin/license/${id}`)
      .then((response) => {
        console.log(
          response.payload,
          licenses.filter((license) => license._id !== response.payload?._id),
          'response',
        );
        if (response.ok) {
          updateShopData({ licenses: licenses.filter((license) => license._id !== response.payload?._id) });
          showToast({
            message: 'License deleted',
          });
        }
      })
      .catch((error) => {
        showToast({
          message: error.message,
          error: true,
        });
      });
  };

  const haveMinimogMegamogCode = useMemo(() => {
    return (
      licenses?.find((lic) => lic?.theme_namespace === THEME_NAME.MEGAMOG) && licenses?.find((lic) => lic?.theme_namespace === THEME_NAME.MINIMOG)
    );
  }, [licenses]);

  const minimogAdded = licenses?.find((lic) => lic?.theme_namespace === THEME_NAME.MINIMOG)
  const megamogAdded = licenses?.find((lic) => lic?.theme_namespace === THEME_NAME.MEGAMOG)

  return (
    <Page
      title="Manage license"
      subtitle={'Use purchase code to un-lock pro features and get our pro service support.'}
      hideBanner
      // primaryAction={!license_active ? {
      // 	content:  'Buy Minimog now',
      // 	onAction: () => window.open(`${process.env.REACT_APP_ITEM_URL}?utm_source=in-app&utm_medium=license-page&utm_campaign=in-app`)
      // } : undefined}
    >
      <Layout>
        <Layout.Section>
          {!haveMinimogMegamogCode && (
            <Card
              title={'Add purchase code'}
            >
              <Card.Section>
                <FormLayout>
                  <ChoiceList
                    title="Theme"
                    choices={[
                      { label: `Minimog${minimogAdded ? ' (Already added)' : ''}`, value: THEME_NAME.MINIMOG, disabled: minimogAdded },
                      { label: `Megamog ${megamogAdded ? ' (Already added)' : ''}`, value: THEME_NAME.MEGAMOG, disabled: megamogAdded },
                    ]}
                    selected={[product]}
                    onChange={(v) => setProduct(v[0])}
                  />
                  <TextField
                    labelHidden
                    placeholder={'Enter your purchase code'}
                    label={'Enter your purchase code'}
                    value={code}
                    onChange={(v) => setCode(v)}
                    connectedRight={
                      <Button loading={loading} primary onClick={handleSubmit} disabled={!code}>
                        Add license
                      </Button>
                    }
                  />
                </FormLayout>
              </Card.Section>
            </Card>
          )}

          {licenses?.length > 0 && (
            <Card title={'Licenses'}>
              {licenses?.map((license, index) => {
                const expired = compareAsc(new Date(), new Date(license?.supported_until)) === 1;

                return (
                  <Card.Section key={index} subdued={index % 2 === 0}>
                    <Table>
                      <tbody>
                        <tr>
                          <th width={200}>Purchase code:</th>
                          <td>
                            <span className={'font-medium'}>{license?.code}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width={200}>Store:</th>
                          <td>
                            <Link url={`https://${license?.shop}`} external>
                              {license?.shop}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th width={200}>Theme:</th>
                          <td>
                            <Link url={license?.item_url} external>
                              {license?.item_name}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th width={200}>Bought at:</th>
                          <td>{license?.sold_at ? format(new Date(license?.sold_at), 'yyyy-MM-dd') : 'N/A'}</td>
                        </tr>
                        <tr>
                          <th width={200}>Supported until:</th>
                          {license.lifetime_support ? (
                            <td>
                              <Badge status={'success'}>Lifetime</Badge>
                            </td>
                          ) : (
                            <td>
                              {license?.supported_until ? format(new Date(license?.supported_until), 'yyyy-MM-dd') : ''}
                              <Badge status={!expired ? 'success' : undefined}>{expired ? 'Expired' : 'Active'}</Badge>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </Table>
                    <Button
                      size={'slim'}
                      onClick={() => {
                        showConfirm({
                          title: `Remove ${license.code}?`,
                          message: "This action can't be undone.",
                          danger: true,
                          confirm: 'Remove',
                        }).then((res) => {
                          if (res) handleDelete(license._id);
                        });
                      }}
                      destructive
                    >
                      Remove
                    </Button>
                  </Card.Section>
                );
              })}
            </Card>
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default License;
