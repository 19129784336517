import { createContext, useContext, useReducer } from 'react';
export const initialState = {
  products: [],
  variantsState: [],
  product: '',
  filter: {
    vendor: '',
    tag: '',
    product_type: '',
    queryValue: '',
    status: '',
    sort: 'TITLE_NOREVERSE',
    has_only_default_variant: false,
  },
  pageInfo: { hasNextPage: false, hasPreviousPage: false },
};

const Context = createContext(initialState);

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'setData':
        return {
          ...state,
          ...action.payload,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const sortQuery = (sort) => {
  let sortValue;
  switch (sort) {
    case 'CREATED_AT_NOREVERSE':
      sortValue = { sortBy: 'CREATED_AT', reverse: false };
      break;
    case 'CREATED_AT_REVERSE':
      sortValue = { sortBy: 'CREATED_AT', reverse: true };
      break;
    case 'INVENTORY_NOREVERSE':
      sortValue = { sortBy: 'INVENTORY_TOTAL', reverse: false };
      break;
    case 'INVENTORY_REVERSE':
      sortValue = { sortBy: 'INVENTORY_TOTAL', reverse: true };
      break;
    case 'PRODUCT_TYPE_NOREVERSE':
      sortValue = { sortBy: 'PRODUCT_TYPE', reverse: false };
      break;
    case 'PRODUCT_TYPE_REVERSE':
      sortValue = { sortBy: 'PRODUCT_TYPE', reverse: true };
      break;
    case 'TITLE_NOREVERSE':
      sortValue = { sortBy: 'TITLE', reverse: false };
      break;
    case 'TITLE_REVERSE':
      sortValue = { sortBy: 'TITLE', reverse: true };
      break;
    case 'UPDATED_AT_NOREVERSE':
      sortValue = { sortBy: 'UPDATED_AT', reverse: false };
      break;
    case 'UPDATED_AT_REVERSE':
      sortValue = { sortBy: 'UPDATED_AT', reverse: true };
      break;
    case 'VENDOR_NOREVERSE':
      sortValue = { sortBy: 'VENDOR', reverse: false };
      break;
    case 'VENDOR_REVERSE':
      sortValue = { sortBy: 'VENDOR', reverse: true };
      break;
    default:
      sortValue = { sortBy: 'TITLE', reverse: false };
      break;
  }
  return sortValue;
};

export const useStore = () => useContext(Context);
