import NoResult from '@assets/no-results.svg';
import DemoCard from '@components/demoCard';
import { useAuthStore } from '@container/Auth';
import { THEME_NAME } from '@helpers/constants';
import { installTheme } from '@helpers/shopifyApi';
import Page from '@modules/page';
import { Skeleton } from '@modules/skeleton';
import axios from '@plugins/axios';
import { showToast } from '@plugins/toast';
import { navigate } from '@reach/router';
import {
  Banner,
  Button,
  ButtonGroup,
  Checkbox,
  FormLayout,
  Icon,
  Layout,
  Link,
  Modal,
  Select,
  Stack,
  TextContainer,
  TextField,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import { DemoList, Filter, industries } from './config';
import { titleCase } from '@helpers/utils';

const InstallDemo = () => {
  const [installed, setInstalled] = useState(false);
  const [ready, setReady] = useState(false);
  const [activeTheme, setActiveTheme] = useState(THEME_NAME.MINIMOG);
  const [active, setActive] = useState(false);
  const [demoList, setDemoList] = useState([]);
  const [activeDemoList, setActiveDemoList] = useState([]);
  const [selectedDemo, setSelectedDemo] = useState({});
  const [osVersion] = useState('2.0');
  const [industry, setIndustry] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTheme, setSelectedTheme] = useState({});

  const { shop_domain, themeList } = useAuthStore();

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (demoList?.length) handleFilter();
  }, [industry, osVersion, demoList, searchQuery, activeTheme]);

  const handleFetchData = async () => {
    await axios
      .get('/api/shopify/theme/demo')
      .then((response) => {
        if (response?.ok) {
          setReady(true);
          setDemoList(response?.payload || []);
        }
      })
      .catch();
  };

  const handleClick = (demo_id) => {
    const selected_demo = activeDemoList?.find((t) => t.demo_id === demo_id);
    setSelectedDemo(selected_demo);
    handleToggleInstall();
  };

  const handleFilter = () => {
    const themeByOs = demoList.find((theme) => theme?.namespace === activeTheme && theme.os_version === osVersion);
    let filtered =
      themeByOs?.data.filter((demo) => {
        return demo?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) && demo.industries.includes(industry);
      }) || [];
    filtered = filtered.map((f) => {
      return {
        os_version: osVersion,
        ...f,
      };
    });
    setSelectedTheme(themeByOs);
    setActiveDemoList(filtered);
  };

  const handleToggleInstall = useCallback(() => {
    if (active) setSelectedDemo(null);
    setActive(!active);
  }, [active]);

  const onSuccess = useCallback(() => setInstalled(true), [installed]);

  console.log('activeDemoList:', activeDemoList);
  return ready ? (
    <Page
      title="Install theme"
      fullWidth
      subtitle={
        <div>
          Just one-single click to get the demo you love. No coding knowledge required, no copy & paste like other normal themes.{' '}
          <Link url={'https://foxecom.link/DKFFxi'} external>
            Learn more.
          </Link>
        </div>
      }
      hideBanner
    >
      {active && (
        <InstallModal
          selectedDemo={selectedDemo}
          open={active}
          handleChange={handleToggleInstall}
          onSuccess={() => onSuccess()}
          activeLicense={themeList?.includes(activeTheme)}
          selectedTheme={selectedTheme}
        />
      )}
      <Layout>
        <Layout.Section>
          {!themeList?.includes(activeTheme) ? (
            <Banner title="You haven't activated your license yet" status="warning">
              <FormLayout>
                <p>Please activate the purchase with your license code & get full access to our premium features.</p>
                <ButtonGroup>
                  <Button onClick={() => navigate('/settings/licenses')}>Activate license</Button>
                </ButtonGroup>
              </FormLayout>
            </Banner>
          ) : null}
          {installed && (
            <Banner
              title="Theme is installed"
              status="success"
              action={{ content: 'View themes', onAction: () => window.open(`https://${shop_domain}/admin/themes`) }}
              onDismiss={() => setInstalled(false)}
            >
              <p>
                The theme will be published only after all of its files have been extracted and stored by Shopify, which might take a couple of
                minutes.
              </p>
            </Banner>
          )}
          <Filter>
            <Stack alignment={'center'}>
              {/*<Select*/}
              {/*	label={'OS Version'}*/}
              {/*	labelHidden*/}
              {/*	value={osVersion}*/}
              {/*	options={[*/}
              {/*		{label: "Online Store 1.0", value: '1.0'},*/}
              {/*		{label: "Online Store 2.0", value: '2.0'},*/}
              {/*	]}*/}
              {/*	onChange={v => setOsVersion(v)}*/}
              {/*/>*/}
              <Select
                label="Product:"
                labelInline
                value={activeTheme}
                options={[
                  { label: 'Minimog', value: THEME_NAME.MINIMOG },
                  { label: 'Megamog', value: THEME_NAME.MEGAMOG },
                ]}
                onChange={(value) => {
                  setActiveTheme(value);
                  setIndustry('all');
                }}
              />
              {activeTheme === THEME_NAME.MINIMOG && (
                <Select label={'Industries'} labelHidden value={industry} options={industries} onChange={(v) => setIndustry(v)} />
              )}
              <TextField
                placeholder={'Search for demo'}
                label={'Search demo'}
                labelHidden
                value={searchQuery}
                onChange={(v) => setSearchQuery(v)}
                suffix={<Icon source={SearchMinor} color="skyDark" />}
                onClearButtonClick={() => setSearchQuery('')}
                clearButton
              />
            </Stack>
            {activeDemoList?.length > 0 ? (
              <h3 className={'text-2xl font-medium showing-result'}>
                Showing {activeDemoList?.length} demo of {selectedTheme?.namespace.toUpperCase()} - OS {selectedTheme.os_version}
              </h3>
            ) : (
              <div />
            )}
          </Filter>
          <DemoList>
            {activeDemoList?.length > 0 ? (
              <div className="c-grid gap-30 c-grid-2-cols xl:c-grid-3-cols demo-list__wrapper">
                {activeDemoList?.map((d) => (
                  <DemoCard selectedTheme={activeTheme} key={d.demo_id} {...d} osVersion={osVersion} handleClick={handleClick} />
                ))}
              </div>
            ) : (
              <div style={{ padding: '12rem 0' }} className={'text-center'}>
                <img src={NoResult} alt="" width={200} />
                <h3 className={'text-3xl text-center'}>There are no demo in this industry!</h3>
              </div>
            )}
          </DemoList>
        </Layout.Section>
      </Layout>
    </Page>
  ) : (
    <Skeleton />
  );
};

const InstallModal = ({ activeLicense, open, selectedDemo, handleChange, selectedTheme, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [role, setRole] = useState('unpublished');

  const { licenses } = useAuthStore();

  const handleInstall = () => {
    setLoading(true);
    installTheme({
      demo_id: selectedDemo?.demo_id,
      role: activeLicense ? role : 'demo',
      namespace: selectedTheme?.namespace,
      evanto_theme_id: selectedTheme?.evanto_theme_id,
      version: selectedTheme?.version,
      os_version: selectedTheme?.os_version || '1.0',
    })
      .then((res) => {
        if (res.ok) {
          onSuccess();
          showToast({
            message: 'Theme installed!',
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          showToast({
            message: res.message,
            error: true,
          });
        }
      })
      .catch((error) => {
        showToast({
          message: error.message,
          error: true,
        });
      })
      .finally(() => {
        setLoading(false);
        handleChange();
      });
  };

  let secondaryActions = [
    {
      content: 'Cancel',
      onAction: handleChange,
      disabled: loading,
      plain: true,
    },
  ];

  let primaryAction = {
    content: 'Install now',
    onAction: () => handleInstall(),
    loading: loading,
  };

  if (!activeLicense) {
    secondaryActions = [
      {
        content: `Purchase ${titleCase(selectedTheme?.namespace)}`,
        disabled: loading,
        primary: true,
        onAction: () =>
          window.open(
            `${
              selectedTheme?.namespace === THEME_NAME.MEGAMOG ? process.env.REACT_APP_MEGAMOG_URL : process.env.REACT_APP_ITEM_URL
            }?utm_source=in-app&utm_medium=modal-install-theme-button&utm_campaign=in-app`,
          ),
      },
      {
        content: 'Install as trial theme',
        onAction: () => handleInstall(),
        disabled: loading || !confirmed,
        loading: loading,
      },
    ];
    primaryAction = null;
  }

  return (
    <Modal
      open={open}
      onClose={!loading ? handleChange : {}}
      title={licenses?.length > 0 ? `Install ${selectedDemo?.name} demo data?` : `Install ${titleCase(selectedTheme?.namespace)} as a trial theme?`}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    >
      <Modal.Section>
        <TextContainer>
          {activeLicense && (
            <FormLayout>
              {/*<Checkbox*/}
              {/*	label={'Publish after install?'}*/}
              {/*	checked={role === 'main'}*/}
              {/*	onChange={v => setRole(v ? 'main' : 'unpublished')}*/}
              {/*	helpText={'This will replace your current public theme.'}*/}
              {/*/>*/}
              <p>This demo will be installed in your store as an unpublished theme.</p>
            </FormLayout>
          )}
          {!activeLicense && (
            <p>
              Install {titleCase(selectedTheme?.namespace)} with {selectedDemo?.name} demo without purchase required.
            </p>
          )}
          {!activeLicense && (
            <TextContainer>
              <Banner status="warning">
                Theme trial is just for playing around - it means you can access all features and try {titleCase(selectedTheme?.namespace)} before
                purchasing, however, you cannot publish or copy the settings and code even after license activating
              </Banner>
              <Checkbox label={'I have read and understand'} checked={confirmed} onChange={(v) => setConfirmed(v)} />
            </TextContainer>
          )}
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default InstallDemo;
