import { useState, useCallback } from 'react';
import { Badge, Banner, Card, FormLayout, Heading, Link, TextStyle, Button, ButtonGroup, ActionList, Popover, Stack } from '@shopify/polaris';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useAuthStore } from '@container/Auth';
import { compareVersions } from '@helpers/utils';
import { navigate } from '@reach/router';

const ThemeCard = ({ theme, handleClick, handlePublish, isMain = false, showUpdate = true }) => {
  const [active, setActive] = useState(false);
  const { themeList } = useAuthStore();

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const { shop_domain } = useAuthStore();

  const activator = (
    <Button onClick={toggleActive} disclosure>
      Actions
    </Button>
  );

  const shopUrl = `https://${shop_domain}`;

  let actionList = [
    {
      items: [{ content: 'Preview', onAction: () => window.open(`${shopUrl}/?preview_theme_id=${theme.shopify_theme_id}`) }],
    },
    {
      items: [
        { content: 'Edit code', onAction: () => window.open(`${shopUrl}/admin/themes/${theme.shopify_theme_id}`) },
        {
          content: 'Edit language',
          onAction: () => window.open(`${shopUrl}/admin/themes/${theme.shopify_theme_id}/language`),
        },
      ],
    },
    {
      items: [
        {
          content: 'Insert code before </head>',
          onAction: () => window.open(`${shopUrl}/admin/themes/${theme.shopify_theme_id}?key=snippets/custom-code-head.liquid`),
        },
        {
          content: 'Insert code before </body>',
          onAction: () => window.open(`${shopUrl}/admin/themes/${theme.shopify_theme_id}?key=snippets/custom-code-body.liquid`),
        },
        {
          content: 'Custom CSS',
          onAction: () => window.open(`${shopUrl}/admin/themes/${theme.shopify_theme_id}?key=assets/custom.css.liquid`),
        },
      ],
    },
  ];

  if (!isMain) {
    actionList[0].items.push({ content: 'Publish', onAction: () => handlePublish(theme) });
  }

  const changelog = 'https://help.foxecom.com/changelog/minimog';

  let bannerAction = {
    content: 'Update',
    onAction: () => handleClick(theme),
  };

  if (!themeList?.includes(theme?.namespace)) {
    bannerAction = {
      content: 'Activate license to update',
      onAction: () => navigate('/settings/licenses'),
    };
  }

  return (
    <Card>
      <Card.Section>
        <div className={'flex items-start card-content'}>
          <div className="flex-1">
            <Heading>{theme?.display_name}</Heading>
            <div className="tag-line">
              {/* Using old stack of this version shopify. At new version, using stack in @component/stack */}
              <Stack>
                {theme?.role === 'demo' && (
                  <Stack.Item>
                    <Badge status={'warning'}>Theme trial</Badge>
                  </Stack.Item>
                )}
                <Stack.Item>
                  <span style={{ paddingRight: '0.5rem' }}>
                    <Badge status={'success'}>OS {theme?.os_version}</Badge>
                    <span>&nbsp;</span>
                    <TextStyle variation={'subdued'}>
                      <Badge>v{theme?.version}</Badge>
                    </TextStyle>
                  </span>
                </Stack.Item>
                <Stack.Item>
                  <TextStyle variation={'subdued'}>Updated: {formatDistanceToNow(new Date(theme?.updatedAt), { addSuffix: true })}</TextStyle>
                </Stack.Item>
              </Stack>
            </div>
          </div>
          <ButtonGroup>
            <Popover active={active} activator={activator} onClose={toggleActive}>
              <ActionList sections={actionList} />
            </Popover>
            <Button onClick={() => window.open(`${shopUrl}/admin/themes/${theme?.shopify_theme_id}/editor`)} primary={isMain}>
              Customize
            </Button>
          </ButtonGroup>
        </div>
        {showUpdate && (
          <FormLayout>
            <div style={{ paddingTop: '1.5rem' }}>
              {compareVersions(theme.version, theme.new_version) > 0 && (
                <Banner
                  status={'info'}
                  action={bannerAction}
                  secondaryAction={{
                    content: 'Learn more',
                    url: 'https://foxecom.link/EvlYXk',
                    external: true,
                  }}
                >
                  <p>
                    There is a new version of <strong>{theme.namespace?.toUpperCase()}</strong> available.{' '}
                    <Link url={changelog} external>
                      View version <strong>{theme?.new_version}</strong> changelog.
                    </Link>
                  </p>
                </Banner>
              )}
            </div>
          </FormLayout>
        )}
      </Card.Section>
    </Card>
  );
};
export default ThemeCard;
