import Page from '@modules/page'
import { navigate, useParams } from "@reach/router"
import { ContextualSaveBar, Layout, PageActions, Button } from "@shopify/polaris"
import { ContextProvider, useStore } from "@pages/pre-order/container"
import ETA from "./components/eta"
import DiscountCard from "./components/discount"
import ProductsCard from "./components/products"
import StatusCard from "./components/status"
import { useCallback, useEffect, useState } from "react"
import API from "@helpers/api"
import { showToast } from "@plugins/toast"
import { Skeleton } from "@modules/skeleton"
import StatusBadge from "@components/statusBadge"
import { CancelToken } from "@plugins/axios"
import { checkRequiredFields } from "@helpers/utils"
import { ViewMinor } from '@shopify/polaris-icons'
import { useAuthStore } from "@container/Auth"
import { showConfirm } from "@plugins/confirm"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'
const EditProduct = () => {
  return (
    <ContextProvider>
      <EditProductView />
    </ContextProvider>
  )
}

const EditProductView = () => {
  const [ready, setReady] = useState(false)
  const [saving, setSaving] = useState(false)
  let [error, setError] = useState([])
  const { pageID } = useParams()
  const { state, dispatch } = useStore()
  const source = CancelToken.source()
  const [activeModal, setActiveModal] = useState(false);
  const [prevState, setPrevState] = useState({})

  const isEqualState = isEqual(omit(state, ['created_at', 'updated_at', 'heading', 'updatedAt']), omit(prevState, ['updatedAt']))

  const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

  const { active, title, _id, target_product} = state

  useEffect(() => {
    const handleReloadPage = (e) => {
      e.preventDefault()
      e.returnValue = ""
    }
    if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
    return () => window.removeEventListener("beforeunload", handleReloadPage)
  }, [isEqualState])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API.getDetail('pre-order', pageID, source.token)
        if (res?.ok) {
          dispatch({ type: 'setData', payload: res.payload })
          setPrevState(res.payload)
          setReady(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
    return () => source.cancel()
  }, [pageID])
  const handleSave = async () => {
    const requiredFields = ['target_product', 'start_time']
    const {show_eta, end_time, set_end_time, eta} = state
    if (show_eta && !eta) requiredFields.push('eta')
    if (set_end_time && !end_time) requiredFields.push('end_time')

    error = await checkRequiredFields(requiredFields, state)
    if (error.length > 0) {
      setError(error)
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return showToast({
        message: 'Please fill in all the mandatory fields',
        error: true
      })
    }

    setSaving(true)
    const { target_product } = state
    API
      .update('pre-order', {
        ...state,
        title: target_product?.label,
        target_product: target_product?.value,
      })
      .then((res) => {
        if (res.ok) {
          setPrevState({
            ...prevState,
            ...res.payload,
            title: target_product?.label,
            target_product: target_product,
          })
          return showToast({ message: 'Updated successfully' })
        }
      })
      .finally(() => setSaving(false))
  }

  const handleDelete = () => {
    showConfirm({
      title: `Delete ${title}?`,
      message: 'This can’t be undone.',
      danger: true,
      confirm: 'Delete'
    }).then(res => {
      if (res) {
        API.delete('pre-order', _id).then((res) => {
          if (res.ok) {
            showToast({ message: 'Pre-order deleted' })
            return navigate('/pre-order')
          } else {
            showToast({
              message: 'Cannot delete, please try again',
              error: true
            })
          }
        })
      }
    })
  }
  const { shop_domain } = useAuthStore()
  return ready ? (
    <Page
      title={title}
      breadcrumbs={[{ content: 'Products', onAction: () => isEqualState ? navigate('/pre-order') : handleModalChange() }]}
      titleMetadata={<StatusBadge status={active} />}
      primaryAction={
        target_product ? <Button
          icon={ViewMinor}
          onClick={() => window.open(`https://${shop_domain}/products/${target_product?.handle}`)}
        >Preview</Button> : ''
      }
    >
      {!isEqualState && <ContextualSaveBar
        message="Unsaved changes"
        saveAction={{
          onAction: handleSave,
          loading: saving,
          disabled: false,
        }}
        discardAction={{
          onAction: () => handleModalChange(),
        }}
      />}
      <Layout>
        <Layout.Section>
          <ProductsCard error={error} />
          <DiscountCard />
        </Layout.Section>
        <Layout.Section secondary>
          <StatusCard error={error}/>
          <ETA error={error}/>
        </Layout.Section>
      </Layout>
      <PageActions
        primaryAction={{
          content: 'Save',
          loading: saving,
          onAction: handleSave

        }}
        secondaryActions={[
          {
            content: 'Delete',
            destructive: true,
            onAction: handleDelete
          },
        ]}
      />
      <DiscardModal
        activeModal={activeModal}
        handleModalChange={handleModalChange}
        backLink='/pre-order'
      />
    </Page>
  ) : <Skeleton />
}

export default EditProduct
