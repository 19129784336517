import { navigate } from '@reach/router';
import { Modal, TextContainer } from '@shopify/polaris';
import React from 'react'

const DiscardModal = ({ activeModal, handleModalChange, backLink }) => {
    return (
        <Modal
            open={activeModal}
            onClose={handleModalChange}
            title="Do you want to leave without saving?"
            primaryAction={{
                content: 'Confirm',
                onAction: () => navigate(backLink)
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleModalChange
                },
            ]}
        >
            <Modal.Section>
                <TextContainer>
                    <p>If you leave this page, any unsaved changes will be lost.</p>
                </TextContainer>
        </Modal.Section>
        </Modal>
    )
}

export default DiscardModal
