import axios from '@plugins/axios';

const PLUGIN_SHEET = `api/sheet/update`;
const PLUGIN_SHEET_POST = PLUGIN_SHEET;

export const updateSheet = async (data) => {
  const response = await axios.post(PLUGIN_SHEET_POST, { ...data });
  console.log(response, 'response')
  return response?.payload?.data;
};
