import React from 'react'
import {Router} from "@reach/router"
import ProductRecommendationList from "@pages/product-recommendation"
import CreateRecommendation from "@pages/product-recommendation/create"
import EditRecommendation from "@pages/product-recommendation/edit"

export default function ProductRecommendationPages() {
	return (
		<Router>
			<ProductRecommendationList path="/" />
			<CreateRecommendation path="/new" />
			<EditRecommendation path="/:pageID" />
		</Router>
	)
}
