import styled from "styled-components"
import {Card} from "@shopify/polaris"
import {useStore} from "../container"
const PreviewWrapper = styled.div`
	table {
		width: 100% !important;
		th, td {
			padding: 0.75rem 1rem;
		}
	}
`

const Preview = () => {
	const {state} = useStore()
	const {chart_content} = state
	return (
		<Card sectioned title={'Size chart preview'}>
			{chart_content ? <PreviewWrapper>
				<div className={'w-full'} dangerouslySetInnerHTML={{__html: chart_content}} />
			</PreviewWrapper> : <div>
					<h3 className={'text-2xl'}>There is no content to preview!</h3>
			</div>}
		</Card>
	)
}
export default Preview
