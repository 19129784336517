import styled from "styled-components"

const Preview = styled.div`
	position: relative;
	width: 100%;
	min-height: 550px;
	padding: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5rem 2rem;
	background: rgba(0,0,0,.1);
	.sale-pop {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
		bottom: 3rem;
		padding: 1.25rem;
		&-remove {
			right: 4px;
			top: 4px;
		}
	}
	.time {
		opacity: .4;
	}
	.info {
		padding-bottom: 1.25rem;
	}
	.bottom-left {
		left: 3rem;
	}
	.bottom-right {
		right: 3rem;
	}
`

const PreviewNotification = ({title, time, position}) => {

	const productTitle = 'Black Body Jean'
	const productImg = 'https://cdn.shopify.com/s/files/1/0555/7220/1635/products/9dcf86db_6af7_300x.jpg'


	title = title.replace("{{name}}", 'Thomas Murray').replace("{{location}}", 'United States')
	time = time.replace("{{time}}", '12 mins').replace("{{location}}", 'United States')

	return (
		<Preview>
			<div className={`sale-pop absolute bg-white flex overflow-hidden ${position}`}>
				<div className="img flex-shrink-0 ">
					<img src={productImg} alt="" width={80}/>
				</div>
				<div className="flex-grow flex flex-col justify-between" style={{ marginLeft: '24px', width: '276px' }}>
					<div className="info">
						{title && <p className="mb-1 text-2xl" style={{ paddingRight: '32px', marginBottom: '0.5rem' }}>{title}</p>}
						<a className="font-medium text-3xl" href={"#"}>{productTitle}</a>
					</div>
					{time && <div className="flex items-center time flex-1">
							<svg className="flex-shrink-0" style={{ width: '12px', height: '12px' }} fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z" /></svg>
							<p className="ml-2 text-base">{time}</p>
						</div>}
				</div>
				<div className="absolute sale-pop-remove cursor-pointer bg-white">
					<svg style={{ width: '12px', height: '12px' }} fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" />
					</svg>
				</div>
			</div>
		</Preview>
	)
}
export default PreviewNotification
