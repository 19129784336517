import {Card, FormLayout, TextField} from "@shopify/polaris"
import {useStore} from "@pages/quantity-discounts/container"

const TitleCard = ({error}) => {
	const {state, dispatch} = useStore()
	const {title, offer_heading} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card sectioned>
			<FormLayout>
				<TextField
					label={'Title'}
					value={title}
					onChange={v => handleChange('title', v)}
					error={error.includes('title')}
				/>

				<TextField
					value={offer_heading}
					label={'Offer heading'}
					onChange={v => handleChange('offer_heading', v)}
					placeholder={'Buy more save more!'}
				/>
			</FormLayout>
		</Card>
	)
}

export default TitleCard
