import {Card, FormLayout, TextField} from "@shopify/polaris"
import {useStore} from "@pages/customers/container"
import {format} from 'date-fns'

const TitleCard = ({error = []}) => {
	const {state, dispatch} = useStore()
	const {firstName, lastName, email, birthday} = state
	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card sectioned>
			<FormLayout>
				<FormLayout.Group>
					<TextField
						label={'First name'}
						value={firstName}
						onChange={v => handleChange('firstName', v)}
						error={error.includes('firstName')}
					/>
					<TextField
						value={lastName}
						label={'Last name'}
						onChange={v => handleChange('lastName', v)}
					/>
				</FormLayout.Group>
				<FormLayout.Group>
					<TextField
						type={'email'}
						label={'Email'}
						value={email}
						onChange={v => handleChange('email', v)}
						error={error.includes('email') ? "Email can't be blank" : undefined}
					/>
					<TextField
						type={'date'}
						label={'Date of birth'}
						value={birthday ? format(new Date(birthday), 'yyyy-MM-dd') : ''}
						onChange={v => handleChange('birthday', v)}
					/>
				</FormLayout.Group>
			</FormLayout>
		</Card>
	)
}

export default TitleCard
