import { useEffect, useState } from "react";
import axios from "@plugins/axios"
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Pagination} from "swiper";
import 'swiper/swiper-bundle.min.css';
import styled from 'styled-components'
const BannerCard = styled.div`
  --swiper-theme-color: var(--p-action-primary);
  overflow: hidden;
  position: relative;
  .BannerCard-skeleton {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: calc(100%/(0 + 783/375));
      background-color: #f5f5f5;
    }
  }
  > .Polaris-Button--iconOnly {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 11;
  }
`

export default function FeaturedBanners() {
  const [loading, setLoading] = useState(true)
  const [banners, setBanners] = useState([])

  useEffect(() => {
    axios.get(`/api/strapi/featured-banners`).then(response => {
      if (response && response?.payload) setBanners(response?.payload)
    }).catch(err => console.log(err))
    .finally(() => setLoading(false))
  }, [])


  console.log(banners, 'banners')
  const className = ['Polaris-Card']
  if (banners.length === 0 && !loading) className.push('hidden')
  return (
    <BannerCard className={className.join(' ')}>
      {loading && <div className="BannerCard-skeleton" />}
      {!loading && banners.length && (
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          pagination={{ clickable: true }}
          loop={true}
        >
          {banners.map((banner, index) => {
            return (
              <SwiperSlide key={index}>
                <a href={banner.link} target={"_blank"} style={{display: 'block'}}>
                  <img src={banner.banner} alt={''} />
                </a>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </BannerCard>
  )
}