import InputField from '@components/inputField';
import { MIN_LENGTH_PASSWORD } from '@helpers/constants';
import { getParam } from '@helpers/utils';
import UserLayout from '@modules/userLayout';
import { showToast } from '@plugins/toast';
import { resetPasswordService } from '@services/user';
import { Button, DisplayText, Form, FormLayout, InlineError, TextContainer } from '@shopify/polaris';
import { useMemo, useState } from 'react';

const ResetPassword = () => {
  const [value, setValue] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [passError, setPassError] = useState('');

  const handleChangeValue = (field, newValue) => {
    setFormError('');
    setValue((prev) => ({ ...prev, [field]: newValue }));
  };

  const handleSubmit = async () => {
    try {
      if (value?.newPassword?.length < MIN_LENGTH_PASSWORD) {
        setPassError(`Your password must have at least ${MIN_LENGTH_PASSWORD} characters`);
        return;
      }
      if (value?.newPassword !== value?.confirmNewPassword) throw new Error('Confirm password does not match!');
      setLoading(true);
      const token = getParam('token');
      await resetPasswordService(token, value?.newPassword);
      showToast({
        message: 'Update password successfully.',
      });
      window.location.href = '/';
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      setFormError(error?.message);
    }
  };

  const disabled = useMemo(() => {
    return !value?.newPassword || !value?.confirmNewPassword;
  }, [value]);

  return (
    <>
      <UserLayout title={'Change password'}>
        <div className="form-container">
          <div className="form-header">
            <TextContainer>
              <DisplayText size={'extraLarge'}>
                <strong>Change your password</strong>
              </DisplayText>
              <p className={'Polaris-DisplayText Polaris-DisplayText--sizeSmall'}>Enter your new password, 6 characters minimum.</p>
            </TextContainer>
          </div>
          <div className="form-content">
            <Form onSubmit={handleSubmit}>
              <FormLayout>
                <InputField
                  error={passError}
                  value={value?.newPassword}
                  label="New password"
                  type="password"
                  onChange={(v) => handleChangeValue('newPassword', v)}
                />
                <InputField
                  value={value?.confirmNewPassword}
                  onChange={(v) => handleChangeValue('confirmNewPassword', v)}
                  label="Confirm new password"
                  type="password"
                />
                <Button primary disabled={disabled} loading={loading} submit fullWidth={true} size="large">
                  Update password
                </Button>
                <InlineError message={formError} />
              </FormLayout>
            </Form>
          </div>
        </div>
      </UserLayout>
    </>
  );
};

export default ResetPassword;
