import { Icon, TextField } from '@shopify/polaris';
import { HideMinor, ViewMinor } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';

function InputField({ value, onChange, type, suffix, ...restProps }) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => setShowPassword((prev) => !prev), []);
  return (
    <TextField
      value={value}
      onChange={onChange}
      type={type !== 'password' ? type : showPassword ? 'text' : 'password'}
      suffix={
        type === 'password' ? (
          <div className={'suffix-action'} onClick={toggleShowPassword}>
            <Icon source={showPassword ? HideMinor : ViewMinor} />
          </div>
        ) : (
          suffix || null
        )
      }
      {...restProps}
    />
  );
}

export default InputField;
