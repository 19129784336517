import {navigate, Link} from "@reach/router"
import Page from '@modules/page'
import {Layout, Card, Icon, Caption, Stack, TextStyle, Button} from "@shopify/polaris"
import {CartUpMajor, CartMajor, AddProductMajor, DiscountsMajor, ProductsMajor} from '@shopify/polaris-icons'

const UpSell = () => {
	return (
		<Page
			title="Upsell offers"
			breadcrumbs={[{content: 'Dashboard', onAction: () => navigate('/')}]}
		>
			<Layout>
				<Layout.Section>
					<Card>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={AddProductMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/bundles'}>Bundle products</Link>
										<Caption>Sell more products by suggesting your customer an attractive combo in the product details page.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button onClick={() => navigate('/bundles/new')}>Create bundle</Button>
								</Stack.Item>
							</Stack>
						</Card.Section>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={DiscountsMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/quantity-discounts'}>Quantity discounts</Link>
										<Caption>Quantity discount aims at motivating customers to buy more by presenting them with irresistible deals.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button onClick={() => navigate('/quantity-discounts/new')}>Create offer</Button>
								</Stack.Item>
							</Stack>
						</Card.Section>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={CartUpMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/pre-purchase'}>Pre-purchase upsell</Link>
										<Caption>Pre-purchase offer shows after user adds target product to cart to suggest a list of products they may want to buy.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button onClick={() => navigate('/pre-purchase/new')}>Create offer</Button>
								</Stack.Item>
							</Stack>
						</Card.Section>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={CartMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/in-cart'}>In-cart upsell</Link>
										<Caption>In-cart offer shows under a product in cart page to suggest one similar product that your buyers may want to buy.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button onClick={() => navigate('/in-cart/new')}>Create offer</Button>
								</Stack.Item>
							</Stack>
						</Card.Section>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={ProductsMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/product-recommendation'}>Related products</Link>
										<Caption>Related products makes it easier for customers to discover new products.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button onClick={() => navigate('/product-recommendation/new')}>Create recommendation</Button>
								</Stack.Item>
							</Stack>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	)
}

export default UpSell
