import { useAuthStore } from '@container/Auth';
import { getGreetingTime } from '@helpers/utils';
import Page from '@modules/page';
import { Banner, Button, ButtonGroup, FormLayout, Layout } from '@shopify/polaris';
import React, { useEffect } from 'react';
import ArticleCard from './components/articleCard';
import RecommendedThemes from './components/recommendedThemes';
import OurApps from './components/ourApps';
import PromotionBanner from './components/promotionBanner';
import FeaturedBanners from './components/featuredBanners';
import InfoBanner from '@components/infoBanner';
import FeedbackCard from '@components/feedbackCard';
import { navigate, Redirect } from '@reach/router';
import axios from '@plugins/axios';

export default function Dashboard() {
  const { shop_owner, license_active, currentUser } = useAuthStore();
  const shop = axios.defaults.headers.common['X-Request-Shop-Origin'];

  const fullName = [currentUser?.firstName, currentUser?.lastName].filter(Boolean);
  useEffect(() => {
    document.title = 'Dashboard';
    document.body.classList.remove('install-page', 'user-page');
  }, []);

  return !shop ? (
    <Redirect from="" to="/select-store" noThrow />
  ) : (
    <Page title={`${getGreetingTime()}, ${fullName.join(' ') || shop_owner}!`} subtitle={'Welcome to FoxHome! 👋 😊'}>
      <Layout>
        <Layout.Section>
          <div style={{ paddingTop: 30 }} />
          <InfoBanner />
          {!license_active && (
            <div style={{ paddingBottom: '1.6rem' }}>
              <Banner title="You haven't activated your license yet" status="warning">
                <FormLayout>
                  <p>Please activate the purchase with your license code & get full access to our premium features.</p>
                  <ButtonGroup>
                    <Button onClick={() => navigate('/settings/licenses')}>Activate license</Button>
                  </ButtonGroup>
                </FormLayout>
              </Banner>
            </div>
          )}
          <FeedbackCard />
          <FeaturedBanners />
          <PromotionBanner />
          <RecommendedThemes />
          <OurApps />
          <ArticleCard />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
