import { useState, useRef } from 'react';
import { Spinner, Avatar, Button, ButtonGroup } from '@shopify/polaris';
import { uploadImageService } from '@services/user';
import { useAuthStore } from '@container/Auth';
import { updateUserProfileService } from '@services/user';
import { showToast } from '@plugins/toast';
import { isNil } from 'lodash';

const UserAvatar = ({ onChange, avatar }) => {
  const [loading, setLoading] = useState(false);
  const { currentUser, updateCurrentUser } = useAuthStore();
  const inputRef = useRef(null);

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const handleSave = async (value) => {
    await updateUserProfileService({ ...value });
    updateCurrentUser({ ...value });
  };

  const onchangeHandle = async (evt) => {
    try {
      if (!evt.target.files[0]) return;
      setLoading(true);
      const uploadedImage = await uploadImageService(evt.target.files[0]);
      await handleSave({
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        dateOfBirth: currentUser?.dateOfBirth,
        signUpNewsletter: currentUser?.signUpNewsletter,
        avatar: uploadedImage,
      });
      showToast({ message: 'Update avatar successfully.' });
    } catch (error) {
      showToast({ message: error.message, error });
    } finally {
      setLoading(false);
    }
  };

  const removeAvatar = () => {
    onChange('');
  };

  return (
    <>
      {loading ? (
        <div style={{ width: 60, height: 60 }}>
          <Spinner />
        </div>
      ) : (
        <ButtonGroup>
          <Avatar initials={currentUser?.firstName?.charAt(0)} source={isNil(avatar) ? currentUser?.avatar : avatar} size="large" />
          <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={onchangeHandle} value={''} accept="image/*" />
          {!currentUser?.isGuest ? (
            <>
              <Button onClick={handleButtonClick} plain>
                {avatar && avatar !== '' ? 'Replace' : 'Upload avatar'}
              </Button>
              {avatar && avatar !== '' && (
                <Button plain destructive onClick={removeAvatar}>
                  Remove
                </Button>
              )}
            </>
          ) : null}
        </ButtonGroup>
      )}
    </>
  );
};

export default UserAvatar;
