import UserLayout from '@modules/userLayout';
import { Button, DisplayText, Form, FormLayout, TextContainer, TextField, Link } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { showToast } from '@plugins/toast';
import { getParam } from '@helpers/utils';
import { getEmailVerificationService, sendVerifyCodeService, verifyCodeService } from '@services/user';

const AccessVerification = () => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = getParam('token');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!token) return;
    (async () => {
      const email = await getEmailVerificationService(token);
      if (email) setEmail(email);
    })();
  }, [token]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await verifyCodeService(code, token);
      setLoading(false);
      window.location.href = '/';
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const handleResendEmail = async () => {
    try {
      await sendVerifyCodeService(token);
      showToast({
        message: 'New verification code has been sent!',
      });
    } catch (error) {
      showToast({
        message: error?.message,
        error: true,
      });
    }
  };

  return (
    <UserLayout title={'Access verification'} showQuote={false}>
      <div className="form-container">
        <div className="form-header">
          <FormLayout>
            <TextContainer>
              <DisplayText size={'extraLarge'}>
                <strong>{'Access verification'}</strong>
              </DisplayText>
              <div className={'Polaris-DisplayText Polaris-DisplayText--sizeSmall Polaris-DisplayText--mobile'}>
                <p>
                  You are attempting to log into <strong>FoxHome</strong> via the Shopify store. We have sent an email to the store owner's email {'('}
                  {email}{')'} with a verification code. Please enter the verification code to continue managing this store.
                </p>
              </div>
            </TextContainer>
          </FormLayout>
        </div>

        <div className="form-content">
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                label="Verification code"
                onChange={(value) => setCode(value)}
                value={code}
                autoComplete="off"
                autoFocus
                error={error ? 'Code is invalid' : undefined}
              />
              <Button primary loading={loading} submit fullWidth={true} size="large">
                <strong>Log in</strong>
              </Button>
              <p>
                Don't receive code? <Link onClick={handleResendEmail}>Resend email</Link>.
                Or already have an account? <Link url="/login">Login</Link>.
              </p>
            </FormLayout>
          </Form>
        </div>
      </div>
    </UserLayout>
  );
};

export default AccessVerification;
