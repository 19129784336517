import { useAuthStore } from "@container/Auth"
import Page from '@modules/page'
import axios from "@plugins/axios"
import { showToast } from "@plugins/toast"
import { Card, ContextualSaveBar, FormLayout, Layout, PageActions, Select, Stack, TextField } from "@shopify/polaris"
import { useCallback, useEffect, useState } from "react"
import { ContextProvider, useStore, defaultLocaleData } from "./container"
import { Skeleton } from "@modules/skeleton"
import { useLocation } from "@reach/router"

const Translations = () => {
  return (
    <ContextProvider>
      <TranslationView />
    </ContextProvider>
  )
}

const TranslationView = () => {
  const { state, dispatch } = useStore()
  const { primary_locale } = useAuthStore()
  const { hash } = useLocation()

  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const [localeOptions, setLocaleOptions] = useState([{ label: 'English', value: 'en' }])
  const [selectedLocale, setSelectedLocale] = useState()

  useEffect(() => {
    if (!loading && hash) {
      const section = document.querySelector(hash)
      if (section) section.scrollIntoView({ behavior: 'smooth' })
    }
  }, [hash, loading])

  useEffect(() => {
    if (selectedLocale) {
      axios
        .get(`/api/shopify/locale/${selectedLocale}`)
        .then(res => {
          if (res?.ok) {
            dispatch({ type: 'setData', payload: res?.payload?.data || defaultLocaleData })
            setLoading(false)
          }
        })
        .catch()
    }
  }, [selectedLocale])

  useEffect(() => {
    if (primary_locale) setSelectedLocale(primary_locale)
  }, [primary_locale])

  useEffect(() => {
    axios
      .get(`/api/shopify/locale/`)
      .then(res => {
        if (res.ok) {
          const locales = res.payload
          if (locales && Array.isArray(locales)) {
            const localeOpts = locales.map(({ locale: value, name: label }) => { return { label, value } })
            setLocaleOptions(localeOpts)
          }
        }
      })
      .catch(console.error)
  }, [])

  const handleSave = async () => {
    setSaving(true)
    axios
      .post(`/api/shopify/locale`, {
        locale: selectedLocale,
        data: { ...state }
      })
      .then(res => res.ok && showToast({ message: 'Updated successfully' }))
      .catch(console.error)
      .finally(() => setSaving(false))
  }

  const {
    date_format,
    discount_noti, fox_discount_noti, discount_summary, discount_title,
    sizechart_button,
    add_button, added_button, free,
    bundle_button, bundle_select, bundle_this_item, bundle_selected, bundle_total, bundle_saved,
    qty_discount_title, qty_discount_note,
    prepurchase_title, prepurchase_added,
    incart_title,
    field_name, field_email, field_birthday,
    bis_name, bis_email, bis_note, bis_phone, bis_signup, bis_heading, bis_desc, bis_open, bis_submit, bis_thankyou,
    preorder_discount_title, preorder_shipping_note, preorder_end_note
  } = state

  const handleChange = (key, value) => {
    dispatch({ type: 'setData', payload: { [key]: value } })
  }

  const handleSelectChange = useCallback(locale => {
    setSelectedLocale(locale)
  }, [])

  if (loading) return <Skeleton />

  return (
    <Page
      title="Translations"
      primaryAction={(
        <Stack alignment={'center'}>
          <span>Current language</span>
          <Select
            label="Select language"
            labelHidden
            options={localeOptions}
            onChange={handleSelectChange}
            value={selectedLocale}
          />
        </Stack>
      )}
      narrowWidth
    >
      <ContextualSaveBar
        saveAction={{
          onAction: handleSave,
          loading: saving,
          disabled: false,
        }}
      />
      <Layout>
        <Layout.Section>
          <Card
            title="Standards and formats"
          >
            <Card.Section>
              <FormLayout>
                <TextField
                  value={date_format}
                  label="Date format"
                  onChange={v => handleChange('date_format', v)}
                  labelAction={{
                    content: 'See format',
                    url: 'https://date-fns.org/v2.28.0/docs/format',
                    external: true
                  }}
                  helpText={'Example: MM/dd/yyyy => 03/12/2022'}
                />
              </FormLayout>
            </Card.Section>
          </Card>
          <Card
            title="General"
          >
            <Card.Section>
              <FormLayout>
                <TextField
                  value={discount_summary}
                  label="Discount summary"
                  onChange={v => handleChange('discount_summary', v)}
                />
                <TextField
                  value={discount_title}
                  label="Discount title"
                  onChange={v => handleChange('discount_title', v)}
                  helpText={'Show in cart summary'}
                />
                <TextField
                  value={discount_noti}
                  label="Discount warning message"
                  onChange={v => handleChange('discount_noti', v)}
                  helpText={'Show in cart summary'}
                />
                <TextField
                  value={fox_discount_noti}
                  multiline
                  label="FoxKit offer's warning when adding a discount code"
                  onChange={v => handleChange('fox_discount_noti', v)}
                  helpText={'Show in cart summary'}
                />
                <TextField
                  value={free}
                  label="Free badge"
                  onChange={v => handleChange('free', v)}
                  helpText={'Show in cart summary'}
                />
                <TextField
                  value={add_button}
                  label="Add button"
                  onChange={v => handleChange('add_button', v)}
                />
                <TextField
                  value={added_button}
                  label="Added button"
                  onChange={v => handleChange('added_button', v)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
          <Card
            title="Product bundles"
          >
            <Card.Section>
              <FormLayout>
                <TextField
                  value={bundle_this_item}
                  label="This item"
                  onChange={v => handleChange('bundle_this_item', v)}
                />
                <TextField
                  value={bundle_button}
                  label="Add button"
                  onChange={v => handleChange('bundle_button', v)}
                />
                <TextField
                  value={bundle_select}
                  label="Select button"
                  onChange={v => handleChange('bundle_select', v)}
                />
                <TextField
                  value={bundle_selected}
                  label="Selected button"
                  onChange={v => handleChange('bundle_selected', v)}
                />
                <TextField
                  value={bundle_total}
                  label="Total price"
                  onChange={v => handleChange('bundle_total', v)}
                />
                <TextField
                  value={bundle_saved}
                  label="Saved"
                  onChange={v => handleChange('bundle_saved', v)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
          <Card
            title="Quantity discount"
          >
            <Card.Section>
              <FormLayout>
                <TextField
                  value={qty_discount_title}
                  label="Discount title"
                  onChange={(v) => handleChange('qty_discount_title', v)}
                />
                <TextField
                  value={qty_discount_note}
                  label="Discount note"
                  onChange={(v) => handleChange('qty_discount_note', v)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
          <Card
            title="In-cart"
          >
            <Card.Section>
              <FormLayout>

                <TextField
                  value={incart_title}
                  label="Offer title"
                  onChange={(v) => handleChange('incart_title', v)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
          <Card
            title="Pre-purchase"
          >
            <Card.Section>
              <FormLayout>
                <TextField
                  value={prepurchase_title}
                  label="Offer title"
                  onChange={(v) => handleChange('prepurchase_title', v)}
                />
                <TextField
                  value={prepurchase_added}
                  label="Added message"
                  onChange={(v) => handleChange('prepurchase_added', v)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
          <Card
            title="Size chart"
          >
            <Card.Section>
              <FormLayout>
                <TextField
                  value={sizechart_button}
                  label="Size chart button"
                  onChange={(v) => handleChange('sizechart_button', v)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
          <Card
            title="Form"
          >
            <Card.Section>
              <FormLayout>
                <TextField
                  value={field_email}
                  label="Email"
                  onChange={(v) => handleChange('field_email', v)}
                />
                <TextField
                  value={field_name}
                  label="Name"
                  onChange={(v) => handleChange('field_name', v)}
                />
                <TextField
                  value={field_birthday}
                  label="Date of birth"
                  onChange={(v) => handleChange('field_birthday', v)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
          <Card
            title={<h2 id="back-in-stock" className="Polaris-Heading">Back in stock</h2>}
          >
            <Card.Section>
              <FormLayout>
                <TextField
                  value={bis_open}
                  label="Open form button"
                  onChange={(v) => handleChange('bis_open', v)}
                />
                <TextField
                  value={bis_heading}
                  label="Form heading"
                  onChange={(v) => handleChange('bis_heading', v)}
                />
                <TextField
                  value={bis_desc}
                  label="Form description"
                  multiline={2}
                  onChange={(v) => handleChange('bis_desc', v)}
                />
                <FormLayout.Group>
                  <TextField
                    value={bis_email}
                    label="Email field placeholder"
                    onChange={(v) => handleChange('bis_email', v)}
                  />
                  <TextField
                    value={bis_name}
                    label="Name field placeholder"
                    onChange={(v) => handleChange('bis_name', v)}
                  />
                  <TextField
                    value={bis_phone}
                    label="Phone number field placeholder"
                    onChange={(v) => handleChange('bis_phone', v)}
                  />
                  <TextField
                    value={bis_note}
                    label="Note field placeholder"
                    onChange={(v) => handleChange('bis_note', v)}
                  />
                </FormLayout.Group>
                  <TextField
                    value={bis_signup}
                    label="Sign-up checkbox"
                    onChange={(v) => handleChange('bis_signup', v)}
                  />
                  <TextField
                    value={bis_submit}
                    label="Submit button"
                    onChange={(v) => handleChange('bis_submit', v)}
                  />
                <TextField
                  value={bis_thankyou}
                  label="Thank you message"
                  onChange={(v) => handleChange('bis_thankyou', v)}
                />
              </FormLayout>
            </Card.Section>
          </Card>
          <Card
            title={<h2 id="preorder-text" className="Polaris-Heading">Pre-order</h2>}
            sectioned
          >
            <FormLayout>
              <TextField
                value={preorder_discount_title}
                label="Discount title"
                onChange={(v) => handleChange('preorder_discount_title', v)}
              />
              <TextField
                value={preorder_shipping_note}
                label="Shipping note"
                onChange={(v) => handleChange('preorder_shipping_note', v)}
                helpText={<p>{`{eta} variable is estimate time arrival.`}</p>}
              />
              <TextField
                value={preorder_end_note}
                label="End time note"
                onChange={(v) => handleChange('preorder_end_note', v)}
                helpText={<p>{`{end_time} variable is end time.`}</p>}
              />
            </FormLayout>
          </Card>
        </Layout.Section>
      </Layout>
      <PageActions
        primaryAction={{
          content: 'Save',
          loading: saving,
          onAction: handleSave

        }}
      />
    </Page>
  )
}

export default Translations
