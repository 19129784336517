import InputField from '@components/inputField';
import { useAuthStore } from '@container/Auth';
import { useNotificationStore } from '@container/NotificationProvider';
import { DEBOUNCE_TIME, LIMIT_STORE, SHOP_SELECTED_KEY, THEME_NAME } from '@helpers/constants';
import { getParam, updateParam } from '@helpers/utils';
import Page from '@modules/page';
import Table from '@modules/table';
import AddStoreModal from '@pages/store-settings/addStoreModal';
import { showToast } from '@plugins/toast';
import { navigate } from '@reach/router';
import { deleteShopService, getAllShopService, updateShopStatusService } from '@services/shop';
import { Button, Card, EmptyState, Icon, Layout, SkeletonBodyText } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ContextProvider } from './container';
import ShopItem from './shopItem';
import { useMedia } from '@shopify/react-hooks';

const StoreSettings = () => {
  return (
    <ContextProvider>
      <SettingsView />
    </ContextProvider>
  );
};
const SkeletonList = () => {
  return Array.from({ length: 4 }).map(() => (
    <tr>
      <td>
        <SkeletonBodyText lines={1} />
      </td>
      <td>
        <SkeletonBodyText lines={1} />
      </td>
      <td>
        <SkeletonBodyText lines={1} />
      </td>
    </tr>
  ));
};

const SettingsView = () => {
  const isSmallScreen = useMedia('(max-width:650px)');
  const isNarrowScreenWithNav = useMedia('(min-width:768px) and (max-width: 860px)');
  const [loading, setLoading] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const { storeList, updateStoreList, currentUser } = useAuthStore();
  const [storesDisplay, setStoresDisplay] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [payloadModal, setPayloadModal] = useState({ product: THEME_NAME.MINIMOG });
  const [purchaseCodeOnly, setPurchaseCodeOnly] = useState(false);
  const { showConfirm } = useNotificationStore();
  const queryParam = getParam('query');

  const handleChangeModal = useCallback(() => setActiveModal(!activeModal), [activeModal]);

  const handleChangeSearch = (newVal) => {
    setSearchValue(newVal);
    setLoading(true);
    debounceSearch(newVal);
  };
  const debounceSearch = useCallback(
    debounce(async (query) => {
      try {
        updateParam('query', query);
        let newList;
        if (!query) {
          newList = storeList;
        } else {
          newList = await getAllShopService({ query });
        }
        setStoresDisplay(newList);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showToast({
          message: error?.message,
          error: true,
        });
      }
    }, DEBOUNCE_TIME),
    [storeList],
  );

  const toggleEditModal = (payload) => {
    handleChangeModal();
    setPayloadModal((prev) => ({
      ...prev,
      edit: true,
      ...payload,
    }));
    setPurchaseCodeOnly(false);
  };

  useEffect(() => {
    (async () => {
      try {
        if (searchValue !== queryParam) setSearchValue(queryParam);
        setLoading(true);
        let newList = storeList;

        if (queryParam) {
          newList = await getAllShopService({ query: queryParam });
        }
        console.log('newList:', newList);
        setStoresDisplay(newList);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showToast({
          message: error?.message,
          error: true,
        });
      }
    })();
  }, [storeList]);

  const handleCloseModal = () => {
    setPayloadModal({ product: THEME_NAME.MINIMOG });
    setPurchaseCodeOnly(false);
    setActiveModal(false);
  };

  const handleFetchNewList = async () => {
    const response = await getAllShopService({});

    updateStoreList(response);
  };

  const handleDeleteStore = async (id) => {
    showConfirm({
      title: 'Delete this store',
      confirmButtonText: 'Delete',
      danger: true,
      message: "Are you sure you want to delete this store. This can't be undone.",
      onConfirm: async () => {
        try {
          await deleteShopService(id);

          const currentShop = storeList.find((storeItem) => storeItem._id === id);

          await handleFetchNewList();

          showToast({
            message: 'Delete store successfully!',
          });

          if (currentShop.shop === localStorage.getItem(SHOP_SELECTED_KEY)) {
            navigate('/select-store');
          }
        } catch (error) {
          showToast({
            message: error?.message,
            error: true,
          });
        }

        return Promise.resolve();
      },
    });
  };

  const handleConfirm = async () => {
    setLoading(true);
    await handleFetchNewList();
    setLoading(false);
    setPurchaseCodeOnly(false);
    setPayloadModal({ product: THEME_NAME.MINIMOG });
  };

  const handleAddPurchaseCode = async (shop) => {
    setPayloadModal({ shop, product: THEME_NAME.MINIMOG });
    setPurchaseCodeOnly(true);
    handleChangeModal();
  };

  const handleUpdateStatus = async (shopId, status) => {
    try {
      setLoading(true);
      await updateShopStatusService(shopId, status);
      await handleFetchNewList();
      setLoading(false);
      showToast({
        message: 'Update shop status successfully',
      });
    } catch (error) {
      console.log(error);
      setLoading(false);

      showToast({
        message: error?.message,
        error: true,
      });
    }
  };

  const handleAddStore = () => {
    setPurchaseCodeOnly(false);
    setPayloadModal({ product: THEME_NAME.MINIMOG });
    handleChangeModal();
  };

  return (
    <Page
      title="Stores manager"
      subtitle="Manage stores connected to your FoxHome account."
      breadcrumbs={[
        {
          content: 'Settings',
          onAction: () => navigate('/settings'),
        },
      ]}
    >
      <div className="settingStore-page__container">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              {storeList?.length > LIMIT_STORE && (
                <div style={{ paddingBottom: '1rem' }}>
                  <InputField
                    prefix={<Icon source={SearchMinor} />}
                    clearButton
                    onClearButtonClick={() => handleChangeSearch('')}
                    placeholder="Search your store domain..."
                    value={searchValue}
                    onChange={handleChangeSearch}
                  />
                </div>
              )}
              <div className="store-table__container">
                <Table bordered>
                  <thead>
                    {isSmallScreen || isNarrowScreenWithNav ? (
                      <tr>
                        <th>Status</th>
                        <th>Store</th>
                        <th>Domain</th>
                        <th />
                        <th />
                      </tr>
                    ) : (
                      <tr>
                        <th>Status</th>
                        <th>Store</th>
                        <th>Domain</th>
                        <th />
                      </tr>
                    )}
                  </thead>
                  <tbody style={{ position: 'relative' }}>
                    <tr></tr>
                    {loading ? (
                      <SkeletonList />
                    ) : storesDisplay?.length ? (
                      storesDisplay.map((store, index) => {
                        return (
                          <ShopItem
                            storesDisplay={storesDisplay}
                            onAddPurchase={handleAddPurchaseCode}
                            onEdit={toggleEditModal}
                            onDelete={handleDeleteStore}
                            onUpdateStatus={handleUpdateStatus}
                            store={store}
                            key={store.id}
                            index={index}
                          />
                        );
                      })
                    ) : (
                      <td style={{ textAlign: 'center' }} colSpan={3}>
                        <EmptyState
                          heading="No store found"
                          action={{ content: 'Add new store', onAction: handleChangeModal }}
                          image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e5ba4e701142a8aa.svg"
                        >
                          <p>No store found. Please add your own store</p>
                        </EmptyState>
                      </td>
                    )}
                    {!loading && storesDisplay?.length && !currentUser?.isGuest ? (
                      <div style={{ paddingTop: 20, position: 'absolute' }}>
                        <Button onClick={handleAddStore}>Add store</Button>
                      </div>
                    ) : null}
                  </tbody>
                </Table>
              </div>
              {activeModal && (
                <AddStoreModal payload={payloadModal} onClose={handleCloseModal} onConfirm={handleConfirm} purchaseCodeOnly={purchaseCodeOnly} />
              )}
            </Card>
          </Layout.Section>
        </Layout>
      </div>
    </Page>
  );
};

export default StoreSettings;
