import { useAuthStore } from "@container/Auth"
import API from "@helpers/api"
import Page from "@modules/page"
import { Skeleton } from "@modules/skeleton"
import { showToast } from "@plugins/toast"
import { Banner, Card, ChoiceList, ContextualSaveBar, FormLayout, Layout, Link, PageActions, TextStyle } from "@shopify/polaris"
import { isEqual } from "lodash"
import { useCallback, useEffect, useState } from "react"
import DiscardModal from "../../components/discardModal"
import { ContextProvider } from "./container"

const Preferences = () => {
  return (
    <ContextProvider>
      <SettingsView />
    </ContextProvider>
  )
}

const SettingsView = () => {
  const { shop, discount_apply_by, updateShopData } = useAuthStore()
  const [discountApplyBy, setDiscountApplyBy] = useState()
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState()

	const isEqualState = isEqual(discountApplyBy, prevState)

  useEffect(() => {
    const handleReloadPage = (e) => {
      e.preventDefault()
      e.returnValue = ""
    }
    if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
    return () => window.removeEventListener("beforeunload", handleReloadPage)
  }, [isEqualState])

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);
  useEffect(() => {
    if (shop) {
      setDiscountApplyBy(discount_apply_by || 'discount_code')
      setPrevState(discount_apply_by || 'discount_code')
      setLoading(false)
    }
  }, [shop])

  const handleSave = async () => {
    setSaving(true)
    updateShopData({ discount_apply_by: discountApplyBy })
    setPrevState(discountApplyBy)
    API
      .updateByShop('shop', { discount_apply_by: discountApplyBy })
      .then(res => {
        if (res.ok) {
          console.log("res", res);
          showToast({ message: 'Updated successfully' })
        }
      })
      .finally(() => setSaving(false))
  }

  const handleChange = useCallback(value => setDiscountApplyBy(value[0]), []);

  if (loading) return <Skeleton />
  return (
    <Page title="General settings">
      {!isEqualState && <ContextualSaveBar
        saveAction={{
          onAction: handleSave,
          loading: saving,
          disabled: false,
        }}
        discardAction={{
          onAction: () => handleModalChange(),
        }}
      />}
      <Layout>
        <Layout.Section>
          <Card title="Discount method">
            <Card.Section subdued>
              <TextStyle variation={'subdued'}>
                Choose how the discount from FoxKit offers applies to your orders. <Link url={'https://docs.minimog.co/foxkit-app/settings'} external>Learn more</Link>
              </TextStyle>
            </Card.Section>
            <Card.Section>
              <FormLayout>
                <ChoiceList
                  title={'Discount method'}
                  titleHidden
                  choices={[
                    {
                      label: 'Use discount code',
                      value: 'discount_code',
                      helpText: (<>
                        <p>A one-time-use Discount Code will be generated and automatically applied to your customer's order at checkout.</p>
                        <span>Learn more about</span>
                        {' '}
                        <Link external url="https://help.shopify.com/en/manual/promoting-marketing/discount-codes">
                          discount code
                        </Link>
                      </>)
                    },
                    {
                      label: 'Use draft order',
                      value: 'draft_order',
                      helpText: (<div style={{ marginBottom: '16px' }}>
                        <p>If your customer is eligible for a FoxKit's offer, a Draft Order with discounted prices will be created for them to checkout.</p>
                        <span>Learn more about</span>
                        {' '}
                        <Link external url="https://help.shopify.com/en/manual/orders/create-orders">
                          draft order
                        </Link>
                      </div>)
                    }
                  ]}
                  selected={discountApplyBy}
                  onChange={handleChange}
                />
                {discountApplyBy === 'draft_order' && <Banner status={'warning'}>
                  When this option is enabled, merchants cannot combine the Fox Kit discount with other discounts in Shopify admin. Customers will not be able to enter another code if a Fox Kit discount is already applied to their cart.
                </Banner>}
                {discountApplyBy === 'discount_code' && <Banner status={'warning'}>
                  Selecting this option will disable the <Link url={'/free-shipping-goal'}>Free shipping
                    goal</Link> add-on since an order can only be applied with 1 discount code. Once Free shipping goal is
                  disabled, you have to set up again by Shipping rates and re-activate it.
                </Banner>}
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <PageActions
        primaryAction={{
          content: 'Save',
          onAction: handleSave,
          loading: saving
        }}
      />
      <DiscardModal 
				activeModal={activeModal}
				handleModalChange={handleModalChange}
        backLink="/"
			/>
    </Page>
  )
}

export default Preferences
