import FoxifyCard from '@components/foxifyCard';
import StatusBadge from '@components/statusBadge';
import API from '@helpers/api';
import { discountMap, getURLParams } from '@helpers/utils';
import DataList from '@modules/dataList';
import Page from '@modules/page';
import { SkeletonList } from '@modules/skeleton';
import { CancelToken } from '@plugins/axios';
import { showConfirm } from '@plugins/confirm';
import { showToast } from '@plugins/toast';
import { navigate } from '@reach/router';
import { Card, Layout, ResourceItem, TextStyle } from '@shopify/polaris';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

const BundlesList = () => {
  const [update, setUpdate] = useState(true);
  const [state, setState] = useState({ ready: false, pageInfo: {}, isSearched: false });
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const source = CancelToken.source();

  useEffect(() => {
    const params = getURLParams();
    fetchData(params);
    return () => source.cancel();
  }, [update]);

  const toggleUpdate = useCallback(() => {
    setUpdate((update) => !update);
    setSelectedItems([]);
  }, []);

  const fetchData = async ({ searchQuery, page = 1, ...filters }) => {
    const params = getURLParams();
    const newParams = {
      ...params,
      query: searchQuery,
      page: page,
      ...filters,
    };
    let result;
    try {
      result = await API.getList('pre-order', newParams, source.token);
      if (result) {
        const { payload } = result;
        setState({ ...state, ...result, ready: true, isSearched: true });
        setItems(
          payload.map((i) => {
            return {
              id: i._id,
              ...i,
            };
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (query) => fetchData({ query });

  const handleBulkDelete = () => {
    showConfirm({
      title: `Delete ${selectedItems.length} pre-order product(s)?`,
      message: "Are you sure? This can't be undone.",
      confirm: 'Delete',
      cancel: 'Cancel',
      danger: true,
    }).then((res) => {
      if (res) {
        API.bulkDelete('pre-order', selectedItems).then((res) => {
          console.log(res);
          if (res.ok) {
            showToast({
              message: 'Pre-order(s) deleted',
            });
            toggleUpdate();
          } else {
            showToast({
              message: 'Cannot delete, please try again',
              error: true,
            });
          }
        });
      }
    });
    return false;
  };

  const handleBulkUpdate = (status = false) => {
    API.bulkUpdate('pre-order', {
      ids: selectedItems,
      active: status,
    }).then((res) => {
      console.log(res);
      if (res.ok) {
        showToast({
          message: 'Pre-order(s) updated!',
        });
        toggleUpdate();
      } else {
        showToast({
          message: 'Cannot update, please try again',
          error: true,
        });
      }
    });
    return false;
  };

  const resourceName = {
    singular: 'product',
    plural: 'products',
  };

  const promotedBulkActions = [
    {
      content: 'Set as active',
      onAction: () => {
        showConfirm({
          title: `Set ${selectedItems.length} pre-order product(s) as active?`,
          message: 'Setting pre-order product(s) as active will make them available to your store.',
          confirm: 'Set as active',
          cancel: 'Cancel',
        }).then((res) => res && handleBulkUpdate(true));
      },
    },
    {
      content: 'Set as inactive',
      onAction: () => {
        showConfirm({
          title: `Set ${selectedItems.length} pre-order product(s) as inactive?`,
          message: 'Setting pre-order product(s) as inactive will hide them from your store.',
          confirm: 'Set as inactive',
          cancel: 'Cancel',
        }).then((res) => res && handleBulkUpdate(false));
      },
    },
  ];

  const bulkActions = [
    {
      content: 'Delete pre-order product(s)',
      onAction: handleBulkDelete,
    },
  ];
  const headers = [
    {
      name: 'Status',
      width: '25%',
    },
    {
      name: 'Discount',
      width: '25%',
    },
  ];

  const { ready, pageInfo } = state;

  return ready ? (
    <Page
      title="Pre-order products"
      subtitle={'Offer Pre-orders to your customers when launching a new product or have out of stock products to keep sales flowing in.'}
      primaryAction={{
        content: 'Add pre-order product',
        onAction: () => navigate('/pre-order/new'),
      }}
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <DataList
              fetchData={fetchData}
              resourceName={resourceName}
              items={items}
              renderItem={renderItem}
              selectedItems={selectedItems}
              onSelectionChange={setSelectedItems}
              promotedBulkActions={promotedBulkActions}
              bulkActions={bulkActions}
              headers={headers}
              handleSearch={handleSearch}
              pageInfo={pageInfo}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  ) : (
    <SkeletonList />
  );
};

function renderItem(item) {
  const { _id, title, active, active_discount, discount, start_time, end_time, set_end_time } = item;
  let time = `From ${format(new Date(start_time), 'MMM dd')}`;
  if (set_end_time && end_time) {
    time += ` - ${format(new Date(end_time), 'MMM dd')}`;
  }
  return (
    <ResourceItem id={_id} accessibilityLabel={`View details for ${title}`} persistActions onClick={() => navigate(`/pre-order/${_id}`)}>
      <div className="resource-item__wrapper">
        <div className="title">
          <TextStyle variation="strong">{title}</TextStyle>
          <div>
            <TextStyle variation={'subdued'}>{time}</TextStyle>
          </div>
        </div>
        <div className="column" style={{ width: '25%' }}>
          <StatusBadge status={active} />
        </div>
        <div className="column" style={{ width: '25%' }}>
          {active_discount ? discountMap(discount.type, discount.value) : 'None'}
        </div>
      </div>
    </ResourceItem>
  );
}

export default BundlesList;
