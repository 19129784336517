import React, {useState} from "react"
import {installTheme} from "@helpers/shopifyApi"
import {showToast} from "@plugins/toast"
import {Modal, TextContainer, Banner, Link} from "@shopify/polaris"

const ConfirmModal = ({open, handleChange, selectedTheme}) => {
	const [loading, setLoading] = useState(false)


	console.log(selectedTheme, 'selectedTheme')

	const handleUpdate = () => {
		setLoading(true)

		installTheme({
			demo_id: selectedTheme?.demo_id,
			role: 'unpublished',
			namespace: selectedTheme?.namespace,
			evanto_theme_id: selectedTheme?.evanto_theme_id,
			version: selectedTheme?.new_version,
			os_version: selectedTheme?.os_version,
			update: true,
			import_data_from: selectedTheme?.shopify_theme_id

		}).then((res) => {
			if (res.ok) {
				showToast({
					message: 'Theme updated!'
				})
			} else {
				showToast({
					message: res.message,
					error: true
				})
			}
		})
			.catch(error => {
				showToast({
					message: error.message,
					error: true
				})
			})
			.finally(() => {
				setLoading(false)
				handleChange()
			})
	}

	return (
		<Modal
			open={open}
			onClose={!loading ? handleChange : void (0)}
			title={`Update ${selectedTheme?.display_name}`}
			primaryAction={{
				content: 'Update now',
				onAction: handleUpdate,
				disabled: loading,
				loading: loading
			}}
			secondaryActions={[
				{
					content: 'Cancel',
					onAction: handleChange,
					disabled: loading
				}
			]}
		>
			<Modal.Section>
				<TextContainer>
					<p>We <strong>don't replace</strong> the original theme, we will <strong>duplicate</strong> your current theme settings and apply them to the new version.</p>
					<p>The updated theme is made available as an <strong>unpublished theme</strong>, you can publish it after checking carefully.</p>
					{selectedTheme?.os_version === '2.0' && <Banner status={'warning'}>
						<p>Starting with version 5.0.0, we've introduced exciting new features! You can now customize your sections with a wider range of color schemes and adjust the spacing around them by adding padding on top & bottom.</p>
            <p style={{marginTop: '10px'}}>After updating your theme, you might need to fine-tune the colors and spacing for some sections.</p>
            <p style={{marginTop: '10px'}}>Don't hesitate to reach out to our live chat team if you need any assistance!</p>
            <p style={{marginTop: '10px'}}><strong>Please check carefully before publishing the updated version.</strong></p>
					</Banner>}
				</TextContainer>
			</Modal.Section>
		</Modal>
	)
}

export default ConfirmModal
