import Page from '@modules/page'
import {Layout, ContextualSaveBar} from "@shopify/polaris"
import {ContextProvider, useStore} from "@pages/bundles/container"
import TitleCard from "./components/title"
import DiscountCard from "./components/discount"
import ProductsCard from "./components/products"
import StatusCard from "./components/status"
import LayoutCard from "./components/layout"
import {navigate} from "@reach/router"
import API from '@helpers/api'
import {useCallback, useState} from "react"
import {showToast} from "@plugins/toast"
import {checkRequiredFields} from "@helpers/utils"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'

const CreateBundle = () => {
	return (
		<ContextProvider>
			<CreateBundleView />
		</ContextProvider>
	)
}

const CreateBundleView = () => {
	const [loading, setLoading] = useState(false)
	let [error, setError] = useState([])
	const {state} = useStore()
	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState(state)

	const isEqualState = isEqual(omit(state, ['updatedAt']), omit(prevState, ['updatedAt']))

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);
	console.log("state", state);
	console.log("prevState", prevState);
	const handleSave = async () => {
		error = await checkRequiredFields(['title', 'products'], state)
		if (error.length > 0) {
			setError(error)
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: 'Please fill in all the mandatory fields',
				error: true
			})
		}

		const {products, target_product, show_target_only} = state
		if (products?.length && !target_product) state['target_product'] = products[0]?.value
		setLoading(true)
		API.create('bundle', {
			...state,
			products: products.map(p => p?.value)
		}).then(res => {
			setPrevState({...prevState, ...res.payload, products: products, show_target_only: show_target_only, target_product: target_product})
			console.log(res, 'res')
			if (res.ok) {
				navigate(`/bundles/${res.payload._id}`)
				return showToast({
					message: 'Bundle created'
				})
			}
		})
			.finally(() => setLoading(false))
	}
	return (
		<Page
			title="Create bundle"
			breadcrumbs={[{content: 'Bundles', onAction: () => isEqualState ? navigate('/bundles') : handleModalChange()}]}
		>
			{!isEqualState && <ContextualSaveBar
				message={'Unsaved changes'}
				saveAction={{
					onAction: handleSave,
					loading: loading
				}}
				discardAction={{
					onAction: () => handleModalChange(),
				}}
			/>}
			<Layout>
				<Layout.Section>
					<TitleCard error={error} />
					<ProductsCard error={error} />
					<LayoutCard />
				</Layout.Section>
				<Layout.Section secondary>
					<StatusCard />
					<DiscountCard />
				</Layout.Section>
			</Layout>
			<DiscardModal
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink='/bundles'
			/>
		</Page>
	)
}

export default CreateBundle
