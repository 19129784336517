import {Card, FormLayout, Link, Checkbox} from "@shopify/polaris"
import {useStore} from "@pages/pre-order/container"
import DatePicker from "@components/datePicker"
import {navigate} from "@reach/router"
const ETA = ({error = []}) => {
	const {state, dispatch} = useStore()
	const {eta, show_eta} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card sectioned title={'Estimate time arrival'}>
			<FormLayout>
				<Checkbox
					label={'Show shipping information'}
					checked={show_eta}
					onChange={v => handleChange('show_eta', v)}
				/>
				{show_eta && <DatePicker
					selected={eta ? new Date(eta) : ''}
					label={'Estimate time arrival'}
					onChange={v => handleChange('eta', v?.toISOString())}
					dateFormat="yyyy/MM/dd h:mm aa"
					minDate={new Date()}
					showTimeSelect
					error={error.includes('eta')}
				/>}
				<p>Go to <Link onClick={() => navigate("/theme/translations#preorder-text")}>Translations setting</Link> to change the message.</p>
			</FormLayout>
		</Card>
	)
}

export default ETA
