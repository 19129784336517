import {EmptyState, Card, Page} from "@shopify/polaris"
import {navigate} from "@reach/router"

const NotFound = () => (
	<Page>
		<Card sectioned>
			<EmptyState
				heading="There’s no page at this address"
				secondaryAction={{content: 'Back to Dashboard', onAction: () => navigate('/')}}
				image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e5ba4e701142a8aa.svg"
			>
				<p>Check the URL and try again.</p>
			</EmptyState>
		</Card>
	</Page>
)

export default NotFound
