import { Card, FormLayout, TextField } from "@shopify/polaris"
import { useStore } from "../container"

const TitleCard = ({ error }) => {
  const { state, dispatch } = useStore()
  const { title, heading } = state

  const handleChange = (key, value) => {
    dispatch({ type: 'setData', payload: { [key]: value } })
  }

  return (
    <Card sectioned>
      <FormLayout>
        <TextField
          label={'Title'}
          value={title}
          placeholder={'Title'}
          onChange={v => handleChange('title', v)}
          error={error.includes('title')}
        />
        <TextField
          value={heading}
          label={'Section heading'}
          onChange={v => handleChange('heading', v)}
          placeholder={'People also bought with {product_title}'}
          helpText="To display product's title, vendor, or type in section heading, add the variable {product_title}, {product_vendor} or {product_type} in the heading text setting"
        />
      </FormLayout>
    </Card>
  )
}

export default TitleCard
