import { useAuthStore } from '@container/Auth';
import Page from '@modules/page';
import PasswordModal from '@pages/account-settings/passwordModal';
import { showToast } from '@plugins/toast';
import { navigate, Redirect } from '@reach/router';
import { forgotPasswordService, updateUserProfileService } from '@services/user';
import { SkeletonBodyText, Button, Card, Checkbox, FormLayout, Layout, PageActions, TextField, TextStyle, Banner, Label } from '@shopify/polaris';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { ContextProvider } from './container';
import Table from '@modules/table';
import { SCOPE_DESCRIPTION_MAPPING } from '@helpers/constants';
import UserAvatar from './components/userAvatar';
import { useMedia } from '@shopify/react-hooks';
import { ChevronDownMinor, ChevronUpMinor } from '@shopify/polaris-icons';
const Settings = () => {
  return (
    <ContextProvider>
      <SettingsView />
    </ContextProvider>
  );
};

const SkeletonList = () =>
  Array.from({ length: 3 }).map(() => (
    <tr>
      <td colSpan={3}>
        <SkeletonBodyText lines={1} />
      </td>
    </tr>
  ));

const SettingsView = () => {
  const { currentUser, updateCurrentUser, scope, missingScopes, appType } = useAuthStore();
  const [saving, setSaving] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [value, setValue] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    avatar: undefined,
    signUpNewsletter: false,
  });
  const [loading, setLoading] = useState(false);
  const [collapsible, setCollapsible] = useState({});
  const isSmallScreen = useMedia('(max-width:650px)');
  const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

  const hideAccountSettings = currentUser?.isGuest && currentUser?.havingPassword;

  const toggleCollapse = (id) => {
    setCollapsible((prevState) => ({
      ...prevState,
      [id]: !(prevState[id] ?? false),
    }));
  };

  const handleUpdateAccount = async () => {
    if (currentUser?.havingPassword) {
      handleModalChange();
    } else {
      try {
        setLoading(true);

        await forgotPasswordService(currentUser?.email, true);

        setLoading(false);
        showToast({
          message: "A request to create account has been sent to store owner's email!",
        });
      } catch (error) {
        setLoading(false);
        showToast({
          error: true,
          message: error?.message,
        });
      }
    }
  };

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      dateOfBirth: currentUser?.dateOfBirth,
      signUpNewsletter: currentUser?.signUpNewsletter,
      avatar: currentUser?.avatar,
    }));
  }, [currentUser]);

  const handleChangeValue = (field, newValue) => {
    console.log(field, newValue);
    setValue((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      await updateUserProfileService({ ...value });
      updateCurrentUser({ ...value });
      showToast({
        message: 'Update profile successfully.',
      });
      setSaving(false);
    } catch (error) {
      showToast({
        message: 'Update profile successfully.',
        error: true,
      });
      setSaving(false);
    }
  };

  return hideAccountSettings ? (
    <Redirect from="" to="/" noThrow />
  ) : (
    <Page
      title="Account settings"
      breadcrumbs={[
        {
          content: 'Settings',
          onAction: () => navigate('/settings'),
        },
      ]}
      primaryAction={
        !currentUser?.isGuest
          ? {
              content: 'Update',
              onAction: handleSave,
              loading: saving,
            }
          : null
      }
    >
      <Layout>
        <Layout.AnnotatedSection title="Account details" description="We will use this information to contact you.">
          <Card>
            <Card.Section>
              <UserAvatar onChange={(newValue) => handleChangeValue('avatar', newValue)} avatar={value?.avatar} />
            </Card.Section>
            <Card.Section>
              <FormLayout>
                <TextField label="Email" onChange={() => {}} value={currentUser?.email} autoComplete="email" disabled />
                <FormLayout.Group condensed>
                  <TextField
                    label="First name"
                    onChange={(newValue) => handleChangeValue('firstName', newValue)}
                    value={value?.firstName}
                    autoComplete="off"
                  />
                  <TextField
                    label="Last name"
                    onChange={(newValue) => handleChangeValue('lastName', newValue)}
                    value={value?.lastName}
                    autoComplete="off"
                  />
                </FormLayout.Group>
                <TextField
                  label="Birthday"
                  onChange={(newValue) => handleChangeValue('dateOfBirth', newValue)}
                  value={value?.dateOfBirth}
                  autoComplete="off"
                  type={'date'}
                />
                <Checkbox
                  label="Sign up to our newsletter?"
                  onChange={(newValue) => handleChangeValue('signUpNewsletter', newValue)}
                  checked={value?.signUpNewsletter}
                />
              </FormLayout>
            </Card.Section>
            {!currentUser?.havingPassword ? (
              <Card.Section>
                <Button loading={loading} onClick={handleUpdateAccount}>
                  Create account
                </Button>
              </Card.Section>
            ) : null}
          </Card>
        </Layout.AnnotatedSection>
        {currentUser?.havingPassword ? (
          <Layout.AnnotatedSection title="Password" description="Update your password.">
            <Card sectioned>
              <FormLayout>
                <Button loading={loading} onClick={handleUpdateAccount}>
                  Update password
                </Button>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        ) : null}
        {appType !== 'foxkit' && (
          <Layout.AnnotatedSection title="Access scopes" description="List of access scopes granted to FoxHome">
            <Card sectioned>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Access scope</th>
                    <th>Status</th>
                    {isSmallScreen ? <th></th> : <th>Description</th>}
                  </tr>
                </thead>
                <tbody>
                  {scope?.split(',')?.length ? (
                    scope?.split(',')?.map((item, id) => {
                      const isMissing = missingScopes?.includes(item);
                      return (
                        <Fragment>
                          <tr key={id}>
                            <td>
                              <TextStyle variation={'code'}>{item}</TextStyle>
                            </td>
                            <td>
                              <TextStyle variation={isMissing ? 'negative' : 'positive'}>{isMissing ? 'Missing' : 'Granted'}</TextStyle>
                            </td>
                            {isSmallScreen ? (
                              <td style={{ textAlign: 'right' }}>
                                {collapsible[id] ? (
                                  <Button icon={ChevronUpMinor} onClick={() => toggleCollapse(id)} plain />
                                ) : (
                                  <Button icon={ChevronDownMinor} onClick={() => toggleCollapse(id)} plain />
                                )}
                              </td>
                            ) : (
                              <td>{SCOPE_DESCRIPTION_MAPPING?.[item]}</td>
                            )}
                          </tr>
                          {isSmallScreen && collapsible[id] && (
                            <tr>
                              <td colSpan={3}>
                                <TextStyle variation="strong">Description: </TextStyle>
                                <span className='scope-description'>{SCOPE_DESCRIPTION_MAPPING?.[item]}</span>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })
                  ) : (
                    <SkeletonList />
                  )}
                </tbody>
              </Table>
            </Card>
          </Layout.AnnotatedSection>
        )}
        {activeModal && <PasswordModal handleChange={handleModalChange} />}
      </Layout>
      {!currentUser?.isGuest ? (
        <PageActions
          primaryAction={{
            content: 'Update',
            onAction: handleSave,
            loading: saving,
          }}
        />
      ) : null}
    </Page>
  );
};

export default Settings;
