import { createContext, useContext, useReducer } from 'react'

export const initialState = {
	isEqualState: false,
	active: true,
	title: '',
	applies_to: 'all_products',
	recommend_to: 'specific_products',
	target_collection: '',
	target_products: [],
	recommended_products: [],
	recommended_collection: '',
	collection_sort_key: 'default',
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
