import Page from '@modules/page'
import {navigate, useParams} from "@reach/router"
import {ContextualSaveBar, Layout, PageActions} from "@shopify/polaris"
import {ContextProvider, useStore} from "./container"
import ProductsCard from "./components/products"
import StatusCard from "./components/status"
import TimerCard from "./components/timer"
import TitleCard from "./components/title"
import Preview from "./components/preview"
import {useCallback, useEffect, useState} from "react"
import API from "@helpers/api"
import {showToast} from "@plugins/toast"
import {Skeleton} from "@modules/skeleton"
import {CancelToken} from "@plugins/axios"
import {checkRequiredFields} from "@helpers/utils"
import StatusBadge from "@components/statusBadge"
import {showConfirm} from "@plugins/confirm"
import FlashSaleCard from "@pages/countdown-timer/components/flash-sale"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'

const EditSaleCountdown = () => {
	return (
		<ContextProvider>
			<EditSaleCountdownView />
		</ContextProvider>
	)
}

const EditSaleCountdownView = () => {
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	let [error, setError] = useState([])
	const {pageID} = useParams()
	const {state, dispatch} = useStore()
	const source = CancelToken.source()

	const {title, active, _id} = state
	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState({})

	const isEqualState = isEqual(omit(state, ['created_at', 'updated_at', "id", 'updatedAt']), omit(prevState, ['updatedAt']))

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

	useEffect(() => {
		const handleReloadPage = (e) => {
		  e.preventDefault()
		  e.returnValue = ""
		}
		if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
		return () => window.removeEventListener("beforeunload", handleReloadPage)
	  }, [isEqualState])

	useEffect(() => {
		let result
		const fetchData = async () => {
			try {
				result = await API.getDetail('countdown', pageID, source.token)
				console.log('Data details', result)
				if (result && result.ok && result.payload) {
					dispatch({type: 'setData', payload: result.payload})
					if (!result.payload.hasOwnProperty("target_collection")) {
						result.payload.target_collection = ""
					}
					setPrevState(result.payload)
					setReady(true)
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
		return () => source.cancel()
	}, [pageID])

	const handleSave = async () => {
		error = await checkRequiredFields(['duration', 'title'], state)
		if (error.length > 0) {
			setError(error)
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: 'Please fill in all the mandatory fields',
				error: true
			})
		}

		setSaving(true)
		const {target_products, target_collection, schedule_time, schedule, expires_date} = state
		API.update('countdown', {
			...state,
			target_products: target_products?.filter(Boolean).map(p => p.value),
			target_collection: target_collection?.value,
			schedule_time: schedule ? schedule_time : new Date()
		}).then((res) => {
			if (res.ok) {
				setPrevState({
					...prevState,
					...res.payload,
					target_products: target_products,
					target_collection: target_collection,
					schedule_time: schedule_time,
				})
				return showToast({
					message: 'Updated successfully'
				})
			}
		}).finally(() => setSaving(false))
	}

	const handleDelete = () => {
		showConfirm({
			title: `Delete ${title}?`,
			message: 'This can’t be undone.',
			danger: true,
			confirm: 'Delete'
		}).then(res => {
			if (res) {
				API.bulkDelete('countdown', [_id]).then((res) => {
					console.log(res)
					if (res.ok) {
						showToast({
							message: 'Countdown deleted'
						})
						return navigate('/countdown-timer')
					} else {
						showToast({
							message: 'Cannot delete, please try again',
							error: true
						})
					}
				})
			}
		})
	}

	return ready ? (
		<Page
			title={title}
			breadcrumbs={[{content: 'Countdown timer', onAction: () => isEqualState ? navigate('/countdown-timer') : handleModalChange()}]}
			titleMetadata={<StatusBadge status={active} />}
		>
			{!isEqualState && <ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: handleSave,
					loading: saving,
					disabled: false,
				}}
				discardAction={{
					onAction: () => handleModalChange()
				}}
			/>}
			<Layout>
				<Layout.Section oneHalf>
					<StatusCard />
					<TitleCard error={error} />
					<TimerCard error={error} />
					<ProductsCard />
					<FlashSaleCard />
				</Layout.Section>
				<Layout.Section oneHalf>
					<Preview />
				</Layout.Section>
			</Layout>
			<PageActions
				primaryAction={{
					content: 'Save',
					onAction: handleSave,
					loading: saving
				}}
				secondaryActions={[
					{
						content: 'Delete',
						destructive: true,
						onAction: handleDelete
					},
				]}
			/>
			<DiscardModal 
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink="/countdown-timer"
			/>
		</Page>
	) : <Skeleton />
}

export default EditSaleCountdown
