import {Card, FormLayout, TextField, ChoiceList, Checkbox, TextStyle} from "@shopify/polaris"
import {useStore, filterPassedTime} from "../container"
import DatePicker from "@components/datePicker"

const TimerCard = ({error = []}) => {
	const {state, dispatch} = useStore()
	const {duration, cdt_type, expires_date} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<>
			<Card title={'Timer settings'}>
				<Card.Section>
					<FormLayout>
						<ChoiceList
							title={'Countdown type'}
							selected={cdt_type}
							onChange={v => handleChange('cdt_type', v[0])}
							choices={[
								{
									label: 'Fixed time',
									value: 'fixed_time'
								},
								{
									label: 'Evergreen',
									value: 'evergreen',
									helpText: 'Repeat countdown timer after it ends.'
								},
							]}
						/>
						{cdt_type === 'evergreen' && (
							<TextField
								label={'Duration'}
								value={duration + ''}
								onChange={v => handleChange('duration', Number(v))}
								type={'number'}
								suffix={'hours'}
								min={0}
								error={error.includes('duration')}
							/>
						)}
						{cdt_type === 'fixed_time' && (
							<DatePicker
								selected={new Date(expires_date)}
								label={'Expires at'}
								onChange={v => handleChange('expires_date', v.toISOString())}
								dateFormat="yyyy/MM/dd h:mm aa"
								showTimeSelect
								minDate={new Date()}
								filterTime={filterPassedTime}
							/>
						)}
					</FormLayout>
				</Card.Section>
			</Card>
		</>
	)
}

export default TimerCard
