import {Card, FormLayout} from "@shopify/polaris"
import {useStore} from "@pages/customers/container"
import {format} from 'date-fns'
import {formatDate} from "@helpers/utils"

const OverviewCard = () => {
	const {state} = useStore()
	const {source, createdAt} = state
	const sourceMap = {
		popup: 'Popup',
		luckywheel: 'Lucky Wheel',
		bis: "Back in stock"
	}
	return (
		<Card sectioned title={'Overview'}>
			<FormLayout>
				<p><strong>Source:</strong> {sourceMap[source]}</p>
				<p><strong>Subscribed at:</strong> {formatDate(createdAt, true)}</p>
			</FormLayout>
		</Card>
	)
}

export default OverviewCard
