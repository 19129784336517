import { FIREBASE_METHOD } from '@helpers/constants';
import axios from '@plugins/axios';
import {
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import firebaseAuth from 'src/configs/firebase';

const ROOT_API = '/api';
const FIREBASE = `${ROOT_API}/thirdParty/firebase`;
const FIREBASE_LOGIN = `${FIREBASE}/login`;
const BASE_USER = '/api/user';
const USER = `${BASE_USER}/profile`;
const USER_GET = USER;
const USER_LOGIN = `${BASE_USER}/login`;
const USER_SIGNUP = `${BASE_USER}/signup`;
const USER_FORGOT_PASSWORD = `${BASE_USER}/forgot-password`;
const USER_RESET_PASSWORD = `${BASE_USER}/reset-password`;
const USER_UPDATE_PASSWORD = `${BASE_USER}/update-password`;
const USER_UPDATE = USER;
const USER_LOGOUT = `${BASE_USER}/logout`;
const USER_VERIFY_CODE = `${BASE_USER}/verify-code`;
const USER_SEND_VERIFY_CODE = `${BASE_USER}/send-code`;
const USER_GRANT_ACCESS = `${BASE_USER}/grant-access`;
const USER_EMAIL_VERIFICATION = `${BASE_USER}/email-verification`;
const USER_REQUEST_ACCESS_SHOP = `${BASE_USER}/request-access`;

const UPLOAD_IMAGE = `${BASE_USER}/avatar`;

const provider = {
  [FIREBASE_METHOD.GOOGLE]: new GoogleAuthProvider(),
  [FIREBASE_METHOD.APPLE]: new OAuthProvider('apple.com'),
  [FIREBASE_METHOD.FACEBOOK]: new FacebookAuthProvider(),
  [FIREBASE_METHOD.GITHUB]: new GithubAuthProvider(),
};

export const firebaseSignInWithPopup = async (method) => {
  const authProvider = provider[method];
  authProvider.setCustomParameters({
    prompt: 'select_account',
  });

  const result = await signInWithPopup(firebaseAuth, authProvider);
  const user = result.user;
  const idToken = await firebaseAuth.currentUser.getIdToken();
  await verifyFirebaseAuth({
    idToken,
    email: user?.email,
    displayName: user?.displayName,
  });
};

export const firebaseSignInWithGmailRedirect = async (method) => {
  const authProvider = provider[method];
  authProvider.setCustomParameters({
    prompt: 'select_account',
  });
  const result = await signInWithRedirect(firebaseAuth, provider[method]);
  const credential = GoogleAuthProvider.credentialFromResult(result);
  const user = result.user;
  const idToken = await firebaseAuth.currentUser.getIdToken();
  await verifyFirebaseAuth({
    ...credential,
    ...user,
    idToken,
  });
};

export const verifyFirebaseAuth = async (payload) => {
  const res = await axios.post(FIREBASE_LOGIN, {
    ...payload,
  });

  return res?.payload;
};

export const signOutService = async () => {
  const currentUser = firebaseAuth.currentUser;
  if (currentUser) {
    await signOut(firebaseAuth);
  }
  await axios.delete(USER_LOGOUT);
};

export const getCurrentUserService = async () => {
  const response = await axios.get(USER_GET);
  return response?.payload;
};

export const loginService = async (email, password, keepLogin) => {
  const response = await axios.post(USER_LOGIN, {
    email,
    password,
    keepLogin,
  });
  return response?.payload;
};

export const signUpService = async (payload) => {
  const response = await axios.post(USER_SIGNUP, {
    ...payload,
  });
  return response?.payload;
};

export const forgotPasswordService = async (email, isUpdatePassword) => {
  const response = await axios.post(USER_FORGOT_PASSWORD, {
    email,
    isUpdatePassword,
  });
  return response?.payload;
};

export const resetPasswordService = async (token, password) => {
  const response = await axios.post(USER_RESET_PASSWORD, {
    token,
    password,
  });
  return response?.payload;
};

export const updateUserProfileService = async (payload) => {
  const response = await axios.put(USER_UPDATE, {
    ...payload,
  });
  return response?.payload;
};

export const updatePasswordService = async (payload) => {
  const response = await axios.put(USER_UPDATE_PASSWORD, {
    ...payload,
  });

  return response?.payload;
};

export const uploadImageService = async (payload) => {
  const form = new FormData();
  form.append('file', payload);
  const response = await axios.post(UPLOAD_IMAGE, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response?.payload;
};

export const verifyCodeService = async (code, token) => {
  const response = await axios.get(USER_VERIFY_CODE, {
    params: {
      code,
      token,
    },
  });
  return response?.payload;
};

export const sendVerifyCodeService = async (token) => {
  const response = await axios.put(
    USER_SEND_VERIFY_CODE,
    {},
    {
      params: { token },
    },
  );
  return response?.payload;
};

export const grantAccessService = async (expiresAt, token) => {
  const response = await axios.put(
    USER_GRANT_ACCESS,
    {
      expiresAt,
    },
    {
      params: {
        token,
      },
    },
  );
  return response?.payload;
};

export const getEmailVerificationService = async (token) => {
  const response = await axios.get(USER_EMAIL_VERIFICATION, {
    params: { token },
  });

  return response?.payload;
};

export const sendRequestAccessService = async (shop) => {
  const response = await axios.get(USER_REQUEST_ACCESS_SHOP, {
    params: { shop },
  });

  return response?.payload;
};
