import { createContext, useContext, useReducer } from "react"
export const initialState = {
    active: false,
    show_name: true,
    show_phone_number: true,
    show_note: true,
    show_signup_newsletter: true,
    save_to: ["foxkit_subscribers"],
    platforms: [],
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "setData":
                return {
                    ...state,
                    ...action.payload
                }
            default:
                throw new Error()
        }
    }, initialState)
    return (
        <Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
    )
}

export const useStore = () => useContext(Context)
