import Page from '@modules/page';
import { EmptyState, Layout, Link } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import ThemeCard from '@components/themeCard';
import Skeleton from '@pages/themes/skeleton';
import ConfirmModal from '@pages/themes/confirm';
import Verify from '@pages/themes/verify';
import PublishModal from '@pages/themes/publish';
import { navigate } from '@reach/router';
import dashboardIcon from '@assets/dashboard.svg';
import axios from '@plugins/axios';
import ShopifyAPI from '@helpers/shopifyApi';
import { compareVersions } from '@helpers/utils';
import { useAuthStore } from '@container/Auth';
import { THEME_NAME } from '../../helpers/constants';
import { showToast } from '@plugins/toast';

const ManageThemes = () => {
  const [ready, setReady] = useState(false);
  const [selected, setSelected] = useState({});
  const [active, setActive] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [activePublish, setActivePublish] = useState(false);
  const [forUpdate, setForceUpdate] = useState(false);
  const [themes, setThemes] = useState([]);
  const [themesMap, setThemesMap] = useState([]);

  const { license_active } = useAuthStore();

  useEffect(() => {
    document.title = 'Themes';
    getThemeMap();
  }, []);

  useEffect(() => {
    if (forUpdate) handleFetchData();
  }, [forUpdate]);

  useEffect(() => {
    if (themesMap?.length) handleFetchData();
  }, [themesMap]);

  const handleToggleUnlisted = useCallback(() => setOpenUpdate(!openUpdate), [openUpdate]);

  const handleFetchData = async () => {
    await axios
      .get('/api/shopify/theme')
      .then(async (response) => {
        if (response?.ok) {
          await handleMapVersion(response?.payload);
          setForceUpdate(false);
          setReady(true);
        }
      })
      .catch((error) => {
        showToast({
          message: error.message,
          error: true,
        });
      });
  };

  const getThemeMap = async (themes) => {
    await ShopifyAPI.getThemesMap()
      .then((response) => {
        if (response.ok) setThemesMap(response?.payload);
      })
      .catch();
  };

  const handleMapVersion = (themes) => {
    const newThemes = themes?.map((theme) => {
      const found = themesMap?.find(
        (t) =>
          (t.namespace === THEME_NAME.MEGAMOG && theme?.namespace === THEME_NAME.MEGAMOG && t.os_version === theme.os_version) ||
          (t.evanto_theme_id === theme.evanto_theme_id && t.os_version === theme.os_version),
      );
      return {
        ...theme,
        new_version: found?.version,
      };
    });
    setThemes(newThemes);
  };

  const handleUpdate = (theme) => {
    setSelected(theme);
    handleToggleUpdate();
  };

  const handlePublish = (theme) => {
    setSelected(theme);
    handleTogglePublish();
  };

  const toggleForceUpdate = useCallback(() => {
    setForceUpdate((forUpdate) => !forUpdate);
  }, []);

  const handleToggleUpdate = useCallback(() => {
    if (active) setSelected({});
    setActive(!active);
  }, [active]);

  const handleTogglePublish = useCallback(() => {
    if (active) setSelected({});
    setActivePublish(!activePublish);
  }, [activePublish]);

  const publishedTheme = themes?.find((theme) => theme.role === 'main');
  const unpublishedThemes = themes?.filter((theme) => {
    return theme.role === 'unpublished' && compareVersions(theme.version, theme.new_version) < 2;
  });
  const outDatedThemes = themes?.filter((theme) => theme.role === 'unpublished' && compareVersions(theme.version, theme.new_version) > 1);

  return ready ? (
    <>
      <Page
        title="Themes"
        // subtitle={'Manage your store\'s themes. Get the latest version of your themes to take advantage of new features, enhancements and improvements.'}
        subtitle={<div>Manage your store's themes. Only made-in-FoxEcom themes are displayed/available here.</div>}
        hideBanner
        primaryAction={
          license_active
            ? {
                content: 'Update unlisted themes',
                primary: false,
                onAction: handleToggleUnlisted,
              }
            : undefined
        }
      >
        {active && <ConfirmModal open={active} selectedTheme={selected} handleChange={handleUpdate} />}
        {openUpdate && <Verify open={openUpdate} handleChange={handleToggleUnlisted} listedThemes={themes} themesMap={themesMap} />}
        {activePublish && (
          <PublishModal open={activePublish} selectedTheme={selected} handleChange={handleTogglePublish} forceUpdate={toggleForceUpdate} />
        )}
        {themes?.length ? (
          <Layout>
            {publishedTheme && (
              <Layout.AnnotatedSection title="Current published theme" description="This is the theme customers see when they visit your store.">
                <ThemeCard theme={publishedTheme} isMain={true} handleClick={handleUpdate} />
              </Layout.AnnotatedSection>
            )}
            {unpublishedThemes?.length > 0 && (
              <Layout.AnnotatedSection title={'Unpublished themes'} description={'Preview and customize your themes before publishing to customers.'}>
                {unpublishedThemes?.map((theme, index) => {
                  return <ThemeCard key={index} theme={theme} handleClick={handleUpdate} handlePublish={handlePublish} />;
                })}
              </Layout.AnnotatedSection>
            )}
            {outDatedThemes?.length > 0 && (
              <Layout.AnnotatedSection title={'Out of dated themes'} description={'The themes are out of date greater than 2 versions.'}>
                {outDatedThemes?.map((theme, index) => {
                  return <ThemeCard key={index} theme={theme} handleClick={handleUpdate} handlePublish={handlePublish} />;
                })}
              </Layout.AnnotatedSection>
            )}
          </Layout>
        ) : (
          <EmptyPage />
        )}
      </Page>
    </>
  ) : (
    <Skeleton />
  );
};

const EmptyPage = () => {
  return (
    <Layout>
      <Layout.Section>
        <div style={{ marginTop: 100 }}>
          <EmptyState
            fullWidth
            heading="Manage your store's themes"
            action={{
              content: 'Install a theme',
              onAction: () => navigate('/theme/install'),
            }}
            image={dashboardIcon}
          >
            <p>There are no our themes installed. Please install our theme to try our premium features.</p>
          </EmptyState>
        </div>
      </Layout.Section>
    </Layout>
  );
};

export default ManageThemes;
