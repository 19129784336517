import API from '@helpers/api';
import { SHOP_SELECTED_KEY, WHITE_LIST } from '@helpers/constants';
import variables from '@helpers/variables';
import Loading from '@modules/loading';
import axios from '@plugins/axios';
import { getCredentialsService } from '@services/credentials';
import { getAllShopService } from '@services/shop';
import { getCurrentUserService } from '@services/user';
import { Crisp } from 'crisp-sdk-web';
import { format } from 'date-fns';
import mixpanel from 'mixpanel-browser';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import getListAvailableTheme from '../shared/utils/getListAvailableTheme';
import { cloneDeep } from 'lodash';

const credentials = {
  authenticated: false,
  shop_domain: '',
  accessToken: null,
  license_active: null,
  currentUser: null,
  showStoreSelector: false,
};

const storageKey = '__fox-shop';

export const AuthContext = React.createContext(credentials);
export const AuthConsumer = AuthContext.Consumer;
export const useAuthStore = () => useContext(AuthContext);

const AuthProvider = (props) => {
  const [authState, setAuthState] = useState({ ...credentials });
  const [shopData, setShopData] = useState({});
  const [fetchingShopData, setFetchingShopData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shop = urlParams.get('shop');
    axios.defaults.headers.common['X-Request-Shop-Origin'] = shop || localStorage.getItem(SHOP_SELECTED_KEY);

    if (WHITE_LIST.includes(window.location.pathname)) {
      setLoading(false);

      (async () => {
        const userResponse = await getCurrentUserService();
        setCurrentUser(userResponse);
      })();

      return;
    }

    (async () => {
      try {
        const [userResponse, credentialsResponse, storeListResponse] = await Promise.allSettled([
          getCurrentUserService(),
          getCredentialsService(),
          getAllShopService({}),
        ]);

        setCurrentUser(userResponse.value);

        setStoreList(storeListResponse.value);

        if (credentialsResponse.value?.ok) {
          const { shop, show_official_app_announcement } = credentialsResponse?.value?.payload || {};
          setAuthState({
            ...authState,
            authenticated: true,
            shop_domain: shop,
            show_announcement: show_official_app_announcement,
          });
          localStorage.setItem(storageKey, shop);
          axios.defaults.headers.common['X-Request-Shop-Origin'] = shop;
          await handleFetchShop();
        } else {
          setAuthState({ ...credentials });
        }
        setLoading(false);
      } catch (error) {
        setAuthState({ ...credentials });
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const { email, shop_owner, shop, plan_name, createdAt, license_active } = shopData;
    // if (email && shop_owner) bootIntercom();
    if (email && shop_owner && variables.MIXPANEL_TOKEN) {
      mixpanel.identify(shop);
      mixpanel.people.set_once({
        'Shopify Plan': plan_name,
        Email: email,
        'Installed at': new Date(createdAt).toDateString(),
        'License activated': license_active,
      });
    }
    if (shopData) {
      initGtm();
    }
  }, [shopData]);

  const bootIntercom = () => {
    const { email, shop_owner, shop, license_active, plan_name, createdAt, _id, licenses } = shopData;
    let license = licenses && licenses.length && licenses[0];

    Crisp.user.setEmail(email);
    Crisp.user.setNickname(shop_owner);
    Crisp.session.setSegments(['foxhome', plan_name]);
    Crisp.session.setData({
      store_url: shop,
      shopify_plan: plan_name,
      store_access: 'unknown',
      review: 'unknown',
      plan_price: '',
      trial_end: '',
      installed_date: createdAt && format(new Date(createdAt), 'yyyy-MM-dd'),
      user_id: `foxhome_${_id}`,
      themeforest_user: license?.buyer || '',
      license_activated: license_active,
      purchase_code: license?.code || '',
    });
  };

  const initGtm = () => {
    const { license_active, plan_name, shop } = shopData;
    if (variables.GTM_ID) {
      const tagManagerArgs = {
        gtmId: variables.GTM_ID,
        dataLayer: {
          shop_domain: shop,
          license_active: license_active,
          shopify_plan: plan_name,
        },
      };

      TagManager.initialize(tagManagerArgs);
    }
  };

  const handleFetchShop = async () => {
    const shopRes = await API.get('shop');
    if (shopRes && shopRes.ok) {
      const {
        email,
        name,
        plan_name,
        shop_owner,
        licenses,
        show_onboarding,
        createdAt,
        _id,
        shop,
        primary_locale,
        currency_symbol,
        discount_apply_by,
        scope,
        missingScopes,
        currentTheme,
        appType,
        themeRating,
        shop_id,
        themeList,
      } = shopRes.payload || {};
      const license_active = handleCheckLicense(licenses);

      setShopData({
        scope,
        missingScopes,
        email,
        name,
        plan_name,
        shop_owner,
        licenses,
        show_onboarding,
        license_active,
        createdAt,
        _id,
        shop,
        primary_locale,
        currency_symbol,
        discount_apply_by,
        currentTheme,
        appType,
        themeRating,
        shop_id,
        themeList,
      });
    }
    setFetchingShopData(false);
  };

  const handleCheckLicense = (licenses) => {
    let license_active = false;
    if (licenses && licenses.length) {
      license_active = licenses.filter((lic) => lic.code)?.length > 0;
    }

    return license_active;
  };

  const updateShopData = (data) => {
    const newShopData = { ...shopData, ...data };
    if (data.licenses) {
      const license_active = handleCheckLicense(data.licenses);
      newShopData.license_active = license_active;
      newShopData.themeList = getListAvailableTheme(data.licenses);
    }

    setStoreList((prev) => {
      const newState = cloneDeep(prev);
      const index = newState?.findIndex((itemData) => itemData?.shop === newShopData?.shop);
      if (index !== -1) {
        newState[index] = {
          ...newState[index],
          ...newShopData,
          license: newShopData?.licenses?.[0],
        };
      }

      return newState;
    });
    setShopData(newShopData);
  };

  const updateCurrentUser = (user) => {
    setCurrentUser((prev) => ({
      ...prev,
      ...user,
    }));
  };

  const updateStoreList = (newStoreList) => setStoreList(newStoreList);

  const authValue = {
    fetchingShopData,
    ...authState,
    ...shopData,
    updateShopData,
    currentUser,
    updateCurrentUser,
    storeList,
    updateStoreList,
    handleFetchShop,
  };

  return <AuthContext.Provider value={authValue}>{loading ? <Loading.Screen /> : props.children}</AuthContext.Provider>;
};

export default AuthProvider;
