import {useCallback, useEffect, useState} from 'react'
import {
	Layout,
	Card,
	TextField,
	FormLayout,
	Checkbox,
	ContextualSaveBar,
	Select,
	Banner,
	AccountConnection, PageActions
} from "@shopify/polaris"
import {ContextProvider} from "./container"
import Page from "@modules/page"
import {useStore, languageOptions} from "./container"
import {navigate} from "@reach/router"
import API from "@helpers/api"
import {showToast} from "@plugins/toast"
import {Skeleton} from "@modules/skeleton"
import {CancelToken} from "@plugins/axios"
import {useAuthStore} from "../../container/Auth"
import ColorPicker from '@components/colorPicker'
import {showConfirm} from "@plugins/confirm"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'

const Messenger = () => {
	return (
		<ContextProvider>
			<MessengerView/>
		</ContextProvider>
	)
}

const MessengerView = () => {
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [forceSave, setForceSave] = useState(false)

	const {state, dispatch} = useStore()
	const source = CancelToken.source()

	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState(state)

	const isEqualState = isEqual(omit(state, ['updatedAt', 'createdAt', 'deleted', 'shop', "__v", "_id"]), omit(prevState, ['updatedAt', 'createdAt', 'deleted', 'shop', "__v", "_id"]))

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

	useEffect(() => {
		const handleReloadPage = (e) => {
		  e.preventDefault()
		  e.returnValue = ""
		}
		if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
		return () => window.removeEventListener("beforeunload", handleReloadPage)
	  }, [isEqualState])

	useEffect(() => {
		let result
		const fetchData = async () => {
			try {
				result = await API.getList('messenger', {}, source.token)
				console.log('Data details', result)
				if (result && result.ok) {
					dispatch({type: 'setData', payload: result.payload})
					if(result.payload) {
						setPrevState(result.payload)
					}
					setReady(true)
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
		return () => source.cancel()
	}, [])
	const handleSave = async () => {
		setSaving(true)
		const saveData = {...state}
		delete saveData['updatedAt']
		API.updateByShop('messenger', saveData).then((res) => {
			if (res.ok) {
				setPrevState({...prevState, ...res.payload})
				return showToast({
					message: 'Updated successfully'
				})
			}
		}).finally(() => {
			setSaving(false)
			setForceSave(false)
		})
	}

	const {active, connected, page_id, language, theme_color, logged_in_greeting, position, auto_expand, added_whitelist} = state

	useEffect(() => {
		if (forceSave) handleSave()
	}, [connected, forceSave])

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	const {shop_domain} = useAuthStore()

	const handleConnect = () => {
		if (connected) {
			return handleDisconnect()
		}
		const {innerHeight} = window
		const height = innerHeight - 100

		const domain = encodeURI(window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : ""))
		const windowFeatures = 'width=1200,height=' + height + ',top=50px,status=no'
		window.open(`https://www.facebook.com/customer_chat/dialog/?domain=${domain}`, '', windowFeatures)

		window.addEventListener("message", (event) => {
			if (!event.origin.includes('facebook.com')) return

			const data = event?.data ? JSON.parse(event?.data) : {}
			console.log(data, 'data event')
			if (data) {
				dispatch({
					type: 'setData',
					payload: {
						connected: !!data?.pageID,
						page_id: data?.pageID,
						language: data?.locale,
						theme_color: data?.themeColorCode
					}
				})
				setForceSave(true)
			}
		}, false)
	}

	const handleAddDomain = () => {
		const {innerHeight} = window
		const height = innerHeight - 100
		const domain = encodeURI(`https://${shop_domain}`)
		const windowFeatures = 'width=1200,height=' + height + ',top=50px,status=no'
		window.open(`https://www.facebook.com/customer_chat/dialog/?domain=${domain}`, '', windowFeatures)
	}

	const handleDisconnect = () => {
		showConfirm({
			title: "Disconnect Facebook page?",
			message: "Do you want to disconnect?",
			confirm: 'Disconnect'
		}).then(res => {
			if (res) {
				dispatch({type: 'setData', payload: {page_id: '', connected: false}})
				setForceSave(true)
			}
		})
	}

	const buttonText = connected ? 'Disconnect' : 'Connect'
	const details = connected ? `Page ID: ${page_id}`: 'No page connected'
	const terms = connected ? null : (
		<p>
			By clicking <strong>Connect</strong>, you agree to accept our terms & conditions.
		</p>
	)

	return ready ? (
		<Page
			title="Facebook Messenger"
			subtitle={'Let people start a conversation on your website and continue it in Messenger with the Chat Plugin.'}
			breadcrumbs={[{content: 'Boost conversion', onAction: () => isEqualState ? navigate('/boost-conversion') : handleModalChange()}]}
			narrowWidth
		>
			{!isEqualState && <ContextualSaveBar
				saveAction={{
					onAction: handleSave,
					loading: saving,
					disabled: false
				}}
				discardAction={{
					onAction: () => handleModalChange()
				}}
			/>}
			{connected && (
				<div style={{marginBottom: '1.6rem'}}>
					<Banner
						title="Add domain to whitelist "
						action={{content: 'Add domain', onAction: handleAddDomain}}
						status="warning"
					>
						<p>Need to add your domain to whitelist to show Chat plugin on the website.</p>
						<p>Just click button below and accomplish required steps in the popup to whitelist your domain automatically.</p>
					</Banner>
				</div>
			)}
			<Layout>
				<Layout.Section secondary>
					<AccountConnection
						accountName={'accountName'}
						connected={connected}
						title="Facebook page"
						action={{
							content: buttonText,
							onAction: handleConnect
						}}
						details={details}
						termsOfService={terms}
						avatarUrl={`https://graph.facebook.com/${page_id}/picture??type=large`}
					/>
					<Card title={'Display settings'}>
						<Card.Section>
							<FormLayout>
								<Checkbox
									label={'Active'}
									checked={active}
									onChange={v => handleChange('active', v)}
								/>
								{/*<Checkbox*/}
								{/*	label={'Automatically expand'}*/}
								{/*	checked={auto_expand}*/}
								{/*	onChange={v => handleChange('auto_expand', v)}*/}
								{/*	helpText={'Expand the Chat Plugin as soon as your page loads on a desktop computer.'}*/}
								{/*/>*/}
								{/*<TextField*/}
								{/*	label={'Greeting message'}*/}
								{/*	value={logged_in_greeting}*/}
								{/*	onChange={v => handleChange('logged_in_greeting', v)}*/}
								{/*	maxLength={80}*/}
								{/*	showCharacterCount*/}
								{/*/>*/}
								{/*<FormLayout.Group>*/}
								{/*	<Select*/}
								{/*		label={'Language'}*/}
								{/*		value={language}*/}
								{/*		onChange={v => handleChange('language', v)}*/}
								{/*		options={languageOptions}*/}
								{/*	/>*/}
								{/*	<div />*/}
								{/*</FormLayout.Group>*/}
								{/*<FormLayout.Group>*/}
								{/*	<Select*/}
								{/*		label={'Position'}*/}
								{/*		value={position}*/}
								{/*		onChange={v => handleChange('position', v)}*/}
								{/*		options={[*/}
								{/*			{label: "Left", value: 'left'},*/}
								{/*			{label: "Right", value: 'right'},*/}
								{/*		]}*/}
								{/*	/>*/}
								{/*	<div />*/}
								{/*</FormLayout.Group>*/}
								{/*<ColorPicker*/}
								{/*	label={'Theme color'}*/}
								{/*	value={theme_color}*/}
								{/*	onChange={v => handleChange('theme_color', v)}*/}
								{/*/>*/}
							</FormLayout>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
			<PageActions
				primaryAction={{
					content: 'Save',
					loading: saving,
					onAction: handleSave

				}}
			/>
			<DiscardModal
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink='/boost-conversion'
			/>
		</Page>
	) : <Skeleton/>
}

export default Messenger
