import API from "@helpers/api"
import Page from "@modules/page"
import { Skeleton } from "@modules/skeleton"
import { CancelToken } from "@plugins/axios"
import { showToast } from "@plugins/toast"
import { navigate } from '@reach/router'
import {
  Badge,
  Button,
  Card,
  Checkbox,
  ChoiceList,
  ContextualSaveBar,
  EmptyState,
  FormLayout,
  InlineError,
  Layout,
  Modal,
  Select,
  Spinner
} from '@shopify/polaris';
import { DeleteMinor, LanguageMinor, PlusMinor } from "@shopify/polaris-icons"
import React, { useCallback, useEffect, useState } from 'react'
import { ContextProvider, useStore } from "./container"
import PreviewStock from "./preview"
import VersionWarning from "@components/versionWarning"
import { isEqual, omit } from "lodash"
import Table from "@modules/table"
import DiscardModal from "../../components/discardModal"
import PlatformModal from "../../components/platformModal"
import axios from "@plugins/axios";
import { showConfirm } from "@plugins/confirm"
const BackInStockSettings = () => {
  return (
    <ContextProvider>
      <BackInStockSettingsView />
    </ContextProvider>
  )
}

const BackInStockSettingsView = () => {
  const [error, setError] = useState(false)
  const [ready, setReady] = useState(false)
  const [saving, setSaving] = useState(false)
  const { state, dispatch } = useStore()
  const source = CancelToken.source()
  const { show_name, show_phone_number, show_note, active, show_signup_newsletter, save_to, shop, platforms } = state
  const [activeModal, setActiveModal] = useState(false);
  const [prevState, setPrevState] = useState(state)
  const [activeModalIntegration, setActiveModalIntegration] = useState(false);
  const isEqualState = isEqual(
    omit(state, ['updatedAt', 'createdAt', 'deleted', "__v", "_id", 'shop']),
    omit(prevState, ['updatedAt', 'createdAt', 'deleted', "__v", "_id", 'shop'])
  )

  const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

  useEffect(() => {
    const handleReloadPage = (e) => {
      e.preventDefault()
      e.returnValue = ""
    }
    if (isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
    return () => window.removeEventListener("beforeunload", handleReloadPage)
  }, [isEqualState])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API.getList("back-in-stock/settings", {}, source.token)
        if (res?.ok) {
          dispatch({ type: 'setData', payload: res?.payload })
          if (res.payload) {
            setPrevState(res.payload)
          }
          setReady(true)
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData()
    return () => source.cancel()
  }, [])
  const handleSave = async () => {
    setError(!save_to.length && !platforms.length)
    if (!save_to.length && !platforms.length) {
      return showToast({
        message: 'Please choose at least 1 option in the "Data collection"',
        error: true
      })
    }
    setSaving(true)
    API
      .updateByShop('back-in-stock/settings', state)
      .then((res) => {
        if (res.ok) {
          setPrevState({
            ...prevState,
            ...res.payload,
          })
          return showToast({
            message: 'Updated successfully'
          })
        }
      })
      .finally(() => setSaving(false))
  }
  const handleChange = (key, value) => {
    dispatch({ type: 'setData', payload: { [key]: value } })
  }

  const handleDelete = (data) => {
    showConfirm({
      title: `Delete this list?`,
      message: 'This can’t be undone.',
      danger: true,
      confirm: 'Delete'
    }).then(res => {
      if (res) {
        const filterPlatforms = platforms.filter(item => item._id !== data._id)
        setError(!save_to.length && !filterPlatforms.length)
        if (!save_to.length && !filterPlatforms.length) {
          return showToast({
            message: 'Please choose at least 1 option in the "Data collection"',
            error: true
          })
        }
        axios.delete(`/api/plugin/integration/lists/${data._id}`)
        const saveData = { ...state, platforms: filterPlatforms }
        API.updateByShop('back-in-stock/settings', {
          ...saveData
        }).then((res) => {
          if (res.ok) {
            dispatch({
              type: 'setData', payload: saveData
            })
            setPrevState({
              ...prevState,
              ...res.payload,
            })
            return showToast({
              message: 'Updated successfully'
            })
          }
        }).finally(() => setSaving(false))
      }
    })
  }
  return ready ? (
    <Page
      title="Back in stock settings"
      subtitle="Allow your customers to sign up to receive email notifications when your product is back in stock."
      breadcrumbs={[{ content: 'Boost conversion', onAction: () => isEqualState ? navigate('/boost-conversion') : handleModalChange() }]}
      fullWidth
    >
      {!isEqualState && <ContextualSaveBar
        fullWidth
        saveAction={{
          onAction: handleSave,
          loading: saving,
          disabled: false
        }}
        discardAction={{ onAction: () => handleModalChange() }}
      />}
      <Layout>
        <Layout.Section secondary>
          <Card sectioned title={'Visibility'}>
            <FormLayout>
              <Checkbox
                label={"Active"}
                checked={active}
                onChange={v => handleChange("active", v)}
              />
            </FormLayout>
          </Card>
          <Card sectioned title={'Form Settings'}>
            <FormLayout>
              <Checkbox
                label={"Show name field"}
                checked={show_name}
                onChange={v => handleChange("show_name", v)}
              />
              <Checkbox
                label={"Show phone number field"}
                checked={show_phone_number}
                onChange={v => handleChange("show_phone_number", v)}
              />
              <Checkbox
                label={"Show note field"}
                checked={show_note}
                onChange={v => handleChange("show_note", v)}
              />
              <Checkbox
                label={"Show field sign up newsletter"}
                checked={show_signup_newsletter}
                onChange={v => handleChange("show_signup_newsletter", v)}
              />
            </FormLayout>
          </Card>
          <Card title={'Form Texts'} sectioned>
            <FormLayout>
              <div style={{ fontSize: "1.5rem", lineHeight: '28px' }}>
                <p>Your Form includes a heading, description, input's placeholders, button's text...</p>
                <p>Go to the <b>Translations</b> section in order to edit your Form's texts.</p>
              </div>
              <Button
                icon={LanguageMinor}
                outline
                onClick={() => navigate("/theme/translations#back-in-stock")}
              >
                Edit texts
              </Button>
            </FormLayout>
          </Card>
          <Card title={'Data collection'} sectioned>
            <FormLayout>
              <ChoiceList
                title={'Store subscribers at:'}
                selected={save_to}
                onChange={v => handleChange('save_to', v)}
                allowMultiple
                choices={[
                  // { label: "Shopify customers list", value: "shopify_customers" },
                  { label: "FoxKit subscriptions list", value: "foxkit_subscribers" },
                ]}
              />
              {platforms?.length !== 0 && <Table>
                <thead>
                  <tr>
                    <th width={'30%'}>Platform</th>
                    <th width={'30%'}>Status</th>
                    <th>List</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {platforms.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <p>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</p>
                        </td>
                        <td>
                          <Badge status={item.active ? "success" : "critical"}>{item.active ? "Active" : "Not Active"}</Badge>
                        </td>
                        <td>
                          <p>{item.list_name}</p>
                        </td>
                        <td>
                          <Button onClick={() => handleDelete(item)} icon={DeleteMinor} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>}
              <Button onClick={() => setActiveModalIntegration(prev => !prev)} icon={PlusMinor}>Add another integration</Button>
              {error && <InlineError fieldID={'fdf'} message={'Choose at least one option to for Data Collection'} />}
            </FormLayout>
          </Card>
          {activeModalIntegration && <PlatformModal setActiveModalIntegration={setActiveModalIntegration} setSaving={setSaving} saving={saving} useStore={useStore} plugin="bis" link_api="back-in-stock/settings"/>}
        </Layout.Section>
        <Layout.Section>
          <Card title={"Preview"}>
            <Card.Section>
              <PreviewStock />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <DiscardModal
        activeModal={activeModal}
        handleModalChange={handleModalChange}
        backLink="/boost-conversion"
      />
    </Page>
  ) : <Skeleton />
}

export default BackInStockSettings
