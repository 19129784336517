import {navigate, Link} from "@reach/router"
import Page from '@modules/page'
import {Layout, Card, Icon, Caption, Stack, TextStyle, Button} from "@shopify/polaris"
import {ProfileMajor,  ManagedStoreMajor} from '@shopify/polaris-icons'

const UpSell = () => {
	return (
		<Page
			title="Settings"
			breadcrumbs={[{content: 'Dashboard', onAction: () => navigate('/')}]}
		>
			<Layout>
				<Layout.Section>
					<Card>
						<Card.Section>
              <Link to={'/settings/account'}>
                <Stack alignment={'center'}>
                  <Stack.Item>
                    <Icon color="base" source={ProfileMajor} />
                  </Stack.Item>
                  <Stack.Item fill>
                      <TextStyle variation="strong">Manage account
                        <Caption>Manage your account information.</Caption>
                      </TextStyle>
                  </Stack.Item>
                </Stack>
              </Link>
						</Card.Section>
						<Card.Section>
              <Link to={'/settings/stores'}>
                <Stack alignment={'center'}>
                  <Stack.Item>
                    <Icon color="base" source={ManagedStoreMajor} />
                  </Stack.Item>
                  <Stack.Item fill>
                      <TextStyle variation="strong">
                        Manage stores
                        <Caption>Manage stores connected to your account.</Caption>
                      </TextStyle>
                  </Stack.Item>
                </Stack>
              </Link>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	)
}

export default UpSell
