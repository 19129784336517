import { Banner, FormLayout } from "@shopify/polaris"
import React from "react"
import { Link } from "@reach/router"

const VersionWarning = ({ version, feature = 'This feature' }) => {
  return (
    <div className="page-banner" style={{ paddingBottom: '2rem' }}>
      <Banner
        title={`${feature} is only available on Minimog ${version} and above`}
        status="warning"
      >
        <FormLayout>
          <p>
            If you're working on an older version of Minimog, go to
            <Link to="/theme/manage">
              <span style={{ textDecoration: "underline", margin: '0 4px' }}>
                Themes Manager
              </span>
            </Link>
            to update your theme to use this feature.
          </p>
        </FormLayout>
      </Banner>
    </div>
  )
}
export default VersionWarning
