import { useAuthStore } from '@container/Auth';
import { SHOP_SELECTED_KEY } from '@helpers/constants';
import { showToast } from '@plugins/toast';
import { firebaseSignInWithPopup, signOutService } from '@services/user';

export default function useSignIn(params) {
  const { updateShopData } = useAuthStore();

  const handleSignInWithFirebase = async (method) => {
    await firebaseSignInWithPopup(method);
    window.location.href = '/';
  };

  const handleLogout = async () => {
    try {
      await signOutService();
      updateShopData({ currentUser: null });
      localStorage.removeItem(SHOP_SELECTED_KEY);
      window.location.reload();
    } catch (error) {
      showToast({
        message: error?.message,
        error: true,
      });
    }
  };

  return {
    handleLogout,
    handleSignInWithFirebase,
  };
}
