import { Card, Checkbox } from "@shopify/polaris"
import { useStore } from "../container"

const StatusCard = () => {
	const {state, dispatch} = useStore()
	const {active} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card sectioned title={'Status'}>
			<Checkbox
				label={'Active'}
				checked={active}
				onChange={v => handleChange('active', v)}
			>
			</Checkbox>
		</Card>
	)
}

export default StatusCard
