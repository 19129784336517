import { useEffect, useState, useCallback } from 'react'
import Page from '@modules/page'
import { Layout, Card, ResourceItem, TextStyle, Badge, Button, ButtonGroup } from "@shopify/polaris"
import DataList from "@modules/dataList"
import { navigate } from "@reach/router"
import { SkeletonList } from "@modules/skeleton"
import { CancelToken } from "@plugins/axios"
import { getURLParams, updateParam } from "@helpers/utils"
import API from "@helpers/api"
import { showConfirm } from "@plugins/confirm"
import { showToast } from "@plugins/toast"
import { ExportMinor } from '@shopify/polaris-icons';
import ExportCustomersList from "./components/modal"

const CustomersList = () => {
	const [update, setUpdate] = useState(true)
	const [state, setState] = useState({ ready: false, pageInfo: {}, isSearched: false })
	const [items, setItems] = useState([])
	const [sourceFrom, setSourceFrom] = useState('')
	const [selectedItems, setSelectedItems] = useState([])
	const source = CancelToken.source()
	const [active, setActive] = useState(false)

	useEffect(() => {
		const params = getURLParams()
		if (params?.source) {
			setSourceFrom(params?.source)
		}
		fetchData(params)
		return () => source.cancel()
	}, [update])


	const toggleUpdate = useCallback(() => {
		setUpdate((update) => !update)
		setSelectedItems([])
	}, [])
	const fetchData = async ({ searchQuery, page = 1, ...filters }) => {
		const params = getURLParams()
		const newParams = {
			...params,
			page,
			query: searchQuery,
			...filters
		}
		let result
		try {
			result = await API.getList('customer', newParams, source.token)
			if (result) {
				const { payload } = result
				setState({ ...state, ...result, ready: true, isSearched: true })
				setItems(payload.map(i => {
					return {
						id: i._id,
						...i
					}
				}))
			}
		} catch (error) {
			console.log(error)
		}
	}
	const handleSearch = (query) => fetchData( {query} )

	const handleBulkDelete = () => {
		showConfirm({
			title: `Delete ${selectedItems.length} subscriber(s)?`,
			message: 'Are you sure? This can\'t be undone.',
			confirm: 'Delete',
			cancel: 'Cancel',
			danger: true
		}).then(res => {
			if (res) {
				API.bulkDelete('customer', selectedItems).then((res) => {
					console.log(res)
					if (res.ok) {
						showToast({
							message: 'Customer(s) deleted'
						})
						toggleUpdate()
					} else {
						showToast({
							message: 'Cannot delete, please try again',
							error: true
						})
					}
				})
			}
		})
		return false
	}

	const handleBulkUpdate = (status = false) => {
		API.bulkUpdate('subscribers', {
			ids: selectedItems,
			active: status
		}).then((res) => {
			console.log(res)
			if (res.ok) {
				showToast({
					message: 'Customer(s) updated!'
				})
				toggleUpdate()
			} else {
				showToast({
					message: 'Cannot update, please try again',
					error: true
				})
			}
		})
		return false
	}

	const resourceName = {
		singular: 'subscriber',
		plural: 'subscribers'
	}

	const promotedBulkActions = [
		{
			content: 'Set as active',
			onAction: () => {
				showConfirm({
					title: `Set ${selectedItems.length} subscriber(s) as active?`,
					message: 'Setting subscriber(s) as active will make them available to your store.',
					confirm: 'Set as active',
					cancel: 'Cancel'
				}).then(res => res && handleBulkUpdate(true))
			}
		},
		{
			content: 'Set as inactive',
			onAction: () => {
				showConfirm({
					title: `Set ${selectedItems.length} subscriber(s) as inactive?`,
					message: 'Setting subscriber(s) as inactive will hide them from your store.',
					confirm: 'Set as inactive',
					cancel: 'Cancel'
				}).then(res => res && handleBulkUpdate(false))
			}
		}
	]

	const bulkActions = [
		{
			content: 'Delete subscriber(s)',
			onAction: handleBulkDelete
		}
	]
	const headers = [
		{
			name: 'Accept marketing',
			width: '25%'
		},
		{
			name: 'Source',
			width: '25%'
		}
	]
	const handleFilter = (source) => {
		setSourceFrom(source)
		updateParam('source', source)
		updateParam('page', 1)
		fetchData({ source: source || undefined })
	}

	const { ready, pageInfo } = state
	const handleModalChange = useCallback(() => { setActive(!active) }, [active])
	return ready ? (
		<Page
			title="Subscribers"
			subtitle={'Manage the customers who subscribed to your newsletter.'}
			secondaryActions={[{
				content: "Export", icon: ExportMinor, onAction: () => {
					handleModalChange();
				}
			}]}
		>
			<Layout>
				<Layout.Section>
					<Card sectioned>
						<div className={'flex items-center'} style={{ paddingBottom: '1.5rem' }}>
							<ButtonGroup segmented>
								<Button size={'slim'} primary={sourceFrom === ''} onClick={() => handleFilter('')}>All</Button>
								<Button size={'slim'} primary={sourceFrom === 'popup'} onClick={() => handleFilter('popup')}>Popup</Button>
								<Button size={'slim'} primary={sourceFrom === 'luckywheel'} onClick={() => handleFilter('luckywheel')}>Lucky wheel</Button>
								<Button size={'slim'} primary={sourceFrom === 'bis'} onClick={() => handleFilter('bis')}>Back in stock</Button>
							</ButtonGroup>
						</div>
						<DataList
							fetchData={fetchData}
							resourceName={resourceName}
							items={items}
							renderItem={renderItem}
							selectedItems={selectedItems}
							onSelectionChange={setSelectedItems}
							promotedBulkActions={promotedBulkActions}
							bulkActions={bulkActions}
							headers={headers}
							handleSearch={handleSearch}
							pageInfo={pageInfo}
						/>
					</Card>
				</Layout.Section>
			</Layout>
			{active && <ExportCustomersList active={active} handleModalChange={handleModalChange} sourceFrom={sourceFrom} />}
		</Page>
	) : <SkeletonList />
}

function renderItem(item) {
	const { _id, firstName, lastName, source, email } = item
	let name = [firstName, lastName]
	name = name.join(' ')

	const sourceMap = {
		popup: 'Popup',
		luckywheel: 'Lucky Wheel',
		bis: 'Back in stock'
	}
	return (
		<ResourceItem
			id={_id}
			accessibilityLabel={`View details for ${name}`}
			persistActions
			onClick={() => navigate(`/manage/leads/${_id}`)}
		>
			<div className="resource-item__wrapper">
				<div className="title">
					<TextStyle variation="strong">{firstName ? name : email}</TextStyle>
				</div>
				<div className="column" style={{ width: '25%' }}>
					<Badge status={'success'}>Subscribed</Badge>
				</div>
				<div className="column" style={{ width: '25%' }}>
					{sourceMap[source]}
				</div>
			</div>
		</ResourceItem>
	)
}

export default CustomersList
