import {createContext, useContext, useReducer} from 'react'

export const initialState = {
	active: false,
	message: 'These products are limited, checkout within {{timer}}',
	message_out: 'You\'re out of time! Checkout now to avoid losing your order!',
	repeat: true,
	show_on_drawer: true,
	duration: 5, // minutes
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
