import { useEffect, useState } from "react";
import axios from "@plugins/axios"
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Pagination} from "swiper";
import 'swiper/swiper-bundle.min.css';
import styled from 'styled-components'
import { useAuthStore } from "@container/Auth";
import {CancelMajor} from "@shopify/polaris-icons";
import {Button} from "@shopify/polaris";
import { getCookie, setCookie } from "@shared/utils/cookie";

const BannerCard = styled.div`
  --swiper-theme-color: var(--p-action-primary);
  overflow: hidden;
  position: relative;
  .BannerCard-skeleton {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: calc(100%/(0 + 783/375));
      background-color: #f5f5f5;
    }
  }
  > .Polaris-Button--iconOnly {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 11;
  }
`

export default function PromotionBanner() {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true)
  const [banners, setBanners] = useState([])
  const {license_active} = useAuthStore()

  const storageKey = 'FoxHome:Banners.dismissed';

  useEffect(() => {
    setVisible(getCookie(storageKey) !== 'true');
  }, []);

  useEffect(() => {
    axios.get(`/api/strapi/promotion-banner`).then(response => {
      if (response && response?.payload) setBanners(response?.payload)
    }).catch(err => console.log(err))
    .finally(() => setLoading(false))
  }, [])

  const onDismiss = () => {
    setVisible(false);
    setCookie(storageKey, 'true', 30);
  };

  const className = ['Polaris-Card']
  if (banners.length === 0 && !loading) className.push('hidden')
  return visible ? <BannerCard className={className.join(' ')}>
    <Button onClick={onDismiss} icon={CancelMajor} plain />
    {loading && <div className="BannerCard-skeleton" />}
    {!loading && banners.length && (
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        pagination={{ clickable: true }}
        loop={true}
      >
        {banners.map((slide, index) => {
          let shouldShow = true

          if (slide.minimogUserOnly && !license_active) {
            shouldShow = false
          }

          return shouldShow ? (
            <SwiperSlide key={index}>
              <a href={slide.link} target={"_blank"} style={{display: 'block'}}>
                <img src={slide.banner} alt={slide.title} />
              </a>
            </SwiperSlide>
          ) : ''
        })}
      </Swiper>
    )}
  </BannerCard> : ''
}