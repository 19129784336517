import axios from '@plugins/axios';

const SHOP = '/api/shop';
const PLUGIN_SHOP = 'api/plugin/shop';

const SHOP_GETS = SHOP;
const SHOP_GET_BY_ID = (id) => `${SHOP}/${id}`;
const SHOP_CREATE = SHOP;
const SHOP_UPDATE = (id) => `${SHOP}/${id}`;
const SHOP_UPDATE_STATUS = (id) => `${SHOP}/${id}/status`;
const SHOP_DELETE = (id) => `${SHOP}/${id}`;
const SHOP_SETTING_UPDATE = PLUGIN_SHOP;
const FOXHOME_AUTH = `/auth/foxhome`;

export const getAllShopService = async ({ status, query, page, limit }) => {
  const response = await axios.get(SHOP_GETS, {
    params: { status, query, page, limit },
  });
  return response?.payload;
};

export const getShopDataById = async (id, { query, page, limit }) => {
  const response = await axios.get(SHOP_GET_BY_ID(id), {
    params: { query, page, limit },
  });
  return response?.payload;
};

export const createNewShopService = async (payload) => {
  const response = await axios.post(SHOP_CREATE, {
    ...payload,
  });
  return response?.payload;
};

export const updateShopService = async (shopId, payload) => {
  const response = await axios.put(SHOP_UPDATE(shopId), {
    ...payload,
  });
  return response?.payload;
};

export const deleteShopService = async (id) => {
  const response = await axios.delete(SHOP_DELETE(id));
  return response?.payload;
};

export const updateShopStatusService = async (id, newStatus, accessToken) => {
  const response = await axios.put(SHOP_UPDATE_STATUS(id), {
    status: newStatus,
    accessToken,
  });
  return response?.payload;
};

export const updateShopDataService = async (data) => {
  const response = await axios.put(SHOP_SETTING_UPDATE, data);
  return response?.payload;
};

export const foxHomeAuthService = async (shop, userId) => {
  const response = await axios.get(FOXHOME_AUTH, {
    params: {
      shop,
      userId,
    },
  });
  return response?.payload;
};
