import {createContext, useContext, useReducer} from 'react'
import {mens_shoes, mens_bottom, mens_top, womens__bottom, womens__shoes, womens__top, kid_shoes, boy_bottom, boy_top, girl_bottom, girl_top} from "@pages/size-chart/presets"

export const initialState = {
	active: true,
	created_at: '',
	updated_at: '',
	id: '',
	title: '',
	chart_content: ``,
	preset: 'none',
	applies_to: 'all_products',
	target_products: [],
	target_collection: '',
}

export const presets = {
	clothing: ``,
	none: ``,
	mens_shoes: mens_shoes,
	mens_top: mens_top,
	mens_bottom: mens_bottom,
	womens_shoes: womens__shoes,
	womens_top: womens__top,
	womens_bottom: womens__bottom,
	girl_top: girl_top,
	girl_bottom: girl_bottom,
	boy_top: boy_top,
	boy_bottom: boy_bottom,
	kid_shoes: kid_shoes
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
