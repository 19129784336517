import styled from 'styled-components';

export const Filter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 5rem 0 2.5rem;
	@media (max-width: 960px) {
		padding: 5rem 0 6rem;
	}
  .Polaris-Select {
    width: 200px;

    @media (max-width: 1220px) {
      width: 175px;
    }

    @media (max-width: 500px) {
			width: calc(100vw - 5.2rem)
		}
  }
  .Polaris-Connected {
    width: 400px;

    @media (max-width: 1220px) {
      width: 300px;
    }

    @media (max-width: 1100px) {
      width: 200px;
    }

    @media (max-width: 1000px) {
      width: 175px;
    }

		@media (max-width: 960px) {
			width: calc(100vw - 50rem)
		}

		@media (max-width: 769px) {
			width: calc(100vw - 27rem)
		}

		@media (max-width: 500px) {
			width: calc(100vw - 5.2rem)
		}
  }

  .showing-result {
    position: absolute;
    right: 0;

		@media (max-width: 960px) {
			top: 9.5rem;
      left: 0 !important;
		}

		@media (max-width: 500px) {
			top: 15rem;
		}
  }
`;
export const DemoList = styled.div`
  @media (min-width: 1681px) {
    .c-grid {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  @media (min-width: 2200px) {
    .c-grid {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
`;

export const industries = [
  { value: 'all', label: 'Select industry: All' },
  { value: 'fashion', label: 'Clothing & Fashion' },
  { value: 'accessories', label: 'Jewelry & Accessories' },
  { value: 'food-drink', label: 'Food & Drink' },
  { value: 'home-garden', label: 'Home & Garden' },
  { value: 'furniture', label: 'Furniture' },
  { value: 'art-photography', label: 'Art & Photography' },
  { value: 'heath-beauty', label: 'Heath & Beauty' },
  { value: 'electronics', label: 'Electronics' },
  { value: 'sports-recreation', label: 'Sports & Recreation' },
  { value: 'toys-games', label: 'Toy & Games' },
  { value: 'other', label: 'Other' },
];
