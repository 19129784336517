import {Link, MediaCard, TextContainer, ButtonGroup, Button, Heading} from "@shopify/polaris"
import styled from 'styled-components'

const CardWrapper = styled.div`
	.Polaris-MediaCard__ActionContainer {
		display: none;
	}
`

export default function FoxifyCard({showText = false}) {
	return (
		<CardWrapper className={'Polaris-Card'}>
			{showText ? (
				<div className={'Polaris-MediaCard Polaris-MediaCard--portrait'}>
					<div className={'Polaris-MediaCard__MediaContainer Polaris-MediaCard--portrait'}>
						<a href={'https://docs.foxify.io/about-foxify/how-to-join-foxify-bfcm-giveaway?utm_source=FoxKit&utm_medium=in-app+modal&utm_campaign=FoxifyReleaseBFCM2022'} target={'_blank'} rel="noreferrer">
							<img src={'https://cdn.shopify.com/s/files/1/0594/4190/5837/files/foxify-release_1.jpg?v=1668103423'} />
						</a>
					</div>
					<div className={'Polaris-MediaCard__InfoContainer Polaris-MediaCard--portrait'}>
						<div className="Polaris-Card__Section">
							<TextContainer>
								<Heading>Biggest BFCM giveaway from FoxEcom</Heading>
								<p>Howdy!</p>
								<p>We’re calling you, our beloved FoxKit user, to join the Biggest BFCM giveaway we could imagine :)</p>
								<p>To celebrate the <strong>release of Foxify</strong> - a conversion-focused storefront builder for growing SMBs, our newest Shopify app, we’d like to invite you to use <strong><Link url={'https://apps.shopify.com/foxify-builder'}>Foxify</Link></strong> <strong>free for 90 days</strong>.</p>
								<p>But it doesn’t stop there.</p>
								<p>We welcome you to sign up as an active early bird. As an early bird, you’ll get to use <strong><Link url={'https://apps.shopify.com/foxify-builder'}>Foxify</Link></strong> free for 180 days!</p>
								<p>After that, you will receive a <strong>30% discount</strong> for the next 3 months.</p>
								<p>This offer values up to <strong>$700</strong>, don’t miss this.</p>
								<div style={{marginTop: '2.5rem'}}>
									<ButtonGroup>
										<Button size={'large'} primary url={'https://forms.gle/PbJEb22GZ7fpyi5w7'} external>Yes, I’m in!</Button>
										<Button plain url={'https://docs.foxify.io/about-foxify/how-to-join-foxify-bfcm-giveaway?utm_source=FoxKit&utm_medium=in-app+modal&utm_campaign=FoxifyReleaseBFCM2022'} external>Learn more</Button>
									</ButtonGroup>
								</div>
							</TextContainer>
						</div>
					</div>
				</div>
			) : (
				<div className={'Polaris-MediaCard Polaris-MediaCard--portrait'}>
					<img
						width="100%"
						height="100%"
						style={{
							objectFit: 'cover',
							objectPosition: 'center',
							cursor: 'pointer'
						}}
						onClick={() => window.open('https://forms.gle/PbJEb22GZ7fpyi5w7')}
						src="https://cdn.shopify.com/s/files/1/0594/4190/5837/files/foxify-release_1.jpg?v=1668103423"
					/>
				</div>
			)}
		</CardWrapper>
	)
}
