import React from 'react'
import {Router} from "@reach/router"
import PrePurchasesList from "@pages/pre-purchase"
import CreatePrePurchase from "@pages/pre-purchase/create"
import EditPrePurchase from "@pages/pre-purchase/edit"

export default function PrePurchasePages() {
	return (
		<Router>
			<PrePurchasesList path="/" />
			<CreatePrePurchase path="/new" />
			<EditPrePurchase path="/:pageID" />
		</Router>
	)
}
