import React from 'react'
import {Router} from "@reach/router"
import QtysList from "@pages/quantity-discounts"
import CreateQty from "@pages/quantity-discounts/create"
import EditQty from "@pages/quantity-discounts/edit"

export default function QtyDiscountPages() {
	return (
		<Router>
			<QtysList path="/" />
			<CreateQty path="/new" />
			<EditQty path="/:pageID" />
		</Router>
	)
}
