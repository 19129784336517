import React from 'react'
import {Router} from "@reach/router"
import CountDownsList from "@pages/countdown-timer"
import CreateCountDown from "@pages/countdown-timer/create"
import EditCountDown from "@pages/countdown-timer/edit"

export default function CountDownPages() {
	return (
		<Router>
			<CountDownsList path="/" />
			<CreateCountDown path="/new" />
			<EditCountDown path="/:pageID" />
		</Router>
	)
}
