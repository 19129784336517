import {Card, Checkbox, FormLayout} from "@shopify/polaris"
import {useStore} from "@pages/pre-order/container"
import DatePicker from "@components/datePicker"

const StatusCard = ({error = []}) => {
	const {state, dispatch} = useStore()
	const {active, start_time, end_time, set_end_time} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card sectioned title={'Status'}>
			<FormLayout>
				<Checkbox
					label={'Active'}
					checked={active}
					onChange={v => handleChange('active', v)}
				/>
				<DatePicker
					selected={start_time ? new Date(start_time) : ''}
					label={'Start at'}
					onChange={v => handleChange('start_time', v?.toISOString())}
					dateFormat="yyyy/MM/dd h:mm aa"
					showTimeSelect
					minDate={new Date()}
					error={error.includes('start_time')}
					// filterTime={filterPassedTime}
				/>
				<Checkbox
					label={'Set end time'}
					checked={set_end_time}
					onChange={v => handleChange('set_end_time', v)}
				/>
				{set_end_time && <DatePicker
					selected={end_time ? new Date(end_time) : ''}
					label={'End at'}
					onChange={v => handleChange('end_time', v?.toISOString())}
					dateFormat="yyyy/MM/dd h:mm aa"
					showTimeSelect
					minDate={new Date(start_time)}
					error={error.includes('end_time')}
					// filterTime={filterPassedTime}
				/>}
			</FormLayout>
		</Card>
	)
}

export default StatusCard
