import FoxifyCard from "@components/foxifyCard";
import StatusBadge from "@components/statusBadge"
import API from "@helpers/api"
import { getURLParams, updateParam } from "@helpers/utils"
import DataList from "@modules/dataList"
import Page from '@modules/page'
import { SkeletonList } from "@modules/skeleton"
import { CancelToken } from "@plugins/axios"
import { showConfirm } from "@plugins/confirm"
import { showToast } from "@plugins/toast"
import { navigate } from "@reach/router"
import { Card, Layout, ResourceItem, TextStyle } from "@shopify/polaris"
import { useCallback, useEffect, useState } from 'react'

const ProductRecommendationList = () => {
  const [update, setUpdate] = useState(true)
  const [state, setState] = useState({ ready: false, pageInfo: {}, isSearched: false })
  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const source = CancelToken.source()

  useEffect(() => {
    const params = getURLParams()
    fetchData(params)
    return () => source.cancel()
  }, [update])

  const toggleUpdate = useCallback(() => {
    setUpdate((update) => !update)
    setSelectedItems([])
  }, [])

  const fetchData = async ({ searchQuery, page = 1, ...filters}) => {
    const params = getURLParams()
    const newParams = {
      ...params,
			query: searchQuery,
			page: page,
      ...filters
		}
    let result
    try {
      result = await API.getList('product-recommendation', newParams, source.token)
      if (result) {
        setState({ ...state, ...result, ready: true, isSearched: true })
        setItems(result.payload.map(item => {
          return {
            id: item._id,
            ...item
          }
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = (query) => fetchData({query})

  const handleBulkDelete = () => {
    showConfirm({
      title: `Delete ${selectedItems.length} recommendation(s)?`,
      message: 'Are you sure? This can\'t be undone.',
      confirm: 'Delete',
      cancel: 'Cancel',
      danger: true
    }).then(res => {
      if (res) {
        API.bulkDelete('product-recommendation', selectedItems).then((res) => {
          console.log(res)
          if (res.ok) {
            showToast({
              message: 'Recommendation(s) deleted'
            })
            toggleUpdate()
          } else {
            showToast({
              message: 'Cannot delete, please try again',
              error: true
            })
          }
        })
      }
    })
    return false
  }

  const handleBulkUpdate = (status = false) => {
    API.bulkUpdate('product-recommendation', {
      ids: selectedItems,
      active: status
    }).then((res) => {
      console.log(res)
      if (res.ok) {
        showToast({
          message: 'Recommendation(s) updated!'
        })
        toggleUpdate()
      } else {
        showToast({
          message: 'Cannot update, please try again',
          error: true
        })
      }
    })
    return false
  }

  const resourceName = {
    singular: 'recommendation',
    plural: 'recommendations'
  }

  const promotedBulkActions = [
    {
      content: 'Set as active',
      onAction: () => {
        showConfirm({
          title: `Set ${selectedItems.length} recommendation(s) as active?`,
          message: 'Setting recommendation(s) as active will make them available to your store.',
          confirm: 'Set as active',
          cancel: 'Cancel'
        }).then(res => res && handleBulkUpdate(true))
      }
    },
    {
      content: 'Set as inactive',
      onAction: () => {
        showConfirm({
          title: `Set ${selectedItems.length} recommendation(s) as inactive?`,
          message: 'Setting recommendation(s) as inactive will hide them from your store.',
          confirm: 'Set as inactive',
          cancel: 'Cancel'
        }).then(res => res && handleBulkUpdate(false))
      }
    }
  ]

  const bulkActions = [
    {
      content: 'Delete recommendation(s)',
      onAction: handleBulkDelete
    }
  ]

  const headers = [
    {
      name: 'Status',
      width: '20%',
    },
    {
      name: 'Applies to',
      width: '25%',
    }
  ]

  const { ready, pageInfo } = state
  return ready ? (
    <Page
      title="Related products"
      subtitle={'Displaying related/recommended products to customers makes it easier for them to discover new products, and can help to increase online store sales.'}
      primaryAction={{
        content: "Create recommendation",
        onAction: () => navigate("/product-recommendation/new")
      }}
      breadcrumbs={[{content: 'Upsell', onAction: () => navigate('/upsells')}]}
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <DataList
              fetchData={fetchData}
              resourceName={resourceName}
              items={items}
              renderItem={renderItem}
              selectedItems={selectedItems}
              onSelectionChange={setSelectedItems}
              promotedBulkActions={promotedBulkActions}
              bulkActions={bulkActions}
              headers={headers}
              handleSearch={handleSearch}
              pageInfo={pageInfo}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  ) : <SkeletonList />
}

function renderItem(item) {
  const { id, title, active, applies_to } = item
  const applies_to_map = {
    all_products: "All products",
    specific_products: "Specific products",
    specific_collection: 'Specific collection'
  }
  return (
    <ResourceItem
      id={id}
      accessibilityLabel={`View details for ${title}`}
      persistActions
      onClick={() => navigate(`/product-recommendation/${id}`)}
    >
      <div className="resource-item__wrapper">
        <div className="title">
          <TextStyle variation="strong">{title}</TextStyle>
        </div>
        <div className="column" style={{ width: '20%' }}>
          <StatusBadge status={active} />
        </div>
        <div className="column" style={{ width: '25%' }}>
          {applies_to_map[applies_to]}
        </div>
      </div>
    </ResourceItem>
  )
}

export default ProductRecommendationList
