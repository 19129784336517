import {createContext, useContext, useReducer} from 'react'
export const initialState = {
	shop: "",
	shopify_id: "",
	acceptsMarketing: true,
	email: "",
	firstName: "",
	lastName: "",
	locale: "",
	note: "",
	phone: "",
	tags: "",
	birthday: '',
	source: ""
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
