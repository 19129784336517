import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStore } from "./container"
import { useAuthStore } from "@container/Auth"
import axios from "@plugins/axios"
import { Skeleton } from "@modules/skeleton"
import { defaultLocaleData } from '../translation/container'

const Preview = styled.div`
  position: relative;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  .stock-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.3);
    width: 100%;
    height: 100%;
    position: absolute;

    .bis-popup {
      width: 700px;
      max-width: 90%;
      display: flex;
      background: white;
      border-radius: 4px;
      padding: 16px;

      & > div {
        width: 50%;
        padding: 16px;
        .product-img {
          position: relative;
          img {
            width: 100%;
          }
          span {
            display: flex;
            align-items: center;
            text-align: center;
            width: 64px;
            height: 64px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            background: #b1b1b1;
            color: #fff;
            border-radius: 50%;
            padding: 0 10px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-weight: 500;
          }
        }
        .product-info {
          h3 {
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
            margin-top: 16px;
          }
          p {
            font-size: 16px;
            margin-top: 4px;
          }
        }
        .heading {
          font-weight: 500;
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 12px;
        }
        .sub-heading {
          margin-bottom: 10px;
          color: #666666;
          font-size: 16px;
        }
        #bis-form {
          margin-top: 16px;
          input:not([type="checkbox"]), textarea {
            font-family: Arial;
            background: #ebebeb;
            margin-top: 5px;
            margin-bottom: 10px;
            width: 100%;
            border-radius: 4px;
            padding: 6px 12px;
            font-size: 15px;
            line-height: 32px;
            border: none;
          }
          label {
            display: flex;
            align-items: center;
            cursor: pointer;
            input {
              width: 18px;
              height: 18px;
              margin: 0;
            }
            span {
              margin-left: 8px;
            }
          }
          button {
            width: 100%;
            background: black;
            color: white;
            border: none;
            font-size: 16px;
            padding: 15px 32px;
            margin-top: 20px;
            border-radius: 4px;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
  }
`

const PreviewBackInStock = () => {
  const { state } = useStore()
  const { show_name, show_phone_number, show_note, show_signup_newsletter } = state
  const { primary_locale } = useAuthStore()
  const [localeData, setLocaleData] = useState(defaultLocaleData)
  const [loading, setLoading] = useState(true)
  console.log(localeData)
  useEffect(() => {
    if (primary_locale) {
      axios
        .get(`/api/shopify/locale/${primary_locale}`)
        .then(res => {
          if (res?.ok) {
            setLocaleData({ ...localeData, ...res?.payload?.data })
          }
        })
        .catch(console.error)
        .finally(() => setLoading(false))
    }
  }, [primary_locale])

  if (loading) return <Skeleton />
  const { bis_heading, bis_desc, bis_email, bis_name, bis_note, bis_phone, bis_signup, bis_submit } = localeData
  return (
    <Preview>
      <div className="stock-overlay">
        <div className="bis-popup">
          <div>
            <div className="product-img">
              <img src="https://cdn.shopify.com/s/files/1/0584/7069/5082/products/47_1.jpg" alt="Product" />
              <span>Sold Out</span>
            </div>
            <div className="product-info">
              <h3>Cotton bucket hat</h3>
              <p>S / Brown - $17.99</p>
            </div>
          </div>
          <div>
            <h3 className="heading">{bis_heading}</h3>
            <p className="sub-heading">{bis_desc}</p>
            <div id="bis-form">
              <input type="text" name="email" placeholder={bis_email} />
              {show_name ? <input type="text" placeholder={bis_name} /> : null}
              {show_phone_number ? <input type="text" placeholder={bis_phone} /> : null}
              {show_note ? <textarea rows="4" placeholder={bis_note} /> : null}
              {!show_signup_newsletter ? null :
                <label>
                  <input type="checkbox" value="true" />
                  <span>{bis_signup}</span>
                </label>
              }
              <button>{bis_submit}</button>
            </div>
          </div>
        </div>
      </div>
    </Preview>
  )
}

export default PreviewBackInStock
