import React, { useEffect, useState } from 'react';
import {TextStyle, TextContainer, DisplayText} from "@shopify/polaris";

const Quote = () => {
  const [quote, setQuote] = useState({});

  const quotesList = [
    {
      content:
        "“Excellent quality. This theme saved me a lot of time, it makes me get rid of 4 different apps on Shopify as it has lot of things integrated, it looks great, the support team is really fast and great. Can't be happier!”",
      author: 'Giacomo Pasquali',
      link: 'giacomopasquali.store',
    },
    {
      content:
        "“I tried many themes but couldn't find one that fit my store until I found Minimog. I was very happy with my purchase, It is flexible, has great design, easy to manage, It has useful tools to help grow sales.”",
      author: 'Huani Miao',
      link: 'happyjournalcraft.com',
    },
    {
      content:
        '“I love this theme, it works amazingly. Have too many features included! easy to manage and the support is great, they helped me very well. Highly recommended. Save time and money.”',
      author: 'Anita Castro',
      link: 'misitiolatino.com',
    },
  ];

  const generateRandomQuote = () => {
    let randNum = Math.floor(Math.random() * 3);
    setQuote(quotesList[randNum]);
  };

  useEffect(() => {
    generateRandomQuote();
  }, []);

  return (
    <div className="User-Layout__quote-text">
      {quote && (
        <TextContainer>
          <DisplayText>
            {quote.content}
          </DisplayText>
          <DisplayText size={'small'}><strong>{quote.author}</strong></DisplayText>
          <TextStyle>{quote.link}</TextStyle>
        </TextContainer>
      )}
    </div>
  );
};

export default Quote;
