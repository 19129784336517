import {Card, FormLayout, ChoiceList, Checkbox} from "@shopify/polaris"
import {useStore} from "@pages/bundles/container"

const LayoutCard = ({error = []}) => {
	const {state, dispatch} = useStore()
	const {position, layout, show_quantity, default_selected} = state
	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	return (
		<Card sectioned title={'Display settings'}>
			<FormLayout>
				<ChoiceList
					title={'Layout'}
					selected={layout}
					choices={[
						{label: 'Layout 1', value: 'layout-1'},
						{label: 'Layout 2', value: 'layout-2'},
					]}
					onChange={v => handleChange('layout', v[0])}
				/>
				<ChoiceList
					title={'Placement'}
					onChange={v => handleChange('position', v[0])}
					selected={position}
					choices={[
						{label: 'Inside of product information', value: 'inside'},
						{label: 'Outside of product information', value: 'outside'},
					]}
				/>
				<Checkbox
					label={'Pre-selected products'}
					onChange={v => handleChange('default_selected', v)}
					checked={default_selected}
					helpText={'Selected products by default.'}
				/>
				<Checkbox
					label={'Show quantity selector'}
					onChange={v => handleChange('show_quantity', v)}
					checked={show_quantity}
					helpText={'Allows customers change the quantity of each product.'}
				/>
			</FormLayout>
		</Card>
	)
}

export default LayoutCard
