import { ChoiceList, DatePicker, Modal, Stack } from '@shopify/polaris'
import React, { useState, useEffect } from 'react'
import { getDate, formatDate } from '@helpers/utils'
import XLSX from 'xlsx'
import { ExportSheet } from 'react-xlsx-sheet'
import axios from "@plugins/axios"
import { format, getMonth, getYear } from "date-fns"
import { CUSTOM_TIME, LAST_30_DAYS, LAST_7_DAYS, LAST_90_DAYS, LAST_YEAR, TODAY, YESTERDAY } from '../../../helpers/constants'
const ExportSubsciptionList = ({ handleModalChange }) => {
  const [selectedExportDate, setSelectedExportDate] = useState(TODAY)
  const [inputData, setInputData] = useState()
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(new Date()),
    end: new Date(new Date()),
  });
  const [{ month, year }, setDate] = useState({ month: getMonth(new Date(new Date().setMonth(new Date().getMonth() - 1))), year: getYear(new Date()) });

  const exportButton = document.querySelector(".export-button")
  useEffect(() => {
    if (inputData !== undefined) {
      exportButton.click()
      handleModalChange()
    }
  }, [inputData])

  const handleTemplateData = (data) => {
    let head = []
    let exportedData = data.map(item => {
      const { customer, title, product_image_url, variant_id, variant_title, note, shop, product_handle, createdAt } = item
      const product_url = shop + "/products/" + product_handle + "?variant_id=" + variant_id
      const importData = {
        product_title: title,
        product_url: product_url,
        product_image_url: "https:" + product_image_url,
        variant_id: variant_id,
        variant_title: variant_title,
        note: note,
        firstName: customer.firstName,
        email: customer.email,
        phone: customer.phone,
        createdAt: formatDate(createdAt, true)
      }
      head = [
        { title: "Product Title", dataIndex: "product_title" },
        { title: "Product URL", dataIndex: "product_url" },
        { title: "Product Image URL", dataIndex: "product_image_url" },
        { title: "Variant ID", dataIndex: "variant_id" },
        { title: "Variant Title", dataIndex: "variant_title" },
        { title: "Customer Note", dataIndex: "note" },
        { title: "Customer Name", dataIndex: "firstName" },
        { title: "Customer Email", dataIndex: "email" },
        { title: "Customer Phone Number", dataIndex: "phone" },
        { title: "Subscribed At", dataIndex: "createdAt" }
      ]
      return importData
    })
    return { head: head, exportedData: exportedData }
  }
  const handleExportSubsciptions = () => {
    if (selectedExportDate.length > 0) {
      let time
      if (selectedExportDate === CUSTOM_TIME) {
        time = selectedDates
      } else {
        time = getDate(selectedExportDate)
      }
      axios(`/api/plugin/back-in-stock/export?startDate=${format(time?.start, "y-MM-dd")}&endDate=${format(time?.end, "y-MM-dd")}`)
        .then(res => {
          setInputData(handleTemplateData(res.payload))
        });
    }
  }
  return (
    <div>
      <Modal
        open={true}
        onClose={handleModalChange}
        title="Export subscriptions list"
        primaryAction={{
          content: 'Export subscriptions',
          onAction: handleExportSubsciptions,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <ExportSheet
            header={inputData?.head}
            fileName={"BIS_subscriptions"}
            dataSource={inputData?.exportedData}
            xlsx={XLSX}
            extName="csv"
          >
            <button hidden className="export-button"></button>
          </ExportSheet>
          <Stack vertical>
            <Stack.Item>
              <ChoiceList
                title="Time range"
                choices={[
                  { label: 'Today', value: TODAY },
                  { label: 'Yesterday', value: YESTERDAY },
                  { label: 'Last 7 days', value: LAST_7_DAYS },
                  { label: 'Last 30 days', value: LAST_30_DAYS },
                  { label: 'Last 90 days', value: LAST_90_DAYS },
                  { label: 'Last year', value: LAST_YEAR },
                  { label: 'Custom time', value: CUSTOM_TIME },
                ]}
                selected={selectedExportDate}
                onChange={v => {
                  let getValue = v.toString()
                  setSelectedExportDate(getValue)
                  if (getValue !== CUSTOM_TIME) {
                    setSelectedDates(getDate(getValue))
                  }
                }}
              />
            </Stack.Item>
            <Stack.Item>
              {selectedExportDate === CUSTOM_TIME && <DatePicker
                month={month}
                year={year}
                onChange={setSelectedDates}
                onMonthChange={(month, year) => setDate({ month, year })}
                selected={selectedDates}
                allowRange
                multiMonth
              />}
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </div>
  )
}
export default ExportSubsciptionList
