import Page from '@modules/page'
import {navigate} from "@reach/router"
import {Layout, ContextualSaveBar} from "@shopify/polaris"
import {ContextProvider, useStore} from "./container"
import TitleCard from "./components/title"
import DiscountCard from "./components/discount"
import ProductsCard from "./components/products"
import StatusCard from "./components/status"
import {useCallback, useState} from "react"
import API from "@helpers/api"
import {showToast} from "@plugins/toast"
import {checkRequiredFields} from "@helpers/utils"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'

const CreateQtyDiscount = () => {
	return (
		<ContextProvider>
			<CreateQtyDiscountView />
		</ContextProvider>
	)
}

const CreateQtyDiscountView = () => {
	const [loading, setLoading] = useState(false)
	let [error, setError] = useState([])
	const {state} = useStore()
	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState({...state, discounts: [...state.discounts]})

	const isEqualState = isEqual(omit(state, ['updatedAt']), omit(prevState, ['updatedAt']))

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);
	const handleSave = async () => {
		error = await checkRequiredFields(['title'], state)
		if (error.length > 0) {
			setError(error)
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: 'Please fill in all the mandatory fields',
				error: true
			})
		}

		setLoading(true)
		const {target_collection, target_products, discounts} = state
		API.create('quantity-discount', {
			...state,
			target_products: target_products?.filter(Boolean).map(p => p.value),
			target_collection: target_collection.value,
		}).then(res => {
			console.log(res, 'res')
			if (res.ok) {
				setPrevState({
					...prevState, 
					...res.payload, 
					target_products: target_products, 
					target_collection: target_collection,
					discounts: [...discounts]
				})
				navigate(`/quantity-discounts/${res.payload._id}`)
				return showToast({
					message: 'Discount created'
				})
			}
		})
			.finally(() => setLoading(false))
	}
	console.log("prevState", prevState);
	console.log("state", state);
	return (
		<Page
			title="Create quantity discount"
			breadcrumbs={[{content: 'Qty Discounts', onAction: () => isEqualState ? navigate('/quantity-discounts') : handleModalChange()}]}
		>
			{!isEqualState && <ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: handleSave,
					loading: loading,
					disabled: false,
				}}
				discardAction={{
					onAction: () => handleModalChange()
				}}
			/>}
			<Layout>
				<Layout.Section>
					<TitleCard error={error} />
					<ProductsCard />
					<DiscountCard />
				</Layout.Section>
				<Layout.Section secondary>
					<StatusCard />
				</Layout.Section>
			</Layout>
			<DiscardModal 
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink='/quantity-discounts'
			/>
		</Page>
	)
}

export default CreateQtyDiscount
