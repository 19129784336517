import {createContext, useContext, useReducer} from 'react'


export const initialState = {
	active: false,
	prizes: [
		{
			name: '10% OFF',
			code: '10_OFF',
			bg_color: '#DA3F3F',
			chance: 1
		},
		{
			name: 'Free shipping',
			code: 'freeship',
			bg_color: '#E49686',
			chance: 1
		},
		{
			name: 'No luck',
			code: '',
			bg_color: '#DA3F3F',
			chance: 1
		},
		{
			name: '20% OFF',
			code: '20_OFF',
			bg_color: '#E49686',
			chance: 1
		},
		{
			name: '30% OFF',
			code: '30_OFF',
			bg_color: '#DA3F3F',
			chance: 1
		},
		{
			name: 'No luck',
			code: '',
			bg_color: '#E49686',
			chance: 0
		}
	],
	title: 'Spin to get a prize!',
	description: 'Enter your email to spin the wheel for a chance to win a coupon.',
	win_title: 'Hurray!',
	win_subtitle: 'You win a',
	win_button: 'Continue & Use discount',
	win_note: 'Remember to use your discount code when you checkout.',
	discount_label: 'Your discount code is',
	lose_label: 'Oops, there is no discount code for this!',
	button: 'Try your luck',
	agree_text: 'I agree to subscribe to the mailing list.',
	show_agree_checkbox: true,
	show_name: false,
	show_on_mobile: true,
	play_multiples: false,
	auto_apply: true,
	platforms: [],
	delay_show: 5,
	trigger: 'after_page_loaded',
	display_on: 'all_pages',
	save_to: ['foxkit_subscribers'],
	template: 'left-side',
	popup_bg: '#F1F1F1',
	field_color: '#fff',
	text_color: '#000',
	button_color: '#000',
	coupon_card_bg: '#DA3F3F',
	auto_trigger: true,
	repeat_open: 'every_day',
	teaser_title: 'Get a discount!',
	teaser_position: 'bottom_left',
	teaser_activate: true,
	teaser_when: 'after',
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
