import { FREE_MEGAMOG_DATE, THEME_NAME } from '@helpers/constants';
import { isWithinInterval } from 'date-fns';

export default function getListAvailableTheme(licenses) {
  let result = [];

  for (const license of licenses) {
    const { theme_namespace, sold_at } = license;
    result.push(theme_namespace);

    if (theme_namespace === THEME_NAME.MINIMOG && sold_at) {
      const haveMegamog = isWithinInterval(new Date(sold_at), { start: FREE_MEGAMOG_DATE.start, end: FREE_MEGAMOG_DATE.end });
      if (haveMegamog) result.push(THEME_NAME.MEGAMOG);
    }
  }

  result = [...new Set(result)];
  return result;
}
