import InputField from '@components/inputField';
import { useAuthStore } from '@container/Auth';
import { SHOP_SELECTED_KEY, SHOP_STATUS_ACTIVE, THEME_NAME } from '@helpers/constants';
import { REACT_APP_FOX_KIT_URL } from '@helpers/env';
import { showToast } from '@plugins/toast';
import { addPurchaseCodeService } from '@services/license';
import { createNewShopService, foxHomeAuthService, updateShopService, updateShopStatusService } from '@services/shop';
import { Banner, ChoiceList, DisplayText, FormLayout, Heading, InlineError, Link, Modal, TextContainer, TextStyle } from '@shopify/polaris';
import { isEqual } from 'lodash';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

const FormPurchaseCode = ({ product, value, onChange, autoFocus = false, readOnly }) => {
  return (
    <FormLayout>
      <div style={{ marginTop: 30 }}>
        <TextContainer>
          <Heading>Theme purchase code</Heading>
          <TextStyle>
            Add your purchase code to unlock all the powerful features of FoxHome.{' '}
            <Link url={'https://docs.minimog.co/getting-started/purchase-code-and-activation'} external>
              Learn more.
            </Link>
          </TextStyle>
        </TextContainer>
      </div>
      {!readOnly && (
        <ChoiceList
          title="Theme"
          choices={[
            { label: 'Minimog', value: THEME_NAME.MINIMOG },
            { label: 'Megamog', value: THEME_NAME.MEGAMOG },
          ]}
          selected={[product]}
          onChange={(v) => onChange('product', v[0])}
        />
      )}

      <InputField
        label={'Purchase code'}
        value={value}
        autoFocus={autoFocus}
        onChange={(v) => onChange('purchaseCode', v)}
        readOnly={readOnly}
        disabled={readOnly}
      />
    </FormLayout>
  );
};

const FormCustomApp = ({ value, onChange }) => {
  return (
    <FormLayout>
      <div style={{ marginTop: '3rem', paddingTop: '2rem', borderTop: '1px solid #ebebeb' }}>
        <TextContainer>
          <DisplayText size={'small'}>
            <strong>API credentials</strong>
          </DisplayText>
          <TextStyle variation={'subdued'}>
            <strong>FoxHome</strong> uses these credentials to work with your store, kindly create a custom app to get credentials.{' '}
            <Link url={'https://docs.minimog.co/foxhome/getting-started/how-to-sign-up-in-foxhome'} external>
              Learn more.
            </Link>
          </TextStyle>
        </TextContainer>
      </div>
      <InputField
        value={value?.accessToken}
        onChange={(v) => onChange('accessToken', v)}
        type={'password'}
        label={'Admin API access token'}
        autoComplete={'off'}
      />
      <InputField
        value={value?.apiSecretKey}
        onChange={(v) => onChange('apiSecretKey', v)}
        type={'password'}
        label={'API secret key'}
        autoComplete={'off'}
      />
    </FormLayout>
  );
};

const FormShopifyApp = forwardRef((props, ref) => {
  const { store, currentUserId } = props;
  return (
    <form ref={ref} action={`${REACT_APP_FOX_KIT_URL}/auth`} method="GET">
      <input type="hidden" name="shop" value={store} />
      <input type="hidden" name="userId" value={currentUserId} />
      <input type="hidden" name="source" value={'foxhome'} />
    </form>
  );
});

const FormApp = forwardRef(({ value, onChange, isEdit, currentUserId, readOnlyPurchaseCode }, ref) => {
  return (
    <>
      {!isEdit && (
        <Modal.Section>
          <ChoiceList
            title="Choose which method you want to use to connect store"
            choices={[
              { label: 'FoxKit app (Recommended)', value: 'foxkit' },
              { label: 'Custom app', value: 'foxhome' },
            ]}
            selected={[value?.appType]}
            onChange={(v) => onChange('appType', v?.[0])}
          />
        </Modal.Section>
      )}
      <Modal.Section subdued>
        {!isEdit && value?.appType === 'foxkit' && (
          <div style={{ marginBottom: '1.6rem' }}>
            <p style={{ marginBottom: '1.6rem' }}>
              This method requires the{' '}
              <Link url={'https://foxecom.link/foxkit'} external>
                FoxKit app
              </Link>
              , which should be installed on your store.
            </p>
            <Banner status={'warning'}>
              <p>By using this method, you agree that you authorize FoxHome to interact with your store through FoxKit permissions.</p>
            </Banner>
          </div>
        )}
        <InputField
          value={value?.store}
          onChange={(v) => onChange('store', v)}
          placeholder="your-store-domain.myshopify.com"
          label={'Store domain'}
          disabled={isEdit}
          autoFocus={isEdit}
          helpText={isEdit ? 'You cannot edit the store domain, please add a new one instead.' : 'Enter your store domain'}
        />
        {value?.appType === 'foxhome' && <FormCustomApp value={value} onChange={onChange} />}
        {value?.appType === 'foxkit' && !isEdit && <FormShopifyApp ref={ref} store={value?.store} currentUserId={currentUserId} />}
        {/*{value?.appType === 'foxhome' || isEdit ? (*/}
        {/*  <FormPurchaseCode product={value?.product} readOnly={readOnlyPurchaseCode} value={value?.purchaseCode} onChange={onChange} />*/}
        {/*) : null}*/}
      </Modal.Section>
    </>
  );
});

export default function AddStoreModal({ onClose, payload, onConfirm, purchaseCodeOnly }) {
  const [value, setValue] = useState({
    accessToken: '',
    purchaseCode: '',
    apiSecretKey: '',
    store: '',
    appType: 'foxkit',
    product: THEME_NAME.MINIMOG,
  });
  const [loading, setLoading] = useState(false);
  const { handleFetchShop, currentUser } = useAuthStore();
  const [error, setError] = useState('');

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      accessToken: payload?.accessToken,
      purchaseCode: payload?.license?.code,
      apiSecretKey: payload?.apiSecretKey,
      store: payload?.shop,
      appType: payload?.appType ?? 'foxkit',
      product: payload?.product,
    }));
  }, [payload]);

  const handleChangeValue = (field, newValue) => {
    setError('');
    setValue((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      const shopifyDomain = value?.store
        .replace(/\s/g, '')
        .replace(/(https?:).*?/i, '')
        .replace(/\//g, '')
        .replace(/\.com.*/, '.com');

      if (purchaseCodeOnly) {
        await addPurchaseCodeService(payload?.shop, value?.purchaseCode, value?.product);
      } else if (payload.edit && !payload.active) {
        await updateShopStatusService(payload?._id, SHOP_STATUS_ACTIVE, value?.accessToken);
      } else {
        const regexShopifyDomain = /^[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com/;

        if (!regexShopifyDomain.test(shopifyDomain)) throw new Error('Invalid shop domain.');

        const requestPayload = {
          shopDomain: shopifyDomain,
          accessToken: value?.accessToken,
          purchaseCode: value?.purchaseCode,
          apiSecretKey: value?.apiSecretKey,
          product: value?.product,
        };
        if (payload?.edit) {
          await updateShopService(payload?._id, requestPayload);
        } else {
          await createNewShopService(requestPayload);
        }
      }

      setLoading(false);

      if (onConfirm) onConfirm(`${shopifyDomain}`);

      showToast({
        message: purchaseCodeOnly ? 'Add purchase code successfully' : `Connect store ${shopifyDomain} successfully`,
      });

      if (payload?.shop === localStorage.getItem(SHOP_SELECTED_KEY)) {
        handleFetchShop();
      }

      onClose();
    } catch (error) {
      const errorMessages =
        error?.data && error?.data?.length > 0
          ? error?.data?.map((err) => `${error?.data.length > 1 ? '-' : ''} ${err?.message}`).join('<br/> ')
          : error?.message;

      const finalErrorMessage = errorMessages ? errorMessages + '<br/>' : '';
      finalErrorMessage &&
        showToast({
          message: finalErrorMessage,
          error: true,
        });

      const newFormErrors = error?.data || error?.message;
      setError(newFormErrors);
      setLoading(false);
    }
  };

  const refForm = useRef();

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const regexShopifyDomain = /^[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com$/;

      if (!regexShopifyDomain.test(value?.store)) throw new Error('Invalid shop domain.');

      const response = await foxHomeAuthService(value.store, currentUser._id);
      const { requiredInstallFoxKit, redirectUrl } = response || {};
      if (!requiredInstallFoxKit && redirectUrl) {
        window.location.href = redirectUrl;
        return;
      }

      if (requiredInstallFoxKit) {
        refForm?.current?.submit();
      }
    } catch (error) {
      const newFormErrors = error?.data || error?.message;
      setError(newFormErrors);
      setLoading(false);
    }
  };

  const disabled = useMemo(() => {
    if (purchaseCodeOnly) return !value?.purchaseCode;
    if (value?.appType === 'foxkit') return !value?.store;
    if (payload?.edit) {
      const currentValue = {
        shopDomain: value?.store,
        accessToken: value?.accessToken,
        apiSecretKey: value?.apiSecretKey,
      };

      const payloadValue = {
        shopDomain: payload?.shop,
        accessToken: payload?.accessToken,
        apiSecretKey: payload?.apiSecretKey,
      };
      return isEqual(currentValue, payloadValue);
    }
    return [value?.store, value?.accessToken, value?.apiSecretKey].findIndex((item) => !item) !== -1;
  }, [value, purchaseCodeOnly, payload]);

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={purchaseCodeOnly ? 'Add purchase code to your store' : 'Connect your store to FoxHome'}
      primaryAction={
        value?.appType === 'foxkit' && payload?.edit
          ? null
          : payload?.edit && payload?.active === false
          ? {
              content: 'Activate',
              onAction: handleConfirm,
              loading: loading,
            }
          : {
              content: purchaseCodeOnly ? 'Add code' : payload?.edit ? 'Update' : 'Connect store',
              onAction: !payload?.edit && !purchaseCodeOnly && value?.appType === 'foxkit' ? handleSubmit : handleConfirm,
              disabled: disabled,
              loading: loading,
            }
      }
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
          plain: true,
        },
      ]}
    >
      {purchaseCodeOnly ? (
        <Modal.Section>
          <FormPurchaseCode
            product={value?.product}
            readOnly={payload?.license?.code}
            value={value?.purchaseCode}
            onChange={handleChangeValue}
            autoFocus
          />
        </Modal.Section>
      ) : (
        <FormApp
          readOnlyPurchaseCode={payload?.license?.code}
          value={value}
          onChange={handleChangeValue}
          isEdit={payload?.edit}
          currentUserId={currentUser._id}
          ref={refForm}
        />
      )}
      {error && (
        <>
          {Array.isArray(error) ? (
            error?.map((err) => {
              const details = err?.details;
              let message = `${err?.message}`;
              return details ? (
                <Modal.Section>
                  <div style={{ marginTop: '1.6rem' }}>
                    <InlineError message={message} />
                    {details && (
                      <div style={{ marginTop: 5, fontSize: 13 }}>
                        <code>{details.join(', ')}</code>
                      </div>
                    )}
                  </div>
                </Modal.Section>
              ) : (
                ''
              );
            })
          ) : (
            <Modal.Section>
              <div style={{ marginTop: '1.6rem' }}>
                <InlineError message={error} />
              </div>
            </Modal.Section>
          )}
        </>
      )}
    </Modal>
  );
}
