import React from "react";
import { Router } from "@reach/router";
import BackInStockList from "@pages/back-in-stock"
import BackInStockSettings from "@pages/back-in-stock/settings"
import EditBackInStock from "@pages/back-in-stock/edit"
export default function BackInStockPages() {
    return (
        <Router>
            <BackInStockList path="/" />
            <BackInStockSettings path="/settings" />
            <EditBackInStock path="/:pageID" />
        </Router>
    )
}