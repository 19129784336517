import {Link, navigate} from "@reach/router"
import Page from '@modules/page'
import {Layout, Card, Icon, Caption, Stack, TextStyle, Button} from "@shopify/polaris"

import {
	SettingsMajor,
	PlusMinor,
	QuickSaleMajor,
	DiscountCodeMajor,
	SocialPostMajor, ConfettiMajor, DetailedPopUpMajor,ThemeEditMajor
} from '@shopify/polaris-icons'
import React from "react"

const UpSell = () => {
	return (
		<Page
			title="Boost conversion tools"
			breadcrumbs={[{content: 'Dashboard', onAction: () => navigate('/')}]}
		>
			<Layout>
				<Layout.Section>
					<Card>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={QuickSaleMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/free-shipping-goal'}>Free shipping goal</Link>
										<Caption>Set a goal for cart that a user will get Free shipping or Discount once a specific cart goal amount reached.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button icon={SettingsMajor} onClick={() => navigate('/free-shipping-goal')} />
								</Stack.Item>
							</Stack>
						</Card.Section>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={DiscountCodeMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/countdown-timer'}>Countdown timer</Link>
										<Caption>A virtual clock that counts down from a certain number or date, that creates sense of urgency and scarcity, <br/>to urge customers to buy before time runs out.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button icon={PlusMinor} onClick={() => navigate('/countdown-timer/new')} />
								</Stack.Item>
							</Stack>
						</Card.Section>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={SocialPostMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/sales-notifications'}>Sales notifications</Link>
										<Caption>Sales notification is an influential selling tool to create sense of a busy store and boost sales.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button icon={SettingsMajor} onClick={() => navigate('/sales-notifications')} />
								</Stack.Item>
							</Stack>
						</Card.Section>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={ConfettiMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/lucky-wheel'}>Lucky wheel</Link>
										<Caption>Increase engagement on your store by showing the visitors a gamified wheel popup where they can insert email and win discounts.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button icon={SettingsMajor} onClick={() => navigate('/lucky-wheel')} />
								</Stack.Item>
							</Stack>
						</Card.Section>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={DetailedPopUpMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/popup'}>Popup</Link>
										<Caption>Easy to create coupon or newsletter popup to interact with your customer.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button icon={SettingsMajor} onClick={() => navigate('/popup')} />
								</Stack.Item>
							</Stack>
						</Card.Section>
						<Card.Section>
							<Stack alignment={'center'}>
								<Stack.Item>
									<Icon color="inkLightest" backdrop source={ThemeEditMajor} />
								</Stack.Item>
								<Stack.Item fill>
									<TextStyle variation="strong">
										<Link to={'/size-charts'}>Size chart</Link>
										<Caption>Improve your conversion rates with different size charts for various types of products.</Caption>
									</TextStyle>
								</Stack.Item>
								<Stack.Item>
									<Button icon={SettingsMajor} onClick={() => navigate('/size-chart/new')} />
								</Stack.Item>
							</Stack>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	)
}

export default UpSell
