import { updateParam } from '@helpers/utils';
import { navigate } from '@reach/router';
import { Badge, Card, ResourceList, TextStyle, Thumbnail } from '@shopify/polaris';
import { ImageMajor } from '@shopify/polaris-icons';
import { getSizedImageUrl } from '@shopify/theme-images';
import { omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useStore } from '../container';
import Filter from './filter';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .title {
    flex: 1;
  }
  .column {
    padding-left: 10px;
    width: 22%;
  }
`;

const Products = ({ loading, products, getProducts }) => {
  const { state, dispatch } = useStore();
  const { sort } = state.filter;

  const sortOptions = [
    { label: 'Title(A-Z)', value: 'TITLE_NOREVERSE' },
    { label: 'Title(Z-A)', value: 'TITLE_REVERSE' },
    { label: 'Created(newest to oldest)', value: 'CREATED_AT_REVERSE' },
    { label: 'Created(oldest to newest)', value: 'CREATED_AT_NOREVERSE' },
    { label: 'Low Inventory', value: 'INVENTORY_NOREVERSE' },
    { label: 'High Inventory', value: 'INVENTORY_REVERSE' },
    { label: 'Product type(A-Z)', value: 'PRODUCT_TYPE_NOREVERSE' },
    { label: 'Product type(Z-A)', value: 'PRODUCT_TYPE_REVERSE' },
    { label: 'Updated(newest to oldest)', value: 'UPDATED_AT_REVERSE' },
    { label: 'Updated(oldest to newest)', value: 'UPDATED_AT_NOREVERSE' },
    { label: 'Vendor(A-Z)', value: 'VENDOR_NOREVERSE' },
    { label: 'Vendor(Z-A)', value: 'VENDOR_REVERSE' },
  ];

  const renderItem = (item) => {
    const productId = item.id.replace('gid://shopify/Product/', '');
    let imageThumbnail;
    if (item?.image)
      imageThumbnail = getSizedImageUrl(item?.image, '100x100')
        ? getSizedImageUrl(item?.image, '100x100')
        : item?.image;
    const media = item && (
      <Thumbnail
        source={imageThumbnail ? imageThumbnail : ImageMajor}
        size="small"
        alt={item?.title ? item?.title : ''}
      />
    );
    return (
      <ResourceList.Item
        id={item?.id}
        onClick={() => navigate(`/variant-group-images/${productId}`)}
        media={media}
        accessibilityLabel={`View details for ${item?.title}`}
      >
        <Wrapper>
          <h3 className="title">
            <TextStyle variation="strong">{item.title}</TextStyle>
            <span style={{ marginLeft: 10 }}>
              <Badge status={item?.status === 'ACTIVE' ? 'success' : 'info'}>
                {item?.status.charAt(0).toUpperCase() + item?.status.toLowerCase().slice(1)}
              </Badge>
            </span>
          </h3>
          <div className="column">
            <Badge status={'info'}>{item.totalVariants} variants</Badge>
          </div>
        </Wrapper>
      </ResourceList.Item>
    );
  };

  const resourceName = { singular: 'Product', plural: 'Products' };

  const handleSortChange = (v) => {
    const filterData = omit(state.filter, ['first', 'after', 'last', 'before']);

    dispatch({ type: 'setData', payload: { ...state, filter: { ...filterData, sort: v } } });

    updateParam('sort', v);
  };

  return (
    <Card sectioned>
      <ResourceList
        sortOptions={sortOptions}
        sortValue={sort}
        onSortChange={(v) => handleSortChange(v)}
        loading={loading}
        resourceName={resourceName}
        items={products ? products : []}
        filterControl={<Filter getProducts={getProducts} />}
        renderItem={renderItem}
      />
    </Card>
  );
};

export default Products;
