import { Card, SkeletonThumbnail, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import axios from '@plugins/axios';
import { stripHtml, truncateString } from '@helpers/utils';
import shuffle from '@shared/utils/shuffle';

const PostCard = styled.div`
  .Polaris-MediaCard {
    display: flex;
    gap: 2rem;
    flex-flow: row;
    align-items: flex-start;

    @media (max-width:1150px) {
      gap: 2rem;
      display: flex;
      flex-flow: column;
      height: auto;
      padding-bottom: 2rem;
    }

    img {
      height: auto;
      aspect-ratio: 16/9
    }
  }
  .Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
    border-radius: var(--p-border-radius-wide);
  }
  .Polaris-SkeletonThumbnail {
    width: 100%;
    height: 100%;
  }
  .Polaris-Card {
    border-bottom: none !important;
  }
  .Polaris-SkeletonDisplayText--sizeSmall {
    max-width: 300px;
    height: 1.8rem;
  }
`;

export default function ArticleCard() {
  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState({});

  useEffect(async () => {
    const res = await axios.get('/api/shopify/foxecom-blog');
    if (res && res.payload) {
      const selected = shuffle(res.payload)?.[0];
      setArticle({
        ...selected,
        featured_image: selected?.image?.src,
      });
      setLoading(false);
    }
  }, []);

  const summary_html = article?.summary_html ? stripHtml(article?.summary_html) : stripHtml(truncateString(article?.body_html, 200))

  return (
    <Card
      title={'Suggested reading'}
      actions={[
        {
          content: 'View more posts',
          url: 'https://foxecom.com/blogs/all?utm_source=FoxHome&utm_medium=suggested+reading&utm_campaign=in-app',
          external: true,
        },
      ]}
    >
      <Card.Section>
        <PostCard>
          <div className="Polaris-MediaCard">
            <a
              className={'Polaris-MediaCard__MediaContainer Polaris-MediaCard--sizeSmall block'}
              href={`https://foxecom.com/blogs/all/${article?.handle}?utm_source=FoxHome&utm_medium=suggested+reading&utm_campaign=in-app`}
            >
              {loading ? <SkeletonThumbnail /> : <img src={article?.featured_image} width={'100%'} />}
            </a>
            <div className="Polaris-MediaCard__InfoContainer Polaris-MediaCard--sizeSmall">
              <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingTight">
                <div className="Polaris-Stack__Item">
                  <div className="Polaris-MediaCard__Heading">
                    {loading ? <SkeletonDisplayText size={'small'} /> : <h2 className="Polaris-Heading">{article?.title}</h2>}
                  </div>
                </div>
                <div className="Polaris-Stack__Item">{loading ? <SkeletonBodyText /> : <p>{summary_html}</p>}</div>
                <div className="Polaris-Stack__Item">
                  <div className="Polaris-MediaCard__ActionContainer">
                    <div className="Polaris-ButtonGroup">
                      <div className="Polaris-ButtonGroup__Item">
                        <div className="Polaris-MediaCard__PrimaryAction">
                          <a
                            target="_blank"
                            className="Polaris-Button"
                            href={`https://foxecom.com/blogs/all/${article?.handle}?utm_source=FoxHome&utm_medium=suggested+reading&utm_campaign=in-app`}
                          >
                            <span className="Polaris-Button__Content">
                              <span className="Polaris-Button__Text">Read blog post</span>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="Polaris-ButtonGroup__Item">
                        <div className="Polaris-MediaCard__SecondaryAction">
                          <button className="Polaris-Button Polaris-Button--disabled Polaris-Button--plain" disabled="">
                            <span className="Polaris-Button__Content">
                              <span className="Polaris-Button__Text">03 mins read</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PostCard>
      </Card.Section>
    </Card>
  );
}
