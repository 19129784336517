import { useAuthStore } from '@container/Auth';
import NotFound from '@pages/404/index';
import AccountSettings from '@pages/account-settings';
import BoostConversion from '@pages/boost-conversion';
import CartCountDown from '@pages/cart-countdown';
import CartGoal from '@pages/cart-goal';
import Dashboard from '@pages/dashboard';
import Preferences from '@pages/general-settings';
import LuckyWheel from '@pages/lucky-wheel';
import FbMessenger from '@pages/messenger';
import Popup from '@pages/popup';
import SaleNotifications from '@pages/sales-notifications';
import Settings from '@pages/settings';
import StockCountDown from '@pages/stock-countdown';
import StoreSettings from '@pages/store-settings';
import License from '@pages/theme-manager/license';
import UpSell from '@pages/upsells';
import { Redirect, Router } from '@reach/router';
import BackInStockPages from './back-in-stock';
import BundlePages from './bundles';
import CountDownPages from './countdown-timer';
import CustomerPages from './customers';
import ImagesVariantsGroup from './images-variants-group';
import InCartPages from './in-cart';
import IntegrationPages from './integration';
import PreOrderPages from './pre-order';
import PrePurchasePages from './pre-purchase';
import ProductRecommendationPages from './product-recommendation';
import QtyDiscountPages from './quantity-discounts';
import SizeChartPages from './size-charts';
import ThemePages from './theme-manager';
import axios from '@plugins/axios';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuthStore();
  const shop = axios.defaults.headers.common['X-Request-Shop-Origin'];
  if (!currentUser) return <Redirect from="" to="/login" noThrow />;
  if (!shop) return <Redirect from="" to="/select-store" noThrow />;
  return <Component {...rest} />;
};

const AppRoutes = () => {
  const { currentUser } = useAuthStore();
  const hideAccountSettings = currentUser?.isGuest && currentUser?.havingPassword;

  return (
    <Router>
      <ProtectedRoute component={Dashboard} path="/" />
      <ProtectedRoute component={Preferences} path="/manage/preferences" />
      <ProtectedRoute component={Settings} path="/settings" />
      {!hideAccountSettings ? <ProtectedRoute component={AccountSettings} path="/settings/account" /> : null}
      <ProtectedRoute component={StoreSettings} path="/settings/stores" />
      <ProtectedRoute component={License} path="/settings/licenses" />
      <ProtectedRoute component={BundlePages} path="/bundles/*" />
      <ProtectedRoute component={CountDownPages} path="/countdown-timer/*" />
      <ProtectedRoute component={SizeChartPages} path="/size-chart/*" />
      <ProtectedRoute component={QtyDiscountPages} path="/quantity-discounts/*" />
      <ProtectedRoute component={PrePurchasePages} path="/pre-purchase/*" />
      <ProtectedRoute component={PreOrderPages} path="/pre-order/*" />
      <ProtectedRoute component={InCartPages} path="/in-cart/*" />
      <ProtectedRoute component={ProductRecommendationPages} path="/product-recommendation/*" />
      <ProtectedRoute component={Popup} path="/popup" />
      <ProtectedRoute component={BackInStockPages} path="/back-in-stock/*" />
      <ProtectedRoute component={LuckyWheel} path="/lucky-wheel" />
      <ProtectedRoute component={CartGoal} path="/free-shipping-goal" />
      <ProtectedRoute component={SaleNotifications} path="/sales-notifications" />
      <ProtectedRoute component={StockCountDown} path="/stock-countdown" />
      <ProtectedRoute component={CartCountDown} path="/cart-countdown" />
      <ProtectedRoute component={BoostConversion} path="/boost-conversion" />
      <ProtectedRoute component={UpSell} path="/upsells" />
      <ProtectedRoute component={ThemePages} path="/theme/*" />
      <ProtectedRoute component={CustomerPages} path="/manage/leads/*" />
      <ProtectedRoute component={FbMessenger} path="/messenger" />
      <ProtectedRoute component={IntegrationPages} path="/manage/integrations/*" />
      <ProtectedRoute component={ImagesVariantsGroup} path="/variant-group-images/*" />
      <NotFound default />
    </Router>
  );
};
export default AppRoutes;
