import FoxHomeLogo from '@components/foxHomeLogo';
import InputField from '@components/inputField';
import { useAuthStore } from '@container/Auth';
import { DEBOUNCE_TIME, LIMIT_STORE, SHOP_SELECTED_KEY, THEME_NAME } from '@helpers/constants';
import { getParam, updateParam } from '@helpers/utils';
import Loading from '@modules/loading';
import AddStoreModal from '@pages/store-settings/addStoreModal';
import ShopItem from '@pages/storeSelector/shopItem';
import axios from '@plugins/axios';
import { showToast } from '@plugins/toast';
import { deleteShopService, getAllShopService, updateShopStatusService } from '@services/shop';
import { Button, Card, DisplayText, EmptyState, Icon, Layout, Page, TextStyle } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import useSignIn from 'src/hooks/useSignIn';
import styled from 'styled-components';
import { useNotificationStore } from '@container/NotificationProvider';
import { sendRequestAccessService } from '@services/user';

const ListContainer = styled.div`
  .Polaris-ActionList {
    padding: 0;
  }

  .Polaris-ActionList__Actions {
    display: grid;
    grid-gap: 1.6rem;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      padding: 1.6rem;
      border-radius: 3px;
      box-shadow: 0 0 0 1px #c4cdd5;
      &.store-disabled {
        background-color: var(--p-surface-neutral-disabled);
        & > button {
          pointer-events: none;
        }
      }
    }
    .Polaris-ActionList__Suffix {
      pointer-events: auto;
    }
  }

  .Polaris-ActionList__ContentBlockInner {
    font-weight: bold;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.4rem 0 2rem;
  .User-Layout__logo {
    position: static;
    transform: none;
  }
`;

function StoreSelector(props) {
  const { storeList, updateStoreList } = useAuthStore();
  const [storesDisplay, setStoresDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [payloadModal, setPayloadModal] = useState({ product: THEME_NAME.MINIMOG });
  const [searchValue, setSearchValue] = useState('');
  const queryParam = getParam('query');
  const error = getParam('error');
  const { showConfirm } = useNotificationStore();

  const toggleActiveModal = useCallback(() => setActiveModal((prev) => !prev), []);

  useEffect(() => {
    document.title = 'Select store';
    document.body.style.overflow = 'auto';
    document.body.classList.add('install-page');
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (searchValue !== queryParam) setSearchValue(queryParam);

        let newStoreList = storeList;
        if (queryParam) {
          newStoreList = await getAllShopService({ query: queryParam });
        }

        setStoresDisplay(newStoreList);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showToast({
          message: error?.message,
          error: true,
        });
      }
    })();
  }, [storeList]);

  const handleSelectShop = (shopData) => {
    localStorage.setItem(SHOP_SELECTED_KEY, shopData?.shop);
    axios.defaults.headers.common['X-Request-Shop-Origin'] = shopData?.shop;
    window.location.href = '/';
  };

  const handleAddNewStore = () => {
    toggleActiveModal();
    setPayloadModal({ product: THEME_NAME.MINIMOG });
  };

  const handleChangeSearch = (newVal) => {
    setLoading(true);
    setSearchValue(newVal);
    debounceSearch(newVal);
  };

  const debounceSearch = useCallback(
    debounce(async (query) => {
      try {
        updateParam('query', query);
        let newList;
        if (!query) {
          newList = storeList;
        } else {
          newList = await getAllShopService({ query });
        }
        setStoresDisplay(newList);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showToast({
          message: error?.message,
          error: true,
        });
      }
    }, DEBOUNCE_TIME),
    [storeList],
  );

  const handleConfirm = async (shop) => {
    setLoading(true);
    await handleFetchNewList();
    setLoading(false);
    setPayloadModal({ product: THEME_NAME.MINIMOG });
  };

  const handleDeleteStore = async (id) => {
    showConfirm({
      title: `Delete this store `,
      confirmButtonText: 'Delete',
      danger: true,
      message: `Are you sure you want to deactivate this store. This can't be undone.`,
      onConfirm: async () => {
        try {
          await deleteShopService(id);
          await handleFetchNewList();

          showToast({
            message: 'Delete store successfully!',
          });
          return Promise.resolve();
        } catch (error) {
          showToast({
            message: error?.message,
            error: true,
          });
          return Promise.resolve();
        }
      },
    });
  };

  const handleFetchNewList = async () => {
    const response = await getAllShopService({});
    updateStoreList(response);
  };

  const handleCloseModal = () => {
    toggleActiveModal();
    setPayloadModal({ product: THEME_NAME.MINIMOG });
  };

  const toggleEditModal = (payload) => {
    toggleActiveModal();
    setPayloadModal((prev) => ({
      ...prev,
      edit: true,
      ...payload,
    }));
  };

  const handleUpdateStatus = async (shopId, status) => {
    try {
      setLoading(true);
      await updateShopStatusService(shopId, status);
      await handleFetchNewList();
      setLoading(false);
      showToast({
        message: 'Update shop status successfully',
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      showToast({
        message: error?.message,
        error: true,
      });
    }
  };
  const { currentUser } = useAuthStore();
  const { handleLogout } = useSignIn();
  const displayName = [currentUser?.firstName, currentUser?.lastName];

  const handleSendRequestAccess = async (shop) => {
    try {
      const email = await sendRequestAccessService(shop);
      showToast({
        message: `A request access has been sent to this store's owner (${email}).`,
      });
    } catch (error) {
      showToast({
        error: true,
        message: error?.message,
      });
    }
  };

  console.log('payload:', payloadModal);
  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <Logo>
            <FoxHomeLogo />
          </Logo>
          <Card title={storesDisplay?.length > 0 ? <DisplayText size={'small'}>Select your store</DisplayText> : ''}>
            <Card.Section>
              <ListContainer>
                {storeList?.length > LIMIT_STORE && (
                  <div style={{ marginBottom: '1.6rem' }}>
                    <InputField
                      clearButton
                      onClearButtonClick={() => handleChangeSearch('')}
                      placeholder="Search your store domain..."
                      prefix={<Icon source={SearchMinor} />}
                      value={searchValue}
                      onChange={handleChangeSearch}
                    />
                  </div>
                )}

                {loading ? (
                  <Loading.Inner size={80} />
                ) : storesDisplay?.length ? (
                  <>
                    <div className="Polaris-ActionList">
                      <div className="Polaris-ActionList__Actions">
                        {storesDisplay.map((store) => (
                          <ShopItem
                            onDelete={handleDeleteStore}
                            onEdit={toggleEditModal}
                            store={store}
                            onSelectShop={handleSelectShop}
                            onUpdateStatus={handleUpdateStatus}
                            onRequestAccess={handleSendRequestAccess}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <EmptyState
                    heading="No active stores found"
                    action={{ content: 'Add new store', onAction: handleAddNewStore }}
                    image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e5ba4e701142a8aa.svg"
                  >
                    <p>No stores found. Please connect a store to start using FoxHome.</p>
                  </EmptyState>
                )}
              </ListContainer>
              {storesDisplay?.length > 0 && (
                <div style={{ marginTop: '1.6rem' }}>
                  <Button size={'large'} fullWidth onClick={handleAddNewStore}>
                    Add another store
                  </Button>
                </div>
              )}
              <div style={{ marginTop: '1rem' }}>
                <span>
                  <TextStyle variation={'subdued'}>Logged in as</TextStyle> {displayName.filter(Boolean).join(' ')}.
                </span>
                &nbsp;
                <Button plain destructive onClick={handleLogout}>
                  Logout?
                </Button>
              </div>
            </Card.Section>
          </Card>
          {activeModal && <AddStoreModal payload={payloadModal} onClose={handleCloseModal} onConfirm={handleConfirm} />}
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default StoreSelector;
