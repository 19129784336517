import { SHOP_STATUS_ACTIVE, SHOP_STATUS_EXPIRED, SHOP_STATUS_INACTIVE, SHOP_STATUS_PENDING, SHOP_STATUS_REVOKE } from '@helpers/constants';
import { titleCase } from '@helpers/utils';
import { STATUS_MAPPING } from '@pages/store-settings/shopItem';
import { ActionList, Badge, Banner, Button, Icon, Popover } from '@shopify/polaris';
import { MobileVerticalDotsMajor, StoreMajor } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import maskEmail from 'src/shared/utils/maskEmail';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  & > .main-content {
    display: flex;
    width: 100%;
    align-items: center;
  }

  & > .expire-banner {
    margin-top: 1rem;
  }
`;

export default function ShopItem({ store, onDelete, onEdit, onSelectShop, onUpdateStatus, onRequestAccess }) {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const { status, _id, appType } = store;

  const handleUpdateStatus = async (newStatus) => {
    if (onUpdateStatus) await onUpdateStatus(_id, newStatus);
    toggleActive();
  };

  let actionList = [];

  if (status === SHOP_STATUS_ACTIVE) {
    actionList.push(
      {
        content: 'Edit',
        onAction: () => onEdit({ ...store }),
      },
      {
        content: 'Deactivate',
        destructive: true,
        onAction: () => handleUpdateStatus(SHOP_STATUS_INACTIVE),
      },
    );
  }

  if (status === SHOP_STATUS_INACTIVE && appType === 'foxhome') {
    actionList.push({
      content: 'Activate',
      onAction: () => onEdit({ ...store }),
    });
  }

  if ([SHOP_STATUS_INACTIVE, SHOP_STATUS_EXPIRED, SHOP_STATUS_PENDING].includes(status)) {
    actionList.push({
      content: 'Delete',
      onAction: () => onDelete(_id),
      destructive: true,
    });
  }

  if (status === SHOP_STATUS_REVOKE) {
    actionList = [
      {
        content: 'Update credentials',
        onAction: () => onEdit({ ...store }),
      },
      {
        content: 'Delete',
        onAction: () => onDelete(_id),
        destructive: true,
      },
    ];
  }

  const handleClickShop = () => {
    if (status !== SHOP_STATUS_ACTIVE) return;
    onSelectShop && onSelectShop(store);
  };

  return (
    <li key={store.id} className={status !== SHOP_STATUS_ACTIVE ? 'store-disabled' : ''}>
      <Wrapper>
        <div className="main-content">
          <button type="button" className="Polaris-ActionList__Item" onClick={handleClickShop}>
            <span className="Polaris-ActionList__Content">
              <span className="Polaris-ActionList__Prefix">
                <Icon source={StoreMajor} />
              </span>
              <span className="Polaris-ActionList__Text">
                <span className="Polaris-ActionList__ContentBlock">
                  <span className="Polaris-ActionList__ContentBlockInner">
                    <div className={'store-name'}>
                      {store?.name} <Badge status={STATUS_MAPPING[store?.status]}>{titleCase(store?.status)}</Badge>{' '}
                      {appType && <Badge>{appType}</Badge>}
                    </div>
                  </span>
                  <span className="Polaris-TextStyle--variationSubdued">{store?.domain}</span>
                </span>
              </span>
            </span>
          </button>
          <span className="Polaris-ActionList__Suffix">
            <Popover active={active} activator={<Button plain onClick={toggleActive} icon={MobileVerticalDotsMajor} />} onClose={toggleActive}>
              <ActionList actionRole="menuitem" items={actionList} />
            </Popover>
          </span>
        </div>
        {status === SHOP_STATUS_EXPIRED && (
          <span className="Polaris-ActionList__ContentBlockInner" style={{ marginLeft: '40px' }}>
            <Button onClick={() => onRequestAccess(store?.shop)}>Request access again</Button>
          </span>
        )}

        {store?.status === SHOP_STATUS_PENDING && (
          <div className="expire-banner">
            <Banner title="Access verification" status="info">
              <p>
                An access verification email has been sent to store owner's email {'('}
                {maskEmail(store?.email)}
                {')'}. Please ask store owner to confirm request.{' '}
              </p>
            </Banner>
          </div>
        )}
      </Wrapper>
    </li>
  );
}
