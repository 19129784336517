import styled from "styled-components"

const Preview = styled.div`
	position: relative;
	width: 100%;
	min-height: 300px;
	padding: 50px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5rem 2rem;
	h3 {
		margin-bottom: 1.5rem;
	}
	.progress {
		position: relative;
		height: 8px;
		width: 100%;
		display: block;
		background: #ccc;
		overflow: hidden;
		border-radius: 2px;
		span {
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;
			background: #000;
		}
	}
`

const PreviewCountDown = ({message}) => {
	return (
		<Preview className={'flex flex-col items-start'}>
			<h3 className="text-2xl mb-5 font-medium text-left w-full">{message.replace('{{stock_number}}', 7)}</h3>
			<div className="progress">
				<span style={{width: '12%'}} />
			</div>
		</Preview>
	)
}
export default PreviewCountDown
