import { Card, FormLayout } from "@shopify/polaris"
import { useStore } from "../container"

const CustomerCard = () => {
  const { state, dispatch } = useStore()
  const { customer, note } = state
  const { firstName, email, phone } = customer

  return (
    <Card sectioned title={'Customer overview'}>
      <FormLayout>
        <p><strong>Email</strong>: {email}</p>
        <p><strong>Name</strong>: {firstName}</p>
        <p><strong>Phone</strong>: {phone || 'N/A'}</p>
        <p><strong>Message</strong>: {note}</p>
      </FormLayout>
    </Card>
  )
}

export default CustomerCard
