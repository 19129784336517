import {
	Layout,
	Card,
	TextField,
	FormLayout,
	Checkbox,
	ChoiceList,
	TextStyle,
	ContextualSaveBar,
	Banner,
	PageActions
} from "@shopify/polaris"
import { ContextProvider, useStore } from "./container"
import Page from "@modules/page"
import ResourcePicker from "@components/resourcePicker"
import { navigate } from "@reach/router"
import Preview from "./preview"
import LayoutSection from "@modules/layoutSection"
import { useEffect, useState, useCallback } from "react"
import API from "@helpers/api"
import { showToast } from "@plugins/toast"
import { Skeleton } from "@modules/skeleton"
import { CancelToken } from "@plugins/axios"
import { isEqual, omit } from "lodash"
import DiscardModal from "../../components/discardModal"


const SaleNotifications = () => {
	return (
		<ContextProvider>
			<SaleNotificationsView />
		</ContextProvider>
	)
}

const SaleNotificationsView = () => {
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const { state, dispatch } = useStore()
	const source = CancelToken.source()
	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState(state)

	const isEqualState = isEqual(
		omit(state, ['updatedAt', 'updated_at', 'deleted', 'shop', 'createdAt', "__v", "_id"]),
		omit(prevState, ['updatedAt', 'updated_at', 'deleted', 'shop', 'createdAt', "__v", "_id"])
	)

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

	useEffect(() => {
		const handleReloadPage = (e) => {
		  e.preventDefault()
		  e.returnValue = ""
		}
		if(isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
		return () => window.removeEventListener("beforeunload", handleReloadPage)
	  }, [isEqualState])

	useEffect(() => {
		let result
		const fetchData = async () => {
			try {
				result = await API.getList('sale-notification', {}, source.token)
				console.log('Data details', result)
				if (result && result.ok) {
					dispatch({ type: 'setData', payload: result.payload })
					setReady(true)
				}
				if (result && result.ok && result.payload) {
					if (!result.payload.hasOwnProperty("target_collection")) {
						result.payload.target_collection = ""
					}
					setPrevState({ ...result.payload, display_time: result.payload.display_time.toString()})
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
		// return () => source.cancel()
	}, [])
	const handleSave = async () => {
		setSaving(true)
		const { target_products, target_collection, display_time } = state
		API.updateByShop('sale-notification', {
			...state,
			target_products: target_products?.filter(Boolean).map(p => p.value),
			target_collection: target_collection?.value,
		}).then((res) => {
			if (res.ok) {
				setPrevState({
					...prevState,
					...res.payload,
					target_products: target_products.filter(Boolean),
					target_collection: target_collection,
					display_time: display_time
				})
				return showToast({
					message: 'Updated successfully'
				})
			}
		}).finally(() => setSaving(false))
	}

	const { active, title, time, names, locations, target_to, target_products, target_collection, show_on_mobile, display_time, delay_time, delay_show, max_show, position } = state

	const handleChange = (key, value) => {
		dispatch({ type: 'setData', payload: { [key]: value } })
	}

	return ready ? (
		<Page
			title="Sales notifications"
			breadcrumbs={[{ content: 'Boost conversion', onAction: () => isEqualState ? navigate('/boost-conversion') : handleModalChange() }]}
			fullWidth
		>
			{!isEqualState && <ContextualSaveBar
				saveAction={{
					onAction: handleSave,
					loading: saving,
					disabled: false,
				}}
				discardAction={{
					onAction: () => handleModalChange(),
				}}
				fullWidth
			/>}
			<Layout>
				<Layout.Section secondary>
					<div style={{ marginBottom: '2rem' }}>
						<Banner>
							Show notifications of recent sales, build trust and credibility by letting customers know what other shoppers are buying. Sales notification is an influential selling tool to create sense of a busy store and boost sales.
						</Banner>
					</div>
					<Card sectioned title={'Visibility'}>
						<FormLayout>
							<Checkbox
								label={'Active'}
								checked={active}
								onChange={v => handleChange('active', v)}
							/>
							<Checkbox
								label={'Show on mobile'}
								checked={show_on_mobile}
								onChange={v => handleChange('show_on_mobile', v)}
								disabled={!active}
							/>
						</FormLayout>
					</Card>
					<Card title={'Message settings'}>
						<Card.Section>
							<FormLayout>
								<TextField
									label={'Title'}
									value={title + ''}
									onChange={v => handleChange('title', v)}
									helpText={'Variables: {{name}}, {{location}}'}
								/>
								<TextField
									label={'Time'}
									value={time + ''}
									onChange={v => handleChange('time', v)}
									helpText={'Variables: {{time}} ago, {{location}}'}
								/>
							</FormLayout>
						</Card.Section>
					</Card>
					<Card title={'Randomize data'}>
						<Card.Section>
							<FormLayout>
								<TextField
									multiline={2}
									label={'Random names'}
									value={names}
									onChange={v => handleChange('names', v)}
									helpText={'Separate by a comma ","'}
								/>
								<TextField
									multiline={2}
									label={'Random locations'}
									value={locations}
									onChange={v => handleChange('locations', v)}
									helpText={'Separate by a comma ","'}
								/>
							</FormLayout>
						</Card.Section>
						<Card.Section title={'Products to show'}>
							<FormLayout>
								<ChoiceList
									choices={[
										{ label: 'Specific collection', value: 'collection' },
										{ label: 'Specific products', value: 'product' },
									]}
									selected={target_to}
									onChange={v => handleChange('target_to', v[0])}
								/>
								{target_to === 'product' && (
									<ResourcePicker
										label={'Select products'}
										isMulti
										resourceType={'product'}
										value={target_products}
										onChange={v => handleChange('target_products', v)}
										isClearable
									/>
								)}

								{target_to === 'collection' && (
									<ResourcePicker
										label={'Select collection'}
										resourceType={'collection'}
										value={target_collection}
										onChange={v => {
											handleChange('target_collection', v)
											console.log(v, 'v')
										}}
										isClearable
									/>
								)}
								<TextStyle variation={'subdued'}>
									Show notifications of the products or the products in the collection selected above.
								</TextStyle>
							</FormLayout>
						</Card.Section>
					</Card>
					<Card sectioned title={'Display settings'}>
						<FormLayout>
							<ChoiceList
								title={'Placement'}
								choices={[
									{ label: 'Bottom left', value: 'bottom-left' },
									{ label: 'Bottom right', value: 'bottom-right' },
								]}
								selected={position}
								onChange={v => handleChange('position', v[0])}
							/>
							<FormLayout.Group>
								<TextField
									type={'number'}
									label={'Display time'}
									value={display_time + ''}
									onChange={v => handleChange('display_time', v)}
									helpText={'The duration that a notification stays on your site.'}
									suffix={'seconds'}
								/>
								<TextField
									label={'Delay time between notifications'}
									value={delay_time}
									onChange={v => handleChange('delay_time', v)}
									suffix={'seconds'}
								/>
							</FormLayout.Group>
							<FormLayout.Group>
								<TextField
									type={'number'}
									label={'Delay time to show first notification'}
									value={delay_show + ''}
									onChange={v => handleChange('delay_show', Number(v))}
									suffix={'seconds'}
								/>
								<TextField
									type={'number'}
									label={'Maximum notifications per page'}
									value={max_show + ''}
									onChange={v => handleChange('max_show', Number(v))}
									suffix={'notis'}
								/>
							</FormLayout.Group>
						</FormLayout>
					</Card>
				</Layout.Section>
				<LayoutSection isStuck>
					<Card title={'Preview'} sectioned>
						<Preview title={title} time={time} position={position} />
					</Card>
					<PageActions
						primaryAction={{
							content: 'Save',
							loading: saving,
							onAction: handleSave

						}}
					/>
				</LayoutSection>
			</Layout>
			<DiscardModal
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink='/boost-conversion'
			/>
		</Page>
	) : <Skeleton />
}

export default SaleNotifications
