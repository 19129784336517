import Page from '@modules/page'
import {navigate} from "@reach/router"
import {Layout, ContextualSaveBar} from "@shopify/polaris"
import {ContextProvider} from "./container"
import TitleCard from "./components/title"
import DiscountCard from "./components/discount"
import ProductsCard from "./components/products"
import RecommendedProducts from "./components/recommned_products"
import StatusCard from "./components/status"
import {useCallback, useState} from "react"
import {useStore} from "./container"
import API from "@helpers/api"
import {showToast} from "@plugins/toast"
import {checkRequiredFields} from "@helpers/utils"
import { isEqual, omit } from 'lodash'
import DiscardModal from '../../components/discardModal'

const CreatePrePurchase = () => {
	return (
		<ContextProvider>
			<CreatePrePurchaseView />
		</ContextProvider>
	)
}

const CreatePrePurchaseView = () => {
	const [loading, setLoading] = useState(false)
	let [error, setError] = useState([])
	const {state} = useStore()
	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState(state)

	const isEqualState = isEqual(omit(state, ['updatedAt']), omit(prevState, ['updatedAt']))

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);
	const handleSave = async () => {
		const requiredFields = ['title']
		const {applies_to, recommend_to, target_products, target_collection, recommended_products, recommended_collection} = state
		if (applies_to === 'specific_products') {
			requiredFields.push('target_products')
		}
		if (applies_to === 'specific_collection') {
			requiredFields.push('target_collection')
		}
		if (recommend_to === 'specific_products') {
			requiredFields.push('recommended_products')
		}
		if (recommend_to === 'specific_collection') {
			requiredFields.push('recommended_collection')
		}
		error = await checkRequiredFields(requiredFields, state)
		if (error.length > 0) {
			setError(error)
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: 'Please fill in all the mandatory fields',
				error: true
			})
		}

		setLoading(true)
		API.create('pre-purchase', {
			...state,
			target_products: target_products?.filter(Boolean).map(p => p.value),
			target_collection: target_collection.value,
			recommended_products: recommended_products.map(p => p.value),
			recommended_collection: recommended_collection.value
		}).then(res => {
			console.log(res, 'res')
			if (res.ok) {
				setPrevState({
					...prevState, 
					...res.payload, 
					target_products: target_products, 
					target_collection: target_collection,
					recommended_products: recommended_products,
					recommended_collection: recommended_collection
				})
				navigate(`/pre-purchase/${res.payload._id}`)
				return showToast({
					message: 'Offer created'
				})
			}
		})
			.finally(() => setLoading(false))
	}

	return (
		<Page
			title="Create pre-purchase offer"
			breadcrumbs={[{content: 'Offers', onAction: () => isEqualState ? navigate('/pre-purchase') : handleModalChange()}]}
		>
			{!isEqualState && <ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: handleSave,
					loading: loading,
					disabled: false,
				}}
				discardAction={{
					onAction: () => handleModalChange(),
				}}
			/>}
			<Layout>
				<Layout.Section>
					<TitleCard error={error} />
					<ProductsCard error={error} />
					<RecommendedProducts error={error} />
				</Layout.Section>
				<Layout.Section secondary>
					<StatusCard />
					<DiscountCard />
				</Layout.Section>
			</Layout>
			<DiscardModal 
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink='/pre-purchase'
			/>
		</Page>
	)
}

export default CreatePrePurchase
