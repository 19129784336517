export const MAILCHIMP_TEMPLATE = 'mailchimp_template';
export const KLAVIYO_TEMPLATE = 'klaviyo_template';
export const SENDGRID_TEMPLATE = 'sendgrid_template';
export const POPUP = 'popup';
export const LUCKY_WHEEL = 'luckywheel';
export const BACK_IN_STOCK = 'bis';
export const ALL = '';
export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export const LAST_7_DAYS = 'last_7_days';
export const LAST_30_DAYS = 'last_30_days';
export const LAST_90_DAYS = 'last_90_days';
export const LAST_YEAR = 'last_year';
export const CUSTOM_TIME = 'custom_time';
export const SHOP_STATUS_ACTIVE = 'active';
export const SHOP_STATUS_INACTIVE = 'inactive';
export const SHOP_STATUS_REVOKE = 'revoked';
export const SHOP_STATUS_PENDING = 'pending';
export const SHOP_STATUS_EXPIRED = 'expired';

export const SHOP_SELECTED_KEY = 'foxhome-selected-shop';
export const MIN_LENGTH_PASSWORD = 8;

export const SCOPE_DESCRIPTION_MAPPING = {
  write_products: 'To update your products',
  read_products: 'To get list of products from your store',
  write_draft_orders: 'To update your draft orders',
  read_draft_orders: 'To get list of your draft orders',
  read_themes: 'To get themes you installed',
  write_themes: 'To install theme to your store',
  read_customers: 'To get customers of your store',
  write_customers: 'To update customers of your store',
  read_locales: 'To get list of locales',
  write_discounts: 'To update discounts of your store',
  read_discounts: 'To get discounts of your store',
};

export const FIREBASE_METHOD = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
  APPLE: 'APPLE',
  GITHUB: 'GITHUB',
};
export const DEBOUNCE_TIME = 250;
export const MAX_STORE = 50;
export const LIMIT_STORE = 10;

export const WHITE_LIST = [
  '/login',
  '/signup',
  '/forgot-password',
  '/reset-password',
  '/shopify-connect',
  '/review-access-request',
  '/access-verification',
];
export const THEME_NAME = {
  MINIMOG: 'minimog',
  MEGAMOG: 'megamog',
};

export const FREE_MEGAMOG_DATE = {
  start: new Date(2023, 10, 14),
  end: new Date(2024, 0, 1)
};
