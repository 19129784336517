import React from 'react';
import { Navigation } from '@shopify/polaris';
import {
  HomeMajor,
  SettingsMajor,
  DiscountCodeMajor,
  AddProductMajor,
  DiscountsMajor,
  DetailedPopUpMajor,
  QuickSaleMajor,
  SocialPostMajor,
  RepeatOrderMajor,
  CartMajor,
  ProductsMajor,
  ThemeEditMajor,
  BuyButtonButtonLayoutMajor,
  ConfettiMajor,
  ThemesMajor,
  ThemeStoreMajor,
  CustomersMajor,
  OrdersMajor,
  ProfileMajor,
  LanguageMinor,
  ExchangeMajor,
  VariantMajor,
  ManagedStoreMajor,
  IdentityCardMajor
} from '@shopify/polaris-icons';
import NavSection from '@modules/navSection';
import { useAuthStore } from '@container/Auth';
import { compareVersions } from '@helpers/utils';
const AppNavigation = ({ onNavigationToggle }) => {
  const { appType, currentTheme, currentUser } = useAuthStore();
  const isFoxHomeUser = appType === 'foxhome' && compareVersions('3.4.0', currentTheme?.theme_version) > 0;
  const hideAccountSettings = currentUser?.isGuest && currentUser?.havingPassword;
  return isFoxHomeUser ? (
    <Navigation location="/">
      <NavSection
        onNavigationToggle={onNavigationToggle}
        items={[
          {
            label: 'Dashboard',
            icon: HomeMajor,
            url: '/',
          },
        ]}
      />
      <NavSection
        onNavigationToggle={onNavigationToggle}
        separator
        title={'Upsells'}
        items={[
          {
            label: 'Product bundles',
            icon: AddProductMajor,
            url: '/bundles',
          },
          {
            label: 'Quantity discounts',
            icon: DiscountsMajor,
            url: '/quantity-discounts',
          },
          {
            label: 'Pre-purchase',
            icon: RepeatOrderMajor,
            url: '/pre-purchase',
          },
          {
            label: 'In-cart',
            icon: CartMajor,
            url: '/in-cart',
          },
          {
            label: 'Related products',
            icon: ProductsMajor,
            url: '/product-recommendation',
          },
        ]}
      />
      <NavSection
        onNavigationToggle={onNavigationToggle}
        title={'Sales boosting'}
        items={[
          {
            label: 'Variant group images',
            icon: VariantMajor,
            url: '/variant-group-images',
          },
          {
            label: 'Free shipping goal',
            icon: QuickSaleMajor,
            url: '/free-shipping-goal',
          },
          {
            label: 'Countdown tools',
            icon: DiscountCodeMajor,
            url: '/countdown-timer',
            subNavigationItems: [
              {
                label: 'Countdown timer',
                url: '/countdown-timer',
              },
              {
                label: 'Stock countdown',
                url: '/stock-countdown',
              },
              {
                label: 'Cart countdown',
                url: '/cart-countdown',
              },
            ],
          },
          {
            label: 'Sales notifications',
            icon: SocialPostMajor,
            url: '/sales-notifications',
          },
          {
            label: 'Lucky wheel',
            icon: ConfettiMajor,
            url: '/lucky-wheel',
          },
          {
            label: 'Popup',
            icon: DetailedPopUpMajor,
            url: '/popup',
          },
          {
            label: 'Size chart',
            icon: ThemeEditMajor,
            url: '/size-chart',
          },
          {
            label: 'Back in stock',
            icon: BuyButtonButtonLayoutMajor,
            url: '/back-in-stock',
            subNavigationItems: [
              {
                label: 'Subscriptions',
                url: '/back-in-stock',
              },
              {
                label: 'Settings',
                url: '/back-in-stock/settings',
              },
            ],
          },
          {
            label: 'Pre-order',
            icon: OrdersMajor,
            url: '/pre-order',
          },
        ]}
      />
      <NavSection
        onNavigationToggle={onNavigationToggle}
        title={'Theme manager'}
        items={[
          {
            label: 'Dashboard',
            icon: HomeMajor,
            url: '/',
          },
          {
            label: 'Install demo',
            url: '/theme/install',
            icon: ThemesMajor,
          },
          {
            label: 'Manage themes',
            url: '/theme/manage',
            icon: ThemeStoreMajor,
          },
          {
            label: 'Translations',
            url: '/theme/translations',
            icon: LanguageMinor,
          },
        ]}
      />
      <NavSection
        onNavigationToggle={onNavigationToggle}
        title={'Manage'}
        fill
        items={[
          {
            label: 'Leads',
            url: '/manage/leads',
            icon: CustomersMajor,
          },
          {
            label: 'Integrations',
            icon: ExchangeMajor,
            url: '/manage/integrations',
          },
          {
            label: 'Preferences',
            icon: SettingsMajor,
            url: '/manage/preferences',
          },
        ]}
      />
      <NavSection
        onNavigationToggle={onNavigationToggle}
        separator
        items={[
          hideAccountSettings
            ? {}
            : {
                label: 'Account settings',
                icon: ProfileMajor,
                url: '/settings/account',
              },
          {
            label: 'Stores manager',
            icon: ManagedStoreMajor,
            url: '/settings/stores',
          },
        ]}
      />
    </Navigation>
  ) : (
    <Navigation location="/">
      <NavSection
        onNavigationToggle={onNavigationToggle}
        items={[
          {
            label: 'Dashboard',
            icon: HomeMajor,
            url: '/',
          },
          {
            label: 'Install demo',
            url: '/theme/install',
            icon: ThemesMajor,
          },
          {
            label: 'Manage themes',
            url: '/theme/manage',
            icon: ThemeStoreMajor,
          },
        ]}
      />
      <NavSection
        onNavigationToggle={onNavigationToggle}
        separator
        items={[
          {
            label: 'Manage stores',
            icon: ManagedStoreMajor,
            url: '/settings/stores',
          },
          {
            label: 'Manage licenses',
            url: '/settings/licenses',
            icon: IdentityCardMajor,
          },
          hideAccountSettings
            ? {}
            : {
              label: 'Account settings',
              icon: ProfileMajor,
              url: '/settings/account',
            },
        ]}
      />
    </Navigation>
  );
};
export default AppNavigation;
