import { useAuthStore } from '@container/Auth';
import { useNotificationStore } from '@container/NotificationProvider';
import {
  SHOP_SELECTED_KEY,
  SHOP_STATUS_ACTIVE,
  SHOP_STATUS_EXPIRED,
  SHOP_STATUS_INACTIVE,
  SHOP_STATUS_PENDING,
  SHOP_STATUS_REVOKE,
} from '@helpers/constants';
import { titleCase } from '@helpers/utils';
import { navigate } from '@reach/router';
import { ActionList, Badge, Button, Popover, TextStyle, Link } from '@shopify/polaris';
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import { Fragment, useCallback, useState } from 'react';

export const STATUS_MAPPING = {
  [SHOP_STATUS_ACTIVE]: 'success',
  [SHOP_STATUS_INACTIVE]: '',
  [SHOP_STATUS_REVOKE]: 'critical',
  [SHOP_STATUS_PENDING]: 'attention',
  [SHOP_STATUS_EXPIRED]: '',
};

const ShopItem = ({ store, onDelete, onEdit, onAddPurchase, onUpdateStatus, index, storesDisplay }) => {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const { showConfirm } = useNotificationStore();
  const { currentUser } = useAuthStore();

  const { status, _id, domain, license, shop, name, appType, expiresAt } = store;
  const handleDelete = async () => {
    await onDelete(_id);
  };

  const handleUpdateStatus = async (newStatus) => {
    if (newStatus === SHOP_STATUS_ACTIVE) {
      if (onUpdateStatus) await onUpdateStatus(_id, newStatus);
      toggleActive();
    } else {
      showConfirm({
        title: `Deactivate this store `,
        confirmButtonText: 'Deactivate',
        danger: true,
        message: `Are you sure you want to deactivate this store.`,
        onConfirm: async () => {
          if (onUpdateStatus) await onUpdateStatus(_id, newStatus);
          if (localStorage.getItem(SHOP_SELECTED_KEY) === shop) navigate('/select-store');
          toggleActive();

          return Promise.resolve();
        },
      });
    }
  };

  const actionList = [
    status === 'active'
      ? {
          content: 'Edit',
          onAction: () => onEdit({ ...store }),
        }
      : null,
    status === 'active'
      ? {
          content: 'Deactivate',
          onAction: () => handleUpdateStatus(SHOP_STATUS_INACTIVE),
          destructive: true,
        }
      : null,
    status === 'inactive' && appType === 'foxhome'
      ? {
          content: 'Activate',
          onAction: () => onEdit({ ...store }),
        }
      : null,
    [SHOP_STATUS_REVOKE, SHOP_STATUS_INACTIVE, SHOP_STATUS_EXPIRED, SHOP_STATUS_PENDING].includes(status)
      ? {
          content: 'Delete',
          onAction: handleDelete,
          destructive: true,
        }
      : null,
  ].filter(Boolean);

  return (
    <Fragment key={_id}>
      <tr>
        <td>
          <Badge status={STATUS_MAPPING[status]}>{titleCase(status)}</Badge>
        </td>
        <td>{name}</td>
        <td>
          <Link url={`https://${domain}`} external>
            <TextStyle variation={'subdued'}>{domain}</TextStyle>
          </Link>
        </td>

        {!currentUser?.isGuest ? (
          <td style={{ textAlign: 'right' }}>
            <Popover active={active} activator={<Button plain onClick={toggleActive} icon={MobileVerticalDotsMajor} />} onClose={toggleActive}>
              <ActionList actionRole="menuitem" items={actionList} />
            </Popover>
          </td>
        ) : null}
      </tr>
    </Fragment>
  );
};

export default ShopItem;
