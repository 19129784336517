import { ChoiceList, DatePicker, Modal, Stack } from '@shopify/polaris'
import React, { useState, useEffect } from 'react'
import { getDate } from '@helpers/utils'
import XLSX from 'xlsx'
import { ExportSheet } from 'react-xlsx-sheet'
import axios from "@plugins/axios"
import { format, getMonth, getYear } from "date-fns"
import { ALL, BACK_IN_STOCK, CUSTOM_TIME, KLAVIYO_TEMPLATE, LAST_30_DAYS, LAST_7_DAYS, LAST_90_DAYS, LAST_YEAR, LUCKY_WHEEL, MAILCHIMP_TEMPLATE, POPUP, SENDGRID_TEMPLATE, TODAY, YESTERDAY } from '../../../helpers/constants'
const ExportCustomersList = ({handleModalChange, active, sourceFrom}) => {
  const [selectedExportDate, setSelectedExportDate] = useState(TODAY)
  const [selectedExportAs, setSelectedExportAs] = useState(MAILCHIMP_TEMPLATE)
  const [selectedExportSource, setSelectedExportSource] = useState([sourceFrom])
  const [inputData, setInputData] = useState()
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(new Date()),
    end: new Date(new Date()),
  });
  const [{ month, year }, setDate] = useState({ month: getMonth(new Date(new Date().setMonth(new Date().getMonth() - 1))), year: getYear(new Date()) });

  const exportButton = document.querySelector(".export-button")
  useEffect(() => {
    if (inputData !== undefined) {
      exportButton.click()
      handleModalChange()
    }
  }, [inputData])

  const handleTemplateData = (data, template) => {
    let exportedData = []
    let head
    if (template.toString() === MAILCHIMP_TEMPLATE || template.toString() === KLAVIYO_TEMPLATE) {
      head = [
        { title: "Email Address", dataIndex: "email" },
        { title: "First Name", dataIndex: "firstName" },
        { title: "Phone", dataIndex: "phone" }
      ]
    } else if (template.toString() === SENDGRID_TEMPLATE) {
      head = [
        { title: "email", dataIndex: "email" },
        { title: "first_name", dataIndex: "firstName" },
        { title: "phone_number", dataIndex: "phone" }
      ]
    }
    data.forEach(({ phone, email, firstName }) => {
      exportedData.push({ "email": email, "phone": phone, "firstName": firstName })
    })
    return { head: head, exportedData: exportedData }
  }
  const handleExportCustomers = () => {
    if (selectedExportDate.length > 0) {
      let time
      if (selectedExportDate === CUSTOM_TIME) {
        time = selectedDates
      } else {
        time = getDate(selectedExportDate)
      }
      axios(`/api/plugin/customer/export?startDate=${format(time?.start, "y-MM-dd")}&endDate=${format(time?.end, "y-MM-dd")}&source=${selectedExportSource.toString()}`)
        .then(res => {
          console.log(res)
          setInputData(handleTemplateData(res.payload, selectedExportAs))
        });
    }
  }
  return (
    <div>
      <Modal
        open={active}
        onClose={handleModalChange}
        title="Export customers list"
        primaryAction={{
          content: 'Export customers',
          onAction: handleExportCustomers,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <ExportSheet
            header={inputData?.head}
            fileName={"Foxkit_subscribers"}
            dataSource={inputData?.exportedData}
            xlsx={XLSX}
            extName="csv"
          >
            <button hidden className="export-button"></button>
          </ExportSheet>
          <Stack vertical>
            <Stack.Item>
              <ChoiceList
                title="Data source"
                choices={[
                  { label: 'All', value: ALL },
                  { label: 'Popup', value: POPUP },
                  { label: 'Lucky wheel', value: LUCKY_WHEEL },
                  { label: 'Back in stock', value: BACK_IN_STOCK }
                ]}
                selected={selectedExportSource}
                onChange={v => {
                  setSelectedExportSource(v)
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <ChoiceList
                title="Choose file template"
                choices={[
                  { label: 'MailChimp', value: MAILCHIMP_TEMPLATE },
                  { label: 'Klaviyo', value: KLAVIYO_TEMPLATE },
                  { label: 'Sendgrid', value: SENDGRID_TEMPLATE },
                ]}
                selected={selectedExportAs}
                onChange={v => {
                  setSelectedExportAs(v)
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <ChoiceList
                title="Time range"
                choices={[
                  { label: 'Today', value: TODAY },
                  { label: 'Yesterday', value: YESTERDAY },
                  { label: 'Last 7 days', value: LAST_7_DAYS },
                  { label: 'Last 30 days', value: LAST_30_DAYS },
                  { label: 'Last 90 days', value: LAST_90_DAYS },
                  { label: 'Last year', value: LAST_YEAR },
                  { label: 'Custom time', value: CUSTOM_TIME },
                ]}
                selected={selectedExportDate}
                onChange={v => {
                  let getValue = v.toString()
                  setSelectedExportDate(getValue)
                  if (getValue !== CUSTOM_TIME) {
                  setSelectedDates(getDate(getValue))
                }
                }}
              />
            </Stack.Item>
            <Stack.Item>
              {selectedExportDate === CUSTOM_TIME && <DatePicker
                month={month}
                year={year}
                onChange={setSelectedDates}
                onMonthChange={(month, year) => setDate({ month, year })}
                selected={selectedDates}
                allowRange
                multiMonth
              />}
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </div>
  )
}
export default ExportCustomersList
