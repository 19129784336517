import {Thumbnail} from "@shopify/polaris"
import NoImage from '@assets/no-image.svg'
import styled from 'styled-components'

const Wrapper = styled.div`
	img {
		padding: 5px;
	}
`

const ThumbnailImg = ({source, size, alt = ''}) => {
	return <Wrapper>
		<Thumbnail source={source ? source : NoImage} alt={alt} size={size || undefined} />
	</Wrapper>
}

export default ThumbnailImg
