import styled from "styled-components"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import {InlineError} from "@shopify/polaris"
import {generateRandomID} from "@helpers/utils"
import React from "react"

const Picker = styled.div`
	.react-datepicker-wrapper {
		width: 100%;
	}
	.input-wrapper {
		position: relative;
		&.showTimeSelect {
			.react-datepicker {
			 	width: auto;
        display: flex;
			}
		}
	}
	.react-datepicker {
		font-size: 1.1rem;
		width: 210px;
		font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	}
  .react-datepicker-time__header, .react-datepicker__current-month {
    font-size: 14px;
    font-weight: 600;
  }
	.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
	.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
		background-color: var(--p-action-primary); 
	}
	.react-datepicker__time-list-item {
    padding: 8px;
    white-space: nowrap;
    font-size: 14px;
	}
	.react-datepicker__time-container {
		width: 110px;
		.react-datepicker__time-box {
			width: 100% !important;
		}
	}
	.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
		right: 105px;
	}
	.react-datepicker-popper {
		z-index: 22;
	}
	.react-datepicker__day--selected {
		color: #fff;
	}
	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    font-size: 14px;
    width: 34px;
    height: 32px;
    line-height: 32px;
  }
  .react-datepicker__day-name {
    font-size: 13px;
  }
`

const DateTimePicker = (props) => {
	let classes = ['input-wrapper', 'Polaris-TextField']
	if (props.error) classes.push('Polaris-TextField--error')
	if (props.showTimeSelect) classes.push('showTimeSelect')
	return (
		<Picker>
			{props.label && (
				<div className="Polaris-Labelled__LabelWrapper">
					<div className="Polaris-Label">
						<label className="Polaris-Label__Text">{props.label}</label>
					</div>
				</div>
			)}
			<div className={classes.join(" ")}>
				<DatePicker
					{...props}
					className={'Polaris-TextField__Input'}
				/>
				<div className="Polaris-TextField__Backdrop" />
			</div>
			{props.error && <div style={{marginTop: 10, '--p-icon': 'rgba(215, 44, 13, 1)'}}><InlineError message={`${props.label} must be added`} fieldID={generateRandomID()} /></div>}
		</Picker>
	)
}

export default DateTimePicker
