export const UNAUTHORIZED = 'UNAUTHORIZED';
export const UNAUTHORIZED_SHOP = 'UNAUTHORIZED_SHOP';
export const EMAIL_EXISTED = 'EMAIL_EXISTED';
export const EMAIL_NOT_EXISTED = 'EMAIL_NOT_EXISTED';
export const SHOP_DOMAIN_INVALID = 'SHOP_DOMAIN_INVALID';
export const SHOP_ACCESS_TOKEN_INVALID = 'SHOP_ACCESS_TOKEN_INVALID';
export const PURCHASE_CODE_INVALID = 'PURCHASE_CODE_INVALID';
export const TOKEN_INVALID = 'TOKEN_INVALID';
export const OLD_PASSWORD_INVALID = 'OLD_PASSWORD_INVALID';
export const SHOP_CONNECTED_TO_OTHER_ACCOUNT = 'SHOP_CONNECTED_TO_OTHER_ACCOUNT';
export const MISSING_ACCESS_SCOPE = 'MISSING_ACCESS_SCOPE';
export const NOT_SELECTED_SHOP = 'NOT_SELECTED_SHOP';
export const SHOP_NOT_ACTIVE = 'SHOP_NOT_ACTIVE';
export const SHOP_NOT_FOUND = 'SHOP_NOT_FOUND';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const ERR_INVALID_CHAR = 'ERR_INVALID_CHAR';
export const FIREBASE_CLOSE_POP_UP = 'auth/popup-closed-by-user';
export const FIREBASE_CANCEL_POPUP = 'auth/cancelled-popup-request';
export const FIREBASE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const ACCOUNT_EXISTED = 'ACCOUNT_EXISTED';

export const FIREBASE_ERROR_CODE_WHITELIST = [FIREBASE_CLOSE_POP_UP, FIREBASE_CANCEL_POPUP];

export const ERROR_MAPPING = {
  [EMAIL_EXISTED]: 'That email already exists. Try another or login',
  [SHOP_NOT_FOUND]: 'Shop not found',
  [SHOP_ACCESS_TOKEN_INVALID]: 'Access token invalid, please check again.',
  [FIREBASE_ACCOUNT_EXISTS]: 'Account already exists with different credential.',
  [SHOP_NOT_ACTIVE]: 'Shop not active.'
};
