import ResourcePicker from "@components/resourcePicker";
import { Card, ChoiceList, FormLayout, Subheading, Select, TextContainer, TextStyle } from "@shopify/polaris";
import { useStore } from "../container";

const RecommendedProducts = ({ error }) => {
  const { state, dispatch } = useStore()
  const { recommend_to, recommended_collection, recommended_products, collection_sort_key } = state

  const handleChange = (key, value) => {
    dispatch({ type: 'setData', payload: { [key]: value } })
  }

  return (
    <Card>
      <Card.Section subdued title="Recommended products">
        <TextStyle variation="subdued">These products will appear in the recommendation section under the main product information.</TextStyle>
      </Card.Section>
      <Card.Section subdued>
        <FormLayout>
          <ChoiceList
            choices={[
              { label: 'Specific products', value: 'specific_products' },
              { label: 'Specific collection', value: 'specific_collection' },
            ]}
            selected={recommend_to}
            onChange={v => handleChange('recommend_to', v[0])}
          />
          {recommend_to === 'specific_products' && (
            <ResourcePicker
              label={'Specific products'}
              labelHidden
              isMulti
              resourceType={'product'}
              value={recommended_products}
              onChange={v => handleChange('recommended_products', v)}
              isClearable
              error={error.includes('recommended_products')}
              placeholder={'Search products'}
              maxLength={20}
              helpText={'Maximum 20 products'}
            />
          )}
          {recommend_to === 'specific_collection' && <>
            <ResourcePicker
              label={'Specific collection'}
              labelHidden
              resourceType={'collection'}
              value={recommended_collection}
              onChange={v => handleChange('recommended_collection', v)}
              isClearable
              error={error.includes('recommended_collection')}
              placeholder={'Search collection'}
            />
            <div style={{ marginTop: '2rem' }}>
              <TextContainer spacing="tight">
                <Subheading>Recommend by</Subheading>
                <p style={{ color: 'var(--p-text-subdued)' }}>
                  Products from the selected collection will be recommended by
                </p>
                <Select
                  options={[
                    { label: 'Default', value: 'default' },
                    { label: 'Best selling', value: 'best_selling' },
                    { label: 'Newest', value: 'newest' },
                    { label: 'Oldest', value: 'oldest' },
                    { label: 'Randomly', value: 'randomly' },
                  ]}
                  onChange={v => handleChange('collection_sort_key', v)}
                  value={collection_sort_key}
                />
              </TextContainer>
            </div>
          </>}
        </FormLayout>
      </Card.Section>
    </Card>
  )
}

export default RecommendedProducts
