import { Pagination } from '@shopify/polaris';
import { sortQuery, useStore } from '../container';
const PaginationComponent = ({ products, getProducts, pageInfo }) => {
  const { state } = useStore();
  const { filter } = state;
  const { sort } = filter;

  const handleChange = async (key) => {
    let variables;

    const sortValue = sortQuery(sort);

    if (key === 'hasPreviousPage') {
      variables = {
        last: 10,
        before: products[0].cursor,
        sortKey: sortValue.sortBy,
        reverse: sortValue.reverse,
        query: { ...filter },
      };
    }

    if (key === 'hasNextPage') {
      variables = {
        first: 10,
        after: products[products.length - 1].cursor,
        sortKey: sortValue.sortBy,
        reverse: sortValue.reverse,
        query: { ...filter },
      };
    }

    await getProducts(variables);
  };
  return (
    <div
      style={{
        margin: '15px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Pagination
        hasPrevious={pageInfo.hasPreviousPage}
        onPrevious={() => {
          handleChange('hasPreviousPage');
        }}
        hasNext={pageInfo.hasNextPage}
        onNext={() => {
          handleChange('hasNextPage');
        }}
      />
    </div>
  );
};

export default PaginationComponent;
