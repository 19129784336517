import { Card, FormLayout, Thumbnail, Heading } from "@shopify/polaris"
import { useStore } from "../container"

const ProductCard = () => {
  const { state } = useStore()
  const { product_image_url, title, variant_title, product_id, variant_id, shop } = state

  return (
    <Card title="Product information" sectioned>
      <FormLayout>
        <a
          href={`https://${shop}/admin/products/${product_id}/variants/${variant_id}`}
          target="_blank"
          style={{ display: "flex" }}
        >
          <Thumbnail
            source={product_image_url}
            size="large"
          />
          <div style={{ marginLeft: '16px' }}>
            <Heading>{title}</Heading>
            <p>{variant_title}</p>
          </div>
        </a>
      </FormLayout>
    </Card>
  )
}

export default ProductCard
