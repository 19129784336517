import {useEffect, useState, useCallback} from 'react'
import Page from '@modules/page'
import {Layout, Card, ResourceItem, TextStyle} from "@shopify/polaris"
import DataList from "@modules/dataList"
import {navigate} from "@reach/router"
import {SkeletonList} from "@modules/skeleton"
import StatusBadge from "@components/statusBadge"
import {CancelToken} from "@plugins/axios"
import {getURLParams, discountMap} from "@helpers/utils"
import API from "@helpers/api"
import {showConfirm} from "@plugins/confirm"
import {showToast} from "@plugins/toast"
import FoxifyCard from "@components/foxifyCard";

const BundlesList = () => {
	const [update, setUpdate] = useState(true)
	const [state, setState] = useState({ready: false, pageInfo: {}, isSearched: false})
	const [items, setItems] = useState([])
	const [selectedItems, setSelectedItems] = useState([])
	const source = CancelToken.source()
	useEffect(() => {
		const params = getURLParams()
		fetchData(params)
		return () => source.cancel()
	}, [update])
	const toggleUpdate = useCallback(() => {
		setUpdate((update) => !update)
		setSelectedItems([])
	}, [])
	const fetchData = async ({searchQuery, page = 1, ...filters}) => {
		const params = getURLParams()
		const newParams = {
			...params,
			query: searchQuery,
			page: page,
			...filters
		}
		let result
		try {
			result = await API.getList('bundle', newParams, source.token)
			if (result) {
				const {payload} = result
				setState({...state, ...result, ready: true, isSearched: true})
				setItems(payload.map(i => {
					return {
						id: i._id,
						...i
					}
				}))
			}
		} catch (error) {
			console.log(error)
		}
	}
	const handleSearch = (query) => fetchData({query})

	const handleBulkDelete = () => {
		showConfirm({
			title: `Delete ${selectedItems.length} bundle(s)?`,
			message: 'Are you sure? This can\'t be undone.',
			confirm: 'Delete',
			cancel: 'Cancel',
			danger: true
		}).then(res => {
			if (res) {
				API.bulkDelete('bundle', selectedItems).then((res) => {
					console.log(res)
					if (res.ok) {
						showToast({
							message: 'Bundle(s) deleted'
						})
						toggleUpdate()
					} else {
						showToast({
							message: 'Cannot delete, please try again',
							error: true
						})
					}
				})
			}
		})
		return false
	}

	const handleBulkUpdate = (status = false) => {
		API.bulkUpdate('bundle', {
			ids: selectedItems,
			active: status
		}).then((res) => {
			console.log(res)
			if (res.ok) {
				showToast({
					message: 'Bundle(s) updated!'
				})
				toggleUpdate()
			} else {
				showToast({
					message: 'Cannot update, please try again',
					error: true
				})
			}
		})
		return false
	}

	const resourceName = {
		singular: 'bundle',
		plural: 'bundles'
	}

	const promotedBulkActions = [
		{
			content: 'Set as active',
			onAction: () => {
				showConfirm({
					title: `Set ${selectedItems.length} bundle(s) as active?`,
					message: 'Setting bundle(s) as active will make them available to your store.',
					confirm: 'Set as active',
					cancel: 'Cancel'
				}).then(res => res && handleBulkUpdate(true))
			}
		},
		{
			content: 'Set as inactive',
			onAction: () => {
				showConfirm({
					title: `Set ${selectedItems.length} bundle(s) as inactive?`,
					message: 'Setting bundle(s) as inactive will hide them from your store.',
					confirm: 'Set as inactive',
					cancel: 'Cancel'
				}).then(res => res && handleBulkUpdate(false))
			}
		}
	]

	const bulkActions = [
		{
			content: 'Delete bundle(s)',
			onAction: handleBulkDelete
		}
	]
	const headers = [
		{
			name: 'Status',
			width: '25%'
		},
		{
			name: 'Discount',
			width: '25%'
		}
	]

	const {ready, pageInfo} = state

	return ready ? (
		<>
			<Page
				title="Product bundles"
				subtitle={'Sell more products by suggesting your customer an attractive combo in the product details page.'}
				primaryAction={{
					content: "Create bundle",
					onAction: () => navigate("/bundles/new")
				}}
				breadcrumbs={[{content: 'Cross-sell', onAction: () => navigate('/upsells')}]}
			>
				<Layout>
					<Layout.Section>
						<Card sectioned>
							<DataList
								fetchData={fetchData}
								resourceName={resourceName}
								items={items}
								renderItem={renderItem}
								selectedItems={selectedItems}
								onSelectionChange={setSelectedItems}
								promotedBulkActions={promotedBulkActions}
								bulkActions={bulkActions}
								headers={headers}
								handleSearch={handleSearch}
								pageInfo={pageInfo}
							/>
						</Card>
					</Layout.Section>
				</Layout>
			</Page>
		</>
	) : <SkeletonList/>
}

function renderItem(item) {
	const {_id, title, active, active_discount, discount} = item
	return (
		<ResourceItem
			id={_id}
			accessibilityLabel={`View details for ${title}`}
			persistActions
			onClick={() => navigate(`/bundles/${_id}`)}
		>
			<div className="resource-item__wrapper">
				<div className="title">
					<TextStyle variation="strong">{title}</TextStyle>
				</div>
				<div className="column" style={{width: '25%'}}>
					<StatusBadge status={active}/>
				</div>
				<div className="column" style={{width: '25%'}}>
					{active_discount ? discountMap(discount.type, discount.value) : 'None'}
				</div>
			</div>
		</ResourceItem>
	)
}

export default BundlesList
