import InputField from '@components/inputField';
import { useAuthStore } from '@container/Auth';
import { MIN_LENGTH_PASSWORD } from '@helpers/constants';
import { OLD_PASSWORD_INVALID } from '@helpers/error.constant';
import { ERROR_MAPPING_MESSAGE } from '@plugins/axios';
import { showToast } from '@plugins/toast';
import { updatePasswordService } from '@services/user';
import { Form, FormLayout, Modal } from '@shopify/polaris';
import { useCallback, useMemo, useState } from 'react';

const FORM_DATA_PASSWORD_KEY = 'password';
const FORM_DATA_NEW_PASSWORD_KEY = 'newPassword';
const FORM_DATA_VERIFY_NEW_PASSWORD_KEY = 'verifyNewPassword';

const validate = (currentUser, formData) => {
  let error = {};

  if (!currentUser?.havingPassword || formData[FORM_DATA_PASSWORD_KEY] === undefined) error[FORM_DATA_PASSWORD_KEY] = undefined;
  else if (formData[FORM_DATA_PASSWORD_KEY] === '') error[FORM_DATA_PASSWORD_KEY] = 'Data required';
  else error[FORM_DATA_PASSWORD_KEY] = undefined;

  if (formData[FORM_DATA_NEW_PASSWORD_KEY] === undefined) error[FORM_DATA_NEW_PASSWORD_KEY] = undefined;
  else if (formData[FORM_DATA_NEW_PASSWORD_KEY] === '') error[FORM_DATA_NEW_PASSWORD_KEY] = 'Data required';
  else error[FORM_DATA_NEW_PASSWORD_KEY] = undefined;

  if (formData[FORM_DATA_VERIFY_NEW_PASSWORD_KEY] === undefined) error[FORM_DATA_VERIFY_NEW_PASSWORD_KEY] = undefined;
  else if (formData[FORM_DATA_VERIFY_NEW_PASSWORD_KEY] === '') error[FORM_DATA_VERIFY_NEW_PASSWORD_KEY] = 'Data required';
  else if (formData[FORM_DATA_NEW_PASSWORD_KEY] && formData[FORM_DATA_NEW_PASSWORD_KEY] !== formData[FORM_DATA_VERIFY_NEW_PASSWORD_KEY])
    error[FORM_DATA_VERIFY_NEW_PASSWORD_KEY] = 'Verify password does not match to new password';
  else error[FORM_DATA_VERIFY_NEW_PASSWORD_KEY] = undefined;

  if (formData[FORM_DATA_NEW_PASSWORD_KEY]?.length < MIN_LENGTH_PASSWORD)
    error[FORM_DATA_NEW_PASSWORD_KEY] = `Your password must have at least ${MIN_LENGTH_PASSWORD} characters`;

  return error;
};

export default function PasswordModal({ handleChange }) {
  const { currentUser, updateCurrentUser } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    [FORM_DATA_PASSWORD_KEY]: undefined,
    [FORM_DATA_NEW_PASSWORD_KEY]: undefined,
    [FORM_DATA_VERIFY_NEW_PASSWORD_KEY]: undefined,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [error, setError] = useState({
    [FORM_DATA_PASSWORD_KEY]: false,
    [FORM_DATA_NEW_PASSWORD_KEY]: false,
    [FORM_DATA_VERIFY_NEW_PASSWORD_KEY]: false,
  });

  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);
  const toggleShowNewPassword = useCallback(() => setShowNewPassword(!showNewPassword), [showNewPassword]);

  const handleChangeFormData = (value, key) => {
    setError({
      [FORM_DATA_PASSWORD_KEY]: false,
      [FORM_DATA_NEW_PASSWORD_KEY]: false,
      [FORM_DATA_VERIFY_NEW_PASSWORD_KEY]: false,
    });
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const disableButtonUpdate = useMemo(() => {
    if (loading) return true;
    if (!formData?.[FORM_DATA_NEW_PASSWORD_KEY] || !formData?.[FORM_DATA_VERIFY_NEW_PASSWORD_KEY]) return true;

    return false;
  }, [loading, formData]);

  const handleUpdatePassword = async () => {
    const validateResult = validate(currentUser, formData);

    setError(validateResult);

    if (Object.values(validateResult).filter(Boolean).length) return;

    setLoading(true);

    try {
      await updatePasswordService({
        oldPassword: formData[FORM_DATA_PASSWORD_KEY],
        newPassword: formData[FORM_DATA_NEW_PASSWORD_KEY],
      });

      showToast({
        message: 'Success',
      });
      updateCurrentUser({ havingPassword: true });

      handleChange && handleChange();
    } catch (error) {
      if (error?.code === OLD_PASSWORD_INVALID) {
        setError({
          [FORM_DATA_PASSWORD_KEY]: ERROR_MAPPING_MESSAGE?.[OLD_PASSWORD_INVALID],
        });
      } else {
        showToast({
          message: 'Fail',
          error: true,
        });
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      open={true}
      onClose={handleChange}
      title="Update your password"
      primaryAction={{
        content: 'Update',
        loading,
        submit: true,
        disabled: disableButtonUpdate,
        onAction: handleUpdatePassword,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleChange,
          plain: true,
        },
      ]}
    >
      <Modal.Section>
        <Form onSubmit={handleUpdatePassword}>
          <FormLayout>
            {currentUser?.havingPassword && (
              <InputField
                label="Current password"
                onChange={(v) => handleChangeFormData(v, FORM_DATA_PASSWORD_KEY)}
                autoComplete="off"
                type={'password'}
                value={formData[FORM_DATA_PASSWORD_KEY]}
                error={error[FORM_DATA_PASSWORD_KEY]}
              />
            )}
            <FormLayout.Group condensed>
              <InputField
                label="New password"
                onChange={(v) => handleChangeFormData(v, FORM_DATA_NEW_PASSWORD_KEY)}
                autoComplete="off"
                value={formData[FORM_DATA_NEW_PASSWORD_KEY]}
                type={'password'}
                error={error[FORM_DATA_NEW_PASSWORD_KEY]}
              />
              <InputField
                label="Verify new password"
                onChange={(v) => handleChangeFormData(v, FORM_DATA_VERIFY_NEW_PASSWORD_KEY)}
                autoComplete="off"
                value={formData[FORM_DATA_VERIFY_NEW_PASSWORD_KEY]}
                error={error[FORM_DATA_VERIFY_NEW_PASSWORD_KEY]}
                type="password"
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
}
