import React, { useEffect, useState } from 'react';
import { getThemeList, installTheme, verifyTheme } from '@helpers/shopifyApi';
import { showToast } from '@plugins/toast';
import { Modal, TextContainer, Banner, FormLayout, Select, Badge } from '@shopify/polaris';
import { compareVersions } from '@helpers/utils';
import { THEME_NAME } from '../../helpers/constants';

const Verify = ({ open, handleChange, listedThemes = [], themesMap = [] }) => {
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [verified, setVerified] = useState(false);
  const [available, setAvailable] = useState(true);
  const [themeList, setThemeList] = useState([]);

  useEffect(() => {
    if (open) handleFetchThemes();
  }, [open]);

  const handleFetchThemes = async () => {
    const res = await getThemeList();
    if (res.ok && res.payload) {
      let themes = [];
      if (listedThemes?.length) {
        themes = res.payload.filter(({ id }) => !listedThemes.find(({ shopify_theme_id: _id }) => Number(_id) === id));
      } else {
        themes = res.payload;
      }
      setThemeList(themes);
      setSelectedId(themes[0]?.id);
    }
  };

  const handleMapVersion = (theme) => {
    console.log('themesMap:', themesMap, theme);
    const found = themesMap?.find(
      (t) =>
        (t.namespace === THEME_NAME.MEGAMOG && theme?.namespace === THEME_NAME.MEGAMOG && t.os_version === theme.os_version) ||
        (t.evanto_theme_id === theme.evanto_theme_id && t.os_version === theme.os_version),
    );
    return {
      ...theme,
      new_version: found?.version,
    };
  };

  const handleVerify = () => {
    const selectedTheme = themeList.find((theme) => theme.id === parseInt(selectedId));
    console.log(selectedTheme, themeList);
    setLoading(true);
    verifyTheme(selectedTheme)
      .then((res) => {
        if (res.ok) {
          setVerified(true);
          const selected = handleMapVersion(res.theme_data);
          setSelectedTheme(selected);
          const compared = compareVersions(selected.version, selected.new_version);
          setAvailable(compared > 0);
        }
      })
      .catch((error) => {
        console.log(error);
        showToast({
          error: true,
          message: error.message,
        });
      })
      .finally(() => setLoading(false));
  };

  const handleUpdate = () => {
    console.log('hello');
    setLoading(true);

    installTheme({
      demo_id: selectedTheme?.demo_id,
      role: 'unpublished',
      namespace: selectedTheme?.namespace,
      evanto_theme_id: selectedTheme?.evanto_theme_id,
      version: selectedTheme?.new_version,
      os_version: selectedTheme?.os_version,
      update: true,
      import_data_from: selectedTheme?.shopify_theme_id,
    })
      .then((res) => {
        if (res.ok) {
          showToast({
            message: 'Theme updated!',
          });
        } else {
          showToast({
            message: res.message,
            error: true,
          });
        }
      })
      .catch((error) => {
        showToast({
          message: error.message,
          error: true,
        });
      })
      .finally(() => {
        setLoading(false);
        handleChange();
      });
  };

  console.log(selectedTheme, 'selected theme');
  return (
    <Modal
      open={open}
      onClose={!loading ? handleChange : void 0}
      title={`Update unlisted themes`}
      primaryAction={{
        content: verified ? 'Continue update' : 'Verify',
        onAction: verified ? handleUpdate : handleVerify,
        disabled: !selectedId || !available,
        loading: loading,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleChange,
          disabled: loading,
          plain: true,
        },
      ]}
    >
      {!verified && (
        <Modal.Section>
          <FormLayout>
            <Select
              label={'Select theme'}
              value={selectedId + ''}
              onChange={(v) => setSelectedId(v)}
              options={themeList.map((theme) => {
                return {
                  label: theme.name,
                  value: theme.id + '',
                };
              })}
            />
            <TextContainer>
              <p>Select a theme that you want to update from the list and click Verify button</p>
            </TextContainer>
          </FormLayout>
        </Modal.Section>
      )}
      {verified && selectedTheme && (
        <Modal.Section>
          <TextContainer>
            <p>
              <strong>Theme name:</strong> {selectedTheme?.display_name}
            </p>
            <p>
              <strong>Theme version:</strong> {selectedTheme?.version}
            </p>
            <p>
              <strong>OS version:</strong> {selectedTheme?.os_version}
            </p>
            <p>
              <strong>Status:</strong> {selectedTheme?.role}
            </p>
            {available && (
              <p>
                <strong>Available to update:</strong> <Badge status={'info'}>{selectedTheme?.new_version}</Badge>
              </p>
            )}
            <Banner status={'info'}>
              {available ? (
                <TextContainer>
                  <p>
                    When update, we <strong>don't replace</strong> the original theme, we will <strong>duplicate</strong> your current theme settings
                    and apply them to the new version.
                  </p>
                  <p>
                    The updated theme is made available as an <strong>unpublished theme</strong>, you can publish it after checking carefully.
                  </p>
                </TextContainer>
              ) : (
                <TextContainer>There is no new version to update!</TextContainer>
              )}
            </Banner>
          </TextContainer>
        </Modal.Section>
      )}
    </Modal>
  );
};

export default Verify;
