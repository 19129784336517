import FoxifyCard from "@components/foxifyCard";
import React, {useState, useEffect, useCallback} from 'react'
import {navigate} from "@reach/router"
import Page from '@modules/page'
import {Layout, Card, ResourceItem, TextStyle} from "@shopify/polaris"
import DataList from "@modules/dataList"
import {CancelToken} from "@plugins/axios"
import {getURLParams, updateParam} from "@helpers/utils"
import API from "@helpers/api"
import {SkeletonList} from "@modules/skeleton"
import StatusBadge from "@components/statusBadge"
import {showToast} from "@plugins/toast"
import {showConfirm} from "@plugins/confirm"

const SizeChartList = () => {
	const [update, setUpdate] = useState(true)
	const [state, setState] = useState({ready: false, pageInfo: {}, isSearched: false})
	const [items, setItems] = useState([])
	const [selectedItems, setSelectedItems] = useState([])
	const source = CancelToken.source()
	const [getParams, setGetParams] = useState({})

	useEffect(() => {
		const params = getURLParams()
		fetchData(params)
		return () => source.cancel()
	}, [update])

	const toggleUpdate = useCallback(() => {
		setUpdate((update) => !update)
		setSelectedItems([])
	}, [])

	const fetchData = async ({searchQuery, page = 1, ...filters}) => {
		const params = getURLParams()
		const newParams = {
			...params,
			query: searchQuery,
			page: page,
			...filters
		}
		setGetParams(newParams)
		let result
		try {
			result = await API.getList('size-chart', newParams, source.token)
			if (result) {
				setState({...state, ...result, ready: true, isSearched: true})
				setItems(result.payload.map(i => {
					return {
						id: i._id,
						...i
					}
				}))
			}
		} catch (error) {
			console.log(error)
		}
	}

	const handleSearch = (query) => fetchData({query})

	const handleBulkDelete = () => {
		showConfirm({
			title: `Delete ${selectedItems.length} size chart(s)?`,
			message: 'Are you sure? This can\'t be undone.',
			confirm: 'Delete',
			cancel: 'Cancel',
			danger: true
		}).then(res => {
			if (res) {
				API.bulkDelete('size-chart', selectedItems).then((res) => {
					console.log(res)
					if (res.ok) {
						showToast({
							message: 'Bundle(s) deleted'
						})
						toggleUpdate()
					} else {
						showToast({
							message: 'Cannot delete, please try again',
							error: true
						})
					}
				})
			}
		})
		return false
	}

	const handleBulkUpdate = (status = false) => {
		API.bulkUpdate('size-chart', {
			ids: selectedItems,
			active: status
		}).then((res) => {
			console.log(res)
			if (res.ok) {
				showToast({
					message: 'Bundle(s) deleted'
				})
				toggleUpdate()
			} else {
				showToast({
					message: 'Cannot delete, please try again',
					error: true
				})
			}
		})
		return false
	}

	const resourceName = {
		singular: 'size chart',
		plural: 'size charts'
	}

	const promotedBulkActions = [
		{
			content: 'Set as active',
			onAction: () => {
				showConfirm({
					title: `Set ${selectedItems.length} size chart(s) as active?`,
					message: 'Setting size chart(s) as active will make them available to your store.',
					confirm: 'Set as active',
					cancel: 'Cancel'
				}).then(res => res && handleBulkUpdate(true))
			}
		},
		{
			content: 'Set as inactive',
			onAction: () => {
				showConfirm({
					title: `Set ${selectedItems.length} size chart(s) as inactive?`,
					message: 'Setting size chart(s) as inactive will hide them from your store.',
					confirm: 'Set as inactive',
					cancel: 'Cancel'
				}).then(res => res && handleBulkUpdate(false))
			}
		}
	]

	const bulkActions = [
		{
			content: 'Delete size chart(s)',
			onAction: handleBulkDelete
		}
	]

	const headers = [
		{
			name: 'Status',
			width: '25%',
		},
		{
			name: 'Displays on',
			width: '25%',
		}
	]
	const {ready, pageInfo} = state
	return ready ? (
		<Page
			title="Size chart"
			subtitle={'Improve your conversion rates with different size charts for various types of products.'}
			primaryAction={{
				content: "Create size chart",
				onAction: () => navigate("/size-chart/new")
			}}
			breadcrumbs={[{content: 'Boost conversion', onAction: () => navigate('/boost-conversion')}]}
		>
			<Layout>
				<Layout.Section>
					<Card sectioned>
						<DataList
							getParams={getParams}
							fetchData={fetchData}
							resourceName={resourceName}
							items={items}
							renderItem={renderItem}
							selectedItems={selectedItems}
							onSelectionChange={setSelectedItems}
							promotedBulkActions={promotedBulkActions}
							bulkActions={bulkActions}
							headers={headers}
							handleSearch={handleSearch}
							pageInfo={pageInfo}
						/>
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	) : <SkeletonList />
}

function renderItem(item) {
	const {id, title, active, applies_to} = item

	const applies_to_map = {
		all_products: "All products",
		specific_products: "Specific products",
		specific_collection: 'Specific collection'
	}

	return (
		<ResourceItem
			id={id}
			accessibilityLabel={`View details for ${title}`}
			persistActions
			onClick={() => navigate(`/size-chart/${id}`)}
		>
			<div className="resource-item__wrapper">
				<div className="title">
					<TextStyle variation="strong">{title}</TextStyle>
				</div>
				<div className="column" style={{width: '25%'}}>
					<StatusBadge status={active} />
				</div>
				<div className="column" style={{width: '25%'}}>
					{applies_to_map[applies_to]}
				</div>
			</div>
		</ResourceItem>
	)
}

export default SizeChartList
