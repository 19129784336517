import {createContext, useContext, useReducer} from 'react'
export const initialState = {
	active: true,
	created_at: '',
	updated_at: '',
	id: '',
	title: '🔥 Hurry up! Sale ends in:',
	duration: 20,
	expires_date: new Date(),
	cdt_type: 'fixed_time',
	cdt_style: 'style-1',
	applies_to: 'all_products',
	target_products: [],
	target_collection: '',
	schedule: false,
	total_quantity: 100,
	sold_from: 3,
	sold_to: 15,
	show_flashsale: false,
	schedule_time: new Date(),
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}
export const useStore = () => useContext(Context)

export const filterPassedTime = (time) => {
	const currentDate = new Date()
	const selectedDate = new Date(time)

	return currentDate.getTime() < selectedDate.getTime()
}
