import LogoLight from '@assets/foxhome-logo-w.svg';
import LogoDark from '@assets/foxhome-logo.svg';
import AppFrame from '@components/appFrame';
import NotificationProvider from '@container/NotificationProvider';
import variables from '@helpers/variables';
import AddStorePage from '@pages/addStore';
import InstallPage from '@pages/install';
import StoreSelector from '@pages/storeSelector';
import Confirmation, { alertRef } from '@plugins/confirm';
import ToastFlash, { toastRef } from '@plugins/toast';
import { Router } from '@reach/router';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import mixpanel from 'mixpanel-browser';
import { ThemeProvider } from 'styled-components';
import AuthProvider from './container/Auth';
import AccessVerification from './pages/access-verification';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import ReviewStoreAccess from './pages/review-store-access';
import ShopifyConnect from './pages/shopify-connect';
import Login from './pages/signin';
import Signup from './pages/signup';
import AppRoutes from './routes';

if (variables.MIXPANEL_TOKEN) {
  mixpanel.init(variables.MIXPANEL_TOKEN, {
    debug: variables.NODE_ENV === 'development',
  });
  mixpanel.track('View app');
}

const theme = {
  primary: 'rgba(0, 128, 96, 1)',
  secondary: '#43467f',
  border: 'rgba(80, 83, 86, 1)',
  overlay: 'rgba(33, 33, 33, 0.5)',
};

const appTheme = {
  logo: {
    width: 150,
    topBarSource: LogoDark,
    contextualSaveBarSource: LogoLight,
    accessibilityLabel: 'FoxHome by FoxEcom',
    url: '/',
  },
};

function MyApp() {
  return (
    <AppProvider i18n={translations} theme={appTheme}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <NotificationProvider>
            <Router>
              <Login path="/login" />
              <ShopifyConnect path="/shopify-connect" />
              <Signup path="/signup" />
              <ForgotPassword path="/forgot-password" />
              <ResetPassword path="/reset-password" />
              <AddStorePage path="/add-store" />
              <InstallPage path={'/install'} />
              <StoreSelector path={'/select-store'} />
              <ReviewStoreAccess path="/review-access-request" />
              <AccessVerification path="/access-verification" />
            </Router>
            <AppFrame>
              <ToastFlash ref={toastRef} />
              <Confirmation ref={alertRef} />
              <AppRoutes />
            </AppFrame>
          </NotificationProvider>
        </AuthProvider>
      </ThemeProvider>
    </AppProvider>
  );
}

export default MyApp;
