import React from 'react'
import {Router} from "@reach/router"
import SizeChartsList from "@pages/size-chart"
import CreateSizeChart from "@pages/size-chart/create"
import EditSizeChart from "@pages/size-chart/edit"

export default function SizeChartPages() {
	return (
		<Router>
			<SizeChartsList path="/" />
			<CreateSizeChart path="/new" />
			<EditSizeChart path="/:pageID" />
		</Router>
	)
}
