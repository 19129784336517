import React from 'react'
import {Router} from "@reach/router"
import InCartsList from "@pages/in-cart"
import CreateInCart from "@pages/in-cart/create"
import EditInCart from "@pages/in-cart/edit"

export default function InCartPages() {
	return (
		<Router>
			<InCartsList path="/" />
			<CreateInCart path="/new" />
			<EditInCart path="/:pageID" />
		</Router>
	)
}
