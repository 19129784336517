import {createContext, useContext, useReducer} from 'react'

export const initialState = {
	active: false,
	title: '{{name}} has purchased!',
	time: '{{time}} ago - {{location}}',
	names: 'Enrique West, Damien Braun, Ellie Osborne, Cierra Vega, Alden Cantrell, Kierra Gentry, Pierre Cox, Thomas Crane',
	locations: 'Morgantown United States, Kuttu Federated States of Micronesia, Wuhu China, East Quincy United States, Raumbach Germany, Wuyang China, Tondela Portugal, N\'Djamena Chad, Yanbei China, Quangang China, Le Pallet France, Servon-sur-Vilaine France, Tumut Australia, Goodman United States, Keluke China, Rauchenwarth Austria, Harran Turkey',
	target_to: 'collection',
	target_products: [],
	target_collection: '',
	show_on_mobile: true,
	display_time: 5,
	delay_time: '10-15',
	delay_show: 5,
	position: 'bottom-left',
	max_show: 20,
	updated_at: '',
}

const Context = createContext(initialState)

export const ContextProvider = ({children}) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'setData':
				return {
					...state,
					...action.payload
				}
			default:
				throw new Error()
		}

	}, initialState)

	return (
		<Context.Provider value={{state, dispatch}}>{children}</Context.Provider>
	)
}

export const useStore = () => useContext(Context)
