import {Modal, TextField, TextStyle, Link} from "@shopify/polaris"

const AddModal = ({handleToggle, handleSave, platformState, handleChange, saving}) => {
	const helpText = platformState.platform === "omnisend"
		? <p>Don't have an api key? Please follow <Link url={platformState.helper_url} external>the instruction</Link> for more details. <TextStyle variation='warning'>Attention: you need to select second option to get API KEY.</TextStyle></p>
		: <p>Don't have an api key? Please follow <Link url={platformState.helper_url} external>instruction</Link> for more details.</p>
	return (
		<Modal
			title={platformState?.label}
			open={true}
			onClose={handleToggle}
			primaryAction={{
				content: 'Add',
				onAction: handleSave,
				loading: saving,
			}}
		>
			<Modal.Section>
				<TextField
					label="API KEY"
					value={platformState.api_key}
					onChange={(v) => handleChange({ ...platformState, api_key: v })}
					autoComplete="off"
					autoFocus
					helpText={helpText}
				/>
			</Modal.Section>
		</Modal>
	)
}
export default AddModal
