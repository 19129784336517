import {Card, Checkbox, ChoiceList, FormLayout, TextField, TextStyle} from "@shopify/polaris"
import {useStore} from "../container"
import {useAuthStore} from "@container/Auth"

const DiscountCard = () => {
	const {state, dispatch} = useStore()
	const {discount, active_discount} = state

	const handleChange = (key, value) => {
		dispatch({type: 'setData', payload: {[key]: value}})
	}

	const {currency_symbol = '$'} = useAuthStore()

	return (
		<Card title={'Offer’s discount'}>
			<Card.Section>
				<FormLayout>
					<TextStyle variation={"subdued"}>This discount will be applied when the selected product is purchased.</TextStyle>
					<Checkbox
						label={'Activate discount'}
						checked={active_discount}
						onChange={v => handleChange('active_discount', v)}
					/>
					{active_discount && (
						<div style={{paddingTop: '0.5rem'}}>
							<FormLayout>
								<ChoiceList
									title={'Discount type'}
									choices={[
										{label: 'Percentage', value: 'PERCENTAGE'},
										{label: 'Fixed amount', value: 'FIXED_AMOUNT'}
									]}
									selected={discount.type}
									onChange={v => handleChange('discount', {...discount, type: v[0]})}
								/>
								<FormLayout.Group>
									<TextField
										label={'Discount value'}
										value={discount.value + ''}
										type={'number'}
										suffix={discount.type === 'PERCENTAGE' ? '%' : undefined}
										prefix={discount.type === 'FIXED_AMOUNT' ? currency_symbol : undefined}
										onChange={v => handleChange('discount', {...discount, value: Number(v)})}
										helpText={discount.type === 'PERCENTAGE' ? `${discount.value}% OFF on each product` : `${currency_symbol}${discount.value} OFF on each product`}
									/>
									<div />
								</FormLayout.Group>
							</FormLayout>
						</div>
					)}
				</FormLayout>
			</Card.Section>
			<Card.Section subdued title={'CAN’T COMBINE WITH OTHER DISCOUNTS YOU CREATED IN SHOPIFY ADMIN'}>
				<p>
					Customers won’t be able to enter a code if an Fox Kit discount is already applied in their cart.
				</p>
			</Card.Section>
		</Card>
	)
}

export default DiscountCard
