import { addPurchaseCodeService } from '@services/license';
import { Button, DisplayText, Form, FormLayout, Link, TextContainer, TextField, TextStyle } from '@shopify/polaris';
import React, { useState } from 'react';
import {REACT_APP_PURCHASE_URL} from '@helpers/env'
import UserLayout from "@modules/userLayout";

function PurchaseCode({ shop }) {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await addPurchaseCodeService(shop, code);
      window.location.href = '/';
    } catch (error) {
      setError(error?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <UserLayout title={'Connect your first store'}>
        <div className="form-container">
          <div className="form-header">
            <TextContainer>
              <DisplayText size={'extraLarge'}><strong>Activate Minimog purchase code</strong></DisplayText>
              <p className={'Polaris-DisplayText Polaris-DisplayText--sizeSmall Polaris-DisplayText--mobile'}>Add your purchase code to unlock all the powerful
                <br /> features of FoxHome. <a
                  href="https://docs.minimog.co/getting-started/purchase-code-and-activation"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more.
                </a></p>
            </TextContainer>
          </div>
          <div className="form-content">
            <Form onSubmit={handleSubmit}>
              <FormLayout>
                <TextField
                  value={code}
                  onChange={(value) => {
                    setError('');
                    setCode(value);
                  }}
                  placeholder={'Enter your purchase code'}
                  label="Purchase code"
                  labelAction={{
                    content: 'How to get',
                    url: 'https://help.market.envato.com/hc/en-us/articles/202822600-Where-Is-My-Purchase-Code-',
                    external: true
                  }}
                  error={error}
                />
                <Button primary loading={loading} submit fullWidth={true} size="large">
                  Activate
                </Button>
                <div className="form-footer">
                  <DisplayText size={'small'}>
                    Dont have it?{' '}
                    <Link url={REACT_APP_PURCHASE_URL} external>
                      <strong>Buy one here</strong>
                    </Link>
                  </DisplayText>
                </div>
                <div
                  className="buy-later"
                  onClick={() => {
                    window.location.href = '/';
                  }}
                >
                  <TextStyle variation="subdued">I will add later</TextStyle>
                </div>
              </FormLayout>
            </Form>
          </div>
        </div>
      </UserLayout>
    </>
  );
}

export default PurchaseCode;
