import {Card} from "@shopify/polaris"
import ProductCard from "@pages/dashboard/components/productCard";
import { useEffect, useState } from "react";
import axios from "@plugins/axios";

export default function ArticleCard() {
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])

  useEffect(() => {
    axios.get(`/api/strapi/fox-products`).then(response => {
      if (response && response?.payload) setProducts(response?.payload?.filter(i => i.type === 'theme'))
    }).catch(err => console.log(err))
    .finally(() => setLoading(false))
  }, [])


  console.log(products, 'products')
	return (
		<Card
			title={'Our Shopify themes'}
      sectioned
		>
			<div className={'flex'} style={{gap: '3rem'}}>
        {products.map(product => {
          return (
            <ProductCard
              key={product.id}
              title={product.title}
              url={product.link}
              learn_more={product.learn_more}
              img={product.image}
              content={product.content}
            />
          )
        })}
			</div>
		</Card>
	)
}
