import styled from 'styled-components'
import { TextField, FormLayout, Page, Layout, Card, Button, Form } from "@shopify/polaris"
import { useEffect, useState, useCallback } from 'react'

const InstallView = styled.div`
	display: flex;
	align-items: center;
	padding-top: 300px;
	overflow: hidden;
`

const InstallPage = () => {
	const [domain, setDomain] = useState('')
	const [shopOrigin, setShopOrigin] = useState('')

	useEffect(() => {
		document.title = 'Installation'
		document.body.style.overflow = 'hidden'
		document.body.classList.add('install-page')
	}, [])

	const handleSubmit = useCallback((evt) => {
		evt.target.submit()
	}, [])

	return (
		<InstallView>
			<Page>
				<Layout>
					<Layout.Section>
						<Form action="/auth" method="GET" onSubmit={handleSubmit}>
							<Card sectioned>
								<FormLayout>
									<h3 className={'text-center text-4xl font-bold'}>Enter your shop domain to install.</h3>
									<TextField
										label={'Shop domain'}
										labelHidden
										value={domain}
										placeholder={'your-store'}
										suffix={'.myshopify.com'}
										onChange={v => {
											setDomain(v)
											setShopOrigin(v + '.myshopify.com')
										}}
									/>
									<input type="hidden" name="shop" value={shopOrigin} />
									<div className={'text-center'}>
										<Button primary submit>Install</Button>
									</div>
								</FormLayout>
							</Card>
						</Form>
					</Layout.Section>
				</Layout>
			</Page>
		</InstallView>
	)
}

export default InstallPage
