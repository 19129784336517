import React from 'react'
import {Router} from "@reach/router"
import ImagesVariantsGrouping from '@pages/images-variants-group'
import Variants from '../pages/images-variants-group/components/variants'

export default function ImagesVariantsGroup() {
	return (
		<Router>
			<ImagesVariantsGrouping path="/" />
			<Variants path="/:id" />
		</Router>
	)
}
