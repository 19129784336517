import InputField from '@components/inputField';
import { useAuthStore } from '@container/Auth';
import { DEBOUNCE_TIME, LIMIT_STORE, MAX_STORE, SHOP_SELECTED_KEY, SHOP_STATUS_ACTIVE } from '@helpers/constants';
import axios from '@plugins/axios';
import { showToast } from '@plugins/toast';
import { navigate } from '@reach/router';
import { getAllShopService } from '@services/shop';
import { ActionList, Badge, Button, ButtonGroup, Icon, Popover, Scrollable, TopBar } from '@shopify/polaris';
import { LogOutMinor, ProfileMinor, QuestionMarkMajor, SearchMinor, StoreMinor } from '@shopify/polaris-icons';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSignIn from 'src/hooks/useSignIn';
import styled from 'styled-components';
import Loading from './loading';
import { useMedia } from '@shopify/react-hooks';
import { truncate } from '@helpers/utils';

const TopBarWrapper = styled.div`
  .Polaris-TopBar__SearchField {
    width: auto;
  }
  .Polaris-TopBar__SecondaryMenu {
    padding-right: 2rem;

    @media (max-width:450px) {
      padding-right: 0;
    }
  }
  .store-selector {
    padding: 0 10px;
    .Polaris-Icon svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const PopoverWrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .Popover__BottomAction {
    margin: 0 16px 16px;
  }
  .Popover__EmptyField {
    padding-left: 16px;
    padding-bottom: 16px;
  }
`;
const ShopifyLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height={20} viewBox="0 0 25 28" fill="none">
    <path
      d="M21.8956 5.39159C21.876 5.25021 21.751 5.17175 21.6473 5.16321C21.5443 5.15466 19.5277 5.12436 19.5277 5.12436C19.5277 5.12436 17.8411 3.50548 17.6745 3.3408C17.5079 3.17611 17.1825 3.22583 17.056 3.26312C17.0544 3.26389 16.7392 3.36022 16.2087 3.52257C16.1199 3.23826 15.9895 2.88869 15.8032 2.53757C15.2028 1.40498 14.3233 0.806052 13.2608 0.804499C13.2592 0.804499 13.2584 0.804499 13.2568 0.804499C13.183 0.804499 13.1099 0.81149 13.036 0.817704C13.0046 0.780417 12.9731 0.743907 12.9401 0.708173C12.4772 0.21878 11.8838 -0.0197018 11.1726 0.00127219C9.80038 0.0401129 8.43367 1.01968 7.32553 2.75974C6.5459 3.984 5.95253 5.52209 5.78435 6.71295C4.20858 7.19535 3.10673 7.53249 3.08237 7.54026C2.28702 7.78728 2.26187 7.81137 2.15813 8.55245C2.08111 9.11253 0 25.0186 0 25.0186L17.4403 28L24.9992 26.1426C24.9992 26.1426 21.9153 5.53297 21.8956 5.39159ZM15.3356 3.7898C14.934 3.91253 14.4774 4.05236 13.9822 4.20384C13.972 3.51714 13.8895 2.56165 13.5657 1.7359C14.607 1.93088 15.1195 3.09532 15.3356 3.7898ZM13.0698 4.48349C12.1558 4.76315 11.1584 5.06843 10.158 5.3745C10.4393 4.30949 10.973 3.24913 11.6284 2.55388C11.8721 2.29521 12.2131 2.00701 12.6171 1.84232C12.9967 2.62535 13.0792 3.73387 13.0698 4.48349ZM11.2001 0.903931C11.5223 0.896939 11.7935 0.966853 12.0253 1.11755C11.6544 1.30787 11.296 1.58131 10.9596 1.93787C10.088 2.86228 9.42 4.29706 9.15357 5.68134C8.32286 5.93536 7.51022 6.18472 6.76203 6.4131C7.23436 4.23414 9.08205 0.964522 11.2001 0.903931Z"
      fill="#95BF47"
    />
    <path
      d="M21.6481 5.16394C21.5451 5.15539 19.5284 5.1251 19.5284 5.1251C19.5284 5.1251 17.8419 3.50622 17.6752 3.34153C17.6132 3.28016 17.5291 3.24831 17.441 3.23511L17.4418 27.9992L25 26.1426C25 26.1426 21.916 5.5337 21.8964 5.39232C21.8768 5.25094 21.751 5.17248 21.6481 5.16394Z"
      fill="#5E8E3E"
    />
    <path
      d="M13.2514 9.00525L12.3735 12.2516C12.3735 12.2516 11.3942 11.8111 10.2334 11.8833C8.53115 11.9898 8.51307 13.0509 8.53036 13.3173C8.6231 14.7692 12.4875 15.0862 12.7044 18.487C12.8749 21.1624 11.2685 22.9926 8.95397 23.1371C6.17576 23.3103 4.64636 21.6906 4.64636 21.6906L5.23501 19.2157C5.23501 19.2157 6.77462 20.3638 8.00694 20.2869C8.81172 20.2364 9.09937 19.5893 9.07029 19.1318C8.94926 17.2379 5.80245 17.3498 5.60361 14.2379C5.43621 11.6192 7.17623 8.96563 11.0154 8.72637C12.4945 8.63238 13.2514 9.00525 13.2514 9.00525Z"
      fill="white"
    />
  </svg>
);

export default function AppTopBar({ onNavigationToggle }) {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { shop_domain, license_active, plan_name, updateShopData, currentUser, storeList, name } = useAuthStore();
  const { handleLogout } = useSignIn();
  const [active, setActive] = useState(false);
  const isSmallScreen = useMedia('(max-width: 530px)');
  const isNarrowScreen = useMedia('(min-width: 768px) and (max-width: 875px)');
  const hideAccountSettings = currentUser?.isGuest && currentUser?.havingPassword;

  const activeStoreList = useMemo(() => {
    return storeList?.filter((store) => store?.status === SHOP_STATUS_ACTIVE);
  }, [storeList]);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toggleIsUserMenuOpen = useCallback(() => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen), []);

  const fullName = (currentUser?.firstName || '') + (currentUser?.lastName || '');
  const avatar = currentUser?.avatar || '';

  const userMenuMarkup = (
    <TopBar.UserMenu
      name={fullName}
      avatar={avatar}
      initials={fullName?.charAt(0)}
      open={isUserMenuOpen}
      detail={currentUser?.email}
      onToggle={toggleIsUserMenuOpen}
      actions={[
        !hideAccountSettings
          ? {
              items: [{ content: 'Manage account', icon: ProfileMinor, onAction: () => navigate('/settings/account') }],
            }
          : null,
        {
          items: [{ content: 'Manage stores', icon: StoreMinor, onAction: () => navigate('/settings/stores') }],
        },
        {
          items: [{ content: 'Help center', icon: QuestionMarkMajor, onAction: () => window.open('https://docs.foxecom.com/foxhome', '_blank') }],
        },
        {
          items: [{ content: 'Logout', icon: LogOutMinor, destructive: true, onAction: handleLogout }],
        },
      ].filter(Boolean)}
    />
  );

  const showSearchStore = activeStoreList?.length > LIMIT_STORE;

  const activator = (
    <div className='shop-domain__name-wrapper'>
      {isSmallScreen || isNarrowScreen ? (
        <Button outline icon={ShopifyLogo} onClick={toggleActive} disclosure>
          {truncate(axios.defaults.headers.common['X-Request-Shop-Origin']) || 'Select a shop'}
        </Button>
      ) : (
        <Button outline icon={ShopifyLogo} onClick={toggleActive} disclosure>
          {axios.defaults.headers.common['X-Request-Shop-Origin'] || 'Select a shop'}
        </Button>
      )}
    </div>
  );

  const secondaryMenuMarkup = (
    <ButtonGroup>
      <Button plain url={'https://foxecom.com'} external>
        foxecom.com
      </Button>
      <Button plain url={'https://themeforest.net/item/minimog-the-high-converting-shopify-theme/33380968'} external>
        Minimog theme
      </Button>
      <Button plain url={'https://themes.shopify.com/themes/zest'} external>
        Zest theme
      </Button>
      <Button plain url={'https://apps.shopify.com/foxify-builder'} external>
        Foxify app
      </Button>
      <Button plain url={'https://foxecom.link/foxkit'} external>
        FoxKit app
      </Button>
      <Button plain url={'https://foxecom.com/pages/contact-us'} external>
        Contact us
      </Button>
      <div className={'store-selector'}>
        <Popover fluidContent active={active} autofocusTarget={showSearchStore ? 'first-node' : 'none'} activator={activator} onClose={toggleActive}>
          <ActiveStoreList showSearchStore={showSearchStore} stores={activeStoreList} toggleActive={toggleActive} />
        </Popover>
      </div>
    </ButtonGroup>
  );
  return (
    <TopBarWrapper>
      <div id={'user-info'} className={'hidden'} hidden>
        <span className="shop-domain">{shop_domain}</span>
        <span className="shopify-plan">{plan_name}</span>
        <span className="license-activated">{license_active ? 'true' : 'false'}</span>
      </div>
      <TopBar showNavigationToggle userMenu={userMenuMarkup} secondaryMenu={secondaryMenuMarkup} onNavigationToggle={onNavigationToggle} />
    </TopBarWrapper>
  );
}

const ActiveStoreList = ({ showSearchStore, toggleActive, stores }) => {
  const [storesDisplay, setStoresDisplay] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const { currentUser } = useAuthStore();

  const handleSearch = (newValue) => {
    setSearchValue(newValue);
    setLoading(true);
    debounceSearch(newValue);
  };

  const debounceSearch = useCallback(
    debounce(async (query) => {
      try {
        let newList;
        if (!query) {
          newList = stores;
        } else {
          newList = await getAllShopService({ query });
        }
        setStoresDisplay(newList);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showToast({
          message: error?.message,
          error: true,
        });
      }
    }, DEBOUNCE_TIME),
    [stores],
  );

  useEffect(() => {
    if (JSON.stringify(stores) !== JSON.stringify(storesDisplay)) {
      setStoresDisplay(stores?.slice(0, MAX_STORE) || []);
    }
  }, [stores]);

  const handleSelectShop = async (shopData) => {
    if (axios.defaults.headers.common['X-Request-Shop-Origin'] === shopData?.shop) {
      toggleActive();
      return;
    }
    localStorage.setItem(SHOP_SELECTED_KEY, shopData?.shop);
    axios.defaults.headers.common['X-Request-Shop-Origin'] = shopData?.shop;
    window.location.href = '/';
  };

  const handleAddNewStore = () => {
    navigate('/settings/stores');
    toggleActive();
  };

  return (
    <PopoverWrapper>
      {showSearchStore && (
        <Popover.Section>
          <InputField
            autoFocus={true}
            prefix={<Icon source={SearchMinor} />}
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search your store domain..."
          />
        </Popover.Section>
      )}

      <Scrollable
        shadow
        style={{
          position: 'relative',
          width: '310px',
          maxHeight: '292px',
          padding: 'var(--p-space-2) 0',
          borderBottomLeftRadius: 'var(--p-border-radius-2)',
          borderBottomRightRadius: 'var(--p-border-radius-2)',
          overflowX: 'hidden',
        }}
      >
        {loading ? (
          <Loading.Inner size={80} />
        ) : storesDisplay?.length ? (
          <ActionList
            actionRole="menuitem"
            items={storesDisplay?.map((item) => ({
              content: (
                <>
                  {item?.name} {item?.appType === 'foxkit' && <Badge>{item.appType}</Badge>}
                </>
              ),
              helpText: item?.shop,
              icon: ShopifyLogo,
              active: axios.defaults.headers.common['X-Request-Shop-Origin'] === item?.shop,
              onAction: () => handleSelectShop(item),
            }))}
          />
        ) : (
          <div className="Popover__EmptyField">No stores found</div>
        )}
        <div className="Popover__BottomAction">
          <Button onClick={handleAddNewStore} fullWidth>
            Manage stores
          </Button>
        </div>
      </Scrollable>
    </PopoverWrapper>
  );
};
