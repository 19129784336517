import FoxifyCard from '@components/foxifyCard';
import API from '@helpers/api';
import { formatDate, getURLParams } from '@helpers/utils';
import DataList from '@modules/dataList';
import Page from '@modules/page';
import { SkeletonList } from '@modules/skeleton';
import { CancelToken } from '@plugins/axios';
import { showConfirm } from '@plugins/confirm';
import { showToast } from '@plugins/toast';
import { navigate } from '@reach/router';
import { Avatar, Card, Layout, ResourceItem, TextStyle } from '@shopify/polaris';
import { ExportMinor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import ExportSubscriptionList from './components/modal';
const BackInStockList = () => {
  const [update, setUpdate] = useState(true);
  const [state, setState] = useState({ ready: false, pageInfo: {}, isSearched: false });
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [active, setActive] = useState(false);
  const source = CancelToken.source();
  useEffect(() => {
    const params = getURLParams();
    fetchData(params);
    return () => source.cancel();
  }, [update]);

  const toggleUpdate = useCallback(() => {
    setUpdate((update) => !update);
    setSelectedItems([]);
  }, []);

  const fetchData = async ({ searchQuery, page = 1, ...filters }) => {
    const params = getURLParams();
    const newParams = {
      ...params,
      query: searchQuery,
      page: page,
      ...filters,
    };
    let result;
    try {
      result = await API.getList('back-in-stock', newParams, source.token);
      console.log(result);
      if (result) {
        const { payload } = result;
        setState({ ...state, ...result, ready: true, isSearched: true });
        setItems(
          payload.map((i) => {
            return {
              id: i._id,
              ...i,
            };
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (query) => fetchData({ query });

  const handleModalChange = () => setActive((prev) => !prev);

  const handleBulkDelete = () => {
    showConfirm({
      title: `Delete ${selectedItems.length} subscription(s)?`,
      message: "Are you sure? This can't be undone.",
      confirm: 'Delete',
      cancel: 'Cancel',
      danger: true,
    }).then((res) => {
      if (res) {
        API.bulkDelete('back-in-stock', selectedItems).then((res) => {
          console.log(res);
          if (res.ok) {
            showToast({
              message: 'Subscription(s) deleted',
            });
            toggleUpdate();
          } else {
            showToast({
              message: 'Cannot delete, please try again',
              error: true,
            });
          }
        });
      }
    });
    return false;
  };

  const resourceName = {
    singular: 'subscription',
    plural: 'subscriptions',
  };

  const promotedBulkActions = [
    {
      content: 'Delete subscription(s)',
      onAction: handleBulkDelete,
    },
  ];

  const headers = [
    {
      name: 'Subscribed at',
      width: '25%',
    },
    {
      name: 'Customer',
      width: '25%',
    },
  ];

  const { ready, pageInfo } = state;

  return ready ? (
    <Page title="Back in stock subscriptions" secondaryActions={[{ content: 'Export', icon: ExportMinor, onAction: handleModalChange }]}>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <DataList
              fetchData={fetchData}
              resourceName={resourceName}
              items={items}
              renderItem={renderItem}
              selectedItems={selectedItems}
              onSelectionChange={setSelectedItems}
              promotedBulkActions={promotedBulkActions}
              bulkActions={[]}
              headers={headers}
              handleSearch={handleSearch}
              pageInfo={pageInfo}
            />
          </Card>
        </Layout.Section>
      </Layout>
      {active && <ExportSubscriptionList handleModalChange={handleModalChange} />}
    </Page>
  ) : (
    <SkeletonList />
  );
};

function renderItem(item) {
  const { _id, title, product_image_url, variant_title, customer, createdAt } = item;
  return (
    <ResourceItem
      id={_id}
      accessibilityLabel={`View details for ${title}`}
      persistActions
      media={<Avatar customer size="medium" name={title} source={product_image_url} />}
      onClick={() => navigate(`/back-in-stock/${_id}`)}
    >
      <div className="resource-item__wrapper">
        <div className="title">
          <TextStyle variation="strong">{title}</TextStyle>
          {variant_title && (
            <div>
              <TextStyle variation="subdued">{variant_title}</TextStyle>
            </div>
          )}
        </div>
        <div className="column" style={{ width: '25%' }}>
          {formatDate(createdAt, true)}
        </div>
        <div className="column" style={{ width: '25%' }}>
          {customer?.firstName} ({customer?.email})
        </div>
      </div>
    </ResourceItem>
  );
}

export default BackInStockList;
