import { Button, ResourceList, TextStyle, Thumbnail } from '@shopify/polaris'
import { ImageMajor } from '@shopify/polaris-icons'
import React from 'react'
import styled from 'styled-components'
import { useStore } from '../container'

const Wrapper = styled.div`
	position: relative;
	.item__wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.title {
			flex: 1;
			padding-left: 56px;
		}
	}
	.Polaris-ResourceItem__OwnedNoMedia + .Polaris-ResourceItem__Content .title {
		padding-left: 0;
	}
	.column {
		width: 25%;
	}
	.empty {
		padding-top: 100px;
	}
	.Polaris-ResourceItem__Media {
    display: flex;
	position: absolute;
    left: 56px;
    top: 50%;
    transform: translateY(-50%);
	}
  .Polaris-Stack {
    position: absolute;
    top: 10px;
    right: 50px;
  }

  .Polaris-Thumbnail {
    border: none;
  }
  .quantity-display {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .selection {
      margin-right: 1rem;
  }
`

const Header = styled.div`
    padding: 1rem 2rem 1rem calc(2rem + 40px);
    position: absolute;
    top: 1.6rem;
    width: 100%;
    z-index: 11;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    span {
      text-align: left;
      font-weight: 500;
      white-space: nowrap;
      border: 0;
    }
  `
const DataList = ({ headers, selectedVariants, setSelectedVariants, setOpen, variantsGroup, setSelectedSingleVariant, selectedSingleVariant }) => {
    const { state } = useStore()
    const { variantsState } = state

    const promotedBulkActions = [
        {
            content: 'Update images',
            onAction: () => {
                setOpen(true)
            },
        }
    ];
    const renderItem = (item) => {
        const variantId = item.id
        const filterVariant = { ...item, images: item.images.filter(image => image.sourceUrl) }
        const media = item && (
            <>
                <Thumbnail
                    source={filterVariant.images[0]?.sourceUrl ? filterVariant.images[0]?.sourceUrl : ImageMajor}
                    size="small"
                />
                {filterVariant.images.length > 1 && <div className='Polaris-Thumbnail Polaris-Thumbnail--sizeSmall"'>
                    <span className='quantity-display'>+{filterVariant.images.length - 1}</span>
                </div>}
            </>
        )
        return (
            <ResourceList.Item
                id={variantId}
                media={media}
                onClick={() => {
                    setOpen(true)
                    setSelectedSingleVariant(variantId)
                }}
                accessibilityLabel={`View details for ${item?.title}`}
            >
                <div className="item__wrapper">
                    {item.variantItem?.selectedOptions.map((selected, key) => (
                        <div className="column" key={key}>
                            <h3>
                                <TextStyle variation="strong">{selected.value}</TextStyle>
                            </h3>
                        </div>
                    ))}
                </div>
            </ResourceList.Item>
        );
    }
    const handleSelect = (v) => {
        const selectedOptions = variantsState.filter(variant => {
            return variant.variantItem.selectedOptions.some(select => select.value === v)
        })
        const selectedVariants = selectedOptions.map(item => item.id)
        setSelectedVariants(selectedVariants)
    }
    return (
        <Wrapper>
            {variantsGroup.length > 0 &&
                <>
                    <span><TextStyle variation='strong'>Select: </TextStyle></span>
                    <span className='selection'><Button plain onClick={() => setSelectedVariants(variantsState.map(item => item.id))}>All</Button></span>
                    <span className='selection'><Button plain onClick={() => setSelectedVariants([])}>None</Button></span>
                    {variantsGroup.map((item, key) => (
                        <span className='selection' key={key}><Button plain onClick={() => handleSelect(item)}>{item}</Button></span>
                    ))}
                </>}
            <div style={{ paddingTop: '1rem', position: "relative" }}>
                {headers && headers.length > 0 && variantsState.length > 0 && !selectedVariants.length && <Header>
                    {!selectedVariants.length && headers.map((item, index) => {
                        return (
                            <div key={index} style={{ width: "25%" }}>
                                <span>{item.name}</span>
                            </div>
                        )
                    })}
                </Header>}
                <ResourceList
                    items={variantsState ? variantsState : []}
                    selectedItems={selectedVariants}
                    onSelectionChange={(v) => {
                        setSelectedVariants(v)
                    }}
                    promotedBulkActions={promotedBulkActions}
                    renderItem={renderItem}
                />
            </div>
        </Wrapper>
    )
}

export default DataList
