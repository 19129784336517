import { Button, ButtonGroup, Link } from "@shopify/polaris";
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown';

const PostCard = styled.div`
  flex: 1;
  line-height: 24px;
  .Polaris-MediaCard {
    gap: 2rem;
    display: flex;
    flex-flow: column;
    height: auto;
    padding-bottom: 2rem;
  }
  .Polaris-Heading {
    font-size: 18px;
  }
	.Polaris-MediaCard__MediaContainer {
			border-radius: var(--p-border-radius-wide);
      width: 100%;
    img {
      height: 230px;
    }
	}
	.Polaris-Card {
		border-bottom: none !important;
	}
`

export default function ProductCard({url, img, title, content, learn_more, cta = 'Try FREE now'}) {
  return (
    <PostCard>
      <div className="Polaris-MediaCard items-center">
        <div className="Polaris-MediaCard__MediaContainer">
          <a className={'block'} target={"_blank"} href={`${url}?utm_source=FoxHome&utm_medium=recommended+themes&utm_campaign=in-app-recommend`}>
            <img src={img}  width={'100%'} />
          </a>
        </div>
        <div className="Polaris-MediaCard__InfoContainer">
          <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingTight">
            <div className="Polaris-Stack__Item">
              <div className="Polaris-MediaCard__Heading">
                <h2 className="Polaris-Heading">{title}</h2>
                {/*<p style={{marginTop: 5}}>By <Link url={'https://foxecom.com?utm_source=FoxHome&utm_medium=recommended+themes&utm_campaign=in-app-recommend'}>FoxEcom</Link></p>*/}
              </div>
            </div>
            <div className="Polaris-Stack__Item" style={{paddingRight: 20}}>
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
            <div className="Polaris-Stack__Item">
              <div className="Polaris-MediaCard__ActionContainer">
                <ButtonGroup>
                  <Button primary external url={`${url}?utm_source=FoxHome&utm_medium=recommended+themes&utm_campaign=in-app-recommend`}>{cta}</Button>
                  <Button plain external url={`${learn_more}?utm_source=FoxHome&utm_medium=recommended+themes&utm_campaign=in-app-recommend`}>Learn more</Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PostCard>
  )
}