import React, {useEffect, useState} from 'react'
import AsyncSelect from 'react-select/async'
import {components} from 'react-select'
import styled from 'styled-components'
import {generateRandomID} from "@helpers/utils"
import {getImageSizeHardCrop} from "@helpers/utils"
import {CancelToken} from "@plugins/axios"
import ShopifyAPI from "@helpers/shopifyApi"
import debounce from "debounce-promise"
import Thumbnail from "@components/thumbnail"
import {InlineError} from "@shopify/polaris"

const SelectWrapper = styled.div`
	.option {
		padding: 1rem 0.5rem;
		border-bottom: 1px solid #ebebeb;
	}
	[class*="-menu"] {
		z-index: 111;
	}
	.Polaris-InlineError {
		margin-top: .4rem;
		.Polaris-Icon__Svg {
			color: var(--p-text-critical) !important;
		}
	}
	&.error .advanced-select [class*="-control"] {
		background-color: var(--p-surface-critical-subdued);
    border-color: var(--p-border-critical);
	}
`

const getProducts = async (query) => {
	const source = CancelToken.source()
	const res = await ShopifyAPI.getProducts({query: query}, source.token)
	if (res && res.edges) {
		const productsList = res.edges.map(p => {
			return {
				label: p.node.title,
				value: p.node.id,
				image: p.node.featuredImage?.originalSrc
			}
		})
		return productsList
	}
}

const getCollections = async (query) => {
	const source = CancelToken.source()
	const res = await ShopifyAPI.getCollections({query: query}, source.token)
	if (res && res.edges) {
		const collectionList = res.edges.map(c => {
			return {
				label: c.node.title,
				value: c.node.id,
				image: c.node.image?.originalSrc
			}
		})
		return collectionList
	}
}

const filterResources = (inputVal, resourceType) => {
	if (resourceType === 'product') return getProducts(inputVal)
	if (resourceType === 'collection') return getCollections(inputVal)
}

const promiseOptions = debounce((inputVal, resourceType) => {
	return new Promise(resolve => {
		resolve(filterResources(inputVal, resourceType))
	})
}, 500)

const Option = props => {
	const image = props.data.image ? getImageSizeHardCrop(props.data.image, '100x100') : ''
	return (
		<div className={'option flex items-center'}>
			<Thumbnail size={'small'} source={image} />
			<components.Option {...props} />
		</div>
	)
}

function ResourcePicker(props) {
	const [resourceList, setResourceList] = useState([])
	const {resourceType} = props
	const source = CancelToken.source()

	useEffect(() => {
		fetchInitialData()
		return () => source.cancel()
	}, [resourceType])

	const fetchInitialData = async () => {
		const results = await filterResources('', resourceType)
		results && setResourceList(results)
	}

	let classes = ''
	if (props.error) {
		classes = 'error'
	}

	return (
		<SelectWrapper className={classes}>
			{props.label && !props.labelHidden && (
				<div className="Polaris-Labelled__LabelWrapper">
					<div className="Polaris-Label">
						<label className="Polaris-Label__Text">{props.label}</label>
					</div>
				</div>
			)}
			<AsyncSelect
				className={'advanced-select'}
				instanceId={generateRandomID()}
				{...props}
				cacheOptions
				defaultOptions={resourceList}
				loadOptions={(val) => promiseOptions(val, resourceType)}
				components={{Option}}
				placeholder={props.placeholder || 'Search'}
				isOptionDisabled={() => props.value?.length >= props.maxLength}
			/>
			{props.helpText && (
				<div className="Polaris-Labelled__HelpText">
					{props.helpText}
				</div>
			)}
			{props.error && <InlineError message={`${props.label} must be added`} fieldID={generateRandomID()} />}
		</SelectWrapper>
	)
}

export default ResourcePicker
