import { Link } from '@reach/router';

export default function FoxHomeLogo() {
  return (
    <div className="User-Layout__logo">
      <Link to={'/'}>
        <svg width="212" height="32" viewBox="0 0 212 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_76_1343)">
            <path
              d="M46.81 27.7201H48.74C48.9487 27.7175 49.1482 27.6334 49.2957 27.4858C49.4433 27.3382 49.5274 27.1388 49.53 26.9301V21.1401H59.42C59.7763 21.1427 60.1193 21.0044 60.374 20.7553C60.6288 20.5061 60.7747 20.1664 60.78 19.8101V18.5601H46.78L46.81 27.7201Z"
              fill="#FF5C00"
            />
            <path
              d="M46.81 13.3199V14.5599H60.81C60.986 14.5626 61.1607 14.5305 61.3243 14.4656C61.4879 14.4007 61.6371 14.3042 61.7634 14.1816C61.8897 14.059 61.9906 13.9128 62.0604 13.7512C62.1301 13.5896 62.1674 13.4159 62.17 13.2399V11.9999H48.17C47.994 11.9973 47.8192 12.0293 47.6557 12.0942C47.4921 12.1591 47.3429 12.2556 47.2166 12.3782C47.0903 12.5008 46.9894 12.647 46.9196 12.8086C46.8499 12.9702 46.8126 13.1439 46.81 13.3199Z"
              fill="#FF5C00"
            />
            <path
              d="M78.72 12.0001C76.5883 11.9572 74.5266 12.7617 72.9874 14.2372C71.4482 15.7126 70.5573 17.7385 70.51 19.8701C70.5843 21.9966 71.4812 24.0113 73.0118 25.4894C74.5425 26.9675 76.5872 27.7936 78.715 27.7936C80.8428 27.7936 82.8875 26.9675 84.4182 25.4894C85.9488 24.0113 86.8458 21.9966 86.92 19.8701C86.9032 18.8135 86.6772 17.7706 86.255 16.8018C85.8329 15.8331 85.223 14.9575 84.4605 14.2258C83.6981 13.494 82.7983 12.9206 81.813 12.5386C80.8277 12.1565 79.7764 11.9735 78.72 12.0001ZM78.72 25.1601C77.2805 25.195 75.8858 24.6578 74.8417 23.6662C73.7976 22.6746 73.1893 21.3095 73.15 19.8701C73.2109 18.435 73.8238 17.0788 74.8607 16.0849C75.8977 15.091 77.2786 14.536 78.715 14.536C80.1514 14.536 81.5323 15.091 82.5693 16.0849C83.6062 17.0788 84.2192 18.435 84.28 19.8701C84.2355 21.3042 83.6263 22.6627 82.5849 23.6497C81.5436 24.6368 80.1545 25.1724 78.72 25.1401V25.1601Z"
              fill="#FF5C00"
            />
            <path
              d="M99.21 17.36C99.3164 17.477 99.4461 17.5704 99.5908 17.6341C99.7354 17.6979 99.8919 17.7305 100.05 17.73C100.209 17.7295 100.367 17.6964 100.513 17.6328C100.659 17.5692 100.791 17.4763 100.9 17.36L101.72 16.5L98 12.6C97.8237 12.4103 97.6101 12.2592 97.3727 12.1559C97.1352 12.0527 96.879 11.9996 96.62 12H94.08L99.21 17.37V17.36Z"
              fill="#FF5C00"
            />
            <path
              d="M109.77 12C109.507 11.9993 109.247 12.0532 109.006 12.1582C108.765 12.2632 108.548 12.417 108.37 12.61L93.92 27.72H96.61C96.8708 27.717 97.1283 27.6614 97.3671 27.5566C97.606 27.4518 97.8212 27.2999 98 27.11L103.27 21.6L108.55 27.11C108.728 27.3008 108.943 27.4533 109.182 27.5582C109.421 27.6631 109.679 27.7181 109.94 27.72H112.47L105 19.85L112.47 12H109.77Z"
              fill="#FF5C00"
            />
            <path
              d="M152.05 12.0001C149.918 11.9572 147.857 12.7617 146.317 14.2372C144.778 15.7126 143.887 17.7385 143.84 19.8701C143.914 21.9966 144.811 24.0113 146.342 25.4894C147.872 26.9675 149.917 27.7936 152.045 27.7936C154.173 27.7936 156.218 26.9675 157.748 25.4894C159.279 24.0113 160.176 21.9966 160.25 19.8701C160.233 18.8135 160.007 17.7706 159.585 16.8018C159.163 15.8331 158.553 14.9575 157.791 14.2258C157.028 13.494 156.128 12.9206 155.143 12.5386C154.158 12.1565 153.106 11.9735 152.05 12.0001ZM152.05 25.1601C150.61 25.195 149.216 24.6578 148.172 23.6662C147.128 22.6746 146.519 21.3095 146.48 19.8701C146.541 18.435 147.154 17.0788 148.191 16.0849C149.228 15.091 150.609 14.536 152.045 14.536C153.481 14.536 154.862 15.091 155.899 16.0849C156.936 17.0788 157.549 18.435 157.61 19.8701C157.566 21.3042 156.956 22.6627 155.915 23.6497C154.874 24.6368 153.484 25.1724 152.05 25.1401V25.1601Z"
              fill="black"
            />
            <path
              d="M185.4 12.6499L178.06 24.8999L170.72 12.6399C170.578 12.4332 170.386 12.2654 170.163 12.1518C169.939 12.0383 169.691 11.9826 169.44 11.9899H168V27.6399H169.08C169.465 27.6678 169.845 27.5445 170.14 27.296C170.435 27.0476 170.622 26.6938 170.66 26.3099V17.6299L176.33 26.8999C176.523 27.1678 176.779 27.3838 177.076 27.5286C177.372 27.6734 177.7 27.7426 178.03 27.7299C178.362 27.7443 178.691 27.6759 178.99 27.531C179.288 27.3861 179.546 27.1692 179.74 26.8999L185.41 17.6299V26.3099C185.446 26.6967 185.633 27.0537 185.931 27.3029C186.229 27.5521 186.613 27.6732 187 27.6399H188.09V11.9899H186.66C186.412 11.9879 186.167 12.0471 185.947 12.1622C185.728 12.2774 185.54 12.4448 185.4 12.6499Z"
              fill="black"
            />
            <path
              d="M210.32 19.8101V18.5601H197.19C196.969 18.56 196.757 18.6472 196.6 18.8025C196.442 18.9579 196.353 19.169 196.35 19.3901V21.1401H209C209.349 21.1323 209.682 20.9894 209.928 20.7414C210.175 20.4933 210.315 20.1595 210.32 19.8101Z"
              fill="black"
            />
            <path
              d="M197.21 14.56H210.34C210.517 14.5626 210.692 14.5303 210.857 14.4647C211.021 14.3992 211.171 14.3018 211.297 14.1782C211.423 14.0545 211.524 13.9071 211.593 13.7444C211.662 13.5816 211.699 13.4068 211.7 13.23V12H196.35V13.72C196.351 13.8316 196.375 13.9419 196.419 14.0445C196.462 14.1472 196.526 14.2401 196.606 14.3181C196.686 14.3961 196.78 14.4576 196.884 14.4991C196.988 14.5406 197.098 14.5613 197.21 14.56Z"
              fill="black"
            />
            <path
              d="M210.56 25.14H197.18C196.96 25.14 196.749 25.2275 196.593 25.3831C196.437 25.5388 196.35 25.7499 196.35 25.97V27.72H211.92V26.47C211.919 26.2932 211.882 26.1184 211.813 25.9557C211.744 25.7929 211.643 25.6455 211.517 25.5219C211.391 25.3982 211.241 25.3008 211.077 25.2353C210.912 25.1698 210.737 25.1374 210.56 25.14Z"
              fill="black"
            />
            <path
              d="M134.58 11.9999H133.22V17.6299H124.11V18.8799C124.115 19.2362 124.261 19.576 124.516 19.8251C124.771 20.0743 125.114 20.2126 125.47 20.2099H133.22V26.3999C133.225 26.7562 133.371 27.096 133.626 27.3451C133.881 27.5943 134.224 27.7326 134.58 27.7299H135.94V13.3199C135.937 13.1439 135.9 12.9702 135.83 12.8086C135.761 12.647 135.66 12.5008 135.533 12.3782C135.407 12.2556 135.258 12.1591 135.094 12.0942C134.931 12.0293 134.756 11.9973 134.58 11.9999Z"
              fill="black"
            />
            <path
              d="M120.89 12.0001H122.25V26.4001C122.245 26.7564 122.099 27.0961 121.844 27.3453C121.589 27.5944 121.246 27.7327 120.89 27.7301H119.53V13.2801C119.548 12.9325 119.7 12.6054 119.953 12.3669C120.206 12.1284 120.542 11.997 120.89 12.0001Z"
              fill="black"
            />
            <path d="M17.03 8.01001L20.03 18.03L16.02 24.04L12.02 18.03L15.02 8.01001H17.03Z" fill="#FF5C00" />
            <path d="M27.04 10.02V0L20.03 8.01L23.04 17.03L32.05 15.02L27.04 10.02Z" fill="#FF5C00" />
            <path d="M5.01 10.02V0L12.02 8.01L9.01 17.03L0 15.02L5.01 10.02Z" fill="#FF5C00" />
            <path d="M23.74 21.0399V27.2299H8.59V21.0399L4.32 20.1699L4.28 31.7199H4.32H8.59H23.74H28.01V20.1699L23.74 21.0399Z" fill="#FF5C00" />
          </g>
          <defs>
            <clipPath id="clip0_76_1343">
              <rect width="211.92" height="31.72" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Link>
    </div>
  );
}
