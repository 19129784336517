import InputField from '@components/inputField';
import { useAuthStore } from '@container/Auth';
import UserLayout from '@modules/userLayout';
import { Button, DisplayText, Form, FormLayout, TextContainer, Link, Banner } from '@shopify/polaris';
import React, { useEffect, useRef, useState } from 'react';
import { navigate, useLocation } from '@reach/router';
import { REACT_APP_FOX_KIT_URL } from '@helpers/env';
import { foxHomeAuthService } from '@services/shop';

const ShopifyConnect = () => {
  const [store, setStore] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const { currentUser } = useAuthStore();
  const location = useLocation();
  const ref = useRef();

  const handleSubmit = async () => {
    try {
      setFormError('');
      setLoading(true);

      const regexShopifyDomain = /^[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com$/;
      if (!regexShopifyDomain.test(store)) {
        setLoading(false);
        return setFormError('Invalid domain. Try this format: your-store.myshopify.com');
      }

      const response = await foxHomeAuthService(store);
      const { requiredInstallFoxKit, redirectUrl } = response || {};
      if (!requiredInstallFoxKit && redirectUrl) {
        navigate(redirectUrl);
        return;
      }

      if (requiredInstallFoxKit) {
        ref.current?.submit();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsSignUp(location?.search?.includes('?action=sign-up'));
  }, [location]);

  useEffect(() => {
    if (currentUser) navigate('/');
  }, [currentUser]);

  return (
    <UserLayout title={'Sign in with Shopify via FoxKit'} showQuote>
      <div className="form-container">
        <div className="form-header">
          <FormLayout>
            <TextContainer>
              <DisplayText size={'extraLarge'}>
                <strong>{isSignUp ? 'Sign up with Shopify' : 'Log in with Shopify'}</strong>
              </DisplayText>
              <p className={'Polaris-DisplayText Polaris-DisplayText--sizeSmall Polaris-DisplayText--mobile'}>
                <p>
                  This method requires you to install the{' '}
                  <Link url={'https://foxecom.link/foxkit'} external>
                    FoxKit app
                  </Link>{' '}
                  on your store.
                </p>
              </p>
            </TextContainer>
          </FormLayout>
        </div>

        <div className="form-content">
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <InputField
                value={store}
                label="Store domain"
                onChange={(value) => setStore(value)}
                autoComplete="website"
                placeholder="your-store-domain.myshopify.com"
                autoFocus
                name={'domain'}
                error={formError || undefined}
              />
              <form ref={ref} action={`${REACT_APP_FOX_KIT_URL}/auth`} method="GET">
                <input type="hidden" name="shop" value={store} />
                <input type="hidden" name="source" value={'foxhome'} />
              </form>
              <Button primary loading={loading} submit fullWidth={true} size="large" disabled={!store}>
                <strong>{isSignUp ? 'Sign up with Shopify' : 'Log in with Shopify'}</strong>
              </Button>
              <Banner status={'warning'}>
                <p>By using this method, you agree that you authorize FoxHome to interact with your store through FoxKit permissions.</p>
              </Banner>
              <div className="form-footer">
                <DisplayText size={'small'}>
                  Back to&nbsp;
                  <Link onClick={() => navigate('/login')}>
                    <strong>Log in options</strong>
                  </Link>
                </DisplayText>
              </div>
            </FormLayout>
          </Form>
        </div>
      </div>
    </UserLayout>
  );
};

export default ShopifyConnect;
