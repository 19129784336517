import {
	Layout,
	Card,
	TextField,
	FormLayout,
	Checkbox,
	ContextualSaveBar,
	ChoiceList,
	Tabs,
	PageActions, Select, Badge, Button, InlineError
} from "@shopify/polaris"
import { ContextProvider } from "./container"
import Page from "@modules/page"
import { useStore } from "./container"
import { navigate } from "@reach/router"
import PrizesList from "./prizes"
import { useCallback, useEffect, useState } from "react"
import API from "@helpers/api"
import { showToast } from "@plugins/toast"
import { Skeleton } from "@modules/skeleton"
import { CancelToken } from "@plugins/axios"
import PreviewWheel from "./preview"
import LayoutSection from "@modules/layoutSection"
import ColorPicker from "@components/colorPicker"
import { isEqual, omit } from "lodash"
import DiscardModal from "../../components/discardModal"
import Table from "@modules/table"
import axios from "@plugins/axios";
import { DeleteMinor, PlusMinor } from '@shopify/polaris-icons';
import { showConfirm } from "@plugins/confirm"
import PlatformModal from "../../components/platformModal"
const LuckyWheel = () => {
	return (
		<ContextProvider>
			<LuckyWheelView />
		</ContextProvider>
	)
}

const LuckyWheelView = () => {
	const [error, setError] = useState(false)
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [selected, setSelected] = useState(0)
	const { state, dispatch } = useStore()
	const source = CancelToken.source()
	const { prizes, active, title, description, show_on_mobile, win_title, win_subtitle, button, agree_text, show_agree_checkbox, show_name, play_multiples, auto_apply, save_to, trigger, display_on, delay_show, text_color, popup_bg, button_color, field_color, repeat_open, teaser_activate, teaser_title, teaser_position, teaser_when, win_button, win_note, discount_label, lose_label, coupon_card_bg, show_birthday, platforms, shop } = state
	const handleTabChange = useCallback(
		(selectedTabIndex) => setSelected(selectedTabIndex),
		[]
	)
	const [activeModal, setActiveModal] = useState(false);
	const [prevState, setPrevState] = useState(state)
	const [activeModalIntegration, setActiveModalIntegration] = useState(false);
	const isEqualState = isEqual(
		omit(state, ['updatedAt', 'auto_trigger', 'createdAt', 'deleted', "__v", "_id", 'shop']),
		omit(prevState, ['createdAt', 'updatedAt', 'auto_trigger', 'deleted', "__v", "_id", 'shop'])
	)
	console.log(state, 'default state')

	const handleModalChange = useCallback(() => setActiveModal(!activeModal), [activeModal]);

	useEffect(() => {
		const handleReloadPage = (e) => {
			e.preventDefault()
			e.returnValue = ""
		}
		if (isEqualState === false) window.addEventListener("beforeunload", handleReloadPage)
		return () => window.removeEventListener("beforeunload", handleReloadPage)
	}, [isEqualState])

	useEffect(() => {
		let result
		const fetchData = async () => {
			try {
				result = await API.getList('lucky-wheel', {}, source.token)
				console.log('Data details', result)
				if (result && result.ok) {
					dispatch({ type: 'setData', payload: result.payload })
					if (result.payload) {
						setPrevState({ ...prevState, ...result.payload })
					}
					setReady(true)
				}
			} catch (error) {
				console.log(error)
			}
		}
		fetchData()
		return () => source.cancel()
	}, [])
	const handleSave = async () => {
		setError(!save_to.length && !platforms.length)
		if (!save_to.length && !platforms.length) {
			return showToast({
				message: 'Please choose at least 1 option in the "Data collection"',
				error: true
			})
		}
		setSaving(true)
		API.updateByShop('lucky-wheel', {
			...state
		}).then((res) => {
			if (res.ok) {
				setPrevState({
					...prevState,
					...res.payload,
					prizes: [...prizes]
				})
				return showToast({
					message: 'Updated successfully'
				})
			}
		}).finally(() => setSaving(false))
	}

	const handleChange = (key, value) => {
		dispatch({ type: 'setData', payload: { [key]: value } })
	}

	const handleDelete = (data) => {
		showConfirm({
			title: `Delete this list?`,
			message: 'This can’t be undone.',
			danger: true,
			confirm: 'Delete'
		}).then(res => {
			if (res) {
				const filterPlatforms = platforms.filter(item => item._id !== data._id)
				setError(!save_to.length && !filterPlatforms.length)
				if (!save_to.length && !filterPlatforms.length) {
					return showToast({
						message: 'Please choose at least 1 option in the "Data collection"',
						error: true
					})
				}

				axios.delete(`/api/plugin/integration/lists/${data._id}`)
				const saveData = { ...state, platforms: filterPlatforms }
				API.updateByShop('lucky-wheel', {
					...saveData
				}).then((res) => {
					if (res.ok) {
						dispatch({
							type: 'setData', payload: saveData
						})
						setPrevState({
							...prevState,
							...res.payload,
							prizes: [...prizes]
						})
						return showToast({
							message: 'Updated successfully'
						})
					}
				}).finally(() => setSaving(false))
			}
		})
	}
	const renderChildren = useCallback(
		(isSelected) =>
			isSelected && (
				<TextField
					label="Delay time to show"
					labelHidden
					onChange={v => handleChange('delay_show', Number(v))}
					value={delay_show + ''}
					suffix={'seconds'}
				/>
			),
		[handleChange, delay_show]
	)

	const tabs = [
		{
			id: 'general',
			content: 'General',
			accessibilityLabel: 'general-settings',
			panelID: 'general-content'
		},
		{
			id: 'prizes',
			content: 'Prizes',
			panelID: 'prizes-content'
		},
		{
			id: 'design',
			content: 'Design',
			panelID: 'design-content'
		},
		{
			id: 'display',
			content: 'Display',
			panelID: 'display-content'
		}
	]
	return ready ? (
		<Page
			title="Lucky wheel"
			subtitle={"Lucky wheel adds the power of gamification to your website to dramatically increase visitor engagement and customer loyalty."}
			breadcrumbs={[{ content: 'Boost conversion', onAction: () => isEqualState ? navigate('/boost-conversion') : handleModalChange() }]}
			fullWidth
			hasPreview
		>
			{!isEqualState && <ContextualSaveBar
				saveAction={{
					onAction: handleSave,
					loading: saving,
					disabled: false
				}}
				discardAction={{
					onAction: () => handleModalChange()
				}}
				fullWidth
			/>}
			<Layout>
				<Layout.Section oneThird>
					<Card>
						<Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
							{selected === 0 && (
								<>
									<Card sectioned title={'Visibility'}>
										<FormLayout>
											<Checkbox
												label={'Active'}
												checked={active}
												onChange={v => handleChange('active', v)}
											/>
											<Checkbox
												label={'Show on mobile'}
												checked={show_on_mobile}
												disabled={!active}
												onChange={v => handleChange('show_on_mobile', v)}
											/>
										</FormLayout>
									</Card>
									<Card title={'Form settings'}>
										<Card.Section>
											<FormLayout>
												<TextField
													label={'Title'}
													value={title}
													onChange={v => handleChange('title', v)}
												/>
												<TextField
													multiline={2}
													label={'Subtitle'}
													value={description}
													onChange={v => handleChange('description', v)}
												/>
												<TextField
													label={'Button'}
													value={button}
													onChange={v => handleChange('button', v)}
												/>
												<Checkbox
													label={'Show field name'}
													checked={show_name}
													onChange={v => handleChange('show_name', v)}
												/>
												<Checkbox
													label={'Show field birthday'}
													checked={show_birthday}
													onChange={v => handleChange('show_birthday', v)}
												/>
												<Checkbox
													label={'Show agreement checkbox'}
													checked={show_agree_checkbox}
													onChange={v => handleChange('show_agree_checkbox', v)}
												/>
												{show_agree_checkbox && <TextField
													label={'Agreement text'}
													value={agree_text}
													onChange={v => handleChange('agree_text', v)}
												/>}
											</FormLayout>
										</Card.Section>
									</Card>
									<Card title={'Win screen'}>
										<Card.Section>
											<FormLayout>
												<TextField
													label={'Title'}
													value={win_title}
													onChange={v => handleChange('win_title', v)}
												/>
												<TextField
													label={'Subtitle'}
													value={win_subtitle}
													onChange={v => handleChange('win_subtitle', v)}
												/>
												<Checkbox
													checked={auto_apply}
													onChange={v => handleChange('auto_apply', v)}
													label={'Auto apply coupon on checkout page'}
												/>
											</FormLayout>
										</Card.Section>
										<Card.Section title={'Coupon card'}>
											<FormLayout>
												<TextField
													label={'Discount code label'}
													value={discount_label}
													onChange={v => handleChange('discount_label', v)}
												/>
												<TextField
													label={'Lose label'}
													value={lose_label}
													onChange={v => handleChange('lose_label', v)}
												/>
											</FormLayout>
										</Card.Section>
										<Card.Section>
											<FormLayout>
												<TextField
													label={'Continue button'}
													value={win_button}
													onChange={v => handleChange('win_button', v)}
												/>
												<TextField
													label={'Note'}
													value={win_note}
													onChange={v => handleChange('win_note', v)}
												/>
											</FormLayout>
										</Card.Section>
									</Card>
									<Card sectioned title={'Limitation'}>
										<FormLayout>
											<Checkbox
												checked={play_multiples}
												onChange={v => handleChange('play_multiples', v)}
												label={'Allow play multiple times with the same email?'}
											/>
										</FormLayout>
									</Card>
									<Card title={'Data collection'} sectioned>
										<FormLayout>
											<ChoiceList
												title={'Store subscribers at:'}
												selected={save_to}
												onChange={v => handleChange('save_to', v)}
												allowMultiple
												// disabled
												choices={[
													// { label: "Shopify customers list", value: "shopify_customers" },
													{ label: "FoxKit subscribers list", value: "foxkit_subscribers" }
													// {label: "Mailchimp list", value: "mailchimp", disabled: true},
													// {label: "Klaviyo", value: "klaviyo", disabled: true}
												]}
											/>
											{platforms?.length !== 0 && <Table>
												<thead>
													<tr>
														<th width={'30%'}>Platform</th>
														<th width={'30%'}>Status</th>
														<th>List</th>
														<th />
													</tr>
												</thead>
												<tbody>
													{platforms.map((item, key) => {
														return (
															<tr key={key}>
																<td>
																	<p>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</p>
																</td>
																<td>
																	<Badge status={item.active ? "success" : "critical"}>{item.active ? "Active" : "Not Active"}</Badge>
																</td>
																<td>
																	<p>{item.list_name}</p>
																</td>
																<td>
																	<Button onClick={() => handleDelete(item)} icon={DeleteMinor} />
																</td>
															</tr>
														)
													})}
												</tbody>
											</Table>}
											<Button onClick={() => setActiveModalIntegration(prev => !prev)} icon={PlusMinor}>Add integration</Button>
											{error && <InlineError fieldID={'fdf'} message={'Choose at least one option to for Data Collection'} />}
										</FormLayout>
									</Card>
									{activeModalIntegration && <PlatformModal setActiveModalIntegration={setActiveModalIntegration} useStore={useStore} plugin="luckywheel" link_api="lucky-wheel"/>}
								</>
							)}
							{selected === 1 && <PrizesList />}F
							{selected === 2 && (
								<Card title={'Design lucky wheel'}>
									<Card.Section>
										<FormLayout>
											<ColorPicker
												label={'Text color'}
												value={text_color}
												onChange={v => handleChange('text_color', v)}
												show_input
											/>
											<ColorPicker
												label={'Popup background'}
												value={popup_bg}
												onChange={v => handleChange('popup_bg', v)}
												show_input
											/>
											<ColorPicker
												label={'Field background'}
												value={field_color}
												onChange={v => handleChange('field_color', v)}
												show_input
											/>
											<ColorPicker
												label={'Button color'}
												value={button_color}
												onChange={v => handleChange('button_color', v)}
												show_input
											/>
											<ColorPicker
												label={'Coupon card background'}
												value={coupon_card_bg}
												onChange={v => handleChange('coupon_card_bg', v)}
												show_input
											/>
										</FormLayout>
									</Card.Section>
								</Card>
							)}
							{selected === 3 && (
								<>
									<Card title={'Display settings'}>
										<Card.Section>
											<FormLayout>
												<ChoiceList
													title={'Trigger open automatically'}
													selected={trigger}
													onChange={v => handleChange('trigger', v[0])}
													choices={[
														{ label: "After page loaded", value: "after_page_loaded" },
														{ label: "After specific time", value: "after_specific_time", renderChildren },
														{ label: "After scrolling down", value: "after_scroll" },
														{ label: "User is leaving the website", value: "exit_intent", helpText: "This option is only working on the desktop. On mobile, it will be shown after page loaded." },
														{ label: "Don't open automatically", value: "off", helpText: "Should enable Teaser button" },
													]}
												/>
												<ChoiceList
													title={'Displays on'}
													selected={display_on}
													onChange={v => handleChange('display_on', v[0])}
													choices={[
														{ label: "All pages", value: "all_pages" },
														{ label: "Home page only", value: "home_only" }
													]}
												/>
												<FormLayout.Group>
													<Select
														label={'Repeat open automatically'}
														value={repeat_open}
														onChange={v => handleChange('repeat_open', v)}
														options={[
															{ label: "No repeat", value: "no_repeat" },
															{ label: "Every 3 minutes", value: "every_3_mins" },
															{ label: "Every 5 minutes", value: "every_5_mins" },
															{ label: "Every 10 minutes", value: "every_10_mins" },
															{ label: "Every 15 minutes", value: "every_15_mins" },
															{ label: "Every 30 minutes", value: "every_30_mins" },
															{ label: "Every 1 hour", value: "every_1_hr" },
															{ label: "Every 6 hours", value: "every_6_hrs" },
															{ label: "Every 12 hours", value: "every_12_hrs" },
															{ label: "Every day", value: "every_day" },
															{ label: "Every 3 days", value: "every_3_days" },
															{ label: "Every week", value: "every_week" },
															{ label: "Every 2 weeks", value: "every_2_week" },
															{ label: "Every month", value: "every_month" },
														]}
														helpText={'We\'ll stop automatically showing this popup to customers who have already converted.'}
													/>
													<div />
												</FormLayout.Group>
											</FormLayout>
										</Card.Section>
									</Card>
									<Card title={'Teaser button settings'}>
										<Card.Section>
											<FormLayout>
												<Checkbox
													label={'Show teaser button'}
													checked={teaser_activate}
													onChange={v => handleChange('teaser_activate', v)}
													helpText={'Encourage users to play wheel.'}
												/>
												<ChoiceList
													title={'When to show'}
													selected={teaser_when}
													onChange={v => handleChange('teaser_when', v[0])}
													choices={[
														{ label: "Show after Lucky wheel opened at the 1st time", value: "after" },
														{ label: "Always show", value: "always" }
													]}
												/>
												<ChoiceList
													title={'Position'}
													selected={teaser_position}
													onChange={v => handleChange('teaser_position', v[0])}
													// disabled
													choices={[
														{ label: "Bottom left", value: "bottom_left" },
														{ label: "Bottom right", value: "bottom_right" },
													]}
												/>
												<TextField
													label={'Title'}
													value={teaser_title}
													onChange={v => handleChange('teaser_title', v)}
												/>
											</FormLayout>
										</Card.Section>
									</Card>
								</>
							)}
						</Tabs>
					</Card>
				</Layout.Section>
				<LayoutSection isStuck>
					<Card sectioned title={'Preview'}>
						<PreviewWheel />
					</Card>
					<PageActions
						primaryAction={{
							content: 'Save',
							loading: saving,
							onAction: handleSave

						}}
					/>
				</LayoutSection>
			</Layout>
			<DiscardModal
				activeModal={activeModal}
				handleModalChange={handleModalChange}
				backLink='/boost-conversion'
			/>
		</Page>
	) : <Skeleton />
}

export default LuckyWheel
